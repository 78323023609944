import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClientMessageModel } from '../models/client.message.model';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-client-messages',
  templateUrl: './client-messages.component.html',
  styleUrls: ['./client-messages.component.scss']
})
export class ClientMessagesComponent implements OnInit, OnChanges {
  isLoading = true;
  isError = false;
  messagesList: ClientMessageModel[];
  refreshSpinner = false;
  sendSpinner = false;

  @Input()
  clientId: string;

  @Input()
  clientName: string;

  @ViewChild('inputMessage')
  inputMessage: ElementRef;

  constructor(private http: HttpCommonService, private notificationService: NotificationService,
    private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    if (!this.clientId)
      this.clientId = this.authenticationService.userValue.clientId;

    this.getMessages();
  }

  getMessages() {
    this.refreshSpinner = true;
    if (this.clientId) {
      this.http.getClientMessagesForCpa(this.clientId).subscribe((res: HttpResponse<ClientMessageModel[]>) => {
        if (res.body !== null) {
          this.messagesList = res.body;
        }
        this.isLoading = false;
        this.refreshSpinner = false;
      }, (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.isError = true;
        this.refreshSpinner = false;
        this.notificationService.notifyText("Something went wrong while fetching messages!! Please try after some time.");
      });
    } else {
      this.http.getClientUserMessages().subscribe((res: HttpResponse<ClientMessageModel[]>) => {
        if (res.body !== null) {
          this.messagesList = res.body;
        }
        this.isLoading = false;
        this.refreshSpinner = false;
      }, (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.isError = true;
        this.refreshSpinner = false;
        this.notificationService.notifyText("Something went wrong while fetching messages!! Please try after some time.");
      });
    }
  }

  createMessages() {
    let message = this.inputMessage.nativeElement.innerHTML;
    if (message.trim().length > 0) {
      this.sendSpinner = true;
      if (this.clientId) {
        let messageReq = { clientId: this.clientId, message: message };
        this.http.createClientMessageCpa(messageReq).subscribe((res: HttpResponse<ClientMessageModel[]>) => {
          this.sendSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Message sent successfully.");
            this.inputMessage.nativeElement.innerHTML = '';
            this.getMessages();
          }
          this.refreshSpinner = false;
        }, (err: HttpErrorResponse) => {
          this.sendSpinner = false;
          this.notificationService.notifyText("Unable to send message.");
        });
      } else {
        let messageReq = { message: message };
        this.http.createClientMessage(messageReq).subscribe((res: HttpResponse<ClientMessageModel[]>) => {
          this.sendSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Message sent successfully.");
            this.inputMessage.nativeElement.innerHTML = '';
            this.getMessages();
          }
        }, (err: HttpErrorResponse) => {
          this.sendSpinner = false;
          this.notificationService.notifyText("Unable to send message.");
        });
      }
    } else {
      this.notificationService.notifyText("Message can't be empty.");
    }
  }

  ngOnChanges() {
    if (this.clientId) {
      this.isLoading = true;
      this.getMessages();
    }
  }

  navigate() {
    this.router.navigateByUrl(`/firm/clients/${this.clientId}/services/client-services`);
  }
}
