import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scj-client-invoices',
  templateUrl: './client-invoices.component.html',
  styleUrls: ['./client-invoices.component.scss']
})
export class ClientInvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
