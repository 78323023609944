export const ICONS = [
    {
        name: 'myOrg', path: 'myOrg.svg'
    },
    {
        name: 'filter', path: 'filter.svg'
    },
    {
        name: 'orgTask', path: 'orgTasks.svg'
    },
    {
        name: 'sign', path: 'sign.svg'
    },
    {
        name: 'excel', path: 'excel.svg'
    },
    {
        name: 'word', path: 'ms-word.svg'
    },
    {
        name: 'pdf', path: 'pdf.svg'
    },
    {
        name: 'text', path: 'text.svg'
    },
    {
        name: 'folder', path: 'folder.svg'
    },
    {
        name: 'merge', path: 'merge.svg'
    },
    {
        name: 'payment_done', path: 'payment_done.svg'
    },
    {
        name: 'invoice', path: 'invoice.svg'
    },
    {
        name: 'assign_one', path: 'assign_one.svg'
    },
    {
        name: 'assign_batch', path: 'assign_batch.svg'
    }
];
