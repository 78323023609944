import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/data-service.service';
import { PageModel, PaginationRequestModel, SortModel } from 'src/app/models/pagination-request.model';
import { TableColumnsConfig } from 'src/app/models/table-columns-config.model';
import { HttpCommonService } from 'src/app/services/http-common.service';
import { DATE_DD_MMM_YYYY_FORMAT } from '../../date-formats/date.format';
import { formatMomentDate } from '../../date-formats/format-moment-date';
import { COLUMN_FILTER_TYPE, COLUMN_TYPES } from '../../enums/table.enum';
import { AuthenticationService } from '../../services/authentication.service';
import { HandSetService } from '../../services/hand-set.service';
import { NotificationService } from '../../services/notification.service';
import { PaymentService } from '../../services/payment.service';
import { TableFilterService } from '../../services/table-filter.service';
import { TableToExcelService } from '../../services/table-to-excel.service';
import { Moment } from 'moment';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
  isHandset$: Observable<boolean>;
  displayColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  isFirmUser: boolean;
  selection = new SelectionModel<any>(true, []);
  filterValue: any[] = [];
  isAchConfigured: boolean;
  isPaypalConfigured: boolean;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  @Input()
  data: any[] = [];

  @Input()
  dataLength: number = 0;

  @Input()
  columnConfig: TableColumnsConfig[] = [];

  @Input()
  sortColumn: string = '';

  @Input()
  sortOrder: string = 'desc';

  @Input()
  pageIndex: number = 0;

  @Input()
  pageSize: number = 20;

  @Input()
  pageSizeOptions: number[] = [20, 30, 40, 50];

  @Input()
  exportFileName: string;

  @Input()
  errorMessege: string;

  @Output()
  navigate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectionList: EventEmitter<any[]> = new EventEmitter<any>();

  @Output()
  paymentStatus: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  esignStatus: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  tableDataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DOCUMENT) private document: Document, private handSet: HandSetService, private cdRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService, private notificationService: NotificationService, private http: HttpCommonService,
    private dataService: DataService, private teService: TableToExcelService, private tfService: TableFilterService, private paymentService: PaymentService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.isFirmUser = this.authenticationService.userValue.roles.some((isFirmUser) => ['firmadmin', 'firmemployee'].includes(isFirmUser));
    this.isAchConfigured = this.paymentService.isAchConfigured.value;
    this.isPaypalConfigured = this.paymentService.isPaypalConfigured.value;
  }


  ngOnInit(): void {
    this.columnConfig.forEach((col: TableColumnsConfig) => {
      this.displayColumns.push(col.name);
    });

    // this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((filterVal: string) => {
    //   this.tfService.setSearchValue(filterVal.trim().toLowerCase());
    // });

    this.dataSource.data = this.data;

    // this.dataSource.sortingDataAccessor = (data: any, property: string): string | number => {
    //   let type: COLUMN_TYPES | string = COLUMN_TYPES.STRING;
    //   this.columnConfig.forEach((col: TableColumnsConfig) => {
    //     if (col.name === property) type = col.type;
    //   });
    //   switch (type) {
    //     case COLUMN_TYPES.DATE:
    //       return new Date(data[property]).getTime();
    //     case COLUMN_TYPES.DATE_TIME:
    //       return new Date(data[property + 'Time']).getTime();
    //     case COLUMN_TYPES.NUMBER:
    //       return data[property];
    //     default:
    //       return data[property]?.toString().toLowerCase();
    //   }
    // };

    this.filterValue = [];

    this.columnConfig.forEach((e: TableColumnsConfig) => {
      if (e?.type) {
        this.filterValue.push({ name: e.name, label: e.header, type: e.filterType, value: this.tfService.filterJson[e.name] });
      }
    });

  }

  ngAfterViewInit() {
    //this.dataSource.data = this.data;
    // this.isHandset$.subscribe((handSet: boolean) => {
    //   if (handSet) {
    //     this.dataSource.paginator = null;
    //   } else this.dataSource.paginator = this.paginator || null;
    // });

    if (this.sortColumn) {
      this.sort.sort({ id: this.sortColumn, start: this.sortOrder, disableClear: false } as MatSortable);
      this.sort.active = this.sortColumn;
      this.sort.disableClear = true;
    }

    this.dataSource.sort = this.sort;
    this.paginator.pageIndex = this.pageIndex;
    this.cdRef.detectChanges();

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.tfService.tableFilter$.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.tfService.tableFilter$)
      .pipe(map(() => {
        this.selection.clear();
        this.selectionList.emit([]);
        let page: PageModel = { index: this.paginator.pageIndex + 1, size: this.paginator.pageSize } as PageModel;
        let sort: SortModel = { column: this.sort.active, order: this.sort.direction } as SortModel;
        let filter = this.formatDateFilter({ ...this.tfService.filterJson });
        //this.formatDateFilter(filter);
        let paginationData: PaginationRequestModel = { page: page, sort: sort, filter: filter } as PaginationRequestModel;
        return paginationData;
      })
      )
      .subscribe(data => this.tableDataChange.emit(data));
  }

  formatDateFilter(filter: any) {
    filter = JSON.parse(JSON.stringify(filter));
    this.columnConfig.forEach((e: TableColumnsConfig) => {
      if (e.filterType === COLUMN_FILTER_TYPE.DATE) {
        let startDate = filter[e.name].startDate;
        let endDate = filter[e.name].endDate;
        filter[e.name].startDate = !!startDate ? formatMomentDate(moment(startDate as Moment)) : null;
        filter[e.name].endDate = !!endDate ? formatMomentDate(moment(endDate as Moment)) : null;
      }
    });
    return filter;
  }

  ngOnChanges(): void {
    this.dataSource.data = this.data;
    this.selection.clear();
    this.selectionList.emit([]);
    //this.paginator.pageIndex = this.pageIndex;
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.filteredData.length;
  }

  exportToExcel() {
    this.teService.exportToExcel(this.exportFileName, this.columnConfig, this.selection.selected);
  }

  clearSelection() {
    this.selection.clear();
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectionList.emit(this.selection.selected);
      return;
    } else {
      this.selection.select(...this.dataSource.filteredData)//(...this.dataSource.connect().value);
      this.selectionList.emit(this.selection.selected);
    }
  }

  toggleSelection(element: any) {
    this.selection.toggle(element);
    this.selectionList.emit(this.selection.selected);
  }

  clearFilter(col: string) {
    this.tfService.clearFilter(col);
  }

  cellselected(element: any) {
    this.navigate.emit(element);
  }

  markAsPaid(payment: any, invoiceId: string, invoiceNum: number) {
    if (payment.status === 'SUCESS') {
      this.notificationService.notifyText(`Paypal payment is successful for invoice id ${invoiceNum}`);
      let data = {
        invoiceId: invoiceId,
        paymentReference: JSON.stringify(payment),
      };
      this.http.updateMarkAsPaid(data).subscribe(
        (res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.paymentStatus.emit(true);
            setTimeout(() => {
              this.notificationService.notifyText(`Invoice payment status updated successfully`);
            }, 3000);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.notificationService.notifyText(`Invoice payment status updated failed. Please contact CPA`);
        }
      );
    } else {
      this.notificationService.notifyText(`Payment failed for invoice id ${invoiceId}`);
    }
  }

  markAsPaidAch(status: string) {
    if (status === 'SUCCESS') {
      this.paymentStatus.emit(true);
    }
  }

  esign(record: any) {
    let element = {
      id: record.esignAttachmentId,
      signatureRequired: record.signatureRequired,
      spouseSignatureRequired: record.spouseSignatureRequired,
      cpaSignatureRequired: record.cpaSignatureRequired,
      isSigned: record.isSigned,
      isSpouseSigned: record.isSpouseSigned,
      isCpaSigned: record.isCpaSigned,
    };
    this.dataService.esign(element).subscribe((reload: boolean) => {
      if (reload) {
        this.esignStatus.emit(true);
      }
    });
  }
}
