import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  exlcudeUrls: string[] = ['login', 'user/activate', 'resend-otp'];
  isApiUrl = false;

  constructor(private authenticationService: AuthenticationService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = this.authenticationService.userValue;
    const isLoggedIn = user && user.jwtToken;
    this.exlcudeUrls.forEach((url: string) => {
      this.isApiUrl = request.url.includes(url);
    });
    if (isLoggedIn && !this.isApiUrl) {
      request = this.addToken(request, user.jwtToken);
    }

    return next.handle(request);
  }

  addToken(request: HttpRequest<any>, jwtToken: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
  }
}
