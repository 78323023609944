export enum COLUMN_TYPES {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    DATE = 'date',
    ACTION = 'any',
    DATE_TIME = 'dateTime'
}

export enum COLUMN_FILTER_TYPE {
    STRING = 'string',
    SELECT = 'select',
    NUMBER = 'number',
    DATE = 'date',
  
}

export enum COLUMN_FORMAT {
    CURRENCY = 'currency'
}

export enum STRING_COLUMN_FILTER_CONDITIONS {
    CONTAINS = 'Contains',
    STARTS_WITH = 'Starts with',
    ENDS_WITH = 'Ends with',
    EQUALS = 'Equals',
    //NULL = 'Null'
}

export enum NUMBER_COLUMN_FILTER_CONDITIONS {
    LESS_THAN_OR_EQUAL = '<=',
    GREATER_THAN_OR_EQUAL = '>=',
    EQUALS = '=',
    RANGE = '>=<='
    //NULL = 'Null'
}

export enum PRICE_COLUMN_FILTER_CONDITIONS {
    RANGE = 'priceRange'
    //NULL = 'Null'
}

export enum BOOLEAN_COLUMN_FILTER_CONDITIONS {
    TRUE = 'True',
    FALSE = 'False',
    //NULL = 'Null'
}

export enum DATE_COLUMN_FILTER_CONDITIONS {
    BEFORE = 'Before',
    AFTER = 'After',
    ON = 'On',
    BETWEEN = 'BETWEEN'
    //RANGE = 'Range',
    //NULL = 'Null'
}