<div class="multiline-tooltip-wrapper">
    <h2 class="scj-h2">{{client?.businessName}}</h2>
    <div class="multiline-tooltip">
      
        <div class="tooltip-content">
            <mat-icon>badge_outline</mat-icon> {{client?.accountNumber}}
        </div>
        <div class="tooltip-content">
            <mat-icon>email_outline</mat-icon> {{client?.businessEmail}}
        </div>
        <div class="tooltip-content">
            <mat-icon>phone_outline</mat-icon> {{client?.businessPhonenumber}}
        </div>
        <div class="tooltip-content">
            <mat-icon>location_on_outline</mat-icon> {{client?.clientAddress?.city}}
        </div>
        
    </div>
</div>

<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError" [style.padding]="'48px'">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="tab-links">
            <nav mat-tab-nav-bar mat-align-tabs="center">
                <a mat-tab-link *ngFor="let link of clientLinks" [routerLink]="link.path"
                    [active]="router.url.indexOf(link.path) !== -1">
                    <!-- <span *ngIf="(link.path === 'client-messages') && messageCount>0"
                        class="notify-message">{{messageCount}}</span> -->
                    {{link.label}}
                </a>
            </nav>

            <div class="nav-router firm">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<div class="chat-fab" cdkDrag (cdkDragStarted)="handleDragStart($event)">
    <span *ngIf="messageCount>0" class="notify-message">{{messageCount}}</span>
    <button mat-fab (click)="openChat($event)">
        <mat-icon>chat</mat-icon>
    </button>
</div>