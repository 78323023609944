import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { domSafeEnum } from '../enums/dom-safe.enum';

@Pipe({
  name: 'domSafe'
})
export class DomSafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, safeType: domSafeEnum | string = domSafeEnum.SAFE_HTML): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (safeType) {
      case domSafeEnum.SAFE_HTML: return this.sanitizer.bypassSecurityTrustHtml(value);
      case domSafeEnum.SAFE_STYLE: return this.sanitizer.bypassSecurityTrustStyle(value);
      case domSafeEnum.SAFE_SCRIPT: return this.sanitizer.bypassSecurityTrustScript(value);
      case domSafeEnum.SAFE_URL: return this.sanitizer.bypassSecurityTrustUrl(value);
      case domSafeEnum.SAFE_RESOURCE_URL: return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid dom safe type specified: ${safeType}`);
    }
  }

}
