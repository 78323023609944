import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scjClickElementOutside]'
})
export class ClickElementOutsideDirective {
  @Output('onClickOutside') onClickOutside = new EventEmitter<any>();

  constructor(private eref: ElementRef) { }

  @HostListener('document:click', ['$event', '$event.target'])
  onDocumentClicked(event: MouseEvent, targetElement: HTMLElement) {
    if (targetElement && document.body.contains(targetElement) && !this.eref.nativeElement.contains(targetElement)) {
      this.onClickOutside.emit({ action: event, element: targetElement });
    }
  }

}
