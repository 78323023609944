<div class="flex-row">
    <h2 class="scj-h2">
        E-Mails
    </h2>
</div>

<div class="mail-router">
    <nav mat-tab-nav-bar mat-align-tabs="center">
        <a mat-tab-link *ngFor="let link of mailLinks" [routerLink]="link.path" (click)="activeLink = link.path"
            [active]="router.url.indexOf(link.path) !== -1">
            {{link.label}}
        </a>
    </nav>

    <div class="nav-router">
        <router-outlet></router-outlet>
    </div>
</div>