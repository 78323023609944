import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChildren, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { FormGroup, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { FormGroupService } from '../shared/services/form-group.service';
import { TabsModel } from '../models/tabs.model';
import { TemplateModel } from '../models/template.model';
import { MatStepper } from '@angular/material/stepper';
import { SectionsModel } from '../models/sections.model';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-template-loader',
  templateUrl: './template-loader.component.html',
  styleUrls: ['./template-loader.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true, displayDefaultIndicatorType: false } }
  ]
})
export class TemplateLoaderComponent implements OnInit, OnChanges, AfterViewInit {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  selectedIndex = 0;
  matStepperIcons: any[];

  @Input()
  template: TemplateModel;

  @Input()
  templateId: string;

  @Input()
  tabs: TabsModel[] = [];

  @Input()
  tabsFormGroup: FormGroup;

  @Input()
  allowSave: boolean = true;

  @Input()
  disableTemplate: boolean = false;

  @Output()
  onSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren('stepperIcon')
  private matStepperIconViewChildren: any;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private cdRef: ChangeDetectorRef, private fgService: FormGroupService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.tabsFormGroup) {
      this.isLoading = false;
    }

    if (this.template) {
      this.tabs = this.template.tabs;
      this.tabsFormGroup = this.fgService.toFormGroupByTabs(this.tabs);
      this.isLoading = false;
    }

    if (this.templateId !== undefined && this.templateId !== null && this.templateId.length > 0) {
      this.isLoading = true;
      this.httpService.getTemplateById(this.templateId).subscribe((res: HttpResponse<TemplateModel>) => {
        if (res.body !== null) {
          this.template = res.body;
          this.tabs = this.template.tabs;
          this.tabsFormGroup = this.fgService.toFormGroupByTabs(this.tabs);
          if (this.disableTemplate) {
            this.tabsFormGroup.disable();
          }
          this.isLoading = false;
        }
      }, (err: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        console.log("Error", err);
      });
    }
  }

  ngAfterViewInit(): void {
    this.matStepperIcons = this.matStepperIconViewChildren.toArray();
    this.cdRef.detectChanges();
  }

  getFormGroup(group: FormGroup) {
    return group as FormGroup;
  }

  previousStep(stepper: MatStepper) {
    //this.markAsTouched(stepper);
    this.save(stepper);
    stepper.previous();
  }

  nextStep(stepper: MatStepper) {
    //this.markAsTouched(stepper);
    this.save(stepper);
    stepper.next();
  }

  save(stepper: MatStepper, forceSave: boolean = false) {
    this.markAsTouched(stepper);
    let stepperForm: FormGroup = this.tabsFormGroup.controls[this.tabs[stepper.selectedIndex].name] as FormGroup;
    if (this.allowSave && stepperForm.dirty) {
      this.onSave.emit(true);
      stepperForm.markAsPristine();
    } else if (this.allowSave && forceSave) {
      this.onSave.emit(true);
    } else {
      this.onSave.emit(false);
    }
  }

  markAsTouched(stepper: MatStepper) {
    // this.tabsFormGroup.controls[this.tabs[stepper.selectedIndex].name].markAsPristine();
    this.tabsFormGroup.controls[this.tabs[stepper.selectedIndex].name].markAllAsTouched();
  }

}
