import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NewWorkFlowModel } from '../models/new-workflow.model';
import { WorkFlowListModel } from '../models/work-flow-list.model';
import { FirmListModel } from '../models/firm-list.model';
import { NewFirmModel } from '../models/new-firm.model';
import { TemplateListModel } from '../models/template-list.model';
import { TemplateModel } from '../models/template.model';
import { UpdateWorkFlowModel } from '../models/update-workflow.model';
import { FirmWorkflowListModel } from '../models/firm-workflow-list.model';
import { AgreementsListModel } from '../models/agreements-list.model';
import { NewAssignWorkflowModel } from '../models/new-assign-workflow.model';
import { UpdateAssignWorkflowModel } from '../models/update-assign-workflow.model';
import { UnassignedWorkFlowModel } from '../models/unassigned-workflow.model';
import { NewAgreementModel } from '../models/new-agreement.model';
import { UsersListModel } from '../models/users-list.model';
import { NewUserModel } from '../models/new-user.model';
import { RoleModel } from '../models/role.model';
import { ManageFirmModel } from '../models/manage-firm.model';
import { ServicesListModel } from '../models/services-list.model';
import { NewServiceModel } from '../models/new-service.model';
import { ClientsListModel } from '../models/client-list.model';
import { NewClientModel } from '../models/new-client.model';
import { ClientContactsModel } from '../models/client-contacts.model';
import { ClientSubscriptionsListModel } from '../models/client-subscription-list.model';
import { ClientSubscriptionModel } from '../models/client-subscription.model';
import { ClientSubscriptionAttachmentsListModel } from '../models/client-subscription-attachments-list.model';
import { AllSubscriptionsModel } from '../models/all-subscriptions.model';
import { MailsModel } from '../models/mails.model';
import { MailContentModel } from '../models/mail-content.model';
import { SubscriptionAttachmentsModel } from '../models/subscription-attachments.model';
import { BillingModel } from '../models/billing.model';
import { BillingListModel } from '../models/billing-list.model';
import { InvoiceDetailsModel } from '../models/invoice-details.model';
import { BillingScheduleModel } from '../models/billing-schedule.model';
import { ClientMessageModel } from '../models/client.message.model';
import { FirmMessageModel } from '../models/firm.message.model';
import { EsignTemplateModel } from '../models/esign-template.model';
import { CompanyDetailsModel } from '../models/company-details.model';
import { EmailSync } from '../models/email-sync.model';
import { Finance } from '../models/finance.model';
import { PaginationModel } from '../models/pagination.model';
import { PaginationRequestModel } from '../models/pagination-request.model';
import { SubscriptionNotesModel } from '../models/subscription-notes.model';

@Injectable({
  providedIn: 'root'
})
export class HttpCommonService {

  private BASE_URL = environment.API_SERVER_URL;

  constructor(private http: HttpClient) { }

  getWorkflowsList(): Observable<HttpResponse<WorkFlowListModel[]>> {
    return this.http.get<WorkFlowListModel[]>(this.BASE_URL + '/api/v1/workflow/list', { observe: 'response' });
  }

  getWorkflowsListByLoggedInUser(): Observable<HttpResponse<WorkFlowListModel[]>> {
    return this.http.get<WorkFlowListModel[]>(this.BASE_URL + '/api/v1/workflow/firm/list', { observe: 'response' });
  }

  getFirmsList(): Observable<HttpResponse<FirmListModel[]>> {
    return this.http.get<FirmListModel[]>(this.BASE_URL + '/api/v1/company/list', { observe: 'response' });
  }

  getFirmWorkflowsList(id: string): Observable<HttpResponse<FirmWorkflowListModel[]>> {
    return this.http.get<FirmWorkflowListModel[]>(this.BASE_URL + `/api/v1/companyworkflow/list/${id}`, { observe: 'response' });
  }

  createfirmWorkflow(firmWorkflow: NewAssignWorkflowModel): Observable<HttpResponse<NewAssignWorkflowModel>> {
    return this.http.post<NewAssignWorkflowModel>(this.BASE_URL + `/api/v1/companyworkflow/create`, firmWorkflow, { observe: 'response' });
  }

  getFirmWorkflowById(id: string): Observable<HttpResponse<NewAssignWorkflowModel>> {
    return this.http.get<NewAssignWorkflowModel>(this.BASE_URL + `/api/v1/companyworkflow/${id}`, { observe: 'response' });
  }

  updateFirmWorkflow(id: string, firmWorkflow: UpdateAssignWorkflowModel): Observable<HttpResponse<NewAssignWorkflowModel>> {
    return this.http.put<NewAssignWorkflowModel>(this.BASE_URL + `/api/v1/companyworkflow/update/${id}`, firmWorkflow, { observe: 'response' });
  }

  createFirm(firm: NewFirmModel): Observable<HttpResponse<NewFirmModel>> {
    return this.http.post<NewFirmModel>(this.BASE_URL + '/api/v1/company/create', firm, { observe: 'response' });
  }

  updateFirm(id: string, firm: NewFirmModel): Observable<HttpResponse<NewFirmModel>> {
    return this.http.put<NewFirmModel>(this.BASE_URL + `/api/v1/company/update/${id}`, firm, { observe: 'response' });
  }

  getFirmById(id: string): Observable<HttpResponse<NewFirmModel>> {
    return this.http.get<NewFirmModel>(this.BASE_URL + `/api/v1/company/${id}`, { observe: 'response' });
  }

  createWorkflow(workflow: NewWorkFlowModel): Observable<HttpResponse<NewWorkFlowModel>> {
    return this.http.post<NewWorkFlowModel>(this.BASE_URL + '/api/v1/workflow/create', workflow, { observe: 'response' });
  }

  updateWorkflow(id: string, workflow: UpdateWorkFlowModel): Observable<HttpResponse<NewWorkFlowModel>> {
    return this.http.put<NewWorkFlowModel>(this.BASE_URL + `/api/v1/workflow/update/${id}`, workflow, { observe: 'response' });
  }

  getWorkflowById(id: string): Observable<HttpResponse<NewWorkFlowModel>> {
    return this.http.get<NewWorkFlowModel>(this.BASE_URL + `/api/v1/workflow/${id}`, { observe: 'response' });
  }

  getUnassignedWorkflows(id: string): Observable<HttpResponse<UnassignedWorkFlowModel[]>> {
    return this.http.get<UnassignedWorkFlowModel[]>(this.BASE_URL + `/api/v1/workflow/unassigned/${id}`, { observe: 'response' });
  }

  getTemplateList(): Observable<HttpResponse<TemplateListModel[]>> {
    return this.http.post<TemplateListModel[]>(this.BASE_URL + '/api/v1/template/list', {}, { observe: 'response' });
  }

  getTemplateById(id: string): Observable<HttpResponse<TemplateModel>> {
    return this.http.get<TemplateModel>(this.BASE_URL + `/api/v1/template/${id}`, { observe: 'response' });
  }

  getAgreementsList(): Observable<HttpResponse<AgreementsListModel[]>> {
    return this.http.get<AgreementsListModel[]>(this.BASE_URL + '/api/v1/agreement/list', { observe: 'response' });
  }

  createAgreement(agreement: NewAgreementModel): Observable<HttpResponse<NewAgreementModel>> {
    return this.http.post<NewAgreementModel>(this.BASE_URL + '/api/v1/agreement/create', agreement, { observe: 'response' });
  }

  updateAgreement(id: string, agreement: NewAgreementModel): Observable<HttpResponse<NewAgreementModel>> {
    return this.http.put<NewAgreementModel>(this.BASE_URL + `/api/v1/agreement/update/${id}`, agreement, { observe: 'response' });
  }

  getAgreementById(id: string): Observable<HttpResponse<NewAgreementModel>> {
    return this.http.get<NewAgreementModel>(this.BASE_URL + `/api/v1/agreement/${id}`, { observe: 'response' });
  }

  getUsersList(): Observable<HttpResponse<UsersListModel[]>> {
    return this.http.get<UsersListModel[]>(this.BASE_URL + '/api/v1/user/list', { observe: 'response' });
  }

  createUser(agreement: NewUserModel): Observable<HttpResponse<NewUserModel>> {
    return this.http.post<NewUserModel>(this.BASE_URL + '/api/v1/user/create', agreement, { observe: 'response' });
  }

  updateUser(id: string, agreement: NewUserModel): Observable<HttpResponse<NewUserModel>> {
    return this.http.put<NewUserModel>(this.BASE_URL + `/api/v1/user/update/${id}`, agreement, { observe: 'response' });
  }

  sendUserActivationMail(idArr: string | string[]): Observable<HttpResponse<any>> {
    let ids: string;
    if (typeof idArr === 'string') {
      ids = idArr;
    } else {
      ids = idArr.join(",");
    }
    return this.http.patch<any>(this.BASE_URL + `/api/v1/user/activate?userIds=${ids}`, {}, { observe: 'response' });
  }

  sendClientActivationMail(idArr: string | string[]) {
    let ids: string;
    if (typeof idArr === 'string') {
      ids = idArr;
    } else {
      ids = idArr.join(",");
    }
    return this.http.put<any>(this.BASE_URL + `/api/v1/client/resend-activation-email?clientIds=${ids}`, {}, { observe: 'response' });
  }

  getUserById(id: string): Observable<HttpResponse<NewUserModel>> {
    return this.http.get<NewUserModel>(this.BASE_URL + `/api/v1/user/${id}`, { observe: 'response' });
  }

  getRolesList(): Observable<HttpResponse<RoleModel[]>> {
    return this.http.get<RoleModel[]>(this.BASE_URL + '/api/v1/role/list', { observe: 'response' });
  }

  getCompanyUserRolesList(): Observable<HttpResponse<RoleModel[]>> {
    return this.http.get<RoleModel[]>(this.BASE_URL + '/api/v1/company/user/role/list', { observe: 'response' });
  }

  updateManageFirm(firm: ManageFirmModel): Observable<HttpResponse<ManageFirmModel>> {
    return this.http.put<ManageFirmModel>(this.BASE_URL + `/api/v1/company/manage`, firm, { observe: 'response' });
  }

  getManageFirm(): Observable<HttpResponse<ManageFirmModel>> {
    return this.http.get<ManageFirmModel>(this.BASE_URL + `/api/v1/company/manage`, { observe: 'response' });
  }

  getServicesList(): Observable<HttpResponse<ServicesListModel[]>> {
    return this.http.get<ServicesListModel[]>(this.BASE_URL + '/api/v1/service/list', { observe: 'response' });
  }

  generateSubscriptionDataReport(serviceId: string) {
    return this.http.get(this.BASE_URL + '/api/v1/service/' + serviceId + '/report', { observe: 'response' })
  }

  deletePendingClient(idArr: string | string[]) {
    let ids: string;
    if (typeof idArr === 'string') {
      ids = idArr;
    } else {
      ids = idArr.join(",");
    }
    return this.http.delete(this.BASE_URL + `/api/v1/client?clientIds=${ids}`, { observe: 'response' });
  }

  deletePendingUser(idArr: string | string[]) {
    let ids: string;
    if (typeof idArr === 'string') {
      ids = idArr;
    } else {
      ids = idArr.join(",");
    }
    return this.http.delete(this.BASE_URL + `/api/v1/user?userIds=${ids}`, { observe: 'response' });
  }

  deleteService(ids: string[]) {
    return this.http.delete(this.BASE_URL + `/api/v1/service?serviceIds=${ids}`, { observe: 'response' });
  }

  getAvailableServicesList(): Observable<HttpResponse<ServicesListModel[]>> {
    return this.http.get<ServicesListModel[]>(this.BASE_URL + '/api/v1/service/list/available', { observe: 'response' });
  }

  createService(service: NewServiceModel): Observable<HttpResponse<NewServiceModel>> {
    return this.http.post<NewServiceModel>(this.BASE_URL + '/api/v1/service/create', service, { observe: 'response' });
  }

  updateService(id: string, service: NewServiceModel): Observable<HttpResponse<NewServiceModel>> {
    return this.http.put<NewServiceModel>(this.BASE_URL + `/api/v1/service/update/${id}`, service, { observe: 'response' });
  }

  getServiceById(id: string): Observable<HttpResponse<NewServiceModel>> {
    return this.http.get<NewServiceModel>(this.BASE_URL + `/api/v1/service/${id}`, { observe: 'response' });
  }

  getClientsList(): Observable<HttpResponse<ClientsListModel[]>> {
    return this.http.get<ClientsListModel[]>(this.BASE_URL + '/api/v1/client/list', { observe: 'response' });
  }

  createClient(client: NewClientModel): Observable<HttpResponse<NewClientModel>> {
    return this.http.post<NewClientModel>(this.BASE_URL + '/api/v1/client/create', client, { observe: 'response' });
  }

  updateClient(id: string, clientData: any): Observable<HttpResponse<ClientContactsModel>> {
    return this.http.put<ClientContactsModel>(this.BASE_URL + `/api/v1/client/${id}`, clientData, { observe: 'response' });
  }

  updateClientOrg(id: string, clientData: any): Observable<HttpResponse<ClientContactsModel>> {
    return this.http.put<ClientContactsModel>(this.BASE_URL + `/api/v1/client/update/org/${id}`, clientData, { observe: 'response' });
  }

  getClientById(id: string): Observable<HttpResponse<ClientContactsModel>> {
    return this.http.get<ClientContactsModel>(this.BASE_URL + `/api/v1/client/${id}`, { observe: 'response' });
  }

  createClientSubscription(subscription: any): Observable<any> {
    return this.http.post<any>(this.BASE_URL + '/api/v1/client/subscription/create', subscription, { responseType: 'text' as 'json' });
  }

  getClientSubscriptionsList(): Observable<HttpResponse<ClientSubscriptionsListModel[]>> {
    return this.http.get<ClientSubscriptionsListModel[]>(this.BASE_URL + '/api/v1/client/subscription/list/active', { observe: 'response' });
  }

  getClientSubscriptionsListByClientId(id: string): Observable<HttpResponse<ClientSubscriptionsListModel[]>> {
    return this.http.get<ClientSubscriptionsListModel[]>(this.BASE_URL + `/api/v1/client/subscription/list/all/${id}`, { observe: 'response' });
  }

  getClientSubscriptionById(id: string): Observable<HttpResponse<ClientSubscriptionModel>> {
    return this.http.get<ClientSubscriptionModel>(this.BASE_URL + `/api/v1/client/subscription/${id}`, { observe: 'response' });
  }

  saveClientSubscription(id: string, data: string): Observable<HttpResponse<ClientSubscriptionModel>> {
    return this.http.post<ClientSubscriptionModel>(this.BASE_URL + `/api/v1/client/subscription/save/${id}`, data, { observe: 'response' });
  }

  submitClientSubscription(id: string, data: any): Observable<HttpResponse<ClientSubscriptionModel>> {
    return this.http.post<ClientSubscriptionModel>(this.BASE_URL + `/api/v1/client/subscription/submit/${id}`, data, { observe: 'response' });
  }

  getClientAttachmentListBySubscriptionId(id: string): Observable<HttpResponse<ClientSubscriptionAttachmentsListModel[]>> {
    return this.http.get<ClientSubscriptionAttachmentsListModel[]>(this.BASE_URL + `/api/v1/client/subscription/list/attachment/${id}`, { observe: 'response' });
  }

  getMySubscriptions(): Observable<HttpResponse<AllSubscriptionsModel[]>> {
    return this.http.get<AllSubscriptionsModel[]>(this.BASE_URL + '/api/v1/client/subscription/my', { observe: 'response' });
  }

  getAllSubscriptions(): Observable<HttpResponse<AllSubscriptionsModel[]>> {
    return this.http.get<AllSubscriptionsModel[]>(this.BASE_URL + '/api/v1/client/subscription/all', { observe: 'response' });
  }

  getInboxMailsByClientId(id: string): Observable<HttpResponse<MailsModel[]>> {
    return this.http.get<MailsModel[]>(this.BASE_URL + `/api/v1/client/email/received/${id}`, { observe: 'response' });
  }

  getInboxMailContent(reqParams: HttpParams): Observable<HttpResponse<MailContentModel>> {
    return this.http.get<MailContentModel>(this.BASE_URL + `/api/v1/client/email/received/content`, { params: reqParams, observe: 'response' });
  }

  getOutboxMailsByClientId(id: string): Observable<HttpResponse<MailsModel[]>> {
    return this.http.get<MailsModel[]>(this.BASE_URL + `/api/v1/client/email/sent/${id}`, { observe: 'response' });
  }

  getOutboxMailContent(reqParams: HttpParams): Observable<HttpResponse<MailContentModel>> {
    return this.http.get<MailContentModel>(this.BASE_URL + `/api/v1/client/email/sent/content`, { params: reqParams, observe: 'response' });
  }

  getFirmSupportEmail(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/company/supportemail`, { responseType: 'text' as 'json' });
  }

  updateUserPassword(passwords: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/user/profile/password`, passwords, { observe: 'response' });
  }

  updateInboxApiKey(inboxApiKey: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/user/apikey`, inboxApiKey, { observe: 'response' });
  }

  getAllAttachmentByClientId(id: string): Observable<HttpResponse<SubscriptionAttachmentsModel[]>> {
    return this.http.get<SubscriptionAttachmentsModel[]>(this.BASE_URL + `/api/v1/client/subscription/list/all/attachment/${id}`, { observe: 'response' });
  }

  getAllAttachmentForClient(): Observable<HttpResponse<SubscriptionAttachmentsModel[]>> {
    return this.http.get<SubscriptionAttachmentsModel[]>(this.BASE_URL + `/api/v1/client/subscription/list/all/attachment`, { observe: 'response' });
  }

  updateAttachmentComment(comments: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/client/subscription/attachment/comment`, comments, { observe: 'response' });
  }

  createBilling(billing: BillingModel): Observable<HttpResponse<BillingModel>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/client/subscription/billing/create`, billing, { observe: 'response' });
  }

  deleteBilling(id: string) {
    return this.http.delete(this.BASE_URL + `/api/v1/client/subscription/billing/${id}`, { observe: 'response' });
  }

  getBillingListByClientId(id: string, isFuture: boolean): Observable<HttpResponse<BillingListModel[]>> {
    return this.http.get<BillingListModel[]>(this.BASE_URL + `/api/v1/client/subscription/billing/client/${id}/?isFuture=${isFuture}`, { observe: 'response' });
  }

  getBillingScheduleDetailsForScheduleId(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/subscription/billing/schedule/${id}`, { observe: 'response' });
  }

  updateBillingPayment(billingData: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/client/subscription/billing/invoice/payment`, billingData, { observe: 'response' });
  }

  viewInvoiceByInvoiceId1(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/subscription/billing/invoice/download/${id}`, { observe: 'response' });
  }


  viewInvoiceByInvoiceId(id: string): Observable<Blob> {
    return this.http.get<Blob>(this.BASE_URL + `/api/v1/client/subscription/billing/invoice/download/${id}`, { observe: 'body', responseType: 'blob' as 'json' })
  }

  downloadAllSubsciptionsReport(): Observable<Blob> {
    return this.http.get<Blob>(this.BASE_URL + `/api/v1/client/subscription/report`, { observe: 'body', responseType: 'blob' as 'json' })
  }


  updateMarkAsPaid(paymentData: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/client/subscription/billing/invoice/paid`, paymentData, { observe: 'response' });
  }

  getInvoiceDueDetailsByClientId(id: string): Observable<HttpResponse<InvoiceDetailsModel[]>> {
    return this.http.get<InvoiceDetailsModel[]>(this.BASE_URL + `/api/v1/client/subscription/billing/invoice/client/${id}`, { observe: 'response' });
  }

  getCompanyDetails(): Observable<HttpResponse<CompanyDetailsModel>> {
    return this.http.get<CompanyDetailsModel>(this.BASE_URL + `/api/v1/company/details`, { observe: 'response' });
  }

  getMerchantId(): Observable<string> {
    return this.http.get<string>(this.BASE_URL + `/api/v1/company/merchantId`, { responseType: 'text' as 'json' });
  }

  getBillingScheduleDetails(id: string): Observable<HttpResponse<BillingScheduleModel[]>> {
    return this.http.get<BillingScheduleModel[]>(this.BASE_URL + `/api/v1/client/subscription/billing/${id}`, { observe: 'response' });
  }

  getCompanyUsersForAssignment(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/subscription/assign/userlist`, { observe: 'response' });
  }

  assignUserToSubscription(subscriptionId: string, userId: string): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/client/subscription/assign/${subscriptionId}/${userId}`, { observe: 'response' });
  }

  addAttachmentSignRequired(signRequest: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/client/subscription/attachment/signrequired`, signRequest, { observe: 'response' });
  }

  cancelSignRequest(cancelSignRequest: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/client/subscription/attachment/cancel-sign`, cancelSignRequest, { observe: 'response' })
  }

  signAttachment(signUser: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/client/subscription/attachment/sign`, signUser, { observe: 'response' });
  }

  getClientUserMessages(): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(this.BASE_URL + `/api/v1/client/message`, { observe: 'response' });
  }

  createClientMessage(message: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/client/message`, message, { observe: 'response' });
  }

  getClientUserUnreadMessages(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/message/unread`, { observe: 'response' });
  }

  getCompanyUnreadMessages(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/company/unread-messages`, { observe: 'response' });
  }

  getClientMessagesForCpa(id: string): Observable<HttpResponse<ClientMessageModel[]>> {
    return this.http.get<ClientMessageModel[]>(this.BASE_URL + `/api/v1/client/cpa/message/${id}`, { observe: 'response' });
  }

  createClientMessageCpa(message: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/client/cpa/message`, message, { observe: 'response' });
  }

  getCpaUnreadMessages(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/cpa/message/unread/${id}`, { observe: 'response' });
  }

  getClientUsersList(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/${id}/users`, { observe: 'response' });
  }

  getClientCountStats(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/stats/clients`, { observe: 'response' });
  }

  getOpenAndClosedSubscriptionCountForActiveServicesPerUser(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `​/api​/v1​/stats​/employee-subscription`, { observe: 'response' });
  }

  getActiveAndOverdueInvoiceCount(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api​/v1​/stats​/invoice`, { observe: 'response' });
  }

  getMySubscriptionStats(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/stats/my-subscriptions`, { observe: 'response' });
  }

  getOpenAndClosedSubscriptionCount(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/stats/org-subscription`, { observe: 'response' });
  }

  getHomePageAdminData(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/stats/admin/home`, { observe: 'response' });
  }

  getUserProfile(): Observable<HttpResponse<NewUserModel>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/user/profile`, { observe: 'response' });
  }

  updateUserProfile(user: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/user/profile/update`, user, { observe: 'response' });
  }

  createClientUser(user: any): Observable<HttpResponse<any>> {
    return this.http.post<NewUserModel>(this.BASE_URL + '/api/v1/client/user', user, { observe: 'response' });
  }

  getBillingScheduleDetailsForCompany(isFuture: boolean): Observable<HttpResponse<BillingListModel[]>> {
    return this.http.get<BillingListModel[]>(this.BASE_URL + '/api/v1/client/subscription/billing/company?isFuture=' + isFuture, { observe: 'response' });
  }

  getClientMessageCount(): Observable<HttpResponse<FirmMessageModel[]>> {
    return this.http.get<FirmMessageModel[]>(this.BASE_URL + '/api/v1/client/message/unread-count', { observe: 'response' });
  }

  setFeebackMessege(payload: any): Observable<any> {
    return this.http.post(this.BASE_URL + '/api/v1/feedback/create', payload, { observe: 'response' });
  }

  getEsignTemplateList(): Observable<HttpResponse<EsignTemplateModel[]>> {
    return this.http.get<EsignTemplateModel[]>(this.BASE_URL + '/api/v1/esign-template/list', { observe: 'response' });
  }

  createEsignTemplate(esignTemplate: EsignTemplateModel): Observable<HttpResponse<EsignTemplateModel>> {
    return this.http.post<EsignTemplateModel>(this.BASE_URL + '/api/v1/esign-template/create', esignTemplate, { observe: 'response' });
  }

  updateEsignTemplate(id: string, esignTemplate: any): Observable<HttpResponse<EsignTemplateModel>> {
    return this.http.put<EsignTemplateModel>(this.BASE_URL + `/api/v1/esign-template/${id}`, esignTemplate, { observe: 'response' });
  }

  getEsignTemplateById(id: string): Observable<HttpResponse<EsignTemplateModel>> {
    return this.http.get<EsignTemplateModel>(this.BASE_URL + `/api/v1/esign-template/${id}`, { observe: 'response' });
  }

  getFirmLogoOrName(subdomain: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/company/info?identifier=${subdomain}`, { observe: 'response' });
  }

  clientServiceAssignment(req: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/client/subscription/subscribe`, req, { observe: 'response' });
  }

  getPlaidAccessToken(amount: number, invoiceId: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/subscription/billing/plaid/link-token?amount=${amount}&invoiceId=${invoiceId}`, { observe: 'response' });
  }

  getCompanyEmailSyncSettings(): Observable<HttpResponse<EmailSync>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/company/manage/email-sync`, { observe: 'response' });
  }

  updateCompanyEmailSyncSettings(emailSyncData: EmailSync): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/company/manage/email-sync`, emailSyncData, { observe: 'response' });
  }

  getCompanyFinanceSettings(): Observable<HttpResponse<Finance>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/company/manage/finance`, { observe: 'response' });
  }

  updateCompanyFinanceSettings(financeData: Finance): Observable<HttpResponse<any>> {
    return this.http.put<any>(this.BASE_URL + `/api/v1/company/manage/finance`, financeData, { observe: 'response' });
  }

  updateAchPaymentStatus(invoiceId: string): Observable<HttpResponse<any>> {
    //replace with required url
    return this.http.put<any>(this.BASE_URL + `/api/v1/company/manage/finance`, { invoiceId: invoiceId }, { observe: 'response' });
  }

  getPublicEsignData(encryptedStr: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/public/esign?enc=${encryptedStr}`);
  }

  publicEsignDoc(signData: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + `/api/v1/public/esign`, signData, { observe: 'response' });
  }

  patchSubscriptionDetaisl(subscriptionId: string, patchData: any): Observable<HttpResponse<any>> {
    return this.http.patch<any>(this.BASE_URL + `/api/v1/client/subscription/${subscriptionId}`, patchData, { observe: 'response' });
  }

  getPaginatedClientsList(req: PaginationRequestModel): Observable<HttpResponse<PaginationModel>> {
    return this.http.post<PaginationModel>(this.BASE_URL + '/api/v1/client/page/list', req, { observe: 'response' });
  }

  getPaginatedAllSubscriptions(req: PaginationRequestModel): Observable<HttpResponse<PaginationModel>> {
    return this.http.post<PaginationModel>(this.BASE_URL + '/api/v1/client/subscription/all', req, { observe: 'response' });
  }

  getPaginatedMySubscriptions(req: PaginationRequestModel): Observable<HttpResponse<PaginationModel>> {
    return this.http.post<PaginationModel>(this.BASE_URL + '/api/v1/client/subscription/my', req, { observe: 'response' });
  }

  getSubscriptionNotes(subscriptionId: string): Observable<HttpResponse<SubscriptionNotesModel[]>> {
    return this.http.get<SubscriptionNotesModel[]>(this.BASE_URL + `/api/v1/client/subscription/${subscriptionId}/notes`, { observe: 'response' });
  }

  createSubscriptionNotes(notes: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + '/api/v1/client/subscription/notes', notes, { observe: 'response' });
  }
}
