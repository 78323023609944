<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" class="inline-block">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20">Finance</h1>

            <div class="flex-row" *ngIf="!disable">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>

                <button class="secondary" mat-flat-button routerLink="/firm/my-org/org-details">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <form [formGroup]="financeForm">

            <mat-form-field appearance="outline">
                <mat-label>Payment Term</mat-label>
                <mat-select placeholder="Default Payment Term" formControlName="defaultPaymentTerm"
                    disableOptionCentering>
                    <mat-option *ngFor="let term of paymentTerm" [value]="term.termKey">
                        {{term.termValue}}</mat-option>
                </mat-select>
                <mat-error
                    *ngIf="financeForm.get('defaultPaymentTerm').hasError('required') && (financeForm.get('defaultPaymentTerm').dirty || financeForm.get('defaultPaymentTerm').touched)">
                    Payment is required.
                </mat-error>
            </mat-form-field>

            <div class="paypal-container">
                <div class="payment-logo-container">
                    <div class="paypal-logo">
                        <img src="../../assets/payment-logo/paypal_logo.png" />
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Paypal Merchant Id</mat-label>
                    <input type="text" placeholder="Enter paypal merchant id" matInput formControlName="merchantId">
                </mat-form-field>
            </div>

            <div class="ach-container">
                <div class="payment-logo-container">
                    <div class="scj-h2">Bank Transfer</div>
                    <div class="stripe-logo">
                        <img src="../../assets/payment-logo/stripe_logo.png" />
                    </div>
                </div>

                <div style="display: inline-block;">
                    <div class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Stripe Client Id</mat-label>
                            <input type="text" placeholder="Enter stripe client id" matInput
                                formControlName="stripeClientId" [required]="stripeRequired">
                            <mat-error
                                *ngIf="financeForm.get('stripeClientId').hasError('required') && (financeForm.get('stripeClientId').dirty || financeForm.get('stripeClientId').touched)">
                                Stripe client id is required.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Stripe Secret</mat-label>
                            <input type="text" placeholder="Enter stripe secret" matInput formControlName="stripeSecret"
                                [required]="stripeRequired">
                            <mat-error
                                *ngIf="financeForm.get('stripeSecret').hasError('required') && (financeForm.get('stripeSecret').dirty || financeForm.get('stripeSecret').touched)">
                                Stripe secret is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>