import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { AppComponent } from './app.component';
import { FirmNavigationComponent } from './firm-navigation/firm-navigation.component';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { SortPipe } from './shared/pipes/sort.pipe';
import { AttachmentsComponent } from './attachments/attachments.component';
import { DynamicElementsFormComponent } from './dynamic-elements-form/dynamic-elements-form.component';
import { MultiSwitchCasePipe } from './shared/pipes/multi-switch-case.pipe';
import { FilesDropZoneDirective } from './shared/directives/files-drop-zone.directive';
import { FirmDetailsComponent } from './firm-details/firm-details.component';
import { SectionTableComponent } from './section-table/section-table.component';
import { TemplateLoaderComponent } from './template-loader/template-loader.component';
import { AlertMessagesComponent } from './common/components/alert-messages/alert-messages.component';
import { TableContentComponent } from './common/components/table-content/table-content.component';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTOR_PROVIDERS } from './shared/interceptors';
import { MyOrgComponent } from './my-org/my-org.component';
import { OrgDetailsComponent } from './org-details/org-details.component';
import { OrgAgreementsComponent } from './org-agreements/org-agreements.component';
import { OrgAgreementsListComponent } from './org-agreements-list/org-agreements-list.component';
import { OrgNewAgreementComponent } from './org-new-agreement/org-new-agreement.component';
import { OrgUsersComponent } from './org-users/org-users.component';
import { OrgUsersListComponent } from './org-users-list/org-users-list.component';
import { OrgNewUserComponent } from './org-new-user/org-new-user.component';
import { FirmBillingComponent } from './firm-billing/firm-billing.component';
import { OrgServicesComponent } from './org-services/org-services.component';
import { OrgServicesListComponent } from './org-services-list/org-services-list.component';
import { OrgNewServiceComponent } from './org-new-service/org-new-service.component';
import { ActivateUserComponent } from './activate_update-user/activate_update-user.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { NewClientComponent } from './new-client/new-client.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ErrorMsgComponent } from './error-msg/error-msg.component';
import { ClientNavigationComponent } from './client-navigation/client-navigation.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { ClientSubscriptionDetailsComponent } from './client-subscription-details/client-subscription-details.component';
import { StepperStatusIndicatorComponent } from './common/components/stepper-status-indicator/stepper-status-indicator.component';
import { ClientServiceDataComponent } from './client-service-data/client-service-data.component';
import { AllSubscriptionsComponent } from './all-subscriptions/all-subscriptions.component';
import { MyAssignmentsComponent } from './my-assignments/my-assignments.component';
import { ClickElementOutsideDirective } from './shared/directives/click-element-outside.directive';
import { ClientEmailComponent } from './client-email/client-email.component';
import { DomSafePipe } from './shared/pipes/dom-safe.pipe';
import { FirmPreferencesComponent } from './firm-preferences/firm-preferences.component';
import { ClientFilesComponent } from './client-files/client-files.component';
import { GroupByPipe } from './shared/pipes/group-by.pipe';
import { LettersPipe } from './shared/pipes/letters.pipe';
import { ClientServicesListComponent } from './client-services-list/client-services-list.component';
import { BillingComponent } from './billing/billing.component';
import { BillingListComponent } from './billing-list/billing-list.component';
import { ManageBillingComponent } from './manage-billing/manage-billing.component';
import { ClientServicesComponent } from './client-services/client-services.component';
import { ClientInvoicesComponent } from './client-invoices/client-invoices.component';
import { FormatColumnPipe } from './shared/pipes/format-column.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ClientUserComponent } from './client-user/client-user.component';
import { ClientMessagesComponent } from './client-messages/client-messages.component';
import { PaypalDirective } from './shared/directives/paypal.directive';
import { EllipsifyMeDirective } from './shared/directives/ellipsify-me.directive';
import { ClientUserListComponent } from './client-user-list/client-user-list.component';
import { ClientUsersComponent } from './client-users/client-users.component';
import { SignAttachmentComponent } from './sign-attachment/sign-attachment.component';
import { FirmHomeComponent } from './firm-home/firm-home.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { RouterModule } from '@angular/router';
import { FirmMessagesComponent } from './firm-messages/firm-messages.component';
import { FirmBillingListComponent } from './firm-billing-list/firm-billing-list.component';
import { ChatFabComponent } from './chat-fab/chat-fab.component';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { BulkClientsCreateComponent } from './bulk-clients-create/bulk-clients-create.component';
import { AppPrefixFocusAndSelectDirective } from './shared/directives/app-prefix-focus-and-select.directive';
import { FirmLogoContainerComponent } from './shared/components/firm-logo-container/firm-logo-container.component';
import { OrgNewSignTemplateComponent } from './org-new-sign-template/org-new-sign-template.component';
import { OrgSignTemplatesComponent } from './org-sign-templates/org-sign-templates.component';
import { OrgSignTemplatesListComponent } from './org-sign-templates-list/org-sign-templates-list.component';
import { ESignComponent } from './e-sign/e-sign.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { EsignTemplateUserMapperComponent } from './esign-template-user-mapper/esign-template-user-mapper.component';
import { AllTasksComponent } from './all-tasks/all-tasks.component';
import { TableFilterComponent } from './shared/components/table-filter/table-filter.component';
import { MultiFactorAuthComponent } from './multi-factor-auth/multi-factor-auth.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { BillingFutureComponentComponent } from './common/components/billing-future-component/billing-future-component.component';
import { FirmServicesComponent } from './firm-services/firm-services.component';
import { NumberRangeInputComponent } from './shared/components/number-range-input/number-range-input.component';
import { ClientServiceAssignmentComponent } from './client-service-assignment/client-service-assignment.component';
import { ClientEmailInboxComponent } from './client-email-inbox/client-email-inbox.component';
import { ClientEmailSentComponent } from './client-email-sent/client-email-sent.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AchPaymentComponent } from './ach-payment/ach-payment.component';
import { FirmFinanceComponent } from './firm-finance/firm-finance.component';
import { FirmEmailSyncComponent } from './firm-email-sync/firm-email-sync.component';
import { ExternalEsignComponent } from './external-esign/external-esign.component';
import { TableComponent } from './shared/components/table/table.component';
import { SubscriptionNotesComponent } from './subscription-notes/subscription-notes.component';

@NgModule({
  declarations: [
    AppComponent,
    FirmNavigationComponent,
    ResourceNotFoundComponent,
    SortPipe,
    AttachmentsComponent,
    DynamicElementsFormComponent,
    MultiSwitchCasePipe,
    AlertMessagesComponent,
    FilesDropZoneDirective,
    FirmDetailsComponent,
    SectionTableComponent,
    TemplateLoaderComponent,
    TableContentComponent,
    LoginComponent,
    MyOrgComponent,
    OrgDetailsComponent,
    OrgAgreementsComponent,
    OrgAgreementsListComponent,
    OrgNewAgreementComponent,
    OrgUsersComponent,
    OrgUsersListComponent,
    OrgNewUserComponent,
    FirmBillingComponent,
    OrgServicesComponent,
    OrgServicesListComponent,
    OrgNewServiceComponent,
    ActivateUserComponent,
    ClientsComponent,
    ClientsListComponent,
    NewClientComponent,
    ClientDetailsComponent,
    ErrorMsgComponent,
    ClientNavigationComponent,
    ClientHomeComponent,
    ClientSubscriptionDetailsComponent,
    StepperStatusIndicatorComponent,
    ClientServiceDataComponent,
    AllSubscriptionsComponent,
    MyAssignmentsComponent,
    ClickElementOutsideDirective,
    ClientEmailComponent,
    DomSafePipe,
    FirmPreferencesComponent,
    ClientFilesComponent,
    GroupByPipe,
    LettersPipe,
    ClientServicesListComponent,
    BillingComponent,
    BillingListComponent,
    ManageBillingComponent,
    ClientServicesComponent,
    ClientInvoicesComponent,
    FormatColumnPipe,
    ClientUserComponent,
    ClientMessagesComponent,
    PaypalDirective,
    EllipsifyMeDirective,
    ClientUserListComponent,
    ClientUsersComponent,
    SignAttachmentComponent,
    FirmHomeComponent,
    MyProfileComponent,
    FirmMessagesComponent,
    FirmBillingListComponent,
    ChatFabComponent,
    EmailModalComponent,
    ForgetPasswordComponent,
    BulkClientsCreateComponent,
    AppPrefixFocusAndSelectDirective,
    FirmLogoContainerComponent,
    OrgNewSignTemplateComponent,
    OrgSignTemplatesComponent,
    OrgSignTemplatesListComponent,
    ESignComponent,
    SignaturePadComponent,
    EsignTemplateUserMapperComponent,
    AllTasksComponent,
    TableFilterComponent,
    MultiFactorAuthComponent,
    BillingFutureComponentComponent,
    FirmServicesComponent,
    NumberRangeInputComponent,
    ClientServiceAssignmentComponent,
    ClientEmailInboxComponent,
    ClientEmailSentComponent,
    AchPaymentComponent,
    FirmFinanceComponent,
    FirmEmailSyncComponent,
    ExternalEsignComponent,
    TableComponent,
    SubscriptionNotesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    AngularEditorModule,
    RouterModule,
    RecaptchaV3Module
  ],
  providers: [SortPipe, HTTP_INTERCEPTOR_PROVIDERS, CurrencyPipe, GroupByPipe, DomSafePipe, FormatColumnPipe, DatePipe,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPCHA_SITE_KEY
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  entryComponents: [AlertMessagesComponent, BillingComponent, SignAttachmentComponent, ChatFabComponent, EmailModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
