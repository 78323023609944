<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="flex-row jc-sb-ai-c">
            <h2 class="scj-h2 mr-20">Subscriptions</h2>

            <div class="search-filter">
                <div class="search-field">
                    <mat-icon>search</mat-icon>
                    <input type="text" [formControl]="searchControl">
                </div>

                <button class="filter-button" (click)="openTableFilter()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </div>

        <scj-table-content [data]="activeServices" [searchControl]="searchControl" [columnConfig]="columnConfig"
            defaultSortColumn="serviceRequested" [pageSizeOptions]="[20, 40, 60, 80, 100]"
            [exportFileName]="exportFileName" (navigate)="navigate($event)" (esignStatus)="esign($event)">
            <button primaryAction mat-flat-button (click)="assignSubscription()" *ngIf="serviceList.length>0 && isFirm"
                class="primary">
                <mat-icon class="button-icon" svgIcon="assign_one" class="service"></mat-icon>
                <span class="button-text">Assign Service</span>
            </button>

            <div spinner *ngIf="isSpinner" style="margin-left: 8px;">
                <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
            </div>
        </scj-table-content>

    </div>
</div>