export enum COLOR {
    COLOR_1 = 'rgba(155, 89, 182, 0.85)',
    COLOR_2 = 'rgba(52, 152, 219, 0.85)',
    COLOR_3 = 'rgba(46, 204, 113, 0.85)',
    COLOR_4 = 'rgba(241, 196, 15, 0.85)',
    COLOR_5 = 'rgba(244, 113, 83, 0.85)',
    COLOR_6 = 'rgba(42, 70, 92, 0.85)',
    COLOR_7 = 'rgba(86, 124, 88, 0.85)',
    COLOR_8 = 'rgba(86, 66, 88, 0.85)',
    COLOR_9 = 'rgba(165, 30, 44, 0.85)',
    COLOR_10 = 'rgba(231, 78, 195, 0.85)'
}