<div class="flex-row jc-sb-ai-c">
    <h2 class="scj-h2">Clients</h2>

    <div class="search-filter">
        <div class="search-field">
            <mat-icon class="search-icon">search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>

        <!-- <button class="filter-button" (click)="openTableFilter()">
            <mat-icon>filter_list</mat-icon>
        </button> -->
    </div>
</div>

<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div class="content-fit" *ngIf="!isError && !isLoading">

        <scj-table [data]="clientListData.data" [dataLength]="clientListData.total" [columnConfig]="columnConfig"
            [sortColumn]="reqBody.sort.column" [sortOrder]="reqBody.sort.order" [pageIndex]="reqBody.page.index - 1"
            [pageSize]="reqBody.page.size" exportFileName="My Clients" (navigate)="navigate($event)"
            (selectionList)="selectedClientList($event)" (tableDataChange)="modifyPageData($event)">

            <button primaryAction mat-flat-button routerLink="../clients-create">
                <mat-icon class="button-icon">add</mat-icon>
                <span class="button-text">New</span>
            </button>

            <button primaryAction mat-flat-button class="secondary" routerLink="../bulk-clients-create">
                <mat-icon class="button-icon">cloud_upload</mat-icon>
                <span class="button-text">Bulk Upload</span>
            </button>

            <button secondaryAction mat-flat-button class="secondary" (click)="resendActivationLink()"
                matTooltip="Resent Activation Mail" *ngIf="enableButton">
                <mat-icon class="button-icon">email</mat-icon>
                <span class="button-text">Resend Activation Link</span>
            </button>

            <button secondaryAction mat-flat-button (click)="deleteClient()" class="secondary" *ngIf="enableButton">
                <mat-icon class="button-icon">delete</mat-icon>
                <span class="button-text">Delete</span>
            </button>

            <button secondaryAction mat-flat-button (click)="assignSubscription()" class="secondary"
                *ngIf="selectedList.length>0 && serviceList.length>0">
                <mat-icon class="button-icon">add_shopping_cart</mat-icon>
                <span class="button-text">Add a Service</span>
            </button>

            <div spinner *ngIf="isSpinner">
                <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
            </div>

        </scj-table>

    </div>
</div>