<mat-tab-group mat-align-tabs="center" dynamicHeight>

    <mat-tab label="My Info">
        <div class="flex-column tab-body">
            <scj-my-profile></scj-my-profile>
        </div>
    </mat-tab>

    <mat-tab label="My Org" *ngIf="!role && isBusiness">
        <div class="flex-column tab-body">
            <div class="flex-row">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text"> Save</span>
                </button>
                <button mat-flat-button routerLink="/client/home" class="secondary">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isMyOrgSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>

            <scj-error-msg *ngIf="orgErrorsList.length > 0" [errors]="orgErrorsList"></scj-error-msg>

            <form [formGroup]="myOrgForm" class="flex-row" autocomplete="off">
                <div class="flex-column right-margin">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input type="text" matInput placeholder="Enter firm name" formControlName="businessName"
                            required>
                        <mat-error *ngFor="let validator of errorMessages.businessName">
                            <mat-error
                                *ngIf="myOrgForm.get('businessName').hasError(validator.type) && (myOrgForm.get('businessName').dirty || myOrgForm.get('businessName').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>EIN Number</mat-label>
                        <input placeholder="Enter EIN number" matInput formControlName="einNumber">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Website</mat-label>
                        <input type="url" placeholder="Enter firm website" matInput formControlName="businessWebsite">
                        <mat-error *ngFor="let validator of errorMessages.businessWebsite">
                            <mat-error
                                *ngIf="myOrgForm.get('businessWebsite').hasError(validator.type) && (myOrgForm.get('businessWebsite').dirty || myOrgForm.get('businessWebsite').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="email" placeholder="Enter firm email" matInput formControlName="businessEmail"
                            >
                        <!--<mat-error *ngFor="let validator of errorMessages.businessEmail">
                            <mat-error
                                *ngIf="myOrgForm.get('businessEmail').hasError(validator.type) && (myOrgForm.get('businessEmail').dirty || myOrgForm.get('businessEmail').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>-->
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input type="text" placeholder="Enter firm phone number" matInput
                            formControlName="businessPhonenumber" >
                        <!--<mat-error *ngFor="let validator of errorMessages.businessPhonenumber">
                            <mat-error
                                *ngIf="myOrgForm.get('businessPhonenumber').hasError(validator.type) && (myOrgForm.get('businessPhonenumber').dirty || myOrgForm.get('businessPhonenumber').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>-->
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput formControlName="startDate" placeholder="Select Start Date" readonly>
                    </mat-form-field>
                </div>

                <div class="flex-column">
                    <div formGroupName="clientAddress" class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Country</mat-label>
                            <mat-select placeholder="Select Country" [formControl]="country"
                                panelClass="new-workflow-select-panel" disableOptionCentering required>
                                <mat-option *ngFor="let el of countries" [value]="el.COUNTRY_CODE">{{el.COUNTRY_NAME}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Addess</mat-label>
                            <input type="text" #addressText placeholder="Enter firm address" matInput
                                formControlName="addressLine1">
                            <mat-error *ngFor="let validator of errorMessages.clientAddress.addressLine1">
                                <mat-error
                                    *ngIf="myOrgForm.get('clientAddress').get('addressLine1').hasError(validator.type) && (myOrgForm.get('clientAddress').get('addressLine1').dirty || myOrgForm.get('clientAddress').get('addressLine1').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input type="text" placeholder="Enter city" matInput formControlName="city">
                            <mat-error *ngFor="let validator of errorMessages.clientAddress.city">
                                <mat-error
                                    *ngIf="myOrgForm.get('clientAddress').get('city').hasError(validator.type) && (myOrgForm.get('clientAddress').get('city').dirty || myOrgForm.get('clientAddress').get('city').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>State/Province</mat-label>
                            <input type="text" placeholder="Enter state/province" matInput formControlName="state">
                            <mat-error *ngFor="let validator of errorMessages.clientAddress.state">
                                <mat-error
                                    *ngIf="myOrgForm.get('clientAddress').get('state').hasError(validator.type) && (myOrgForm.get('clientAddress').get('state').dirty || myOrgForm.get('clientAddress').get('state').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Zip</mat-label>
                            <input type="text" #zip placeholder="Enter zip" matInput formControlName="zip">
                            <mat-error *ngFor="let validator of errorMessages.clientAddress.zip">
                                <mat-error
                                    *ngIf="myOrgForm.get('clientAddress').get('zip').hasError(validator.type) && (myOrgForm.get('clientAddress').get('zip').dirty || myOrgForm.get('clientAddress').get('zip').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </mat-tab>

    <mat-tab label="Change Password">
        <form [formGroup]="changePasswordForm" #formDirective="ngForm" class="flex-column tab-body">

            <div class="flex-row bottom-padding">
                <button type="submit" mat-flat-button (click)="changePassword()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button type="button" mat-flat-button class="secondary" (click)="reset()">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>

                <div *ngIf="isPasswordSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Current Password</mat-label>
                <input [type]="hideCurrentPassword ? 'password' : 'text'" placeholder="Enter current password" matInput
                    formControlName="currentPassword" required>
                <button mat-icon-button matSuffix type="button" (click)="hideCurrentPassword = !hideCurrentPassword">
                    <mat-icon>{{hideCurrentPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <button mat-icon-button matSuffix type="button" (click)="toggle(tooltip)" #tooltip="matTooltip"
                    matTooltip="{{passwordTooltip}}">
                    <mat-icon>info</mat-icon>
                </button>
                <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('required')">
                    Current password is required
                </mat-error>
                <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('pattern')">
                    Hover on <mat-icon>info </mat-icon> to view Password format
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input [type]="hideNewPassword ? 'password' : 'text'" placeholder="Enter new password" matInput
                    formControlName="password" required>
                <button mat-icon-button matSuffix type="button" (click)="hideNewPassword = !hideNewPassword">
                    <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <button mat-icon-button matSuffix type="button" (click)="toggle(tooltip)" #tooltip="matTooltip"
                    matTooltip="{{passwordTooltip}}">
                    <mat-icon>info</mat-icon>
                </button>
                <mat-error *ngIf="changePasswordForm.get('password').hasError('required')">
                    New password is required
                </mat-error>
                <mat-error *ngIf="changePasswordForm.get('password').hasError('pattern')">
                    Hover on <mat-icon>info </mat-icon> to view Password format
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Confirm New Password</mat-label>
                <input [type]="hideRetypePassword ? 'password' : 'text'" matInput placeholder="Enter new password"
                    formControlName="retypePassword" required>
                <button mat-icon-button matSuffix type="button" (click)="hideRetypePassword = !hideRetypePassword">
                    <mat-icon>{{hideRetypePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="changePasswordForm.controls['retypePassword'].invalid">
                    <mat-error *ngIf="changePasswordForm.controls['retypePassword'].hasError('required')">
                        Re-enter your new password
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.controls['retypePassword'].hasError('passwordMatch')">
                        Not matching with new password
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </form>
    </mat-tab>
</mat-tab-group>