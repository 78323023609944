import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  loadMapsScript() {
    let mapsScriptElement: HTMLScriptElement = this.document.createElement('script');
    mapsScriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAPS_KEY}&libraries=places`;
    mapsScriptElement.id = 'g-map';

    this.document.head.appendChild(mapsScriptElement);
  }

  loadGAScript() {
    let host = window.location.hostname;
    if (host !== "localhost") {
      let gtagScriptElement: HTMLScriptElement = document.createElement('script');
      gtagScriptElement.async = true;
      gtagScriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
      gtagScriptElement.id = 'g-analytic'
      document.head.prepend(gtagScriptElement);
      gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
    }
  }

  loadRecaptchaScript() {
    let recaptchaScriptElement: HTMLScriptElement = this.document.createElement('script');
    recaptchaScriptElement.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.RECAPCHA_SITE_KEY}`;
    recaptchaScriptElement.id = 'g-recaptch';
    this.document.head.appendChild(recaptchaScriptElement);
  }

}
