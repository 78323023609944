import { Component, OnInit, Input, Inject, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, TemplateRef } from '@angular/core';
import { SectionsModel } from '../models/sections.model';
import { FormGroup, FormArray } from '@angular/forms';
import { HandSetService } from '../shared/services/hand-set.service';
import { Observable } from 'rxjs';
import { FormGroupService } from '../shared/services/form-group.service';
import { NotificationService } from '../shared/services/notification.service';
import { HttpCommonService } from '../services/http-common.service';
import { DOCUMENT } from '@angular/common';
import { FileHandlerService } from '../shared/services/file-handler.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services/authentication.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SignAttachmentComponent } from '../sign-attachment/sign-attachment.component';
import { DataService } from '../data-service.service';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ElementsModel } from '../models/elements.model';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { ESignComponent } from '../e-sign/e-sign.component';
import { FILE_EXTENSION } from '../shared/constants/app.constants';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'scj-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  isHandset: boolean;
  isHovering: boolean = false;
  isUploaded: boolean = false;
  addFormData: FormData;
  controlsArr: FormArray;
  commentData: any;
  attachmentList: any;
  clientAttachments: any;
  firmAttachments: any;
  isSpinner: boolean = false;
  Object = Object;
  clientName = '';
  firmName = '';
  accept: string = "*";

  @Input()
  section: SectionsModel;

  @Input()
  attachmentsForm: FormGroup;

  @ViewChild('fileField')
  fileField: ElementRef;

  @ViewChild('folderField')
  folderField: ElementRef;

  @ViewChild('attachmentInfo')
  attachmentInfo: TemplateRef<any>;

  constructor(@Inject(DOCUMENT) private document: Document, private handSet: HandSetService, private fgService: FormGroupService,
    private notificationService: NotificationService, private http: HttpCommonService, private dataService: DataService,
    private authenticationService: AuthenticationService, private dialog: MatDialog, private fhService: FileHandlerService) {
    this.isHandset = this.handSet.isHandset;
    if (this.authenticationService.userValue.clientId) {
      this.clientName = this.authenticationService.userValue.clientName;
      this.firmName = this.authenticationService.userValue.firmName;
    } else {
      this.clientName = this.dataService.client?.businessName;
      this.firmName = this.authenticationService.userValue.firmName;
    }
  }

  ngOnInit(): void {
    this.accept = FILE_EXTENSION.map(e => '.' + e.toLowerCase()).join(",");
    this.controlsArr = this.attachmentsForm.controls[this.section.name] as FormArray;
    this.getAllFiles();

    let formData = this.section?.data?.addFormData;
    if (formData) {
      this.addFormData = new FormData();
      formData?.forEach((e: any) => {
        if (e?.key && e?.value) {
          this.addFormData.append(e.key, e.value);
        }
      });
    }

    this.fhService.comments.pipe(
      debounceTime(2000),
      distinctUntilChanged(),
      switchMap(() => (this.http.updateAttachmentComment({ attachmentId: this.commentData.id, comments: this.commentData.comments }))
      )
    ).subscribe((res: HttpResponse<any>) => {
      if (res?.status !== 200 && res !== null) {
        this.notificationService.notifyText(`Unable to update comments for file ${this.commentData.fileName}.`);
      }
    }, (err: HttpResponse<any>) => {
      this.notificationService.notifyText(`Unable to update comments for file ${this.commentData.fileName}.`);
    });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList, accept: string[]) {
    let unExt = new Set();

    [...files].forEach((file: File) => {
      let isValidFileExt = this.fhService.validateFileExtension(file.name, FILE_EXTENSION);
      if (isValidFileExt) {
        this.uploadFile(file);
      } else {
        let fileName = file.name;
        let fileExt: string = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
        unExt.add(fileExt);
      }
    });
    this.fileField.nativeElement.value = "";
    if (unExt.size > 0) {
      this.notificationService.notifyText(`${[...unExt].join(", ")} file formats are not supported.`, "X");
    }
  }

  uploadFile(file: File) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    if (this.addFormData) {
      this.addFormData.forEach((v, k) => {
        formData.append(k, v);
      });
    }

    if (this.section.uploadUrl) {
      this.isSpinner = true;
      this.fhService.uploadFile(this.section.uploadUrl, formData).subscribe((res: HttpResponse<any>) => {
        if (res.body !== null) {
          this.addFileControl(res.body);
        }
        this.notificationService.notifyText("Document uploaded successfully.");
        this.isSpinner = false;
        this.getAllFiles();
      }, err => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Could not upload the file: ${file.name}`, "X");
        console.log("Error:", err);
      });
    } else {
      this.notificationService.notifyText(`File upload url is required.`, "X");
    }
  }

  downloadFile(element: any) {
    if (this.section.downloadUrl && element.id) {
      let url = this.section.downloadUrl.replace('attachmentId', element.id);
      this.isSpinner = true;
      this.fhService.downloadFile(url).subscribe(res => {
        let blob: Blob = res;
        let downloadlink = this.document.createElement('a');
        downloadlink.href = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
        downloadlink.download = element.fileName;
        downloadlink.click();
        this.isSpinner = false;
      }, err => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Could not download the file: ${element.fileName}`, "X");
        console.log("Error:", err);
      });
    } else {
      this.notificationService.notifyText(`File download url is required or File is not available.`, "X");
    }
  }

  deleteFile(element: any) {
    if (this.section.deleteUrl && element.id) {
      this.isSpinner = true;
      this.fhService.deleteFile(this.section.deleteUrl, { id: element.id }).subscribe(res => {
        if (res.status === 200) {
          this.notificationService.notifyText(`Attachment ${element.fileName} successfully deleted`, 'X');
          this.getAllFiles();
        } else {
          this.isSpinner = false;
          this.notificationService.notifyText(`Could not delete file: ${element.fileName}`, "X");
        }
      }, err => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Could not delete file: ${element.fileName}`, "X");
        console.log("Error:", err);
      });
    } else {
      this.notificationService.notifyText(`File delete url is required or File is not available.`, "X");
    }
  }

  getAllFiles() {
    this.controlsArr.clear();
    if (this.section.attachmentListUrl) {
      this.isSpinner = true;
      this.fhService.getFilesList(this.section.attachmentListUrl).subscribe((res: HttpResponse<any[]>) => {
        if (res.body !== null) {
          // res.body.forEach(e => {
          //   if (!e.comments)
          //     e.comments = '';
          //   this.addFileControl(e);
          // });
          this.attachmentList = res.body;//.map(e => {
          //   if (!e.comments)
          //     e.comments = '';
          //   return e;
          // });
          let clientAttachmentKeys: any[] = this.Object.keys(this.attachmentList?.clientAttachments);
          if (clientAttachmentKeys?.length > 0) {
            clientAttachmentKeys.forEach((key: string) => {
              this.attachmentList.clientAttachments[key] = this.attachmentList.clientAttachments[key].map(e => {
                if (!e.comments)
                  e.comments = '';
                return e;
              });
            });
            this.clientAttachments = this.attachmentList.clientAttachments
          } else {
            this.clientAttachments = undefined;
          }

          let firmAttachmentKeys: any[] = this.Object.keys(this.attachmentList?.firmAttachments);
          if (firmAttachmentKeys?.length > 0) {
            let keys: any[] = this.Object.keys(this.attachmentList?.firmAttachments);
            keys.forEach((key: string) => {
              this.attachmentList.firmAttachments[key] = this.attachmentList.firmAttachments[key].map(e => {
                if (!e.comments)
                  e.comments = '';
                return e;
              });
            });
            this.firmAttachments = this.attachmentList.firmAttachments
          } else {
            this.firmAttachments = undefined;
          }

          if (this.firmAttachments) {
            let containsFolder: boolean = this.Object.keys(this.firmAttachments).reduce((fileList: any, x: any) => {
              fileList = [...fileList, ...this.firmAttachments[x]];
              return fileList;
            }, []).filter((e: any) => !!e?.folderName).length > 0;
            if (containsFolder) {
              this.createFolderList();
            }
          }

          // if (this.authenticationService.userValue.clientId) {
          //   let fileList = this.attachmentList?.filter((x: any) => this.authenticationService.userValue.username === x.uploadedBy);
          //   this.clientAttachments = fileList?.length > 0 ? this.attachmentsByDate(fileList) : undefined;
          //   fileList = this.attachmentList?.filter((x: any) => this.authenticationService.userValue.username !== x.uploadedBy);
          //   let containsFolder: boolean = fileList?.filter((e: any) => !!e?.folderName).length > 0;
          //   this.firmAttachments = fileList?.length > 0 ? this.attachmentsByDate(fileList) : undefined;
          //   if (containsFolder) {
          //     this.createFolderList();
          //   }
          // } else {
          //   let fileList = this.attachmentList?.filter((x: any) => this.authenticationService.userValue.username !== x.uploadedBy);
          //   this.clientAttachments = fileList?.length > 0 ? this.attachmentsByDate(fileList) : undefined;
          //   fileList = this.attachmentList?.filter((x: any) => this.authenticationService.userValue.username === x.uploadedBy);
          //   let containsFolder: boolean = fileList?.filter((e: any) => !!e?.folderName).length > 0;
          //   this.firmAttachments = fileList?.length > 0 ? this.attachmentsByDate(fileList) : undefined;
          //   if (containsFolder) {
          //     this.createFolderList();
          //   }
          // }
        } else {
          this.firmAttachments = undefined;
          this.clientAttachments = undefined;
        }
        this.isUploaded = true;
        this.isSpinner = false;
      }, err => {
        this.isSpinner = false;
        console.log("Error:", err);
      });
    }
  }

  attachmentsByDate(arr: any) {
    let attList = arr.sort((a: any, b: any) => {
      b.uploadedDateTime = new Date(b.uploadedDateTime);
      a.uploadedDateTime = new Date(a.uploadedDateTime);
      return b.uploadedDateTime - a.uploadedDateTime;
    }).reduce((map: any, x: any) => {
      let date = moment(x.uploadedDateTime).format('DD-MMM-YYYY');
      if (!map[date]) {
        map[date] = [x];
      } else {
        map[date].push(x);
      }
      return map;
    }, {});
    return attList;
  }

  createFolderList() {
    for (const [key, value] of Object.entries(this.firmAttachments)) {
      let files = this.firmAttachments[key];
      let index = files.length;
      let removedFiles = [];
      while (index--) {
        if (files[index] && files[index].hasOwnProperty("folderName") && !!files[index].folderName) {
          removedFiles.push(...files.splice(index, 1));
        }
      }
      if (removedFiles.length > 0) {
        let folders = removedFiles.reduce((map: any, x: any) => {
          let folderName = x.folderName;
          if (!map[folderName]) {
            map[folderName] = [x];
          } else {
            map[folderName].push(x);
          }
          return map;
        }, {});

        Object.entries(folders).sort(([a,]: any, [b,]: any) => {
          return b.localeCompare(a);
        }).forEach(([k, v]) => {
          let folder = { folderName: k, isFolder: true, files: v, isExpanded: true };
          this.firmAttachments[key].unshift(folder);
        });
      }
    }
  }

  addFileControl(fileRes: any) {
    let fileForm = this.fgService.toFormGroupByElements(this.section.elements);
    fileForm.patchValue(fileRes);
    this.controlsArr.push(fileForm);
  }

  updateComments(comments: any, element: any) {
    this.commentData = element;
    this.commentData.comments = comments;
    this.fhService.comments.next(comments);
  }

  toggle(tooltip: MatTooltip) {
    return tooltip.toggle();
  }

  openAttachmentInfo() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.handSet.isHandset ? '80vw' : 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = false;
    dialogConfig.data = this.section.elements.find((e: ElementsModel) => e.type === 'display');
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'transparent-mat-dialog-backdrop';
    dialogConfig.position = this.handSet.isHandset ? { bottom: '8px', right: '24px' } : { bottom: '12px', right: '36px' };

    this.dialog.open(this.attachmentInfo, dialogConfig);
  }

  signRequest(element: any) {
    if (!this.handSet.isHandset) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.minWidth = '50vw';
      dialogConfig.width = 'auto';
      dialogConfig.maxWidth = '80vw';
      dialogConfig.minHeight = '50%';
      dialogConfig.height = '90%';
      dialogConfig.maxHeight = '90%';
      dialogConfig.closeOnNavigation = true;
      dialogConfig.disableClose = false;
      dialogConfig.data = element;
      dialogConfig.panelClass = "esign-modal";

      dialogConfig.data = {
        attachment: element
      }

      this.dialog.open(SignAttachmentComponent, dialogConfig).afterClosed().subscribe((data: any) => {
        if (data) {
          let signRequest = {
            attachmentId: `${element.id}`,
            jsonData: JSON.stringify(data),

          };
          this.isSpinner = true;
          this.http.addAttachmentSignRequired(signRequest).subscribe((res: HttpResponse<any>) => {
            if (res.status === 200) {
              this.notificationService.notifyText(`Attachment ${element.fileName} requested for client signature`);
              this.getAllFiles();
            }
          }, (err: HttpErrorResponse) => {
            this.isSpinner = false;
            this.notificationService.notifyText(`Unable to request E-sign for attachment ${element.fileName}`);
          });
        }
      });
    } else {
      this.notificationService.notifyText("E-sign request supported only in desktop/laptops.", "X");
    }
  }

  cancelSignRequest(element: any) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = element;

    dialogConfig.data = {
      message: `You are about to cancel signature request for ${element.fileName} from the client. Please confirm`,
      isHTML: false,
      buttonText: { ok: 'Yes', cancel: 'No' },
      revertBoolean: true
    }
    this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
      if (!confirm) {
        let cancelRequest = {
          attachmentId: `${element.id}`,
          signatureData: JSON.stringify({})
        };
        this.isSpinner = true;
        this.http.cancelSignRequest(cancelRequest).subscribe((res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.notificationService.notifyText(`Signature request canceled for attachment ${element.fileName}`);
            this.getAllFiles();
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          this.notificationService.notifyText(`Unable to cancel signature request for attachment ${element.fileName}`);
        });
      }
    });
  }

  esign(element: any) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '50vw'
    dialogConfig.width = this.isHandset ? "90vw" : 'auto';
    dialogConfig.maxWidth = this.isHandset ? "90vw" : "80vw";
    dialogConfig.height = '100%';
    dialogConfig.maxHeight = this.isHandset ? "90vh" : '90vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "esign-modal";
    dialogConfig.data = {
      attachment: element
    }

    this.dialog.open(ESignComponent, dialogConfig).afterClosed().subscribe((signReq: any) => {
      if (signReq) {
        this.http.signAttachment(signReq).subscribe((res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.notificationService.notifyText(`Attachment ${element.fileName} E-signed successfully.`, 'X');
            this.getAllFiles();
          }
        }, (err: HttpErrorResponse) => {
          this.notificationService.notifyText('Error occurred during the esign.', "X");
        });
      }
    });
  }

  validateFolderContent(files: FileList) {
    let unExt = new Set();
    let fileArr: File[] = [];
    let folderName: string;

    [...files].forEach((file: any) => {
      let isValidFileExt = this.fhService.validateFileExtension(file.name, FILE_EXTENSION);
      if (isValidFileExt) {
        fileArr.push(file);
        folderName = !!folderName ? folderName : file.webkitRelativePath?.split("/")[0];
      } else {
        let fileName = file.name;
        let fileExt: string = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
        unExt.add(fileExt);
      }
    });
    this.folderField.nativeElement.value = "";
    if (unExt.size > 0) {
      this.notificationService.notifyText(`${[...unExt].join(", ")} file formats are not supported.`, "X");
    }

    if (fileArr.length > 0 && folderName) {
      this.uploadFolder(fileArr, folderName);
    } else {
      this.notificationService.notifyText("There are no valid files extension to upload.", "X")
    }
  }

  uploadFolder(files: File[], folderName: string) {
    let formData = new FormData();

    formData.append("folder", folderName);

    files.forEach((file: File) => {
      formData.append("files", file, file.name);
    });

    if (this.addFormData) {
      this.addFormData.forEach((v, k) => {
        formData.append(k, v);
      });
    }

    if (this.section.canUploadFolder) {
      this.isSpinner = true;
      this.fhService.uploadFolder(this.section.folderUploadUrl, formData).subscribe((res: HttpResponse<any>) => {
        if (res.body !== null) {
          this.addFileControl(res.body);
        }
        this.notificationService.notifyText("Folder uploaded successfully.");
        this.isSpinner = false;
        this.getAllFiles();
      }, err => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Could not upload the folder: ${folderName}`, "X");
        console.log("Error:", err);
      });
    }
  }

  toggleFolderExpansion(attachment: any) {
    attachment.isExpanded = !attachment.isExpanded;
  }
}