import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DataService } from '../data-service.service';
import { FirmMessageModel } from '../models/firm.message.model';
import { HttpCommonService } from '../services/http-common.service';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-firm-messages',
  templateUrl: './firm-messages.component.html',
  styleUrls: ['./firm-messages.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FirmMessagesComponent implements OnInit {
  isHandset$: Observable<boolean>;
  showMore: boolean = true;
  unreadMessageControl: FormControl = new FormControl(false);
  searchControl: FormControl = new FormControl('');
  isLoading: boolean = true;
  isError: boolean = false;
  messages: FirmMessageModel[] = [];
  filteredMessages: FirmMessageModel[] = [];
  isRefresh: boolean = false;
  selectedMessage: FirmMessageModel;

  constructor(private handSet: HandSetService, private http: HttpCommonService, private notificationService: NotificationService, private dataService: DataService) {
    this.isHandset$ = this.handSet.isHandset$;
    if (this.handSet.isHandset) {
      this.showMore = false;
    }
  }

  ngOnInit(): void {
    this.getClientMessageCount();

    this.unreadMessageControl.valueChanges.subscribe((val: boolean) => {
      this.filterMessages(val, this.searchControl.value);
    });

    this.searchControl.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), switchMap((val: string) => of(val))).subscribe((val: string) => {
      this.filterMessages(this.unreadMessageControl.value, val);
    });
  }

  getClientMessageCount() {
    this.http.getClientMessageCount().subscribe((res: HttpResponse<FirmMessageModel[]>) => {
      if (res.body !== null) {
        this.messages = this.filteredMessages = res.body;
        this.filterMessages(this.unreadMessageControl.value, this.searchControl.value);
        if (this.filteredMessages.length > 0) {
          this.selectedMessage = this.filteredMessages[0];
        }
      }
      this.isLoading = false;
      this.isRefresh = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.isRefresh = false;
      this.notificationService.notifyText("Something went wrong!! Please try after some time.");
    });
  }

  refresh() {
    this.isRefresh = true;
    this.getClientMessageCount();
  }

  filterMessages(unread?: boolean, search?: string) {
    if (unread) {
      this.filteredMessages = this.messages.filter((message: FirmMessageModel) => message.messageCount > 0);
    } else {
      this.filteredMessages = this.messages;
    }

    if (search.length > 0) {
      this.filteredMessages = this.filteredMessages.filter((message: FirmMessageModel) => message.name.toLowerCase().includes(search));
    }

    if (this.filteredMessages.length > 0) {
      this.sortMessages();
    }
  }

  loadMessage(message: FirmMessageModel) {
    let prevMessage = this.selectedMessage;
    this.selectedMessage = message;
    this.dataService.setTriggerMessages(true);
    this.messages[this.messages.findIndex(x => x.clientId === prevMessage.clientId)].messageCount = 0;
    if (message.messageCount > 0) {
      this.messages[this.messages.findIndex(x => x.clientId === message.clientId)].messageCount = 0;
    }
  }

  sortMessages() {
    if (this.filteredMessages.length > 0) {
      let unread = this.filteredMessages.filter((message: FirmMessageModel) => message.messageCount > 0);
      if (unread.length > 0) {
        unread.sort((a: FirmMessageModel, b: FirmMessageModel) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
      }
      let read = this.filteredMessages.filter((message: FirmMessageModel) => message.messageCount === 0);
      if (read.length > 0) {
        read.sort((a: FirmMessageModel, b: FirmMessageModel) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
      }

      this.filteredMessages = [...unread, ...read];
    }
  }

}
