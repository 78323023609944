<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="flex-row">
            <div class="name-tags">
                <h2>{{firm.name}}</h2>
                <div>{{firm.tags}}</div>
            </div>

            <div class="email-phone">
                <div>{{firm?.email}}</div>
                <div>{{firm.phoneNumber}}</div>
            </div>

            <div class="message-icon">
                <mat-icon>sms_outlined</mat-icon>
            </div>
        </div>

        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of firmLinks" (click)="navigate(link.path)"
                [active]="activeLink == link.path && router.url.indexOf(link.path) !== -1">
                {{link.label}}
            </a>
        </nav>


        <div class="firm-router">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>