import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../data-service.service';
import { EmailModalComponent } from '../email-modal/email-modal.component';
import { MailsModel } from '../models/mails.model';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HttpCommonService } from '../services/http-common.service';
import { COLUMN_FILTER_TYPE, COLUMN_TYPES } from '../shared/enums/table.enum';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';
import { TableFilterService } from '../shared/services/table-filter.service';

@Component({
  selector: 'scj-client-email-sent',
  templateUrl: './client-email-sent.component.html',
  styleUrls: ['./client-email-sent.component.scss']
})
export class ClientEmailSentComponent implements OnInit {
  searchControl: FormControl = new FormControl('');
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  outboxList: MailsModel[] = [];
  clientId: string;
  errorMessage: string;
  enableFilter: boolean = false;
  sentName: string;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService,
    private matDialog: MatDialog, private notificationService: NotificationService, private tfService: TableFilterService, private dataService: DataService) {
    this.sentName = `${this.dataService.client.businessName} Recieved Mails`;
    this.clientId = this.dataService.client.clientId;
  }

  ngOnInit(): void {
    this.getOutboxMails();
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'date', header: 'Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'to', header: 'To', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'subject', header: 'Subject', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'date', label: 'Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'to', label: 'To', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'subject', label: 'Subject', type: COLUMN_FILTER_TYPE.STRING, value: '' }
    ]
    return colFilters;
  }

  getOutboxMails() {
    this.searchControl.setValue('');
    this.isLoading = true;
    this.errorMessage = undefined;
    this.isError = false;
    this.httpService.getOutboxMailsByClientId(this.clientId).subscribe((res: HttpResponse<MailsModel[]>) => {
      if (res.body !== null && res.body.length > 0) {
        this.outboxList = res.body;
        this.tfService.initializeColumnFilter(this.columnFilterConfig);
        this.enableFilter = true;
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      if (err.status === 400) {
        this.errorMessage = err.error.message;
        this.notificationService.notifyText(err.error.message);
      } else {
        this.errorMessage = 'Something went wrong!! Please try after some time.';
        this.notificationService.notifyText("Something went wrong!! Please try after some time.");
      }
    });
  }

  openOutboxMail(record: MailsModel) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = '80vh';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.height = 'auto';
    dialogConfig.width = '50vw';
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = { mail: record, type: 'OUTBOX' };
    this.matDialog.open(EmailModalComponent, dialogConfig);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }
}
