<div class="flex-column mat-elevation-z8 password">
    <scj-firm-logo-container></scj-firm-logo-container>
    <div class="flex-row header" *ngIf="activateUser; else forgotPassword">
        <h1>Activate your account</h1>
    </div>
    <ng-template #forgotPassword>
        <div class="flex-row header">
            <h1> Reset Password </h1>
        </div>
    </ng-template>

    <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

    <form [formGroup]="activateUserForm" (ngSubmit)="onSubmit(activateUserForm.value)" class="flex-column"
        autocomplete="off">
        <div class="flex-row">
            <div class="flex-column">
                <mat-form-field appearance="legacy">
                    <mat-label>Email ID</mat-label>
                    <input type="email" matInput placeholder="Enter your email id" formControlName="username" readonly>
                    <mat-error *ngIf="activateUserForm.controls['username'].invalid">Enter registered mail id
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="legacy">
                    <mat-label>Password</mat-label>
                    <input [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Enter your password"
                        formControlName="password" tabindex="1" required>
                    <button mat-icon-button matSuffix type="button" (click)="hidePassword = !hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <button mat-icon-button matSuffix type="button" (click)="toggle(tooltip)" #tooltip="matTooltip"
                        matTooltip="{{passwordTooltip}}">
                        <mat-icon>info</mat-icon>
                    </button>
                    <mat-error *ngIf="activateUserForm.controls['password'].invalid">
                        <mat-error *ngIf="activateUserForm.controls['password'].hasError('required')">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="activateUserForm.controls['password'].hasError('pattern')">
                            Hover on <mat-icon>info</mat-icon> to view Password format
                        </mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="legacy">
                    <mat-label>Confirm Password</mat-label>
                    <input [type]="hideConfirmPassword ? 'password' : 'text'" matInput
                        placeholder="Retype your password" formControlName="retypePassword" tabindex="2" required>
                    <button mat-icon-button matSuffix type="button"
                        (click)="hideConfirmPassword = !hideConfirmPassword">
                        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="activateUserForm.controls['retypePassword'].invalid">
                        <mat-error *ngIf="activateUserForm.controls['retypePassword'].hasError('required')">
                            Re-enter your password
                        </mat-error>
                        <mat-error *ngIf="activateUserForm.controls['retypePassword'].hasError('passwordMatch')">
                            Not matching with password
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider [vertical]="true"></mat-divider>

            <div class="flex-column password-info">
                <div>Password must contain : </div>
                <div class="condition"
                    [ngClass]="passwordValidators.length.value? 'highlight-green' : (passwordValidators.length.value !== null)? 'highlight-red' : ''">
                    <mat-icon>{{passwordValidators.length.name}}</mat-icon>
                    <div>At least 8 characters</div>
                </div>
                <div class="condition"
                    [ngClass]="passwordValidators.lower.value? 'highlight-green' : (passwordValidators.lower.value !== null)? 'highlight-red' : ''">
                    <mat-icon>
                        {{passwordValidators.lower.name}}</mat-icon>
                    <div>At least one lower case character</div>
                </div>
                <div class="condition"
                    [ngClass]="passwordValidators.upper.value? 'highlight-green' : (passwordValidators.upper.value !== null)? 'highlight-red' : ''">
                    <mat-icon>{{passwordValidators.upper.name}}</mat-icon>
                    <div>At least one upper case character</div>
                </div>
                <div class="condition"
                    [ngClass]="passwordValidators.number.value? 'highlight-green' : (passwordValidators.number.value !== null)? 'highlight-red' : ''">
                    <mat-icon>{{passwordValidators.number.name}}</mat-icon>
                    <div>At least one number</div>
                </div>
            </div>
        </div>

        <div class="acceptance" *ngIf="activateUser">
            <mat-checkbox [formControl]="accepted" color="primary" tabindex="3"></mat-checkbox>
            <span>I certify that I have read and accept the <b><a href="https://simplecj.com/terms.html"
                        target="_blank">Terms of Use</a></b> and <b><a href="https://simplecj.com/privacy.html"
                        target="_blank">Privacy Policy</a></b></span>
        </div>
        
        <div class="flex-row cancel-submit">
            <button class="backButton" routerLink="/login">Cancel</button>
            <button class="submitButton" type="submit" tabindex="4" *ngIf="activateUser"
                [disabled]="!accepted.value || activateUserForm.invalid">Submit</button>

                <button class="submitButton" type="submit" tabindex="4" *ngIf="!activateUser"
                [disabled]="activateUserForm.invalid">Submit</button>
        </div>
    </form>
</div>