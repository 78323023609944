<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" class="flex-column editor">
        <div class="flex-row chat-title">
            <h2 class="scj-subheader">Messages</h2>
            <div class="flex-row">
                <mat-icon *ngIf="!refreshSpinner" (click)="refreshMessages()">refresh</mat-icon>
                <mat-spinner *ngIf="refreshSpinner" [diameter]="24" [strokeWidth]="2"></mat-spinner>
                <mat-icon mat-dialog-close>close</mat-icon>
            </div>
        </div>

        <div class="chat-content" #scroll [scrollTop]="scroll.scrollHeight">
            <ng-container *ngFor="let message of messagesList">
                <div class="flex-column message-body">
                    <div class="flex-row message-align">
                        <div class="name-highlight" [class.client]="!message.isCpa">{{message.createdBy | letters}}
                        </div>
                        <div>
                            <span class="name">{{message.createdBy}}</span>
                            <span class="date">{{message.createdOn}}</span>
                            <div [innerHtml]="message.message | domSafe"> </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="flex-row chat-input">
            <div #inputMessage placeholder="Type your message" contenteditable="true"></div>
            <mat-icon *ngIf="!sendSpinner" (click)="createMessages()">send</mat-icon>
            <mat-spinner *ngIf="sendSpinner" [diameter]="24" [strokeWidth]="2"></mat-spinner>
        </div>
    </div>
</div>