<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20" *ngIf="!signTemplateId">New Sign Template</h1>

            <h1 class="scj-h2 mr-20" *ngIf="signTemplateId">
                <span class="text-header">E-Sign Template - </span>
                {{header}}
            </h1>

            <div class="flex-row">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button class="secondary" mat-flat-button (click)="cancel()">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form class="flex-column" [formGroup]="signTemplateForm">
            <div class="flex-row align-fields">
                <mat-form-field appearance="outline">
                    <mat-label>Sign Template Name</mat-label>
                    <input type="text" placeholder="Sign Template Name" matInput formControlName="name" required>
                    <mat-error *ngIf="signTemplateForm.get('name').invalid">
                        <mat-error *ngIf="signTemplateForm.get('name')?.hasError('required')">
                            Sign Template Name required
                        </mat-error>
                        <mat-error *ngIf="signTemplateForm.get('name')?.hasError('maxlength')">
                            Sign Template Name can't exceed more than 250 characters</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="signTemplateId">
                    <mat-label>Status</mat-label>
                    <mat-select placeholder="Status" formControlName="status" disableOptionCentering required>
                        <mat-option *ngFor="let stat of status" [value]="stat.statKey">{{stat.statValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div>
                    <div class="template-upload" *ngIf="!pdfBase64">
                        <div class="dropzone" appFilesDropZone (filesHovered)="toggleHover($event)"
                            (filesDropped)="onDrop($event)" [class.hovering]="isHovering">
                            <input type="file" #fileField (change)="onDrop($event.target.files)"
                                accept="application/pdf" hidden>
                            <div class="upload-actions" (click)="fileField.click()">
                                <mat-icon>cloud_upload_outlined</mat-icon>
                                Drag & Drop PDF file here, or
                                <a>browse</a>
                                <mat-icon (click)="toggle(tooltip);$event.stopPropagation()" #tooltip="matTooltip"
                                    matTooltip="Upload PDF Document only">
                                    info_outlined
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <h3 class="template-upload" *ngIf="pdfBase64">
                        <span>{{signTemplateForm.get('docName').value}}</span>
                        <mat-icon *ngIf="!signTemplateId" (click)="setFileValidations()" class="delete-icon">delete
                        </mat-icon>
                    </h3>
                    <div *ngIf="fileError && !pdfBase64" style="color: #f13637;">{{fileError}}</div>
                </div>
            </div>

            <div class="flex-row">

                <mat-form-field appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" [min]="minStartDate" formControlName="startDate"
                        placeholder="Start Date" required readonly>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker" matTooltip="Select start date"
                        [disableRipple]="true">
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker color="primary" [touchUi]="isHandset$ | async"
                        [restoreFocus]="false">
                    </mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" class="endDate">
                    <mat-label>End Date</mat-label>
                    <input matInput #endDate [matDatepicker]="endDatePicker" [min]="minEndDate"
                        formControlName="endDate" placeholder="End Date" readonly>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker" matTooltip="Select end date"
                        [disableRipple]="true">
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker color="primary" [touchUi]="isHandset$ | async"
                        [restoreFocus]="false"></mat-datepicker>
                    <mat-icon matSuffix *ngIf="endDate.value" (click)="resetEndDate()" matTooltip="Clear">
                        clear
                    </mat-icon>
                    <mat-error *ngIf="signTemplateForm.get('endDate').invalid">
                        Select end date
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

        <div class="esign-container">
            <div>
                <mat-form-field appearance="outline" style="padding-top: 6px;">
                    <mat-label>Select User</mat-label>
                    <mat-select placeholder="Select User" [formControl]="selectedUserControl" disableOptionCentering
                        [compareWith]="compareFn">
                        <mat-select-trigger>
                            <svg height="15px" width="15px">
                                <circle cx="8" cy="9" r="6" [attr.fill]="selectedUserControl.value.color"></circle>
                            </svg>
                            &nbsp;
                            <span> User {{selectedUserControl.value.index + 1}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let user of userList" [value]="user">
                            <div style="display: flex;flex-direction: row; align-items: center;">
                                <svg height="15px" width="15px">
                                    <circle cx="8" cy="8" r="7" [attr.fill]="user.color"></circle>
                                </svg>
                                &nbsp;
                                <span>User {{ user.index + 1 }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div id="cloner" draggable="true" class="field-view" style="display: none;">
                    <img class="esign-icon" src="">
                    <span class="display-text"></span>
                    <span class="esign-tooltip"></span>
                    <ng-template *ngTemplateOutlet="deleteField">
                    </ng-template>
                </div>

                <div id="fields-list" class="fields-list">
                    <div class="scj-subheader">Fields</div>
                    <div draggable="true" class="field-view draggable" field-type="NAME"
                        attr.username="USER_{{userSelected.index + 1}}" attr.name="User {{userSelected.index + 1}}">
                        <img class="esign-icon" [src]="iconList.name">
                        <span class="display-text">Name</span>
                        <span class="esign-tooltip">User {{userSelected.index + 1}} Name</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                    <div draggable="true" class="field-view draggable" field-type="INITIALS"
                        attr.username="USER_{{userSelected.index + 1}}" attr.name="User {{userSelected.index + 1}}">
                        <img class="esign-icon" [src]="iconList.initial">
                        <span class="display-text">Initials</span>
                        <span class="esign-tooltip">User {{userSelected.index + 1}} Initials</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                    <div draggable="true" class="field-view draggable" field-type="SIGNATURE"
                        attr.username="USER_{{userSelected.index + 1}}" attr.name="User {{userSelected.index + 1}}">
                        <img class="esign-icon" [src]="iconList.sign">
                        <span class="display-text">Signature</span>
                        <span class="esign-tooltip">User {{userSelected.index + 1}} Signature</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                    <div draggable="true" class="field-view draggable" field-type="SIGN_DATE"
                        attr.username="USER_{{userSelected.index + 1}}" attr.name="User {{userSelected.index + 1}}">
                        <img class="esign-icon" [src]="iconList.date">
                        <span class="display-text">Sign Date</span>
                        <span class="esign-tooltip">User {{userSelected.index + 1}} Sign Date</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="pdf-container">
                <div class="page" *ngFor="let page of pages;let index = index;" [style.width]="page.width+'px'"
                    [style.height]="page.height+'px'">
                    <img [src]="page.data" [style.width]="page.width+'px'" [style.height]="page.height+'px'">
                    <div class="page-index">{{ index + 1 }} of {{pages.length}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteField>
    <svg id="close" height="16px" width="16px" viewBox="0 0 24 24" fill="red" class="field-delete field-delete-hide">
        <path d="M7.05026 7.55025L16.9498 17.4497" stroke="red" stroke-width="3" stroke-linecap="round"
            stroke-linejoin="round"></path>
        <path d="M16.9497 7.55026L7.05017 17.4497" stroke="red" stroke-width="3" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
</ng-template>