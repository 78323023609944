<div class="flex-row jc-sb-ai-c">
    <h1 class="scj-h2 mr-20">Files</h1>

    <div class="search-filter">
        <div class="search-field">
            <mat-icon>search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>
    </div>
</div>

<div class="flex-row" *ngIf="attachmentData?.length>0">
    <button *ngIf="checklistSelection.selected.length>0" mat-flat-button (click)="downloadSelectedFiles()"
        class="secondary">
        <mat-icon class="button-icon">cloud_download</mat-icon>
        <span class="button-text">Download Files</span>
    </button>
    <button *ngIf="!isClient" mat-flat-button (click)="mergeFiles()" class="secondary">
        <mat-icon class="button-icon">picture_as_pdf_outline</mat-icon>
        <span class="button-text">Create PDF</span>
    </button>
    <div *ngIf="isSpinner">
        <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
    </div>
</div>

<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">

        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox color="primary" (change)="masterToggle()"
                            [checked]="checklistSelection.hasValue() && isAllSelected()"
                            [indeterminate]="checklistSelection.hasValue() && !isAllSelected()"> Name</mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let node" style="display:flex;align-items: center">
                        <div style="display:flex" [style.marginLeft.px]="(node.level == 1 && node.expandable)? 56 : 0">
                            <mat-checkbox *ngIf="treeControl.isExpandable(node)" color="primary"
                                [checked]="descendantsAllSelected(node)"
                                [indeterminate]="descendantsPartiallySelected(node)"
                                (change)="nodeSelectionToggle(node)">

                            </mat-checkbox>
                            <div *ngIf="treeControl.isExpandable(node)">
                                <mat-icon class="file-icon" svgIcon="folder"></mat-icon> <span class="hyperlink"
                                    (click)="navigate(node)">{{node.name}}</span>
                            </div>
                        </div>
                        <button mat-icon-button [style.visibility]="!node.expandable ? 'hidden' : ''"
                            [style.marginRight.px]="(node.level == 1 && !node.expandable)? 16 : 64"
                            (click)="treeControl.toggle(node)">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-checkbox *ngIf="!node.expandable" color="primary" class="node-checkbox"
                            [checked]="checklistSelection.isSelected(node)" (change)="leafSelectionToggle(node)">
                        </mat-checkbox>
                        <span *ngIf="!node.expandable" class="file" (click)="downloadFile(node)">
                            <mat-icon class="file-icon"
                                svgIcon="{{node.name.includes('pdf')? 'pdf' : node.name.includes('docx')? 'word' : node.name.includes('xlsx')? 'excel' : 'text'}}">
                            </mat-icon>
                            {{node.name}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="uploadedBy">
                    <th mat-header-cell *matHeaderCellDef> Uploaded By </th>
                    <td mat-cell *matCellDef="let node"> {{node.uploadedBy}} </td>
                </ng-container>

                <ng-container matColumnDef="uploadedDate">
                    <th mat-header-cell *matHeaderCellDef> Uploaded Date Time</th>
                    <td mat-cell *matCellDef="let node"> {{node.uploadedDateTime}} </td>
                </ng-container>

                <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef> Comments</th>
                    <td mat-cell *matCellDef="let node"> {{node.comments}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                <tr class="no-data" *matNoDataRow>
                    <td [attr.colspan]="displayColumns.length">
                        No Files Found
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>