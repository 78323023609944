import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'scj-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent implements OnInit, AfterViewInit {
  header: string;
  message: string = "Are you sure?";
  confirmButtonText = "Ok";
  cancelButtonText = "Cancel";
  isHTML: boolean = false;
  button1: string = "primary";
  button2: string = "secondary";

  @ViewChild('displayHTML')
  displayHTML: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AlertMessagesComponent>) {
    if (this.data) {
      this.header = this.data?.header;
      this.message = this.data.message || this.message;
      this.isHTML = this.data?.isHTML;
      this.confirmButtonText = this.data?.buttonText?.ok || this.confirmButtonText;
      this.cancelButtonText = this.data?.buttonText?.cancel || this.cancelButtonText;
      this.button1 = this.data?.revertBoolean ? "secondary" : this.button1;
      this.button2 = this.data?.revertBoolean ? "primary" : this.button2;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.isHTML)
      this.displayHTML.nativeElement.innerHTML = this.message;
  }

  accept(): void {
    this.dialogRef.close(this.data?.revertBoolean ? false : true);
  }

  close() {
    this.dialogRef.close(this.data?.revertBoolean ? true : false);
  }
}
