export const UserValidationMessages = {
    "fullname": [
        { type: "required", message: "Full name is required" },
        { type: "maxlength", message: "Full name can't exceed 250 characters" }
    ], 
    "prefferedName": [
        { type: "maxlength", message: "Preffered name can't exceed 250 characters" },
    ],
    "phoneNumber": [
        // { type: "required", message: "Phone number is required" },
        { type: "pattern", message: "Phone number must be formatted or have only numbers" }
    ],
    "username": [
        { type: "required", message: "Email is required" },
        { type: "email", message: "Email is invalid" },
        { type: "maxlength", message: "Email can't exceed 250 characters" }
    ],
    "status": [
        { type: "required", message: "Status is required" }
    ],
    "comments": [
        { type: "maxlength", message: "Comments can't exceed 4000 characters" }
    ],
    "roleIdList": [
        { type: "required", message: "Atleast one role is rquired" }
    ],
    "address": {
        "addressLine1": [
            // { type: "required", message: "Address is required" },
            { type: "maxlength", message: "Address can't exceed 500 characters" }
        ],
        "city": [
            // { type: "required", message: "City is required" },
            { type: "pattern", message: "Numbers or Special characters are not allowed" }
        ],
        "state": [
            // { type: "required", message: "State is required" },
            { type: "pattern", message: "Numbers or Special characters are not allowed" },
            { type: "maxlength", message: "State can't exceed 150 characters" }
        ],
        "zip": [
            // { type: "required", message: "Zip is required" },
            { type: "maxlength", message: "Zip can't exceed 15 characters" }
        ]
    }
};