<div class="table-responsive">
    <div class="table-action-list">
        <div class="primarySet">
            <ng-content select="[primaryAction]"></ng-content>

            <ng-content select="[secondaryAction]"></ng-content>

            <button *ngIf="(selection.selected.length>0)" (click)="exportToExcel()" mat-flat-button class="secondary"
                matTooltip="Download CSV">
                <mat-icon class="button-icon">cloud_download</mat-icon>
                <span class="button-text"> Download CSV</span>
            </button>

            <ng-content select="[spinner]"></ng-content>


        </div>

        <div class="secondarySet">
            <div class="select-list" *ngIf="selection.selected.length > 0">
                <span>{{selection.selected.length}} <span class="button-text">selected</span> </span>
                <span style="display:flex;cursor: pointer;">
                    <mat-icon (click)="clearSelection()">close</mat-icon>
                </span>
            </div>
        </div>
    </div>

    <div class="filter-container">
        <div *ngFor="let item of filterValue">
            <div *ngIf="item.type === 'select' && item.value?.length>0" class="flex-row">
                <div class="chip filter-chip">
                    <div class="filter-label"> {{item.label}} : </div>
                    <div class="filter-value">{{item.value.join(", ")}}</div>
                    <mat-icon (click)="clearFilter(item.name)" class="clear-filter-icon">close</mat-icon>
                </div>
            </div>
            <div *ngIf="item.type === 'number' && (item.value?.min || item.value?.max)" class="flex-row">
                <div class="chip filter-chip">
                    <div class="filter-label"> {{item.label}} : </div>
                    <div class="filter-value">
                        <div *ngIf="item.value.min">Minimum : {{item.value?.min}}</div>
                        <div *ngIf="item.value.min && item.value.max" class="pr-8"></div>
                        <div *ngIf="item.value.max">Maximum : {{item.value?.max}}</div>
                    </div>
                    <mat-icon (click)="clearFilter(item.name)" class="clear-filter-icon">close</mat-icon>
                </div>
            </div>
            <div *ngIf="item.type === 'date' && (item.value.startDate || item.value.endDate)" class="flex-row">
                <div class="chip filter-chip">
                    <div class="filter-label"> {{item.label}} : </div>
                    <div class="filter-value">
                        <div *ngIf="item.value.startDate">Start : {{item.value.startDate | date: 'dd-MMM-YYYY'}}</div>
                        <div *ngIf="item.value.startDate && item.value.endDate" class="pr-8"></div>
                        <div *ngIf="item.value.endDate">End : {{item.value.endDate | date:'dd-MMM-YYYY'}}</div>
                    </div>
                    <mat-icon (click)="clearFilter(item.name)" class="clear-filter-icon">close</mat-icon>
                </div>
            </div>
            <div *ngIf="item.type === 'string' && item.value" class="flex-row">
                <div class="chip filter-chip">
                    <div class="filter-label"> {{item.label}} : </div>
                    <div class="filter-value">{{item.value}}</div>
                    <mat-icon (click)="clearFilter(item.name)" class="clear-filter-icon">close</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="table-content" matSort>

        <ng-container *ngFor="let col of columnConfig; let index = index">
            <ng-container *ngIf="col.name !== 'pay'" matColumnDef="{{col.name}}">
                <th mat-header-cell *matHeaderCellDef [class.select]="col.name === 'select'">
                    <div *ngIf="col.name === 'select'">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </div>
                    <!-- [class.jc-fe]="col?.format === 'currency'" 
                    [ngClass]="{'header-col':col.type !== 'number','digital': col.type === 'number'}"-->
                    <div *ngIf="col.name !== 'select'" class="header-col">
                        <div mat-sort-header>{{col.header}}</div>
                    </div>
                </th>

                <td mat-cell *matCellDef="let element"
                    [attr.cell]="(col.name === 'displayStatus' || col.name === 'status' || col.name === 'paymentStatus')? 'status-'+(element.paymentStatus? 'payment-'+element.paymentStatus : col.classType? col.classType+'-'+element.status : element.status) : col.name">

                    <div *ngIf="col.name === 'select'" class="select">
                        <mat-checkbox (change)="toggleSelection(element)" [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </div>
                    <!-- [class.currency]="col?.format === 'currency'" 
                    {'column-align':col.type !== 'number','digital': col.type === 'number'}-->
                    <div class="column-align">
                        <span appEllipsifyMe [ngClass]="col.class" [class.enable-navigation]="col?.enableNavigation"
                            (click)="col?.enableNavigation? cellselected(element) : ''">
                            {{element[col.name] | formatColumn: col.format}}
                        </span>
                        <mat-icon svgIcon="sign" matTooltip="E-Sign document"
                            *ngIf="(col.name === 'displayStatus') && element.canEsign"
                            (click)="$event.stopPropagation();esign(element);"></mat-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="col.name === 'pay'" matColumnDef="{{col.name}}">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" [attr.cell]="col.name">
                    <ng-container *ngIf="!isFirmUser && element.invoiceNumber && element.paymentStatus === 'Active'">
                        <div class="payment-button"
                            [style.grid-gap]="(isAchConfigured && isPaypalConfigured)? '16px' : '0px'">

                            <div *ngIf="isPaypalConfigured" scjPaypal description="Payment for {{element.serviceName}}"
                                [amount]="element.scheduleAmount"
                                (paymentStatus)="markAsPaid($event, element?.invoiceId, element?.invoiceNumber)">
                            </div>

                            <scj-ach-payment *ngIf="isAchConfigured" [amount]="element?.scheduleAmount"
                                [invoiceId]="element?.invoiceId" [invoiceNum]="element?.invoiceNumber"
                                [textRequired]="false" (paymentStatus)="markAsPaidAch($event)">
                            </scj-ach-payment>
                        </div>

                    </ng-container>
                </td>
            </ng-container>


        </ng-container>

        <tr class="table-header" mat-header-row *matHeaderRowDef="displayColumns;sticky: true"></tr>
        <ng-container>
            <tr mat-row *matRowDef="let row; columns: displayColumns;let index=index"
                [class.row-select]="selection.isSelected(row)? true : false">
            </tr>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayColumns.length">
                {{dataSource.data?.length === 0? (errorMessege?errorMessege:'No data found') :'No data matching filter
                value'}}</td>
        </tr>
    </table>

    <mat-paginator *ngIf="!(isHandset$ | async) && enablePaginator" #paginator [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons (page)="pageChanged($event)">
    </mat-paginator>
</div>