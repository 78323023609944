import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientSubscriptionModel } from '../models/client-subscription.model';
import { TemplateModel } from '../models/template.model';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { FormGroupService } from '../shared/services/form-group.service';
import { NotificationService } from '../shared/services/notification.service';
import { TabsModel } from '../models/tabs.model';
import { SectionsModel } from '../models/sections.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BillingComponent } from '../billing/billing.component';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { BillingScheduleModel } from '../models/billing-schedule.model';
import { TableColumnsConfig } from '../models/table-columns-config.model';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { DataService } from '../data-service.service';
import { HandSetService } from '../shared/services/hand-set.service';
import { MatTooltip } from '@angular/material/tooltip';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { PRIORITY } from '../shared/constants/app.constants';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { of } from 'rxjs/internal/observable/of';
import { SubscriptionNotesComponent } from '../subscription-notes/subscription-notes.component';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'scj-client-subscription-details',
  templateUrl: './client-subscription-details.component.html',
  styleUrls: ['./client-subscription-details.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class ClientSubscriptionDetailsComponent implements OnInit, OnChanges, ComponentCanDeactivate {
  isHandSet: boolean;
  isLoading = true;
  isError = false;
  subscription: ClientSubscriptionModel;
  template: TemplateModel;
  tabsFormGroup: FormGroup
  stepperStatus: any;
  isFirmUser: boolean;
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  assignee: FormControl;
  usersList: any;
  firstTimeLoad: boolean = true;
  prevAssignee: string;
  completed: boolean = false;
  warningMessage: string;
  isSpinner: boolean = false;
  dueDateControl: FormControl;
  minStartDate: Date | null = new Date();
  priorityControl: FormControl;
  priorityList: any[] = PRIORITY;
  //prevPriority: string;
  //prevDueDate: Date;

  @Input()
  subscriptionId: string;

  @ViewChild("viewBillingModel")
  viewBillingModel: TemplateRef<any>;

  constructor(private handset: HandSetService, private http: HttpCommonService, private activatedRoute: ActivatedRoute, private dialog: MatDialog,
    private fgService: FormGroupService, private notificationService: NotificationService, private httpService: HttpCommonService, private matDialog: MatDialog, private router: Router,
    private authenticationService: AuthenticationService, private dataService: DataService) {
    this.isHandSet = this.handset.isHandset;
    this.subscriptionId = this.activatedRoute.snapshot.params.subscriptionId || this.subscriptionId;
    this.isFirmUser = this.authenticationService.userValue.roles.some(role => ['firmadmin', 'firmemployee'].includes(role));
  }

  ngOnInit(): void {
    this.getClientSubscriptionDetails();
    this.getUsersList();

    this.assignee = new FormControl('');
    //this.dueDate = new FormControl({ value: null, disabled: !this.isFirmUser }, Validators.compose([Validators.required]))
    this.dueDateControl = new FormControl(null);
    this.priorityControl = new FormControl('');
    this.assignee.valueChanges.subscribe((val: string) => {
      if (!this.firstTimeLoad && this.prevAssignee !== val) {
        this.confirmUpdateAssignee();
      } else {
        this.firstTimeLoad = false;
      }
    });

    this.priorityControl.valueChanges.subscribe((val: string) => {
      if (this.subscription.priority !== val) {
        this.confirmPriorityDueDate("PRIORITY");
      }
    });

    this.dueDateControl.valueChanges.subscribe((val: string) => {
      let dueDate = (val === null) ? null : formatMomentDate(val);
      if (!!dueDate && this.subscription.dueDate !== dueDate) {
        this.confirmPriorityDueDate("DUE_DATE");
      }
    });
  }

  confirmPriorityDueDate(option: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '20%';
    dialogConfig.minHeight = '20%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message: `Do you want to update ${option === "PRIORITY" ? 'Priority' : 'Due Date'}?`,
      isHTML: false,
      buttonText: { ok: 'Yes', cancel: 'No' },
      revertBoolean: false
    }
    this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.updatePriorityDueDate(option);
      } else {
        if (option === "PRIORITY") {
          this.priorityControl.setValue(this.subscription.priority);
        } else {
          this.dueDateControl.setValue(this.subscription.dueDate);
        }
      }
    });
  }

  updatePriorityDueDate(option: string) {
    let patchData = {};
    if (option === "PRIORITY") {
      patchData["priority"] = this.priorityControl.value;
    } else {
      patchData["dueDate"] = formatMomentDate(this.dueDateControl.value);
    }
    this.http.patchSubscriptionDetaisl(this.subscriptionId, patchData).subscribe((res: HttpResponse<any>) => {
      //if (res.status === 200) {
      this.notificationService.notifyText(`Subscription ${option === "PRIORITY" ? 'Priority' : 'Due Date'} updated successfully`);
      this.getClientSubscriptionDetails();
      //}
    }, (err: HttpErrorResponse) => {
      if (option === "PRIORITY") {
        this.priorityControl.setValue(this.subscription.priority);
      } else {
        this.dueDateControl.setValue(this.subscription.dueDate);
      }
      this.notificationService.notifyText(`Failed to update ${option === "PRIORITY" ? 'Priority' : 'Due Date'} to subscription`);
    });
  }

  toggle(tooltip: MatTooltip) {
    return tooltip.toggle();
  }

  getSubscriptionsData(data: any) {
    return `Id: ${data.subscriptionId}
      Created: ${data.serviceRequested}
      Modified: ${data.lastUpdateDate}`;
  }

  // getErrorStepper() {
  //   return this.template.tabs.filter((e: TabsModel) => this.tabsFormGroup.controls[e.name].invalid).map((e: TabsModel) => e.label).join(", ");
  // }

  save(enabled: boolean) {
    if (enabled) {
      let data = this.tabsFormGroup.getRawValue();
      this.http.saveClientSubscription(this.subscriptionId, JSON.stringify(data)).subscribe((res: HttpResponse<ClientSubscriptionModel>) => {
        if (res.status === 200) {
          //this.getClientSubscriptionDetails();
          this.notificationService.notifyText("Success! Subscription Saved");
        }
      }, (err: HttpResponse<any>) => {
        this.notificationService.notifyText("Something went wrong!! Please try after some time.");
      });
    }
  }

  onSubmit(nextAction: string) {
    this.tabsFormGroup.markAllAsTouched()
    if (!this.tabsFormGroup.invalid && nextAction !== 'CANCEL') {
      let data = this.tabsFormGroup.getRawValue();
      this.isLoading = true;
      this.isError = false;
      this.warningMessage = undefined;
      this.http.submitClientSubscription(this.subscriptionId, { formData: JSON.stringify(data), nextAction: nextAction })
        .subscribe((res: HttpResponse<ClientSubscriptionModel>) => {
          if (res.status == 200) {
            this.getClientSubscriptionDetails();
            this.notificationService.notifyText("Sucess! Subscription Submitted");
          }
        }, (err: HttpErrorResponse) => {
          this.isLoading = false;
          if (err.error) {
            this.warningMessage = err.error.message;
            this.notificationService.notifyText(`${this.warningMessage}`);
          } else {
            this.isError = true;
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
    } else if (nextAction === 'CANCEL') {
      let data = this.tabsFormGroup.getRawValue();
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.width = '30%';
      dialogConfig.minHeight = '20%';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.closeOnNavigation = false;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: 'Are you sure you want to Cancel the Subscription?',
        isHTML: false,
        buttonText: { ok: 'No', cancel: 'Yes' },
        revertBoolean: false
      }
      this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
        if (!confirm) {
          this.isLoading = true;
          this.isError = false;
          this.warningMessage = undefined;
          this.http.submitClientSubscription(this.subscriptionId, { formData: JSON.stringify(data), nextAction: nextAction })
            .subscribe((res: HttpResponse<ClientSubscriptionModel>) => {
              if (res.status == 200) {
                this.getClientSubscriptionDetails();
                this.notificationService.notifyText("Subscription canceled successfully.");
              }
            }, (err: HttpErrorResponse) => {
              this.isLoading = false;
              if (err.error) {
                this.warningMessage = err.error.message;
                this.notificationService.notifyText(`${this.warningMessage}`);
              } else {
                this.isError = true;
                this.notificationService.notifyText("Something went wrong!! Please try after some time.");
              }
            });
        }
      });
    } else {
      this.notificationService.notifyText(`Information required in tabs: ${this.template.tabs.filter((e: TabsModel) => this.tabsFormGroup.controls[e.name].invalid).map((e: TabsModel) => e.label).join(", ")}.`);
    }

  }

  createBilling() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = this.isHandSet ? "95vw" : "80vw";
    dialogConfig.maxWidth = this.isHandSet ? "95vw" : "40vw";
    dialogConfig.minHeight = "50vh";
    dialogConfig.height = this.isHandSet ? "95vh" : "50vh";
    dialogConfig.maxHeight = this.isHandSet ? "95vh" : "80vh";
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { name: this.subscription.serviceName, id: this.subscriptionId };

    let dialogRef = this.dialog.open(BillingComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data: any) => {
      //   this.subscription.billCreated = data.billCreated;
      //   this.subscription.serviceFee = data.serviceFee;
      this.getClientSubscriptionDetails();
    });

  }


  viewInvoice() {
    this.isSpinner = true;
    this.httpService.viewInvoiceByInvoiceId(this.subscription.invoiceId).subscribe((data: any) => {
      let file = new Blob([data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      this.isSpinner = false;
      window.open(fileURL, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=600,width=800,scrollbars=yes,status=yes');
    });
  }


  deleteBilling() {
    let header = 'Confirm Delete';
    let message = `Are you sure you want to delete the Invoice/s </b>`;
    this.opeDialog(header, message).afterClosed().pipe(switchMap((confirm: any) => {
      if (confirm) {
        this.isSpinner = true;
        return this.httpService.deleteBilling(this.subscriptionId);
      } else {
        return of(confirm);
      }
    })).subscribe((res: boolean | HttpResponse<any>) => {
      if (res) {
        this.isSpinner = false;
        this.notificationService.notifyText(`Success! Invoice deleted`);
        this.getClientSubscriptionDetails();
      }
    },
      (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(
          `Failed! Unable to delete Invoice. 'If the Problem persists Please contact support@simplecj.com`
        );
      }
    );
  }

  opeDialog(header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true,
    };
    return this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(
      AlertMessagesComponent,
      dialogConfig
    );
  }

  ngOnChanges() {
    this.isLoading = true;
    this.isError = false;
    this.getClientSubscriptionDetails();
  }

  getClientSubscriptionDetails() {
    this.isLoading = true;
    this.isError = false;
    this.firstTimeLoad = true;
    this.http.getClientSubscriptionById(this.subscriptionId).subscribe((res: HttpResponse<ClientSubscriptionModel>) => {
      if (res.body !== null) {
        this.subscription = res.body;
        this.prevAssignee = this.subscription.cpaUserId;
        this.assignee.setValue(this.prevAssignee);
        this.priorityControl.setValue(this.subscription.priority);
        this.dueDateControl.setValue(!!this.subscription.dueDate ? moment(this.subscription.dueDate) : null);
        this.stepperStatus = this.subscription.uiStatus;
        let templateJson = JSON.parse(this.subscription.templateJson)
        this.template = Object.assign(new TemplateModel, templateJson);
        this.tabsFormGroup = this.fgService.toFormGroupByTabs(this.template.tabs);

        this.template.tabs.forEach((tab: TabsModel, i) => {
          tab.sections.forEach((sec: SectionsModel, j) => {
            if (sec.type === 'fileUpload') {
              let section = this.template.tabs[i].sections[j];
              if (this.subscription.status === 'COMPLETED' || this.subscription.status === 'CANCEL') {
                section.isEditable = false;
                section.canUploadFolder = false;
              }
              section.downloadUrl = "/api/v1/client/subscription/attachment/attachmentId";
              section.uploadUrl = `/api/v1/client/subscription/attachment/file/${this.subscriptionId}`;
              //section.attachmentListUrl = `/api/v1/client/subscription/list/attachment/${this.subscriptionId}`;
              section.attachmentListUrl = `/api/v1/client/subscription/${this.subscriptionId}/attachments`;
              section.deleteUrl = `/api/v1/client/subscription/attachment/delete`;
              section.folderUploadUrl = `/api/v1/client/subscription/attachment/folder/files/${this.subscriptionId}`;
            }
          });
        });

        if (this.subscription.formData) {
          let formData = JSON.parse(this.subscription.formData);
          this.template.tabs.forEach((tab: TabsModel, i) => {
            tab.sections.forEach((section: SectionsModel, j) => {
              if (section.type === 'table' || section.type === 'fileUpload') {
                let formsLen = formData[tab.name][section.name].length;
                let controlsArr = this.tabsFormGroup.controls[tab.name].get(section.name) as FormArray;
                if (formsLen > 0) {
                  for (let i = 1; i < formsLen; i++) {
                    controlsArr.push(this.fgService.toFormGroupByElements(section.elements));
                  }
                } else {
                  controlsArr.clear();
                }
              }
            });
          });
          this.tabsFormGroup.patchValue(formData);
        }
        //this.tabsFormGroup.markAllAsTouched();
        if (!this.subscription.allowSave || this.subscription.status === 'COMPLETED' || this.subscription.status === 'CANCEL') {
          this.template.tabs.forEach((tab: TabsModel, i) => {
            tab.sections.forEach((section: SectionsModel, j) => {
              if (!(this.subscription.status === 'COMPLETED' || this.subscription.status === 'CANCEL') && section.type === 'fileUpload') {
                this.template.tabs[i].sections[j].isEditable = true;
                if (!this.isFirmUser) {
                  this.template.tabs[i].sections[j].canUploadFolder = false;
                }
              } else {
                this.template.tabs[i].sections[j].isEditable = false;
                this.template.tabs[i].sections[j].canUploadFolder = false;
              }
            });
          });
          this.tabsFormGroup.disable();
        }
        this.isLoading = false;
      }
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }

  markAsPaid(payment: any) {
    if (payment.status === 'SUCESS') {
      this.notificationService.notifyText(`Paypal payment is successful for invoice id ${this.subscription.invoiceIdList}`);
      let data = { invoiceId: this.subscription.invoiceIdList, paymentReference: payment };
      this.http.updateMarkAsPaid(data).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.notificationService.notifyText(`Invoice payment status updated successfully`);
            this.getClientSubscriptionDetails();
          }, 3000);
        }
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
    } else {
      this.notificationService.notifyText(`Payment failed for invoice id ${this.subscription.invoiceIdList}`);
    }
  }

  canDeactivate() {
    return !this.tabsFormGroup?.dirty || this.isError;
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'invoiceNumber', header: 'Invoice Number' },
      { name: 'paymentStatus', header: 'Status' },
      { name: 'scheduleStartDate', header: 'Invoice Date' },
      { name: 'scheduleDueDate', header: 'Due Date' },
      { name: 'scheduleAmount', header: 'Amount' },
      { name: 'paymentMethod', header: 'Payment Method' },
      { name: 'paymentReference', header: 'Payment Reference' },
      { name: 'paymentDate', header: 'Payment Date' }
    ];
    return columns;
  }

  getUsersList() {
    this.http.getCompanyUsersForAssignment().subscribe((res: HttpResponse<any>) => {
      if (res.body !== null) {
        this.usersList = res.body;
      }
    }, (error: HttpErrorResponse) => {
      console.log("error", error);
    });
  }

  confirmUpdateAssignee() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '20%';
    dialogConfig.minHeight = '20%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message: 'Do you want to update assignee?',
      isHTML: false,
      buttonText: { ok: 'Yes', cancel: 'No' },
      revertBoolean: false
    }
    this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.updateAssignee();
      } else {
        this.assignee.setValue(this.prevAssignee);
      }
    });
  }

  updateAssignee() {
    this.http.assignUserToSubscription(this.subscriptionId, this.assignee.value).subscribe((res: HttpResponse<any>) => {
      //if (res.status === 200) {
      this.notificationService.notifyText('Subscription assignee updated successfully');
      this.getClientSubscriptionDetails();
      //}
    }, (err: HttpErrorResponse) => {
      this.notificationService.notifyText('Failed to update assignee to subscription');
    });
  }

  eSign() {
    let element = {
      id: this.subscription.esignAttachmentId,
      signatureRequired: this.subscription.signatureRequired,
      spouseSignatureRequired: this.subscription.spouseSignatureRequired,
      cpaSignatureRequired: this.subscription.cpaSignatureRequired,
      isSigned: this.subscription.isSigned,
      isSpouseSigned: this.subscription.isSpouseSigned,
      isCpaSigned: this.subscription.isCpaSigned
    };
    this.dataService.esign(element).subscribe((reload: boolean) => {
      if (reload) {
        this.getClientSubscriptionDetails();
      }
    });
  }

  openSubscriptionNotes() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.handset.isHandset ? '80vw' : '400px';
    dialogConfig.height = '75%';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = 'notes-panel';
    dialogConfig.data = { subscriptionId: this.subscriptionId };
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'transparent-mat-dialog-backdrop';
    dialogConfig.position = { bottom: '12px', left: '72px' };

    let dialogRef = this.dialog.open(SubscriptionNotesComponent, dialogConfig);
  }

}
