<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">

        <div *ngIf="subscription?.isPendingSignature && !isFirmUser" class="warning">
            Some of the documents require your signature
        </div>

        <div *ngIf="warningMessage" class="warning">
            {{warningMessage}}
        </div>

        <div>
            <div class="flex-column">
                <div class="flex-row space-between">
                    <div class="flex-column space-between expand-first">
                        <div class="flex-row" style="align-items: center;">
                            <div class="flex-row" style="margin-right: 24px;">
                                <ng-container
                                    *ngFor="let bt of subscription.availableActionList;let first=first;let last=last">
                                    <div class="button-padding">
                                        <button mat-flat-button [class.button-right-margin]="!last"
                                            (click)="onSubmit(bt.value)">
                                            {{bt.name}}
                                        </button>
                                    </div>
                                    <div *ngIf="bt?.addAnimation" style="position: relative;">
                                        <div class="subscription-submit" [hidden]="!tabsFormGroup.valid">
                                            <mat-icon>arrow_back</mat-icon>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <div *ngIf="!isFirmUser" class="flex-row">
                                <div *ngIf="subscription.amountDue && subscription.invoiceId" scjPaypal
                                    description="Payment for {{subscription.serviceName}}"
                                    [amount]="subscription.amountDue"
                                    (paymentStatus)="markAsPaid($event, subscription.invoiceId)" class="mb-12">
                                </div>
                                <button mat-flat-button class="secondary button-padding"
                                    *ngIf="subscription.billCreated" (click)="viewInvoice()">
                                    View Invoice
                                </button>
                                <div class="button-padding">
                                    <div *ngIf="isSpinner" class="right-margin">
                                        <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                                    </div>
                                </div>
                            </div>

                            <div class="rightSet">
                                <div class="flex-column justify-end">
                                    <div style="display: flex;" *ngIf="isFirmUser">
                                        <div class="button-padding">
                                            <button *ngIf="subscription?.editBilling" mat-flat-button class="secondary"
                                                (click)="deleteBilling()">
                                                Delete Invoice
                                            </button>
                                        </div>
                                        <div class="button-padding">
                                            <button mat-flat-button class="secondary"
                                                (click)="subscription.billCreated? viewInvoice() : createBilling()">
                                                {{subscription.billCreated? 'View Invoice' : 'Create Invoice'}}
                                            </button>
                                        </div>
                                        <div class="button-padding">
                                            <div *ngIf="isSpinner" class="right-margin">
                                                <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="section">
            <div *ngIf="isFirmUser">
                <div class="notes-fab">
                    <button mat-fab (click)="openSubscriptionNotes($event)">
                        <mat-icon>note_alt_outlined</mat-icon>
                    </button>
                </div>
            </div>
            <div class="template-loader">
                <scj-template-loader [tabsFormGroup]="tabsFormGroup" [tabs]="template.tabs"
                    [allowSave]="subscription.allowSave" (onSave)="save($event)">
                </scj-template-loader>
            </div>
            <div class="right-section">
                <div style="padding-bottom: 16px;">
                    <h5 class="scj-h2">
                        {{subscription.serviceName}}
                    </h5>
                    <div *ngIf="subscription.canEsign" (click)="eSign()" class="chip">
                        E-Sign
                    </div>
                </div>
                <div>
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Id</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text" value="{{subscription.subscriptionId}}" readonly
                            disabled>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Status</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text" value="{{subscription.displayStatus}}" readonly disabled>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="subscription-field no-line" *ngIf="!subscription?.isEditCpaAssignment">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Asignee</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text" value="{{subscription.cpa}}" readonly disabled>
                    </mat-form-field>

                    <mat-form-field class="subscription-field no-line" *ngIf="subscription?.isEditCpaAssignment">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Asignee</mat-label>
                        <!-- </div> -->
                        <mat-select [formControl]="assignee" class="highlight-text" disableOptionCentering>
                            <mat-option *ngFor="let user of usersList" [value]="user.id">
                                {{user.fullname}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="isFirmUser">
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Priority</mat-label>
                        <!-- </div> -->
                        <mat-select placeholder="Default Payment Term" [formControl]="priorityControl"
                            disableOptionCentering class="highlight-text">
                            <mat-option *ngFor="let priority of priorityList" [value]="priority">
                                {{priority}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="isFirmUser">
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Due Date</mat-label>
                        <!-- </div> -->
                        <input matInput [matDatepicker]="datePicker" [min]="minStartDate" [formControl]="dueDateControl"
                            placeholder="Due Date" readonly class="highlight-text">
                        <mat-datepicker-toggle matSuffix [for]="datePicker" matTooltip="Select due date"
                            [disableRipple]="true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #datePicker color="primary" [touchUi]="isHandset$ | async"
                            [restoreFocus]="false">
                        </mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Service Fee ($)</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text"
                            value="{{subscription?.serviceFee? (subscription?.serviceFee | currency) : '-'}}" readonly
                            disabled>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Amount Due ($)</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text"
                            value="{{subscription?.amountDue? (subscription?.amountDue | currency) : '-'}}" readonly
                            disabled>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Created</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text" value="{{subscription.serviceRequested}}" readonly
                            disabled>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="subscription-field no-line">
                        <!-- <div> -->
                        <mat-label class="subscription-label">Modified</mat-label>
                        <!-- </div> -->
                        <input matInput class="highlight-text" value="{{subscription.lastUpdateDate}}" readonly
                            disabled>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewBillingModel let-data>
    <div class="flex-row view-bill-header">
        <h2 class="scj-h2">
            <span class="text-header">Billing - </span>
            <span>{{data.name}}</span>
        </h2>

        <button mat-flat-button class="secondary" mat-dialog-close>
            <mat-icon class="button-icon">close</mat-icon>
            <span class="button-text">Cancel</span>
        </button>
    </div>

    <scj-table-content [data]="data.scheduleList" [columnConfig]="columnConfig" [enableSearch]="false"
        [enableFilter]="false" [enableSort]="false" [enablePaginator]="false" [selectRow]="false">
    </scj-table-content>
</ng-template>