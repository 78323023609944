import { Pipe, PipeTransform } from '@angular/core';

export type Capitalize = true | false;

@Pipe({
  name: 'letters'
})
export class LettersPipe implements PipeTransform {

  transform(value: string, len: number = 1, capitalize: Capitalize | boolean = true): any {
    if (!value) return value;

    value = value.substr(0, len);

    if (capitalize) {
      return value.toUpperCase();
    }

    return value.toLowerCase();
  }

}
