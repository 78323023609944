import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';
import { PasswordService } from '../shared/services/password.service';
import { CustomValidators } from '../shared/validators/form-validators/custom.validators';
import { PASSWORD_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';

@Component({
  selector: 'scj-activate-user',
  templateUrl: './activate_update-user.component.html',
  styleUrls: ['./activate_update-user.component.scss'],
})
export class ActivateUserComponent implements OnInit {
  activateUserForm: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;
  passwordTooltip =
    'Password must be 8 characters length with atleast 1 lowercase, 1 upper case letter & numbers';
  encryptedString: string;
  username: string;
  errorsList: string[] = [];
  accepted: FormControl = new FormControl(false);
  passwordValidators: any = { length: {}, lower: {}, upper: {}, number: {} };
  activateUser: boolean = false;
  activatedRouteActive: ActivatedRoute;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ps: PasswordService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {

    this.activatedRouteActive = this.activatedRoute;
    if (this.activatedRoute.snapshot.routeConfig.path === 'activate-user') {
      this.activateUser = true;
    }
    this.encryptedString = this.activatedRoute.snapshot.queryParams.enc;
    this.username = this.activatedRoute.snapshot.queryParams.username;

    this.activateUserForm = this.fb.group(
      {
        username: [this.username],
        password: [
          null,
          [Validators.required, Validators.pattern(PASSWORD_PATTERN)],
        ],
        retypePassword: ['', [Validators.required]],
        encryptedString: [this.encryptedString, Validators.required],
      },
      {
        validator: CustomValidators.passwordMatch('password', 'retypePassword'),
      }
    );

    this.passwordValidator();
    this.activateUserForm
      .get('password')
      .valueChanges.subscribe((val: string) => {
        this.passwordValidator(val);
      });
  }

  onSubmit(pm: any) {
    this.activateUserForm.markAllAsTouched();
    this.errorsList = [];
    if (
      this.validLink(this.activateUserForm.get('encryptedString').value) &&
      this.activateUserForm.valid &&
      this.activateUser
    ) {
      delete pm['username'];
      this.ps.setPassword(pm).subscribe(
        (res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.notificationService.notifyText(
              'New Password set successfully.'
            );
            this.router.navigateByUrl('/login');
          } else {
            this.notificationService.notifyText(
              'Something went wrong. Please try after some time.'
            );
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) =>
              this.errorsList.push(error.message)
            );
          } else if (err.status === 500) {
            this.notificationService.notifyText(err.error?.message);
          } else {
            this.notificationService.notifyText('Something went wrong. Please try after some time.');
          }
        }
      );
    } else if (
      this.validLink(this.activateUserForm.get('encryptedString').value) &&
      this.activateUserForm.valid &&
      !this.activateUser
    ) {
      delete pm['username'];
      this.ps.updateForgotPassword(pm).subscribe(
        (res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.notificationService.notifyText(
              'New Password set successfully.'
            );
            this.router.navigateByUrl('/login');
          } else {
            this.notificationService.notifyText(
              'Something went wrong. Please try after some time.'
            );
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) =>
              this.errorsList.push(error.message)
            );
          } else {
            this.notificationService.notifyText(
              'Something went wrong. Please try after some time.'
            );
          }
          console.log('error', err);
        }
      );
    }
  }

  toggle(tooltip: MatTooltip) {
    return tooltip.toggle();
  }

  validLink(link: string): boolean {
    if (link !== undefined && link?.length > 0) return true;

    this.notificationService.notifyText('Activation link is invalid/expired');
    return false;
  }

  passwordValidator(password: string = null) {
    if (password === null) {
      this.passwordValidators.length.name = 'fiber_manual_record';
      this.passwordValidators.lower.name = 'fiber_manual_record';
      this.passwordValidators.upper.name = 'fiber_manual_record';
      this.passwordValidators.number.name = 'fiber_manual_record';
      this.passwordValidators.length.value = null;
      this.passwordValidators.lower.value = null;
      this.passwordValidators.upper.value = null;
      this.passwordValidators.number.value = null;
      return;
    }

    if (password.length > 7) {
      this.passwordValidators.length.name = 'check_circle_outlined';
      this.passwordValidators.length.value = true;
    } else {
      this.passwordValidators.length.name = 'highlight_off_rounded';
      this.passwordValidators.length.value = false;
    }
    let regex = new RegExp('(.*[a-z].*)');
    if (regex.test(password)) {
      this.passwordValidators.lower.name = 'check_circle_outlined';
      this.passwordValidators.lower.value = true;
    } else {
      this.passwordValidators.lower.name = 'highlight_off_rounded';
      this.passwordValidators.lower.value = false;
    }

    regex = new RegExp('(.*[A-Z].*)');
    if (regex.test(password)) {
      this.passwordValidators.upper.name = 'check_circle_outlined';
      this.passwordValidators.upper.value = true;
    } else {
      this.passwordValidators.upper.name = 'highlight_off_rounded';
      this.passwordValidators.upper.value = false;
    }

    regex = new RegExp('(.*[0-9].*)');
    if (regex.test(password)) {
      this.passwordValidators.number.name = 'check_circle_outlined';
      this.passwordValidators.number.value = true;
    } else {
      this.passwordValidators.number.name = 'highlight_off_rounded';
      this.passwordValidators.number.value = false;
    }
  }
}
