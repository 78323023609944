import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'scj-my-org',
  templateUrl: './my-org.component.html',
  styleUrls: ['./my-org.component.scss']
})
export class MyOrgComponent implements OnInit {
  orgLinks = [
    { path: 'org-details', label: 'Details' },
    { path: 'org-finance', label: 'Finance' },
    { path: 'org-email-sync', label: 'Email Sync' },
    { path: 'org-agreements', label: 'Agreements' },
    { path: 'org-services', label: 'Services' },
    { path: 'org-users', label: 'Users' },
    { path: 'org-sign-templates', label: 'E-Sign Templates' }
  ];
  activeLink = this.orgLinks[0].path;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
