export const COUNTRY_CODES_NAMES = [
    { COUNTRY_CODE: 'AF', COUNTRY_NAME: 'Afghanistan' },
    { COUNTRY_CODE: 'AL', COUNTRY_NAME: 'Albania' },
    { COUNTRY_CODE: 'DZ', COUNTRY_NAME: 'Algeria' },
    { COUNTRY_CODE: 'AS', COUNTRY_NAME: 'American Samoa' },
    { COUNTRY_CODE: 'AD', COUNTRY_NAME: 'Andorra' },
    { COUNTRY_CODE: 'AO', COUNTRY_NAME: 'Angola' },
    { COUNTRY_CODE: 'AI', COUNTRY_NAME: 'Anguilla' },
    { COUNTRY_CODE: 'AQ', COUNTRY_NAME: 'Antarctica' },
    { COUNTRY_CODE: 'AG', COUNTRY_NAME: 'Antigua & Barbuda' },
    { COUNTRY_CODE: 'AR', COUNTRY_NAME: 'Argentina' },
    { COUNTRY_CODE: 'AM', COUNTRY_NAME: 'Armenia' },
    { COUNTRY_CODE: 'AW', COUNTRY_NAME: 'Aruba' },
    { COUNTRY_CODE: 'AC', COUNTRY_NAME: 'Ascension Island' },
    { COUNTRY_CODE: 'AU', COUNTRY_NAME: 'Australia' },
    { COUNTRY_CODE: 'AT', COUNTRY_NAME: 'Austria' },
    { COUNTRY_CODE: 'AZ', COUNTRY_NAME: 'Azerbaijan' },
    { COUNTRY_CODE: 'BS', COUNTRY_NAME: 'Bahamas' },
    { COUNTRY_CODE: 'BH', COUNTRY_NAME: 'Bahrain' },
    { COUNTRY_CODE: 'BD', COUNTRY_NAME: 'Bangladesh' },
    { COUNTRY_CODE: 'BB', COUNTRY_NAME: 'Barbados' },
    { COUNTRY_CODE: 'BY', COUNTRY_NAME: 'Belarus' },
    { COUNTRY_CODE: 'BE', COUNTRY_NAME: 'Belgium' },
    { COUNTRY_CODE: 'BZ', COUNTRY_NAME: 'Belize' },
    { COUNTRY_CODE: 'BJ', COUNTRY_NAME: 'Benin' },
    { COUNTRY_CODE: 'BM', COUNTRY_NAME: 'Bermuda' },
    { COUNTRY_CODE: 'BT', COUNTRY_NAME: 'Bhutan' },
    { COUNTRY_CODE: 'BO', COUNTRY_NAME: 'Bolivia' },
    { COUNTRY_CODE: 'BA', COUNTRY_NAME: 'Bosnia & Herzegovina' },
    { COUNTRY_CODE: 'BW', COUNTRY_NAME: 'Botswana' },
    { COUNTRY_CODE: 'BV', COUNTRY_NAME: 'Bouvet Island' },
    { COUNTRY_CODE: 'BR', COUNTRY_NAME: 'Brazil' },
    { COUNTRY_CODE: 'IO', COUNTRY_NAME: 'British Indian Ocean Territory' },
    { COUNTRY_CODE: 'VG', COUNTRY_NAME: 'British Virgin Islands' },
    { COUNTRY_CODE: 'BN', COUNTRY_NAME: 'Brunei' },
    { COUNTRY_CODE: 'BG', COUNTRY_NAME: 'Bulgaria' },
    { COUNTRY_CODE: 'BF', COUNTRY_NAME: 'Burkina Faso' },
    { COUNTRY_CODE: 'BI', COUNTRY_NAME: 'Burundi' },
    { COUNTRY_CODE: 'KH', COUNTRY_NAME: 'Cambodia' },
    { COUNTRY_CODE: 'CM', COUNTRY_NAME: 'Cameroon' },
    { COUNTRY_CODE: 'CA', COUNTRY_NAME: 'Canada' },
    { COUNTRY_CODE: 'IC', COUNTRY_NAME: 'Canary Islands' },
    { COUNTRY_CODE: 'CV', COUNTRY_NAME: 'Cape Verde' },
    { COUNTRY_CODE: 'BQ', COUNTRY_NAME: 'Caribbean Netherlands' },
    { COUNTRY_CODE: 'KY', COUNTRY_NAME: 'Cayman Islands' },
    { COUNTRY_CODE: 'CF', COUNTRY_NAME: 'Central African Republic' },
    { COUNTRY_CODE: 'EA', COUNTRY_NAME: 'Ceuta & Melilla' },
    { COUNTRY_CODE: 'TD', COUNTRY_NAME: 'Chad' },
    { COUNTRY_CODE: 'CL', COUNTRY_NAME: 'Chile' },
    { COUNTRY_CODE: 'CN', COUNTRY_NAME: 'China' },
    { COUNTRY_CODE: 'CX', COUNTRY_NAME: 'Christmas Island' },
    { COUNTRY_CODE: 'CP', COUNTRY_NAME: 'Clipperton Island' },
    { COUNTRY_CODE: 'CC', COUNTRY_NAME: 'Cocos (Keeling) Islands' },
    { COUNTRY_CODE: 'CO', COUNTRY_NAME: 'Colombia' },
    { COUNTRY_CODE: 'KM', COUNTRY_NAME: 'Comoros' },
    { COUNTRY_CODE: 'CG', COUNTRY_NAME: 'Congo - Brazzaville' },
    { COUNTRY_CODE: 'CD', COUNTRY_NAME: 'Congo - Kinshasa' },
    { COUNTRY_CODE: 'CK', COUNTRY_NAME: 'Cook Islands' },
    { COUNTRY_CODE: 'CR', COUNTRY_NAME: 'Costa Rica' },
    { COUNTRY_CODE: 'HR', COUNTRY_NAME: 'Croatia' },
    { COUNTRY_CODE: 'CU', COUNTRY_NAME: 'Cuba' },
    { COUNTRY_CODE: 'CW', COUNTRY_NAME: 'Curaçao' },
    { COUNTRY_CODE: 'CY', COUNTRY_NAME: 'Cyprus' },
    { COUNTRY_CODE: 'CZ', COUNTRY_NAME: 'Czechia' },
    { COUNTRY_CODE: 'CI', COUNTRY_NAME: 'Côte d’Ivoire' },
    { COUNTRY_CODE: 'DK', COUNTRY_NAME: 'Denmark' },
    { COUNTRY_CODE: 'DG', COUNTRY_NAME: 'Diego Garcia' },
    { COUNTRY_CODE: 'DJ', COUNTRY_NAME: 'Djibouti' },
    { COUNTRY_CODE: 'DM', COUNTRY_NAME: 'Dominica' },
    { COUNTRY_CODE: 'DO', COUNTRY_NAME: 'Dominican Republic' },
    { COUNTRY_CODE: 'EC', COUNTRY_NAME: 'Ecuador' },
    { COUNTRY_CODE: 'EG', COUNTRY_NAME: 'Egypt' },
    { COUNTRY_CODE: 'SV', COUNTRY_NAME: 'El Salvador' },
    { COUNTRY_CODE: 'GQ', COUNTRY_NAME: 'Equatorial Guinea' },
    { COUNTRY_CODE: 'ER', COUNTRY_NAME: 'Eritrea' },
    { COUNTRY_CODE: 'EE', COUNTRY_NAME: 'Estonia' },
    { COUNTRY_CODE: 'SZ', COUNTRY_NAME: 'Eswatini' },
    { COUNTRY_CODE: 'ET', COUNTRY_NAME: 'Ethiopia' },
    { COUNTRY_CODE: 'FK', COUNTRY_NAME: 'Falkland Islands (Islas Malvinas)' },
    { COUNTRY_CODE: 'FO', COUNTRY_NAME: 'Faroe Islands' },
    { COUNTRY_CODE: 'FJ', COUNTRY_NAME: 'Fiji' },
    { COUNTRY_CODE: 'FI', COUNTRY_NAME: 'Finland' },
    { COUNTRY_CODE: 'FR', COUNTRY_NAME: 'France' },
    { COUNTRY_CODE: 'GF', COUNTRY_NAME: 'French Guiana' },
    { COUNTRY_CODE: 'PF', COUNTRY_NAME: 'French Polynesia' },
    { COUNTRY_CODE: 'TF', COUNTRY_NAME: 'French Southern Territories' },
    { COUNTRY_CODE: 'GA', COUNTRY_NAME: 'Gabon' },
    { COUNTRY_CODE: 'GM', COUNTRY_NAME: 'Gambia' },
    { COUNTRY_CODE: 'GE', COUNTRY_NAME: 'Georgia' },
    { COUNTRY_CODE: 'DE', COUNTRY_NAME: 'Germany' },
    { COUNTRY_CODE: 'GH', COUNTRY_NAME: 'Ghana' },
    { COUNTRY_CODE: 'GI', COUNTRY_NAME: 'Gibraltar' },
    { COUNTRY_CODE: 'GR', COUNTRY_NAME: 'Greece' },
    { COUNTRY_CODE: 'GL', COUNTRY_NAME: 'Greenland' },
    { COUNTRY_CODE: 'GD', COUNTRY_NAME: 'Grenada' },
    { COUNTRY_CODE: 'GP', COUNTRY_NAME: 'Guadeloupe' },
    { COUNTRY_CODE: 'GU', COUNTRY_NAME: 'Guam' },
    { COUNTRY_CODE: 'GT', COUNTRY_NAME: 'Guatemala' },
    { COUNTRY_CODE: 'GG', COUNTRY_NAME: 'Guernsey' },
    { COUNTRY_CODE: 'GN', COUNTRY_NAME: 'Guinea' },
    { COUNTRY_CODE: 'GW', COUNTRY_NAME: 'Guinea-Bissau' },
    { COUNTRY_CODE: 'GY', COUNTRY_NAME: 'Guyana' },
    { COUNTRY_CODE: 'HT', COUNTRY_NAME: 'Haiti' },
    { COUNTRY_CODE: 'HM', COUNTRY_NAME: 'Heard & McDonald Islands' },
    { COUNTRY_CODE: 'HN', COUNTRY_NAME: 'Honduras' },
    { COUNTRY_CODE: 'HK', COUNTRY_NAME: 'Hong Kong' },
    { COUNTRY_CODE: 'HU', COUNTRY_NAME: 'Hungary' },
    { COUNTRY_CODE: 'IS', COUNTRY_NAME: 'Iceland' },
    { COUNTRY_CODE: 'IN', COUNTRY_NAME: 'India' },
    { COUNTRY_CODE: 'ID', COUNTRY_NAME: 'Indonesia' },
    { COUNTRY_CODE: 'IR', COUNTRY_NAME: 'Iran' },
    { COUNTRY_CODE: 'IQ', COUNTRY_NAME: 'Iraq' },
    { COUNTRY_CODE: 'IE', COUNTRY_NAME: 'Ireland' },
    { COUNTRY_CODE: 'IM', COUNTRY_NAME: 'Isle of Man' },
    { COUNTRY_CODE: 'IL', COUNTRY_NAME: 'Israel' },
    { COUNTRY_CODE: 'IT', COUNTRY_NAME: 'Italy' },
    { COUNTRY_CODE: 'JM', COUNTRY_NAME: 'Jamaica' },
    { COUNTRY_CODE: 'JP', COUNTRY_NAME: 'Japan' },
    { COUNTRY_CODE: 'JE', COUNTRY_NAME: 'Jersey' },
    { COUNTRY_CODE: 'JO', COUNTRY_NAME: 'Jordan' },
    { COUNTRY_CODE: 'KZ', COUNTRY_NAME: 'Kazakhstan' },
    { COUNTRY_CODE: 'KE', COUNTRY_NAME: 'Kenya' },
    { COUNTRY_CODE: 'KI', COUNTRY_NAME: 'Kiribati' },
    { COUNTRY_CODE: 'XK', COUNTRY_NAME: 'Kosovo' },
    { COUNTRY_CODE: 'KW', COUNTRY_NAME: 'Kuwait' },
    { COUNTRY_CODE: 'KG', COUNTRY_NAME: 'Kyrgyzstan' },
    { COUNTRY_CODE: 'LA', COUNTRY_NAME: 'Laos' },
    { COUNTRY_CODE: 'LV', COUNTRY_NAME: 'Latvia' },
    { COUNTRY_CODE: 'LB', COUNTRY_NAME: 'Lebanon' },
    { COUNTRY_CODE: 'LS', COUNTRY_NAME: 'Lesotho' },
    { COUNTRY_CODE: 'LR', COUNTRY_NAME: 'Liberia' },
    { COUNTRY_CODE: 'LY', COUNTRY_NAME: 'Libya' },
    { COUNTRY_CODE: 'LI', COUNTRY_NAME: 'Liechtenstein' },
    { COUNTRY_CODE: 'LT', COUNTRY_NAME: 'Lithuania' },
    { COUNTRY_CODE: 'LU', COUNTRY_NAME: 'Luxembourg' },
    { COUNTRY_CODE: 'MO', COUNTRY_NAME: 'Macao' },
    { COUNTRY_CODE: 'MG', COUNTRY_NAME: 'Madagascar' },
    { COUNTRY_CODE: 'MW', COUNTRY_NAME: 'Malawi' },
    { COUNTRY_CODE: 'MY', COUNTRY_NAME: 'Malaysia' },
    { COUNTRY_CODE: 'MV', COUNTRY_NAME: 'Maldives' },
    { COUNTRY_CODE: 'ML', COUNTRY_NAME: 'Mali' },
    { COUNTRY_CODE: 'MT', COUNTRY_NAME: 'Malta' },
    { COUNTRY_CODE: 'MH', COUNTRY_NAME: 'Marshall Islands' },
    { COUNTRY_CODE: 'MQ', COUNTRY_NAME: 'Martinique' },
    { COUNTRY_CODE: 'MR', COUNTRY_NAME: 'Mauritania' },
    { COUNTRY_CODE: 'MU', COUNTRY_NAME: 'Mauritius' },
    { COUNTRY_CODE: 'YT', COUNTRY_NAME: 'Mayotte' },
    { COUNTRY_CODE: 'MX', COUNTRY_NAME: 'Mexico' },
    { COUNTRY_CODE: 'FM', COUNTRY_NAME: 'Micronesia' },
    { COUNTRY_CODE: 'MD', COUNTRY_NAME: 'Moldova' },
    { COUNTRY_CODE: 'MC', COUNTRY_NAME: 'Monaco' },
    { COUNTRY_CODE: 'MN', COUNTRY_NAME: 'Mongolia' },
    { COUNTRY_CODE: 'ME', COUNTRY_NAME: 'Montenegro' },
    { COUNTRY_CODE: 'MS', COUNTRY_NAME: 'Montserrat' },
    { COUNTRY_CODE: 'MA', COUNTRY_NAME: 'Morocco' },
    { COUNTRY_CODE: 'MZ', COUNTRY_NAME: 'Mozambique' },
    { COUNTRY_CODE: 'MM', COUNTRY_NAME: 'Myanmar (Burma)' },
    { COUNTRY_CODE: 'NA', COUNTRY_NAME: 'Namibia' },
    { COUNTRY_CODE: 'NR', COUNTRY_NAME: 'Nauru' },
    { COUNTRY_CODE: 'NP', COUNTRY_NAME: 'Nepal' },
    { COUNTRY_CODE: 'NL', COUNTRY_NAME: 'Netherlands' },
    { COUNTRY_CODE: 'NC', COUNTRY_NAME: 'New Caledonia' },
    { COUNTRY_CODE: 'NZ', COUNTRY_NAME: 'New Zealand' },
    { COUNTRY_CODE: 'NI', COUNTRY_NAME: 'Nicaragua' },
    { COUNTRY_CODE: 'NE', COUNTRY_NAME: 'Niger' },
    { COUNTRY_CODE: 'NG', COUNTRY_NAME: 'Nigeria' },
    { COUNTRY_CODE: 'NU', COUNTRY_NAME: 'Niue' },
    { COUNTRY_CODE: 'NF', COUNTRY_NAME: 'Norfolk Island' },
    { COUNTRY_CODE: 'KP', COUNTRY_NAME: 'North Korea' },
    { COUNTRY_CODE: 'MK', COUNTRY_NAME: 'North Macedonia' },
    { COUNTRY_CODE: 'MP', COUNTRY_NAME: 'Northern Mariana Islands' },
    { COUNTRY_CODE: 'NO', COUNTRY_NAME: 'Norway' },
    { COUNTRY_CODE: 'OM', COUNTRY_NAME: 'Oman' },
    { COUNTRY_CODE: 'PK', COUNTRY_NAME: 'Pakistan' },
    { COUNTRY_CODE: 'PW', COUNTRY_NAME: 'Palau' },
    { COUNTRY_CODE: 'PS', COUNTRY_NAME: 'Palestine' },
    { COUNTRY_CODE: 'PA', COUNTRY_NAME: 'Panama' },
    { COUNTRY_CODE: 'PG', COUNTRY_NAME: 'Papua New Guinea' },
    { COUNTRY_CODE: 'PY', COUNTRY_NAME: 'Paraguay' },
    { COUNTRY_CODE: 'PE', COUNTRY_NAME: 'Peru' },
    { COUNTRY_CODE: 'PH', COUNTRY_NAME: 'Philippines' },
    { COUNTRY_CODE: 'PN', COUNTRY_NAME: 'Pitcairn Islands' },
    { COUNTRY_CODE: 'PL', COUNTRY_NAME: 'Poland' },
    { COUNTRY_CODE: 'PT', COUNTRY_NAME: 'Portugal' },
    { COUNTRY_CODE: 'PR', COUNTRY_NAME: 'Puerto Rico' },
    { COUNTRY_CODE: 'QA', COUNTRY_NAME: 'Qatar' },
    { COUNTRY_CODE: 'RO', COUNTRY_NAME: 'Romania' },
    { COUNTRY_CODE: 'RU', COUNTRY_NAME: 'Russia' },
    { COUNTRY_CODE: 'RW', COUNTRY_NAME: 'Rwanda' },
    { COUNTRY_CODE: 'RE', COUNTRY_NAME: 'Réunion' },
    { COUNTRY_CODE: 'WS', COUNTRY_NAME: 'Samoa' },
    { COUNTRY_CODE: 'SM', COUNTRY_NAME: 'San Marino' },
    { COUNTRY_CODE: 'SA', COUNTRY_NAME: 'Saudi Arabia' },
    { COUNTRY_CODE: 'SN', COUNTRY_NAME: 'Senegal' },
    { COUNTRY_CODE: 'RS', COUNTRY_NAME: 'Serbia' },
    { COUNTRY_CODE: 'SC', COUNTRY_NAME: 'Seychelles' },
    { COUNTRY_CODE: 'SL', COUNTRY_NAME: 'Sierra Leone' },
    { COUNTRY_CODE: 'SG', COUNTRY_NAME: 'Singapore' },
    { COUNTRY_CODE: 'SX', COUNTRY_NAME: 'Sint Maarten' },
    { COUNTRY_CODE: 'SK', COUNTRY_NAME: 'Slovakia' },
    { COUNTRY_CODE: 'SI', COUNTRY_NAME: 'Slovenia' },
    { COUNTRY_CODE: 'SB', COUNTRY_NAME: 'Solomon Islands' },
    { COUNTRY_CODE: 'SO', COUNTRY_NAME: 'Somalia' },
    { COUNTRY_CODE: 'ZA', COUNTRY_NAME: 'South Africa' },
    { COUNTRY_CODE: 'GS', COUNTRY_NAME: 'South Georgia & South Sandwich Islands' },
    { COUNTRY_CODE: 'KR', COUNTRY_NAME: 'South Korea' },
    { COUNTRY_CODE: 'SS', COUNTRY_NAME: 'South Sudan' },
    { COUNTRY_CODE: 'ES', COUNTRY_NAME: 'Spain' },
    { COUNTRY_CODE: 'LK', COUNTRY_NAME: 'Sri Lanka' },
    { COUNTRY_CODE: 'BL', COUNTRY_NAME: 'St. Barthélemy' },
    { COUNTRY_CODE: 'SH', COUNTRY_NAME: 'St. Helena' },
    { COUNTRY_CODE: 'KN', COUNTRY_NAME: 'St. Kitts & Nevis' },
    { COUNTRY_CODE: 'LC', COUNTRY_NAME: 'St. Lucia' },
    { COUNTRY_CODE: 'MF', COUNTRY_NAME: 'St. Martin' },
    { COUNTRY_CODE: 'PM', COUNTRY_NAME: 'St. Pierre & Miquelon' },
    { COUNTRY_CODE: 'VC', COUNTRY_NAME: 'St. Vincent & Grenadines' },
    { COUNTRY_CODE: 'SD', COUNTRY_NAME: 'Sudan' },
    { COUNTRY_CODE: 'SR', COUNTRY_NAME: 'Suriname' },
    { COUNTRY_CODE: 'SJ', COUNTRY_NAME: 'Svalbard & Jan Mayen' },
    { COUNTRY_CODE: 'SE', COUNTRY_NAME: 'Sweden' },
    { COUNTRY_CODE: 'CH', COUNTRY_NAME: 'Switzerland' },
    { COUNTRY_CODE: 'SY', COUNTRY_NAME: 'Syria' },
    { COUNTRY_CODE: 'ST', COUNTRY_NAME: 'São Tomé & Príncipe' },
    { COUNTRY_CODE: 'TW', COUNTRY_NAME: 'Taiwan' },
    { COUNTRY_CODE: 'TJ', COUNTRY_NAME: 'Tajikistan' },
    { COUNTRY_CODE: 'TZ', COUNTRY_NAME: 'Tanzania' },
    { COUNTRY_CODE: 'TH', COUNTRY_NAME: 'Thailand' },
    { COUNTRY_CODE: 'TL', COUNTRY_NAME: 'Timor-Leste' },
    { COUNTRY_CODE: 'TG', COUNTRY_NAME: 'Togo' },
    { COUNTRY_CODE: 'TK', COUNTRY_NAME: 'Tokelau' },
    { COUNTRY_CODE: 'TO', COUNTRY_NAME: 'Tonga' },
    { COUNTRY_CODE: 'TT', COUNTRY_NAME: 'Trinidad & Tobago' },
    { COUNTRY_CODE: 'TA', COUNTRY_NAME: 'Tristan da Cunha' },
    { COUNTRY_CODE: 'TN', COUNTRY_NAME: 'Tunisia' },
    { COUNTRY_CODE: 'TR', COUNTRY_NAME: 'Turkey' },
    { COUNTRY_CODE: 'TM', COUNTRY_NAME: 'Turkmenistan' },
    { COUNTRY_CODE: 'TC', COUNTRY_NAME: 'Turks & Caicos Islands' },
    { COUNTRY_CODE: 'TV', COUNTRY_NAME: 'Tuvalu' },
    { COUNTRY_CODE: 'UM', COUNTRY_NAME: 'U.S. Outlying Islands' },
    { COUNTRY_CODE: 'VI', COUNTRY_NAME: 'U.S. Virgin Islands' },
    { COUNTRY_CODE: 'UG', COUNTRY_NAME: 'Uganda' },
    { COUNTRY_CODE: 'UA', COUNTRY_NAME: 'Ukraine' },
    { COUNTRY_CODE: 'AE', COUNTRY_NAME: 'United Arab Emirates' },
    { COUNTRY_CODE: 'GB', COUNTRY_NAME: 'United Kingdom' },
    { COUNTRY_CODE: 'US', COUNTRY_NAME: 'United States' },
    { COUNTRY_CODE: 'UY', COUNTRY_NAME: 'Uruguay' },
    { COUNTRY_CODE: 'UZ', COUNTRY_NAME: 'Uzbekistan' },
    { COUNTRY_CODE: 'VU', COUNTRY_NAME: 'Vanuatu' },
    { COUNTRY_CODE: 'VA', COUNTRY_NAME: 'Vatican City' },
    { COUNTRY_CODE: 'VE', COUNTRY_NAME: 'Venezuela' },
    { COUNTRY_CODE: 'VN', COUNTRY_NAME: 'Vietnam' },
    { COUNTRY_CODE: 'WF', COUNTRY_NAME: 'Wallis & Futuna' },
    { COUNTRY_CODE: 'EH', COUNTRY_NAME: 'Western Sahara' },
    { COUNTRY_CODE: 'YE', COUNTRY_NAME: 'Yemen' },
    { COUNTRY_CODE: 'ZM', COUNTRY_NAME: 'Zambia' },
    { COUNTRY_CODE: 'ZW', COUNTRY_NAME: 'Zimbabwe' },
    { COUNTRY_CODE: 'AX', COUNTRY_NAME: 'Åland Islands' }
];