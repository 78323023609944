<div class="flex-row jc-sb">
    <button mat-flat-button class="mb-12" (click)="getInboxMails()">Refresh</button>

    <div class="search-filter" *ngIf="enableFilter">
        <div class="search-field">
            <mat-icon>search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>

        <button class="filter-button" (click)="openTableFilter()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>
</div>

<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        {{errorMessage}}
    </div>

    <div class="content-fit" *ngIf="!isError && !isLoading">

        <scj-table-content [data]="inboxList" [searchControl]="searchControl" [columnConfig]="columnConfig"
            defaultSortColumn="date" [pageSizeOptions]="[20, 40, 60, 80, 100]" [exportFileName]="inboxName"
            (navigate)="openInboxMail($event)" errorMessege="No conversations to display">
        </scj-table-content>

    </div>
</div>