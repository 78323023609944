import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { User } from '../models/user.model';
import { NotificationService } from '../shared/services/notification.service';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { throwError } from 'rxjs';

@Component({
  selector: 'scj-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  error = '';
  hide = true;
  subdomain: string;
  isSpinner: boolean = false;
  errorMessage ='';

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,
    private authenticationService: AuthenticationService, private notificationService: NotificationService,
    private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.isSpinner = true;
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      this.recaptchaV3Service.execute('login').pipe(map((recaptcha: string) => {
        if (recaptcha) {
          return recaptcha;
        } else {
          throw new Error("User is not validated as human");
        }
      })).pipe(exhaustMap((recaptcha: string) => {
        let deviceToken = localStorage.getItem("deviceToken");
        if (deviceToken) {
          let userCredentials = this.loginForm.getRawValue();
          userCredentials.deviceToken = deviceToken;
          return this.authenticationService.login(userCredentials, this.subdomain, recaptcha, false).pipe(
            // map((data: User) => {
            //   if (data !== null) {
            //     let url = '/firm';
            //     if (data.roles.length === 1 && data.roles.indexOf('firmclient') !== -1) {
            //       url = '/client';
            //     }
            //     return "SUCCESS";
            //   } else {
            //     return throwError(undefined);
            //   }
            // }),
            catchError((err: any) => {
              if (err.status === 422) {
                localStorage.removeItem("deviceToken");
                this.isSpinner = false;
                return this.authenticationService.login(this.loginForm.getRawValue(), this.subdomain, recaptcha);
              } else {
                this.isSpinner = false;
                return throwError(err);
              }
            })
          );
        } else {
          localStorage.removeItem("deviceToken");
          return this.authenticationService.login(this.loginForm.getRawValue(), this.subdomain, recaptcha);
        }
      })).subscribe({
        next: (res: User | string) => {
          if (typeof res === 'string') {
            this.isSpinner = false;
            this.router.navigate(['/multi-factor-auth'], { state: { mfaToken: res, username: this.loginForm.get('username').value, subdomain: this.subdomain } });
          } else if (res?.username) {
            let url = '/firm';
            if (res.roles.length === 1 && res.roles.indexOf('firmclient') !== -1) {
              url = '/client';
            }
            this.isSpinner = false;
            this.router.navigateByUrl(url);
          } else {
            this.isSpinner = false;
            this.notificationService.notifyText(`Error occurred try after some time.`, "X");
          }
          
        },
        error: (error) => {
          this.errorMessage = error?.error?.message;
          this.isSpinner = false;
          this.notificationService.notifyText(`${this.errorMessage}`);
        }
      });
    } else {
      this.notificationService.notifyText(`Please fill in the required fields.`, "X");

    }
  }

  setSubdomain(event: string) {
    this.subdomain = event;
  }
}