<div class="loader">
  <div class="spinner" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>

  <div *ngIf="isError">
    Something went wrong!! Please try after some time
  </div>

  <div *ngIf="!isError && !isLoading">
    <div class="flex-row" [style.align-items]="'stretch'">

     

      <div *ngIf="activeServices" class="table-responsive mb-12">
        <div class="spinner" *ngIf="isActiveServicesLoading">
          <mat-spinner *ngIf="isActiveServicesLoading"></mat-spinner>
        </div>
        <div *ngIf="!isActiveServicesLoading">
          <div class="flex-row" [style.align-items]="'center'">
            <div>
              <h2 class="scj-h2">Your Subscriptions</h2>
            </div>
            <div style="padding:3px;"> <button class="primary" mat-flat-button routerLink="../firm-services">
                <mat-icon class="button-icon">add</mat-icon>
                <span class="button-text">Subscribe</span>
              </button></div>
          </div>
          <mat-divider [vertical]="false"></mat-divider>
          <table>
            <tr>
              <th style="width:35%">Service</th>
              <th style="width:35%"> Status</th>
              <th style="width:13%">Subscribed</th>
              <th style="width:10%">Id</th>
              <th style="width:7%"> </th>
            </tr>
            <tbody>
              <ng-container *ngFor="let service of activeServices;let i = index">
                <tr *ngIf="i < 5">
                  <td routerLink="../subscription/{{service.id}}">{{service.serviceName}}</td>
                  <td [attr.cell]="service?.status">
                    <div [style.display]="'flex'">
                      <span class="chip">{{service?.displayStatus}}</span>
                     <!-- <mat-icon svgIcon="sign" matTooltip="E-Sign document" *ngIf="service.esignRequested"
                        (click)="$event.stopPropagation();esign(service);"></mat-icon>-->
                    </div>
                  </td>
                  <td>{{service?.serviceRequested}}</td>
                  <td>{{service?.subscriptionId}}</td>
                  <td>
                    <a [routerLink]="" (click)="esign(service?.attachment)" *ngIf="service?.attachment?.canSign">E-Sign</a>
                   
                
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div *ngIf="activeServices.length > 4" [style.text-align]="'right'">
            <a routerLink="../services/list">View More</a>
          </div>
        </div>


      </div>
      <div class="tile-container">
        <div class="tile-items">
          <div>
            <h2 class="scj-h2">Open Invoices</h2>
          </div>
          <mat-divider style="padding-bottom:20px" [vertical]="false"></mat-divider>
          <div class="tile" [class.no-invoice]="invoiceList.length === 0">
            <ng-container *ngIf="invoiceList.length !== 0">
              <div class="action-buttons">
                <button style="top:50px" mat-icon-button (click)="prev()" [disabled]="invoiceIndex === 0">
                  <mat-icon>chevron_left</mat-icon>
                </button>
                <div class="flex-column">
                  <span> <a
                      routerLink="../invoices/manage-billing/{{invoiceList[invoiceIndex]?.scheduleId}}">{{invoiceList[invoiceIndex]?.serviceName}}</a></span>
                  <span>{{invoiceList[invoiceIndex]?.invoiceNumber}}</span>
                </div>
                <button style="top:50px" mat-icon-button (click)="next()"
                  [disabled]="!(invoiceIndex < invoiceList.length-1)">
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>

              <div class="tile-content">
                <h3 class="scj-subheader padding-bottom">
                  Due: {{invoiceList[invoiceIndex]?.invoiceAmount | currency}}
                </h3>
                <span class="padding-bottom">By {{invoiceList[invoiceIndex]?.invoiceDueDate}}</span>
                <ng-container *ngIf="invoiceList.length>0">
                  <div class="payment-button"
                    [style.grid-gap]="(isAchConfigured && isPaypalConfigured)? '48px' : '0px'">

                    <div *ngIf="isPaypalConfigured" scjPaypal
                      description="Payment for {{invoiceList[invoiceIndex]?.serviceName}}"
                      [amount]="invoiceList[invoiceIndex]?.invoiceAmount"
                      (paymentStatus)="markAsPaid($event, invoiceList[invoiceIndex]?.invoiceId, invoiceList[invoiceIndex]?.invoiceNumber)">
                    </div>

                    <scj-ach-payment *ngIf="isAchConfigured" [amount]="invoiceList[invoiceIndex]?.invoiceAmount"
                      [invoiceId]="invoiceList[invoiceIndex]?.invoiceId"
                      [invoiceNum]="invoiceList[invoiceIndex]?.invoiceNumber" (paymentStatus)="markAsPaidAch($event)">
                    </scj-ach-payment>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <h3 class="scj-subheader" *ngIf="invoiceList.length === 0">
              Due: {{'0' | currency}}
            </h3>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-row" [style.align-items]="'stretch'">

    <div class="table-responsive mb-12">
      <div>
        <div>
          <h2 class="scj-h2">Your Notifications</h2>
        </div>
        <mat-divider [vertical]="false"></mat-divider>
        <p> No New Notifications</p>
      </div>
    </div>
    <div class="tile-container">
      <div *ngIf="companyList" class="tile-items">
        <div>
          <h2 class="scj-h2">{{companyList.name}}</h2>
        </div>
        <mat-divider style="padding-bottom:20px" [vertical]="false"></mat-divider>
        <div>
          <p class="flex-row"><span>
              <mat-icon class="padding-right">email</mat-icon>
            </span><span class="companyText">{{companyList.supportEmail}}</span></p>
          <p class="flex-row"><span>
              <mat-icon class="padding-right">phone</mat-icon>
            </span><span class="companyText">{{companyList.phoneNumber}}</span></p>
          <p class="flex-row"><span>
              <mat-icon class="padding-right">website</mat-icon>
            </span><span class="companyText">{{companyList.website}}</span></p>
          <p class="flex-row"><span>
              <mat-icon class="padding-right">location_city</mat-icon>
            </span><span class="companyText">{{companyList.address.addressLine1}}{{' ,'+companyList.address.city+',
              '}}{{companyList.address.state+', '}}{{companyList.address.zip}}</span></p>
        </div>
      </div>
      <div *ngElse>
        <p>No Company Details Found</p>
      </div>
    </div>
  </div>
</div>