<div class="flex-row">
    <scj-firm-logo-container class="nav-header-logo">
    </scj-firm-logo-container>
</div>

<div class="esign-main-container">

    <div *ngIf="isFileLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="isFileLoadingError" class="error">
        Unable to fetch the esign data. Due to one of the below reasons:
        <ol>
            <li>Document is already signed by you.</li>
            <li>Signature request is cancelled by the requestor.</li>
            <li>Some thing would have been went wrong... If above reasons not valid for you, please try after some time.
            </li>
        </ol>
    </div>

    <div *ngIf="success" class="success">
        Your document has been successfully signed. <a routerLink="/login">Click Here To Login </a>
    </div>

    <div *ngIf="pdfBase64 && !isFileLoading && !isFileLoadingError" style="height: 100%">
        <div class="activate-esign">
            <input type="checkbox" [formControl]="tcControl">I agree to use
            <a href="https://www.govinfo.gov/content/pkg/PLAW-106publ229/pdf/PLAW-106publ229.pdf" target="_blank">
                electronic records and Signatures
            </a>
        </div>

        <div class="esign-box">
            <div class="overlay-esign" [hidden]="enableEsign"></div>
            <div class="esign-indicator-box">
                <div></div>
                <div [hidden]="displayFieldCount == 0" class="mandatory">
                    Please fill in {{ displayFieldCount }} required fields.
                </div>
                <div style="position: relative;display: flex;">
                    <div class="esign-submission" [hidden]="!isSigned">
                        <mat-icon>arrow_forward</mat-icon>
                    </div>
                    <button *ngIf="!isHandSet && !isEsignStarted" mat-flat-button (click)="next()">Start</button>
                    <button *ngIf="!isHandSet && isEsignStarted" mat-flat-button (click)="esign()">E-sign</button>
                    <button mat-flat-button class="secondary" (click)="cancel()">Cancel</button>

                    <div *ngIf="esignSpinner">
                        <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                    </div>
                </div>
            </div>

            <div class="esign-indicator-minibox">
                <div class="button">
                    <div>
                        <button *ngIf="isEsignStarted && (displayFieldCount > 0)" mat-flat-button
                            (click)="next()">Next</button>
                    </div>
                    <div class="esign-finish">
                        <div class="esign-submission" [hidden]="!isSigned">
                            <svg height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.589 6.41l4.58 4.59H5v2h10.169l-4.589 4.59 1.419 1.409 7-6.999-7-7z"
                                    fill-rule="evenodd"></path>
                            </svg>
                        </div>
                        <button *ngIf="!isEsignStarted" mat-flat-button (click)="next()">Start</button>
                        <button *ngIf="isEsignStarted" mat-flat-button (click)="esign()">E-sign</button>
                        <button mat-flat-button class="secondary" (click)="cancel()">Cancel</button>
                        <div *ngIf="esignSpinner">
                            <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                        </div>
                    </div>
                </div>
                <div [hidden]="displayFieldCount == 0" class="mandatory">
                    Please fill in {{ displayFieldCount }} required fields.
                </div>
            </div>

            <div id="esign-container" class="esign-container" style="pointer-events: none;">
                <div id="cloner" class="field-view" style="display: none;">
                    <img class="esign-icon" src="">
                    <span></span>
                    <span class="esign-tooltip"></span>
                </div>

                <button *ngIf="isEsignStarted && (displayFieldCount > 0)" mat-flat-button (click)="next()"
                    class="hide-next">Next</button>

                <div class="pdf-container">
                    <div class="page" *ngFor="let page of pages;let index = index;" [style.width]="page.width+'px'"
                        [style.height]="page.height+'px'">
                        <img [src]="page.data" [style.width]="page.width+'px'" [style.height]="page.height+'px'">
                        <div class="page-index">{{ index + 1 }} of {{pages.length}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>