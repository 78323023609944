import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';
import { PaymentService } from '../shared/services/payment.service';

declare var Stripe: any

@Component({
  selector: 'scj-ach-payment',
  templateUrl: './ach-payment.component.html',
  styleUrls: ['./ach-payment.component.scss']
})
export class AchPaymentComponent implements OnInit {
  clientSecret: string;
  firmName: string;
  isSpinner: boolean = false;
  isAchConfigured: boolean = false;

  @Input()
  amount: number;

  @Input()
  invoiceId: string;

  @Input()
  invoiceNum: number;

  @Input()
  textRequired: boolean = true;

  @Output()
  paymentStatus: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpCommonService, private matDialog: MatDialog, private paymentService: PaymentService,
    private authService: AuthenticationService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.firmName = this.authService.userValue.firmName;
    this.paymentService.isAchConfigured.subscribe((val: boolean) => {
      this.isAchConfigured = val;
    })
  }

  opeDialog(header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true
    }
    return this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(AlertMessagesComponent, dialogConfig)
  }

  initiatePayment() {
    this.isSpinner = true;
    this.http.getPlaidAccessToken(this.amount, this.invoiceId).subscribe((res: HttpResponse<any>) => {
      this.clientSecret = res.body.token;
      this.isSpinner = false;
      let stripe = Stripe(this.paymentService.paymentConfig.stripeClientId);
      stripe.collectBankAccountForPayment({
        clientSecret: this.clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: this.authService.userValue.clientName,
              email: this.authService.userValue.username
            },
          },
        },
        expand: ['payment_method'],
      }).then(({ paymentIntent, error }) => {
        if (error) {
          this.notificationService.notifyText("Failed to fetch bank details.", 'X');
        } else if (paymentIntent.status === 'requires_confirmation') {

          let message: string = `By clicking [Yes], you authorize ${this.firmName} to debit the bank account specified for any amount owed for charges arising from your use of ${this.firmName}’ services and/or purchase of products from ${this.firmName}, pursuant to ${this.firmName}’ website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to ${this.firmName} with 30 (thirty) days notice.`
          this.opeDialog("ACH Payment", message).afterClosed().subscribe(
            (confirm: any) => {
              if (confirm) {
                this.confirmPayment(stripe);
              }
            });
        }
      });
    }, (error: HttpErrorResponse) => {
      this.isSpinner = false;
      this.notificationService.notifyText("Failed to fetch ACH payment details.", 'X');
      console.log(error);
    });

  }

  confirmPayment(stripe: any) {
    stripe.confirmUsBankAccountPayment(this.clientSecret)
      .then(({ paymentIntent, error }) => {
        if (error) {
          this.notificationService.notifyText("Payment processing failed.", "X");
          this.paymentStatus.emit('FAILURE');
        } else if (paymentIntent.status === "requires_payment_method") {
          console.log("requires_payment_method");
        } else if (paymentIntent.status === "processing") {
          this.notificationService.notifyText(`Amount is deducted towards invoice ${this.invoiceNum}`, 'X');
          this.http.updateAchPaymentStatus(this.invoiceId).subscribe((res: HttpResponse<any>) => {
            setTimeout(() => {
              this.notificationService.notifyText(`Invoice ${this.invoiceNum} payment is under process.`, 'X')
            }, 4000);
          }, (err: HttpErrorResponse) => {
            setTimeout(() => {
              this.notificationService.notifyText(`Error occurred while updating invoice ${this.invoiceNum} payment status. Contact support`, 'X')
            }, 4000);
          });
          this.paymentStatus.emit('SUCCESS');
        }
        // else if (paymentIntent.next_action?.type === "verify_with_microdeposits") {
        //   console.log("verify_with_microdeposits");
        // }
      });
  }
}
