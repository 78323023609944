<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" class="inline-block">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20">Email Sync</h1>

            <div class="flex-row" *ngIf="!disable">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>

                <button class="secondary" mat-flat-button routerLink="/firm/my-org/org-details">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <form [formGroup]="emailSyncForm">

            <mat-form-field appearance="outline">
                <mat-label>Enter Email</mat-label>
                <input type="text" placeholder="Enter email" matInput formControlName="emailSyncEmail" required>
                <mat-error
                    *ngIf="(emailSyncForm.get('emailSyncEmail').dirty || emailSyncForm.get('emailSyncEmail').touched)">
                    <mat-error *ngIf="emailSyncForm.get('emailSyncEmail').hasError('email')">
                        Enter valid email.
                    </mat-error>
                    <mat-error *ngIf="emailSyncForm.get('emailSyncEmail').hasError('required')">
                        Email is required.
                    </mat-error>
                </mat-error>
            </mat-form-field>

            <div class="scj-subheader">Server Settings</div>

            <mat-form-field appearance="outline">
                <mat-label>Select email integration</mat-label>
                <mat-select placeholder="Select email integration" formControlName="emailIntegrationType"
                    disableOptionCentering required>
                    <mat-option *ngFor="let server of emailServerList" [value]="server">
                        {{server}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="emailSyncForm.get('emailIntegrationType').hasError('required') && (emailSyncForm.get('emailIntegrationType').dirty || emailSyncForm.get('emailIntegrationType').touched)">
                    Email integration required.
                </mat-error>
            </mat-form-field>

            <div *ngIf="emailServer === 'IMAP'" class="grid-column">
                <mat-form-field appearance="outline">
                    <mat-label>Enter IMAP server name</mat-label>
                    <input type="text" placeholder="Enter IMAP server name" matInput formControlName="imapServerName"
                        required>
                    <mat-error
                        *ngIf="emailSyncForm.get('imapServerName').hasError('required') && (emailSyncForm.get('imapServerName').dirty || emailSyncForm.get('imapServerName').touched)">
                        IMAP server name required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Enter IMAP api key</mat-label>
                    <input type="text" placeholder="Enter IMAP api key" matInput formControlName="imapApiKey" required>
                    <mat-error
                        *ngIf="emailSyncForm.get('imapApiKey').hasError('required') && (emailSyncForm.get('imapApiKey').dirty || emailSyncForm.get('imapApiKey').touched)">
                        IMAP API key required
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="emailServer === 'API'" class="grid-column">
                <mat-form-field appearance="outline">
                    <mat-label>Enter api client id</mat-label>
                    <input type="text" placeholder="Enter api client id" matInput formControlName="apiClientId"
                        required>
                    <mat-error
                        *ngIf="emailSyncForm.get('apiClientId').hasError('required') && (emailSyncForm.get('apiClientId').dirty || emailSyncForm.get('apiClientId').touched)">
                        API client id required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Enter api tenant id</mat-label>
                    <input type="text" placeholder="Enter api tenant id" matInput formControlName="apiTenantId"
                        required>
                    <mat-error
                        *ngIf="emailSyncForm.get('apiTenantId').hasError('required') && (emailSyncForm.get('apiTenantId').dirty || emailSyncForm.get('apiTenantId').touched)">
                        API tenant id required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Enter api secret</mat-label>
                    <input type="text" placeholder="Enter api secret" matInput formControlName="apiSecret" required>
                    <mat-error
                        *ngIf="emailSyncForm.get('apiSecret').hasError('required') && (emailSyncForm.get('apiSecret').dirty || emailSyncForm.get('apiSecret').touched)">
                        API secret required
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>