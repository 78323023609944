import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { PaymentService } from '../services/payment.service';

declare const paypal: any;

@Directive({
  selector: '[scjPaypal]'
})
export class PaypalDirective implements AfterViewInit {

  @Input()
  description: string = "Service subscription payment";

  @Input()
  currency: string = "USD";

  @Input()
  amount: number;

  @Output()
  paymentStatus: EventEmitter<any> = new EventEmitter();

  constructor(private paypalElement: ElementRef, private paymentService: PaymentService) { }

  ngAfterViewInit() {
    this.paymentService.isPaypalConfigured.subscribe((val: boolean) => {
      if (val) {
        this.loadPaypalButton();
      }
    });
  }

  loadPaypalButton() {
    paypal.Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'pay',
        size: 'small',
        height: 25,
        tagline: false
      },

      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [
            {
              description: `${this.description}`,
              amount: {
                currency_code: `${this.currency}`,
                value: `${this.amount}`,
              }
            }
          ]
        });
      },

      onApprove: async (data: any, actions: any) => {
        let order = await actions.order.capture();
        this.paymentStatus.emit({ status: 'SUCESS', data: order });
      },

      onError: function (err: any) {
        this.paymentStatus.emit({ status: 'ERROR' });
      }
    }).render(this.paypalElement.nativeElement);
  }

}
