import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HandSetService } from '../shared/services/hand-set.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'scj-client-nav',
  templateUrl: './client-navigation.component.html',
  styleUrls: ['./client-navigation.component.scss']
})
export class ClientNavigationComponent implements OnInit {
  isHandset$: Observable<boolean>;
  isPreferences: boolean = false;
  navigationLinks: any[] = [
    {
      link: 'home', title: 'Home', icon: 'apps', type: '', active: true
    }, {
      link: 'service', title: 'Service', icon: 'description_outlined', type: '', active: false
    }, {
      link: 'messages', title: 'Messages', icon: 'chat_bubble_outlined', type: '', active: false
    }
  ];
  headerTitle: string = '';

  constructor(private handSet: HandSetService, private router: Router, private authenticationService: AuthenticationService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit() {
    this.isHandset$.subscribe((handSet: boolean) => {
      if (handSet) {
        this.navigationLinks.push({ link: 'preferences', title: 'Preferences', icon: 'tune_outlined', type: '' });
        this.navigationLinks.forEach(el => {
          if (this.router.url.indexOf(el.link) !== -1) {
            this.headerTitle = el.title;
          }
        })
      }
    });
  }

  highlightLinks(link: string) {
    this.isPreferences = false;
    this.navigationLinks.forEach((e, i) => {
      if (e.link === link) {
        this.navigationLinks[i].active = true;
      } else {
        this.navigationLinks[i].active = false;
      }
    });
  }

  highlightPreferences() {
    this.isPreferences = true;
    this.navigationLinks.forEach((e, i) => {
      this.navigationLinks[i].active = false;
    });
  }

  logout() {
    this.authenticationService.logout();
  }
}
