import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'scj-all-tasks',
  templateUrl: './all-tasks.component.html',
  styleUrls: ['./all-tasks.component.scss']
})
export class AllTasksComponent implements OnInit {
  orgLinks = [
    { path: 'my-assignments', label: 'My Tasks' }
    ];
  activeLink = this.orgLinks[0].path;
  isFirmAdmin: any;
  user: any;
  isFirmEmployee: any;

  

  constructor(private router: Router,private authenticationService: AuthenticationService) {
    this.user = this.authenticationService.userValue;
    this.isFirmAdmin = this.user.roles.find(x => (x === "firmadmin"));
    this.isFirmEmployee = this.user.roles.find(x => (x === "firmemployee"));
   }

  ngOnInit(): void {
    if (this.isFirmAdmin){
      this.orgLinks = [
        { path: 'my-assignments', label: 'My Tasks' },
        { path: 'all-subscriptions', label: 'Org Tasks' },
      ];
    }
  }

}
