<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form [formGroup]="profileForm" autocomplete="off">
            <div class="flex-row button-section">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text"> Save</span>
                </button>
                <button mat-flat-button class="secondary" (click)="cancel()">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>

                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>

            <div class="flex-column">
                <mat-form-field appearance="outline">
                    <mat-label>Full Name</mat-label>
                    <input type="text" matInput placeholder="Enter your full name" formControlName="fullname" required>
                    <mat-error *ngFor="let validator of errorMessages.fullname">
                        <mat-error
                            *ngIf="profileForm.get('fullname').hasError(validator.type) && (profileForm.get('fullname').dirty || profileForm.get('fullname').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Preferred Name</mat-label>
                    <input type="url" placeholder="Enter your preferred name" matInput formControlName="prefferedName">
                    <mat-error *ngFor="let validator of errorMessages.prefferedName">
                        <mat-error
                            *ngIf="profileForm.get('prefferedName').hasError(validator.type) && (profileForm.get('prefferedName').dirty || profileForm.get('prefferedName').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="email" placeholder="Enter your email" matInput formControlName="username" required>
                    <mat-error *ngFor="let validator of errorMessages.username">
                        <mat-error
                            *ngIf="profileForm.get('username').hasError(validator.type) && (profileForm.get('username').dirty || profileForm.get('username').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input type="text" placeholder="Enter your phone number" matInput formControlName="phoneNumber">
                    <mat-error *ngFor="let validator of errorMessages.phoneNumber">
                        <mat-error
                            *ngIf="profileForm.get('phoneNumber').hasError(validator.type) && (profileForm.get('phoneNumber').dirty || profileForm.get('phoneNumber').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <div formGroupName="address" class="flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Country</mat-label>
                        <mat-select placeholder="Select Country" [formControl]="country" disableOptionCentering>
                            <mat-option *ngFor="let el of countries" [value]="el.COUNTRY_CODE">{{el.COUNTRY_NAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Addess</mat-label>
                        <input type="text" #addressText placeholder="Enter your address" matInput
                            formControlName="addressLine1">
                        <mat-error *ngFor="let validator of errorMessages.address.addressLine1">
                            <mat-error
                                *ngIf="profileForm.get('address').get('addressLine1').hasError(validator.type) && (profileForm.get('address').get('addressLine1').dirty || profileForm.get('address').get('addressLine1').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input type="text" placeholder="Enter city" matInput formControlName="city">
                        <mat-error *ngFor="let validator of errorMessages.address.city">
                            <mat-error
                                *ngIf="profileForm.get('address').get('city').hasError(validator.type) && (profileForm.get('address').get('city').dirty || profileForm.get('address').get('city').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>State/Province</mat-label>
                        <input type="text" placeholder="Enter state/province" matInput formControlName="state">
                        <mat-error *ngFor="let validator of errorMessages.address.state">
                            <mat-error
                                *ngIf="profileForm.get('address').get('state').hasError(validator.type) && (profileForm.get('address').get('state').dirty || profileForm.get('address').get('state').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Zip</mat-label>
                        <input type="text" #zip placeholder="Enter zip" matInput formControlName="zip">
                        <mat-error *ngFor="let validator of errorMessages.address.zip">
                            <mat-error
                                *ngIf="profileForm.get('address').get('zip').hasError(validator.type) && (profileForm.get('address').get('zip').dirty || profileForm.get('address').get('zip').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>