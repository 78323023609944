<div mat-dialog-title>
    <div class="scj-subheader">Signature Request</div>
    <mat-icon class="close-sign-request" (click)="cancel()" matTooltip="Close">close</mat-icon>
</div>

<mat-dialog-content>
    <div class="preview-spinner" *ngIf="isFileLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="isFileLoadingError" class="error">
        Unable to fetch the attachment.
    </div>

    <div *ngIf="pdfBase64 && !isFileLoading && !isFileLoadingError" style="height: 100%;max-height: 100%;">
        <div class="flex-row" style="align-items: baseline;">
            <mat-form-field appearance="outline" *ngIf="esignTemplateList" style="margin-top: 6px;">
                <mat-label>Select Esign Template</mat-label>
                <mat-select placeholder="Select Esign Template" [formControl]="esignTemplateControl"
                    disableOptionCentering>
                    <mat-option *ngFor="let template of esignTemplateList" [value]="template.id">{{template.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="flex-row" style="justify-content: space-between;">
                <button *ngIf="esignTemplateControl.value" mat-flat-button (click)="applyEsignTemplate()">
                    Apply
                </button>

                <button *ngIf="enableSignRequest" mat-flat-button (click)="requestEsign()">
                    Request E-sign
                </button>
            </div>
        </div>

        <div class="esign-container">
            <div>
                <mat-form-field appearance="outline" style="padding-top: 6px;">
                    <mat-label>Select Signer</mat-label>
                    <mat-select placeholder="Select User" [formControl]="selectedUserControl" disableOptionCentering
                        [compareWith]="compareFn">
                        <mat-select-trigger>
                            <svg height="15px" width="15px">
                                <circle cx="8" cy="9" r="6" [attr.fill]="selectedUserControl.value.color"></circle>
                            </svg>
                            &nbsp;
                            <span> {{selectedUserControl.value.name}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let user of userList" [value]="user">
                            <div style="display: flex;flex-direction: row; align-items: center;">
                                <svg height="15px" width="15px">
                                    <circle cx="8" cy="8" r="7" [attr.fill]="user.color"></circle>
                                </svg>
                                &nbsp;
                                <span>{{user.name}} </span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div id="cloner" draggable="true" class="field-view" style="display: none;">
                    <img class="esign-icon" src="">
                    <span class="display-text"></span>
                    <span class="esign-tooltip"></span>
                    <ng-template *ngTemplateOutlet="deleteField">
                    </ng-template>
                </div>

                <div id="fields-list" class="fields-list">
                    <div class="scj-subheader">Fields</div>
                    <div draggable="true" class="field-view draggable" field-type="NAME"
                        attr.username="{{userSelected.username}}">
                        <img class="esign-icon" [src]="iconList.name">
                        <span class="display-text">Name</span>
                        <span class="esign-tooltip">{{userSelected.name}} Name</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                    <div draggable="true" class="field-view draggable" field-type="INITIALS"
                        attr.username="{{userSelected.username}}">
                        <img class="esign-icon" [src]="iconList.initial">
                        <span class="display-text">Initials</span>
                        <span class="esign-tooltip">{{userSelected.name}} Initials</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                    <div draggable="true" class="field-view draggable" field-type="SIGNATURE"
                        attr.username="{{userSelected.username}}">
                        <img class="esign-icon" [src]="iconList.sign">
                        <span class="display-text">Signature</span>
                        <span class="esign-tooltip">{{userSelected.name}} Signature</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                    <div draggable="true" class="field-view draggable" field-type="SIGN_DATE"
                        attr.username="{{userSelected.username}}">
                        <img class="esign-icon" [src]="iconList.date">
                        <span class="display-text">Sign Date</span>
                        <span class="esign-tooltip">{{userSelected.name}} Sign Date</span>
                        <ng-template *ngTemplateOutlet="deleteField">
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="pdf-container">
                <div class="page" *ngFor="let page of pages;let index = index;" [style.width]="page.width+'px'"
                    [style.height]="page.height+'px'">
                    <img [src]="page.data" [style.width]="page.width+'px'" [style.height]="page.height+'px'">
                    <div class="page-index">{{ index + 1 }} of {{pages.length}}</div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<ng-template #deleteField>
    <svg id="close" height="16px" width="16px" viewBox="0 0 24 24" fill="red" class="field-delete field-delete-hide">
        <path d="M7.05026 7.55025L16.9498 17.4497" stroke="red" stroke-width="3" stroke-linecap="round"
            stroke-linejoin="round"></path>
        <path d="M16.9497 7.55026L7.05017 17.4497" stroke="red" stroke-width="3" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
</ng-template>