import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { HandSetService } from '../shared/services/hand-set.service';
import { Observable } from 'rxjs';
import { NotificationService } from '../shared/services/notification.service';
import { NUMBERS_PATTERN, NUMBERS_WITH_DECIMALS_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse } from '@angular/common/http';
import { BillingModel } from '../models/billing.model';


const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class BillingComponent implements OnInit {
  isHandset$: Observable<boolean>;
  minBillingDate: Date | null = new Date();
  headerLabels: string[] = ['Invoice Date', 'Due Date', 'Amount', 'Notes (Printed on Invoice)'];
  billingForm: FormGroup;
  subscriptionId: string;
  isLoading: boolean = false;
 
  constructor(private handSet: HandSetService, private dialogRef: MatDialogRef<BillingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private notificationService: NotificationService, private http: HttpCommonService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.billingForm = new FormGroup({
      subscriptionId: new FormControl(this.data.id),
      amount: new FormControl('', [Validators.required, Validators.pattern(NUMBERS_WITH_DECIMALS_PATTERN)]),
      scheduleStartDate: new FormControl(moment(this.minBillingDate)),
      invoiceNotes: new FormControl('Thanks for your business.')
    });
  }

  onSubmit() {
    this.billingForm.markAllAsTouched();
    if (this.billingForm.valid) {
      let billingFormRawValue = this.billingForm.getRawValue() as BillingModel;
      billingFormRawValue.scheduleStartDate = formatMomentDate(billingFormRawValue.scheduleStartDate);
      this.isLoading = true;
      this.http.createBilling(billingFormRawValue).subscribe((res: HttpResponse<BillingModel>) => {
        this.isLoading = false;
        if (res.status === 200) {
          this.notificationService.notifyText(`Invoice for subscription ${this.data.name} created successfully`);
          this.dialogRef.close({ billCreated: true, serviceFee: billingFormRawValue.amount });
        }
      }, (err: HttpResponse<any>) => {
        this.isLoading = false;
        this.notificationService.notifyText("Something went wrong!! Please try after some time.");
      });
    }
  }
}
