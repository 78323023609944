import { AbstractControl, FormGroup } from '@angular/forms';
import { TEXT_SPACE_PATTERN } from '../regex-expressions/regex-expressions';

export class CustomValidators {

    static textwithspace = (fc: AbstractControl): { [key: string]: boolean } | null => {
        if (fc.value !== '' && fc.value !== null && fc.value !== undefined) {
            if (fc.value.match(TEXT_SPACE_PATTERN)) {
                return null;
            } else {
                return { textwithspace: true };
            }
        } else {
            return null;
        }
    }

    static website = (fc: AbstractControl): { [key: string]: boolean } | null => {
        if (fc.value !== '' && fc.value !== null && fc.value !== undefined && fc.value.length > 0) {
            let url = undefined;
            if (fc.value.includes('www.'))
                return null;
            try {
                url = new URL(fc.value);
                if (url?.protocol === 'http:' || url?.protocol === 'https:') {
                    return null;
                } else {
                    return { website: true };
                }
            } catch (Error) {
                return { website: true };
            }
        }
        return null;
    }

    static passwordMatch(passwordControlName: string, confirmPasswordControlName: string) {
        return (formGroup: FormGroup) => {
            const passwordControl = formGroup.controls[passwordControlName];
            const confirmPasswordControl = formGroup.controls[confirmPasswordControlName];

            if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMatch) {
                return;
            }

            if (passwordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({ passwordMatch: true });
            } else {
                confirmPasswordControl.setErrors(null);
            }
        }
    }
}