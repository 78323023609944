import { Pipe, PipeTransform } from '@angular/core';
import { COLUMN_FORMAT } from '../enums/table.enum';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'formatColumn'
})
export class FormatColumnPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) { }

  transform(value: any, format: string): any {
    if (value === undefined || value === null) {
      return null;
    }

    if (format === COLUMN_FORMAT.CURRENCY) {
      return this.currencyPipe.transform(value, 'USD', 'symbol');
    }

    return value;
  }

}
