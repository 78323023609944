<div class="flex-column mat-elevation-z8 login-main">
    
    <scj-firm-logo-container (subdomain)="setSubdomain($event)">
    </scj-firm-logo-container>
    <div style="display: flex;">
        <div class="right-margin">
            <h1 class="scj-h1">Sign in </h1>
        </div>
        <div *ngIf="isSpinner">
            <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
        </div>
    </div>
    
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="flex-column" autocomplete="off"  *ngIf="!isSpinner">
        <mat-form-field appearance="legacy">
            <mat-label>Email ID</mat-label>
            <input type="email" matInput placeholder="Enter your email id" formControlName="username" required>
            <mat-error *ngIf="loginForm.controls['username'].invalid">Enter registered mail id</mat-error>
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Password</mat-label>
            <input [type]="hide ? 'password' : 'text'" matInput placeholder="Enter your password"
                formControlName="password" required>
            <button mat-icon-button matSuffix type="button" (click)="hide = !hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.controls['password'].invalid">Enter your password</mat-error>
        </mat-form-field>
        <div class="flex-row forget-submit">
            <a class="linkHover" href="/forgetPassword">Forgot Password</a>
            <button class="submitButton" type="submit">Sign in</button>
        </div>
    </form>
   
 

<div fxLayout="row" style="float: right;" class="right">
    <a href="https://simplecj.com" target="_blank"><img src="https://scj-prd-firm.s3.us-east-2.amazonaws.com/poweredBySimpleCJ-horizontal.png" alt="Powered by SimpleCJ" 
        class="img-responsive" style="opacity:0.9;width:100px;height:15px;"></a>
</div>
</div>
