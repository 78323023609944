import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertMessagesComponent } from 'src/app/common/components/alert-messages/alert-messages.component';
import { AuthenticationService } from './authentication.service';

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Observable<boolean>;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private matDialog: MatDialog) { }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '50vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      header: 'Leave Page?',
      message: 'You have Unsaved Changes',
      isHTML: false,
      buttonText: { ok: 'Stay on Page', cancel: 'Leave Page' },
      revertBoolean: true
    }
    return component ? (component.canDeactivate() || this.matDialog.open<AlertMessagesComponent, void, boolean>(AlertMessagesComponent,
      dialogConfig).afterClosed()) : true;

  }

}

