<div class="flex-column mat-elevation-z8 login-main">
    <scj-firm-logo-container (subdomain)="setSubdomain($event)">
    </scj-firm-logo-container>
    <h1 *ngIf=" !isDisabled" class="scj-h1">Forgot Password?</h1>

    <form *ngIf="!isDisabled;else passwordreset" [formGroup]="forgotForm" (ngSubmit)="onSubmit(forgotForm.value)"
        class="flex-column" autocomplete="off">
        <i class="forgetPassinfo">
            Please enter your Email address, and we will send you instructions to reset password If the Email is
            valid.
        </i>

        <mat-form-field appearance="legacy">
            <mat-label>Email ID</mat-label>
            <input type="email" matInput placeholder="Enter your email id" formControlName="username" required>
            <mat-error *ngIf="forgotForm.controls['username'].invalid">Enter registered mail id</mat-error>
        </mat-form-field>

        <div class="flex-row forget-submit">
            <button class="backButton" type="button" (click)="redirect()"> Back </button>
            <button class="submitButton" type="submit"
                [disabled]="!forgotForm.dirty || !forgotForm.valid || isDisabled">Send Verfication Mail</button>
        </div>
    </form>
    <ng-template #passwordreset>
        <div>
            <h2> Password Reset Sent </h2>
            <p>
                We've emailed you instructions for setting up your password, You should recieve them shortly. If you
                dont receive an emai , please make sure you've entered the email you registered with.
                You can redirect to <a routerLink="/login"><b>login </b></a>
            </p>
        </div>
    </ng-template>


</div>