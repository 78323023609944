<div class="available-services">
    <div style="display:flex"><div><h1 class="scj-h2 mr-20">Available Services</h1> </div>
     <div *ngIf="isSpinner">
      <mat-spinner [diameter]="25" [strokeWidth]="2"></mat-spinner>
    </div> </div>
  
    <ul>
      <ng-container *ngFor="let service of availableServices; let i = index">
        <li id="tile-{{i}}" class="availableServicestile">
          <h2 class="scj-h2 mr-20" style="color:#0178d4">
            {{service.name}}
          </h2>
          <p>
            {{service?.helpTextForClients}}
          </p>
          <!-- <a (click)="expand(i)">Get Started >></a> -->
          <div *ngIf="!service.isActiveSubscription;else elseBlock" id="tile-expand-{{i}}" [formGroup]="servicesForm.controls[i]">
            <div class="service-details">
              <div *ngIf="service.tcAgreementId" class="checkbox">
                <mat-checkbox color="primary" formControlName="tcAgreement" tabindex="3">
                </mat-checkbox>
                <span class="agreement-link">
                  By clicking this you agree to
                  <a  (click)="loadAgreementData(servicesForm.controls[i]?.get('tcAgreement'), service.tcAgreementId)">
                    Terms and Conditions
                  </a>
                </span>
              </div>
              <div *ngIf="service.srvcAgreementId" class="checkbox">
                <mat-checkbox color="primary" formControlName="svrAgreement">
                </mat-checkbox>
                <span class="agreement-link">
                  By clicking this you agree to
                  <a style="font-size:13px;" (click)="loadAgreementData(servicesForm.controls[i]?.get('svrAgreement'), service.srvcAgreementId)">
                    Service Agreement
                  </a>
                </span>
              </div>
              <div class="flex-row">
                <button class="primary" mat-flat-button (click)="subscribe(service.id)" [disabled]="validSubscribe(i)">Subscribe</button>
                <!-- <button mat-flat-button class="secondary" (click)="cancel(i)">Cancel</button> -->
                
              </div>
            </div>
          </div>
          <ng-template #elseBlock><div class="flex-row"><mat-icon class="step-valid"> check_circle_outlined_rounded </mat-icon> <span>You have already Subscribed</span>  </div></ng-template>
          <mat-divider [vertical]="false"></mat-divider>
        </li>
      </ng-container>
    </ul>
  
   
  </div>