<header class="flex-row nav-header">
    <div class="flex-row">
        <scj-firm-logo-container class="nav-header-logo">
        </scj-firm-logo-container>
    </div>

    <div class="flex-row nav-header-button" (click)="openTopNav()" matRipple matRippleCentered="true"
        matRippleRadius="24">
        <span> {{user.fullname | letters}}</span>
    </div>
</header>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

        <mat-nav-list *ngIf="(isHandset$ | async)" class="handset-nav">
            <a mat-list-item *ngFor="let item of navigationLinks" routerLink="{{item.link}}"
                routerLinkActive="nav-active" #rla="routerLinkActive" class="nav-non-active"
                (click)="drawer.toggle(); headerTitle = item.title">
                <div class="flex-column nav-icon-text">
                    <mat-icon *ngIf="!item.type">{{item.icon}}</mat-icon>
                    <mat-icon *ngIf="item.type" [svgIcon]="item.icon"></mat-icon>
                    <div>{{item.title}}</div>
                </div>
            </a>
            <div class='bottomStick'> 
                        
                <a mat-list-item target="_blank" href="https://simplecj.com"><img src="https://scj-prd-firm.s3.us-east-2.amazonaws.com/poweredBySimpleCJ.png" alt="Powered by SimpleCJ" style="opacity:0.7;width:50px;height:25px;"></a>
            
        </div>
        </mat-nav-list>


        <ng-container *ngIf="!(isHandset$ | async)">
            <mat-nav-list class="flex-column">
                <div class="nav-links">
                    <a mat-list-item *ngFor="let item of navigationLinks" routerLink="{{item.link}}"
                        (click)="highlightLinks(item.link)" routerLinkActive="nav-active" #rla="routerLinkActive"
                        class="nav-non-active">
                        <div class="flex-column nav-icon-text">
                            <span *ngIf="(item.link === 'messages') && messageCount > 0"
                                class="notify-message">{{messageCount}}</span>
                            <span *ngIf="(item.link === 'messages') && messageIndicator"
                                class="notify-message notify-message-cpa"></span>
                            <mat-icon *ngIf="!item.type">{{item.icon}}</mat-icon>
                            <mat-icon *ngIf="item.type" [svgIcon]="item.icon"></mat-icon>
                            <div>{{item.title}}</div>
                        </div>
                    </a>
                    <div class='bottomStick'> 
                        
                            <a mat-list-item target="_blank" href="https://simplecj.com"><img src="https://scj-prd-firm.s3.us-east-2.amazonaws.com/poweredBySimpleCJ.png" alt="Powered by SimpleCJ" style="opacity:0.7;width:50px;height:20px;"></a>
                        
                    </div>
                </div>
            </mat-nav-list>
        </ng-container>

    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>{{headerTitle}}</span>
        </mat-toolbar>

        <div class="nav-router" [ngClass]="{'message': router.url.indexOf('message') !== -1}">
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="isFirmClient" class="chat-fab" cdkDrag (cdkDragStarted)="handleDragStart($event)">
    <span *ngIf="messageCount>0" class="notify-message">{{messageCount}}</span>
    <button mat-fab (click)="openChat($event)">
        <mat-icon>chat</mat-icon>
    </button>
</div>

<button *ngIf="enableFeedback" (click)="openFeedback()" class="feedback"> Feedback </button>

<ng-template #headerButton let-data>
    <div class="flex-column nav-header-dialog">
        <div class="firmname">
            {{data.navHeaderTitle}}
        </div>
        <div class="flex-column">
            <span class="username">{{data.name}}</span>
            <span>{{data.email}}</span>
        </div>
        <div class="flex-row logout">
            <a (click)="logout()" matDialogClose>Logout </a>
        </div>
    </div>
</ng-template>

<ng-template #feedback>
    <form class="feedback-form" [formGroup]="feedbackForm" (ngSubmit)="onSubmitFeedback(feedbackForm.value)">
        <div class="contentArea">
            <div>
                <textarea scjAppPrefixFocusAndSelect placeholder="Max 2500 characters..."
                    formControlName="feedbackcontent" rows="10" columns="10" maxlength="2500">
                </textarea>
            </div>
            <div> <i>Characters Count: {{feedbackForm.value.feedbackcontent.length}}</i></div>
        </div>

        <div class="butonactions">
            <button class="mat-flat-button" type="submit" [disabled]="!feedbackForm.valid">
                Submit</button>
            <button type="button" class="mat-flat-button" (click)="goTocancel()"> Cancel</button>
        </div>
    </form>
</ng-template>