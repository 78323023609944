import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';
import { NUMBERS_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';

@Component({
  selector: 'scj-multi-factor-auth',
  templateUrl: './multi-factor-auth.component.html',
  styleUrls: ['./multi-factor-auth.component.scss']
})
export class MultiFactorAuthComponent implements OnInit {
  subdomain: string;
  mfaToken: string;
  username: string;
  otpControl: FormControl = new FormControl('', [Validators.minLength(6), Validators.maxLength(6), Validators.pattern(NUMBERS_PATTERN)]);
  rememberMeControl: FormControl = new FormControl(false);
  error: string;
  isLoading = false;

  constructor(private router: Router, private authService: AuthenticationService, private notificationService: NotificationService) {
    let extras = this.router.getCurrentNavigation().extras.state;
    this.mfaToken = extras.mfaToken;
    this.username = extras.username;
    this.subdomain = extras.subdomain;
  }

  ngOnInit(): void {
    if (!this.mfaToken || !this.username) {
      this.notificationService.notifyText('Token or username is invalid.');
      this.router.navigateByUrl("/login");
    }
  }

  onSubmit() {
    if (this.otpControl.valid) {
      this.isLoading = true;
      let otpData = { otpToken: this.mfaToken, otp: parseInt(this.otpControl.value), rememberMe: this.rememberMeControl.value };
      this.authService.mfaConfirm(otpData, this.subdomain).subscribe({
        next: (data: User | any) => {
          if (data !== null) {
            let url = '/firm';
            if (data.roles.length === 1 && data.roles.indexOf('firmclient') !== -1) {
              url = '/client';
            }
            const returnUrl = url;
            this.isLoading = false;
            this.router.navigateByUrl(returnUrl);
          } else {
            this.isLoading = false;
            this.router.navigateByUrl("/login");
            this.notificationService.notifyText('Something went wrong. Please try after some time.');
          }
        },
        error: (error: any) => {
          this.error = error.message;
          this.isLoading = false;
          this.notificationService.notifyText(`${this.error}`);
        },
      });
    }
  }

  resendOtp() {
    let otpToken = { otpToken: this.mfaToken };
    this.authService.resendOtp(otpToken).subscribe((res: HttpResponse<any>) => {
      if (res.status === 200) {
        this.mfaToken = res.body.token;
        this.notificationService.notifyText('OTP resent to your email.', 'X');
      } else {
        this.notificationService.notifyText('OTP resend failed.', 'X');
      }
    }, (err: HttpErrorResponse) => {
      this.notificationService.notifyText('OTP resend failed. Try logging after some tiem.', 'X');
      this.router.navigateByUrl("/login");
    });
  }
}
