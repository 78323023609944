import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ColumnFilterConfig } from 'src/app/models/table-columns-config.model';
import { DATE_DD_MMM_YYYY_FORMAT } from '../../date-formats/date.format';
import { TableFilterService } from '../../services/table-filter.service';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'scj-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class TableFilterComponent implements OnInit {
  columnFilterForm: FormGroup;
  columnFilterConfig: ColumnFilterConfig[];

  constructor(private dialogRef: MatDialogRef<TableFilterComponent>, private tfService: TableFilterService,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.columnFilterForm = this.tfService.getColumnFilteForm();
    //this.columnFilterForm.setValue(this.tfService.filterJson);
    this.columnFilterConfig = this.tfService.columnFilterConfig;
  }

  submit() {
    this.tfService.applyFilter(this.columnFilterForm.getRawValue());
    this.dialogRef.close();
  }

  reset() {
    this.tfService.resetFilter();
    this.dialogRef.close();
  }
}
