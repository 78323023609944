<h2 class="scj-h2" *ngIf="header">{{header}}</h2>

<mat-dialog-content>
    <p *ngIf="!isHTML">
        {{message}}
    </p>
    <div *ngIf="isHTML" #displayHTML>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button [class]="button1" (click)="accept()" tabindex="1">{{confirmButtonText}}</button>
    <button mat-flat-button [class]="button2" mat-dialog-close (click)="close()">{{cancelButtonText}}</button>
</mat-dialog-actions>