import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../data-service.service';
import { ContactsModel } from '../models/client-contacts.model';
import { HttpCommonService } from '../services/http-common.service';
import { FileHandlerService } from '../shared/services/file-handler.service';
import { NotificationService } from '../shared/services/notification.service';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { AuthenticationService } from '../shared/services/authentication.service';
import { EsignTemplateModel } from '../models/esign-template.model';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { EsignTemplateFeildsModel } from '../models/esign-template-fields.model';
import { HandSetService } from '../shared/services/hand-set.service';
import { DomSafePipe } from '../shared/pipes/dom-safe.pipe';
import { domSafeEnum } from '../shared/enums/dom-safe.enum';
import { DATE_ICON, ESIGN_ICON, INITIALS_ICON, NAME_ICON } from '../shared/constants/svg-icon';
import { COLOR } from '../shared/enums/color.enum';
import { EsignTemplateUserMapperComponent } from '../esign-template-user-mapper/esign-template-user-mapper.component';
import { map } from 'rxjs/operators';

declare let $: any;
export let signMarked: boolean;
export let isSignMarked = new EventEmitter();
export let selectedUser: any;

pdfjsLib.GlobalWorkerOptions.workerSrc = './assets/js/pdf.worker.min.js';

@Component({
  selector: 'scj-sign-attachment',
  templateUrl: './sign-attachment.component.html',
  styleUrls: ['./sign-attachment.component.scss']
})
export class SignAttachmentComponent implements OnInit {
  isFileLoading: boolean = true;
  isFileLoadingError: boolean = false;
  pdfBase64: string;
  pages: any[] = [];
  esignTemplateList: EsignTemplateModel[];
  esignTemplateControl: FormControl = new FormControl('');
  previousEsignTemplate: string;
  enableSignRequest: boolean = false;
  isHandSet: boolean = false;
  userList: any[] = [];
  selectedUserControl = new FormControl();
  iconList: any;
  userSelected: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<SignAttachmentComponent>,
    private fhService: FileHandlerService, private dataService: DataService, private dialog: MatDialog, private cdRef: ChangeDetectorRef,
    private http: HttpCommonService, private notificationService: NotificationService, private authService: AuthenticationService,
    private handSet: HandSetService, private domSafe: DomSafePipe) {
    this.isHandSet = this.handSet.isHandset;
    let name = '';//`${this.authService.userValue.fullname} (${this.authService.userValue.firmName})`;

    this.dataService.client.contacts.forEach((e: ContactsModel, index: number) => {
      name = `${e.fullname} `;
      name = name + "(Client)";
      this.userList.push({ name: name, username: e.username, color: COLOR[`COLOR_${index + 1}`] });
    });
    name = `${this.authService.userValue.fullname} (${this.authService.userValue.firmName})`;
    this.userList.push({ name: name, username: this.authService.userValue.username, color: COLOR[`COLOR_10`] });

    this.userSelected = selectedUser = this.userList[0];
    this.selectedUserControl.setValue(this.userSelected);

    if (this.isHandSet) {
      this.notificationService.notifyText("Mobile device only supports apply & editing E-sign template.", "X");
    }
  }

  ngOnInit(): void {
    signMarked = false;
    this.getFilePreview();
    this.getEsignTemplateList();

    isSignMarked.subscribe(() => {
      this.enableSignRequest = true;
      this.cdRef.detectChanges();
    });

    this.iconList = {
      sign: this.generateIcons(ESIGN_ICON),
      name: this.generateIcons(NAME_ICON),
      initial: this.generateIcons(INITIALS_ICON),
      date: this.generateIcons(DATE_ICON)
    };

    this.selectedUserControl.valueChanges.subscribe((user: any) => {
      this.userSelected = selectedUser = { name: user.name, username: user.username, color: user.color };
    });
  }

  generateIcons(icon: string) {
    let SVGContainer = document.createElement("div");
    SVGContainer.style.display = "none";
    SVGContainer.innerHTML = icon;
    let svgNode = SVGContainer.firstElementChild as SVGElement;
    svgNode.setAttribute("fill", "rgba(0,0,0,0.5");

    let svgXml = new XMLSerializer().serializeToString(svgNode);
    return this.domSafe.transform("data:image/svg+xml;base64," + btoa(svgXml), domSafeEnum.SAFE_RESOURCE_URL);
  }

  compareFn(o1: any, o2: any) {
    if (o1.username === o2.username && o1.color === o2.color)
      return true;
    else return false;
  }

  getFilePreview() {
    this.isFileLoading = true;
    this.isFileLoadingError = false;
    this.fhService.downloadFile(`/api/v1/client/subscription/attachment/${this.data.attachment.id}`).subscribe(res => {
      let blob: Blob = new Blob([res], { type: res.type });
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.pdfBase64 = reader.result as string;
        this.setPages();
      }
      this.isFileLoading = false;
    }, err => {
      this.isFileLoading = false;
      this.isFileLoadingError = true;
    });
  }

  async setPages(): Promise<void> {
    let pdfDoc = await pdfjsLib.getDocument({ url: this.pdfBase64 }).promise;
    const totalPages = pdfDoc.numPages;
    this.pages = [];

    for (let i = 0; i < totalPages; i++) {
      await pdfDoc.getPage(i + 1).then(async (page: PDFPageProxy) => {
        let canvas = document.createElement('canvas');
        canvas.setAttribute("id", `canvas-${i}`);
        const viewport = page.getViewport({ scale: 1 });
        const height = viewport.height;
        const width = viewport.width;
        canvas.height = height;
        canvas.width = width;
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };
        await page.render(renderContext).promise.then(() => {
          this.pages.push({ data: canvas.toDataURL('image/png'), width: width, height: height });
        });
      });
    }
    loadJQuery();
  }

  getEsignTemplateList() {
    this.http.getEsignTemplateList().subscribe((res: HttpResponse<EsignTemplateModel[]>) => {
      if (res.body?.length > 0) {
        this.esignTemplateList = res.body.filter((x: EsignTemplateModel) => x.status === 'ACTIVE');
      } else {
        this.notificationService.notifyText("There are no Esign templates", "X");
      }
    }, (err: HttpErrorResponse) => {
      this.notificationService.notifyText("Unable to fetch Esign templates", "X");
    });
  }

  applyEsignTemplate() {
    let templateId = this.esignTemplateControl.value;
    if (templateId !== this.previousEsignTemplate) {
      if (!signMarked) {
        this.openEsignTemplateUserMapper(templateId);
      } else {
        let message = 'Are you sure you want to change Esign template? All the previously marked fields will be deleted.';
        let dialogConfig = this.getAlertConfig(message);
        this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
          if (!confirm) {
            this.deleteEsignTemplate();
            this.openEsignTemplateUserMapper(templateId);
          }
        });
      }
    }
  }

  openEsignTemplateUserMapper(templateId: string) {
    let template = this.esignTemplateList.find((x: EsignTemplateModel) => x.id === templateId);
    let signMarkers = JSON.parse(template.jsonData) as EsignTemplateFeildsModel[];
    let map: Map<string, any> = new Map();
    signMarkers.forEach((e: EsignTemplateFeildsModel) => {
      map.set(e.username, e);
    });
    let userSet: any[] = Array.from(map.values()).sort((a: EsignTemplateFeildsModel, b: EsignTemplateFeildsModel) => a.username.localeCompare(b.username));
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '25vw';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50vw';
    dialogConfig.height = '100%';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "esign-modal";
    dialogConfig.data = { templateUserList: userSet, userList: this.userList };

    this.dialog.open<EsignTemplateUserMapperComponent>(EsignTemplateUserMapperComponent, dialogConfig).afterClosed().subscribe((result: any) => {
      if (result) {
        this.loadEsignTemplate(signMarkers, result);
        signMarked = true;
        this.previousEsignTemplate = templateId;
      }
    });
  }

  loadEsignTemplate(signMarkers: EsignTemplateFeildsModel[], templateUserMapper: any) {
    signMarkers = signMarkers.reduce((markers, e: EsignTemplateFeildsModel) => {
      let user = templateUserMapper[e.username];
      if (user) {
        e.bgColor = user.color;
        e.username = user.username;
        let tooltipArr = e.tooltip.split(" ")
        tooltipArr.splice(0, 2, user.name);
        e.tooltip = tooltipArr.join(" ");
        markers.push(e);
      }
      return markers;
    }, []);

    let pageCount = Math.max(...signMarkers.map((o: any) => o.page));
    let timer = setInterval(() => {
      let pages = document.querySelectorAll(".page");
      if (pageCount <= pages?.length) {
        signMarkers.forEach((e: EsignTemplateFeildsModel, index: number) => {
          let page = pages[e.page - 1];
          let div = document.getElementById('cloner').cloneNode(true) as HTMLElement;
          div.setAttribute('field-type', e.fieldType);
          div.setAttribute('username', e.username);
          div.classList.add('field-editable');
          div.removeAttribute('id');
          div.removeAttribute('style');
          div.style.top = `${e.y}px`;
          div.style.left = `${e.x}px`;
          div.style.width = `${e.width}px`;
          div.style.height = `${e.height}px`;
          div.style.backgroundColor = `${e.bgColor}`;
          let nodes = div.children;
          nodes.item(0).setAttribute("src", e.icon);
          nodes.item(1).innerHTML = e.displayText;
          nodes.item(2).innerHTML = e.tooltip;
          nodes.item(3).classList.remove('field-delete-hide');
          page.appendChild(div);
        });
        clearInterval(timer);
        esignTemplateActivate();
        this.enableSignRequest = true;
      }
    }, 200);
  }

  deleteEsignTemplate() {
    document.querySelectorAll(".field-editable").forEach((e: HTMLElement) => {
      e.remove();
    })
  }

  getAlertConfig(message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '30%';
    dialogConfig.minHeight = '20%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message: message,
      isHTML: false,
      buttonText: { ok: 'Ok', cancel: 'No' },
      revertBoolean: true
    }
    return dialogConfig;
  }

  requestEsign() {
    let pages = document.querySelectorAll(".page");
    let signTemplateMarkers: EsignTemplateFeildsModel[] = [];

    pages.forEach((page: any, index: number) => {
      let fields = page?.querySelectorAll('.field-editable') as NodeListOf<Element>;
      fields.forEach((field: HTMLElement) => {
        let width = (field.clientWidth < 100) ? 100 : (field.clientWidth > 250) ? 250 : field.clientWidth;
        let height = (field.clientHeight < 25) ? 25 : (field.clientHeight > 60) ? 60 : field.clientHeight
        signTemplateMarkers.push({
          page: index + 1, fieldType: field.getAttribute("field-type"), username: field.getAttribute("username"),
          width: width - 16, height: height - 8, x: field.offsetLeft, y: field.offsetTop, name: field.getAttribute('name'),
          bgColor: field.style.getPropertyValue('background-color'), icon: field.children.item(0).getAttribute("src"),
          displayText: field.children.item(1).innerHTML, tooltip: field.children.item(2).innerHTML
        });
      })
    });

    if (signTemplateMarkers.length > 0) {
      this.dialogRef.close(signTemplateMarkers);
    } else {
      this.notificationService.notifyText("Esign not supported without marker fileds.", "X");
    }
  }

  cancel() {
    if (signMarked) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.width = 'auto';
      dialogConfig.height = 'auto';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.closeOnNavigation = true;
      dialogConfig.disableClose = false;

      dialogConfig.data = {
        message: `You are about to exit signature request for ${this.data.attachment.fileName} and signature data will not be saved. Please confirm`,
        isHTML: false,
        buttonText: { ok: 'Yes', cancel: 'No' },
        revertBoolean: true
      }
      this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
        if (!confirm) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}

function loadJQuery() {
  $(function () {

    $('.draggable').draggable({
      helper: 'clone',
      cursor: 'move',
      revert: true,
      revertDuration: 0,
      tolerance: 'fit',
      drag: function (event: any, ui: any) {
        ui.helper.addClass('field-editable');
        ui.helper.css('border-style', 'dashed').css('background-color', `${selectedUser.color}`).css('color', 'white');
        let fieldType = ui.helper.attr('field-type')
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = (fieldType === 'NAME') ? NAME_ICON : (fieldType === 'INITIALS') ? INITIALS_ICON : (fieldType === 'SIGNATURE') ? ESIGN_ICON : DATE_ICON;
        let svgNode = SVGContainer.firstElementChild as SVGElement;
        svgNode.setAttribute("fill", "rgba(255,255,255,1)");

        let svgXml = new XMLSerializer().serializeToString(svgNode);
        ui.helper.find("img.esign-icon").attr("src", "data:image/svg+xml;base64," + btoa(svgXml));
      }
    });

    $(".page").droppable({
      accept: '.draggable',
      drop: function (event: any, ui: any) {
        if (!signMarked) {
          signMarked = true;
          isSignMarked.emit('esign');
        }
        let dropped = ui.helper.clone();
        let width = ui.helper.width();
        let height = ui.helper.height();
        ui.helper.remove();
        $(this).append(dropped);
        let leftPosition = ui.offset.left - $(this).offset().left;
        let topPosition = ui.offset.top - $(this).offset().top;
        dropped.find("svg.field-delete").removeClass("field-delete-hide");
        dropped.css({
          top: topPosition, left: leftPosition, width: width, height: height
        });
        dropped.addClass("field-editable");
        $(dropped).draggable({
          appendTo: '.page',
          cursor: 'move',
          containment: "parent",
        }).resizable({
          //aspectRatio: true,
          containment: "parent",
          handles: "se, nw, sw, s, n, w",
          minWidth: 75,
          minHeight: 25,
          maxWidth: 250,
          maxHeight: 50
        });
      }
    });

    $(document).on("click", ".field-delete", function () {
      let parent = $(this).parent();
      parent.remove();
    });
  });
}

function esignTemplateActivate() {
  $('.field-editable').draggable({
    appendTo: '.page',
    cursor: 'move',
    containment: "parent",
  }).resizable({
    containment: "parent",
    handles: "se, nw, sw, s, n, w",
    minWidth: 75,
    minHeight: 25,
    maxWidth: 250,
    maxHeight: 50
  });
}