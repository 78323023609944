import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElementsModel } from '../models/elements.model';
import { Papa } from 'ngx-papaparse';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { Router } from '@angular/router';
import { FileHandlerService } from '../shared/services/file-handler.service';
import { NotificationService } from '../shared/services/notification.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'scj-bulk-clients-create',
  templateUrl: './bulk-clients-create.component.html',
  styleUrls: ['./bulk-clients-create.component.scss']
})
export class BulkClientsCreateComponent implements OnInit {
  isHovering: boolean = false;
  csvTableHeader: any;
  csvTableData: any;
  clickedTemplateURL: string;
  selectedOption: string;
  finalizedfile: File;
  isSpinner: boolean = false;
  fileExtension: string[] = ["CSV"];

  constructor(private papa: Papa, private matDialog: MatDialog, private router: Router, private fhService: FileHandlerService, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  onDrop(files: FileList) {
    if (files.length > 1) {
      this.notificationService.notifyText('Multiple files upload not supported.', "X");
      return;
    }

    let file: File = files.item(0);

    if (!this.fhService.validateFileExtension(file.name, this.fileExtension)) {
      this.notificationService.notifyText('Only CSV file is supported.', "X");
      return;
    }

    this.finalizedfile = file;
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e: any) => {
      let csv: string = reader.result as string;

      const results = this.papa.parse(csv as string, { header: false });

      if (!!results && !!results.data && results.data.length > 0 && results.errors.length === 0) {
        this.csvTableHeader = results.data[0];
        this.csvTableData = [...results.data.slice(1, results.data.length - 1)];
      } else {
        if (results.errors?.length > 0) {
          console.log(results.errors);
          this.notificationService.notifyText("Error while parsing CSV file data.", "X");
        } else {
          this.notificationService.notifyText("There is no data to preview the file.", "X");
        }
      }
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  toggle(tooltip: MatTooltip) {
    return tooltip.toggle();
  }

  onItemChange(event: any) {
    this.selectedOption = event.value;
    this.clickedTemplateURL = this.selectedOption === 'Individual' ? '../../assets/excel-templates/individual_clients_sample.csv' : '../../assets/excel-templates/business_clients_sample_template.csv'
  }


  onSubmit() {
    if (this.selectedOption != null && (this.selectedOption === 'Individual' || this.selectedOption === 'Organization') && (this.csvTableData != null)) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.minWidth = '20%';
      dialogConfig.width = 'auto';
      dialogConfig.height = 'auto';
      dialogConfig.maxHeight = '50vh';
      dialogConfig.closeOnNavigation = false;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        header: 'Bulk CLient Upload',
        message: 'Are you sure you want to Bulk load the Client Data?',
        isHTML: false,
        buttonText: { ok: 'Cancel', cancel: 'Upload' },
        revertBoolean: true
      }

      this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(AlertMessagesComponent, dialogConfig).afterClosed().subscribe(data => {
        if (data) {
          this.isSpinner = true;
          let formData = new FormData();
          formData.append("file", this.finalizedfile, this.finalizedfile.name);
          let uploadUrl = this.selectedOption === 'Individual' ? 'individual' : 'business';
          this.fhService.uploadBulkFile(uploadUrl, formData).subscribe((data) => {
            this.isSpinner = false;
            this.notificationService.notifyText('Successfully upload Data to Database' + data);
            this.router.navigateByUrl('firm/clients-list');
          }, err => {
            this.isSpinner = false;
            console.log('Found Error', err);
            this.notificationService.notifyText('Something went wrong');
          });
        }
      });
    } else {
      this.notificationService.notifyText('Please select Type of Clinet Data and Upload atleast one File');
    }
  }

  goToCancel() {
    this.router.navigateByUrl('firm/clients-list');
  }
}
