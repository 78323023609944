import { Component, OnInit } from '@angular/core';
import { ClientSubscriptionsListModel } from '../models/client-subscription-list.model';
import { HttpResponse } from '@angular/common/http';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { COLUMN_TYPES, COLUMN_FORMAT, COLUMN_FILTER_TYPE } from '../shared/enums/table.enum';
import { HttpCommonService } from '../services/http-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Observable, of } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';
import { DataService } from '../data-service.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { NotificationService } from '../shared/services/notification.service';
import { ServicesListModel } from '../models/services-list.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ClientServiceAssignmentComponent } from '../client-service-assignment/client-service-assignment.component';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'scj-client-services-list',
  templateUrl: './client-services-list.component.html',
  styleUrls: ['./client-services-list.component.scss']
})
export class ClientServicesListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  tableExpand = false;
  clientId: string;
  activeServices: ClientSubscriptionsListModel[] = [];
  searchControl: FormControl = new FormControl('');
  columnConfig: TableColumnsConfig[];
  columnFilterConfig: ColumnFilterConfig[];
  exportFileName = '';
  serviceList: ServicesListModel[] = [];
  isFirm: boolean = false;
  isSpinner: boolean = false;
  serviceName: string;

  constructor(private handSet: HandSetService, private activatedRoute: ActivatedRoute, private http: HttpCommonService, private router: Router,
    private authenticationService: AuthenticationService, private dataService: DataService, private tfService: TableFilterService,
    private notificationService: NotificationService, private matDialog: MatDialog) {
    this.isHandset$ = this.handSet.isHandset$;
    if (this.authenticationService.userValue.clientId !== null) {
      this.clientId = this.authenticationService.userValue.clientId;
      this.exportFileName = 'My Subscriptions';
    } else {
      this.clientId = this.activatedRoute.snapshot.parent.parent.params.clientId;
      this.exportFileName = `${this.dataService.client.businessName} Client Subscriptions`;
      this.isFirm = true;
      this.getAvailableServices();
    }
    this.columnConfig = this.getTableColumnsConfig();
    this.columnFilterConfig = this.getColumnFilterConfig();
  }

  ngOnInit(): void {
    this.getClientSubscriptions();
  }

  getClientSubscriptions() {
    this.http.getClientSubscriptionsListByClientId(this.clientId).subscribe((res: HttpResponse<ClientSubscriptionsListModel[]>) => {
      if (res.body !== null) {
        this.activeServices = res.body;

        for (let i = 0; i < this.activeServices.length; i++) {
          if (this.activeServices[i].pendingCpaAction && this.activeServices[i].status !== 'COMPLETED' && this.activeServices[i].status !== 'CANCEL') {
            this.activeServices[i].status = 'PENDING_CPA'
          } else if (!this.activeServices[i].pendingCpaAction && this.activeServices[i].status !== 'COMPLETED' && this.activeServices[i].status !== 'CANCEL') {
            this.activeServices[i].status = 'PENDING_CLIENT'
          }
        }
        this.tfService.initializeColumnFilter(this.columnFilterConfig);
      }
      this.isLoading = false;
    }, (err: HttpResponse<any>) => {
      this.isError = true;
      this.isLoading = false;
      console.log("Error", err);
    });
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'serviceName', header: 'Service', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip' },
      { name: 'priority', header: 'Priority', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT },
      { name: 'cpa', header: 'Asignee', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'serviceFee', header: '$ Service Fee', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'amountDue', header: '$ Due', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'serviceRequested', header: 'Created', type: COLUMN_TYPES.DATE_TIME, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'dueDate', header: 'Due Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'subscriptionId', header: 'ID', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
    ];
    if (!this.isFirm) {
      columns = columns.filter((e: TableColumnsConfig) => (e.name !== 'priority') && (e.name !== 'dueDate'));
    }
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'serviceName', label: 'Service', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Pending data from Client', 'Under Review', 'Completed', 'Canceled'] },
      { name: 'priority', label: 'Priority', type: COLUMN_FILTER_TYPE.SELECT, value: ['Critical', 'High', 'Medium', 'Low'] },
      { name: 'cpa', label: 'Asignee', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'serviceFee', label: '$ Service Fee', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'amountDue', label: '$ Due', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'serviceRequested', label: 'Created', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'dueDate', label: 'Due Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'subscriptionId', label: 'ID', type: COLUMN_FILTER_TYPE.STRING, value: '' }
    ]
    if (!this.isFirm) {
      colFilters = colFilters.filter((e: ColumnFilterConfig) => (e.name !== 'priority') && (e.name !== 'dueDate'));
    }
    return colFilters;
  }

  navigate(record: any) {
    if (this.authenticationService.userValue.clientId !== null)
      this.router.navigateByUrl(`/client/subscription/${record.id}`);
    else
      this.router.navigateByUrl(`/firm/clients/${this.clientId}/services/subscription/${record.id}`);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }

  esign(esigned: boolean) {
    if (esigned) {
      this.getClientSubscriptions();
    }
  }

  assignSubscription() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: "Service Assignment",
      clientList: [{ id: this.clientId, name: this.dataService.client.businessName }],
      serviceList: this.serviceList
    }

    this.matDialog.open<ClientServiceAssignmentComponent, void, MatDialogConfig>(ClientServiceAssignmentComponent, dialogConfig)
      .afterClosed().pipe(switchMap((data: any) => {
        if (data) {
          this.isSpinner = true;
          this.serviceName = data.serviceName;
          let req = {
            "clientIds": [this.clientId],
            "serviceId": data.serviceId
          };
          return this.http.clientServiceAssignment(req);
        } else {
          return of(data);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! ${this.dataService.client.businessName} is assigned to service ${this.serviceName}`);
          this.getClientSubscriptions();
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to Assign service. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  getAvailableServices() {
    this.http.getAvailableServicesList().subscribe((res: HttpResponse<ServicesListModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.serviceList = res.body;
      } else {
        this.notificationService.notifyText("There are no services available.", "X")
      }
    }, (err: HttpResponse<any>) => {
      console.log("Error", err);
      this.notificationService.notifyText("Error while fetching services list.", "X")
    });
  }

}