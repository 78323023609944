import { Injectable } from '@angular/core';
import { TableColumnsConfig } from 'src/app/models/table-columns-config.model';

@Injectable({
  providedIn: 'root'
})
export class TableToExcelService {

  constructor() { }

  exportToExcel(fileName: string, columns: TableColumnsConfig[], data: any[]) {
    let csvContent = "";
    columns = columns.filter(e => e.name !== 'select');
    let headerColumns = columns.map(column => column.header).join(',');
    csvContent += headerColumns + "\n";
    data.forEach((row: any) => {
      let columnData: any[] = [];
      columns.forEach(column => {
        if (column.name !== 'select') {
          columnData.push(`"${row[column.name]}"`);
        }
      });
      let record = columnData.join(',');
      csvContent += record + "\n";
    });
    let csvFile = new Blob([csvContent], { type: "text/csv" });
    let link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(csvFile));
    link.setAttribute("download", `${fileName}.csv`);
    link.click();
  }
}
