import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HandSetService } from '../shared/services/hand-set.service';
import { ActivatedRoute } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { GoogleMapsService } from '../shared/services/google-maps.service';
import { NotificationService } from '../shared/services/notification.service';
import { AddressModel } from '../models/address.model';
import { Observable } from 'rxjs';
import { CustomValidators } from '../shared/validators/form-validators/custom.validators';
import { COUNTRY_CODES_NAMES } from '../shared/constants/country-codes-names';
import { FirmValidationMessages } from '../shared/validators/validation-messages/firm-validation.messages';
import { PHONE_NUMBER_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { ManageFirmModel } from '../models/manage-firm.model';
import { PAYMENT_TERM } from '../shared/constants/app.constants';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { UsersListModel } from '../models/users-list.model';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'scj-org-details',
  templateUrl: './org-details.component.html',
  styleUrls: ['./org-details.component.scss']
})
export class OrgDetailsComponent implements OnInit, ComponentCanDeactivate {
  isHandset$: Observable<boolean>;
  disable: boolean = false;
  orgForm: FormGroup = new FormGroup({});
  country: FormControl = new FormControl('US');
  errorMessages = FirmValidationMessages;
  countries = COUNTRY_CODES_NAMES;
  isLoading = true;
  isSpinner = false;
  isError = false;
  errorsList: string[] = [];
  paymentTerm = PAYMENT_TERM;
  cpaUsersList: any = [];
  primaryUsersList: UsersListModel[] = [];
  isFirmAdmin: boolean = true;

  @ViewChild('addressText')
  addressText: HTMLInputElement;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private httpService: HttpCommonService, private gmService: GoogleMapsService, private authService: AuthenticationService,
    private notificationService: NotificationService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.isFirmAdmin = this.authService.userValue.roles.includes('firmadmin');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getOrgDetails();

    this.gmService.addressComponent.subscribe((place: any) => {
      let firmAddress = new AddressModel();
      firmAddress.addressLine1 = '';
      place.address_components.forEach((el: any) => {
        if (el.types?.indexOf('street_number') > -1) {
          firmAddress.addressLine1 = el.short_name + ' ';
        }
        if (el.types.indexOf('route') > -1) {
          firmAddress.addressLine1 = firmAddress.addressLine1 + el.short_name;
        }

        if (el.types.indexOf('locality') > -1) {
          firmAddress.city = el.long_name
        }

        if (el.types.indexOf('administrative_area_level_1') > -1) {
          firmAddress.state = el.short_name
        }

        if (el.types.indexOf('postal_code') > -1) {
          firmAddress.zip = el.short_name;
        }
      });
      firmAddress.country = this.country.value;
      this.orgForm.get('address')?.patchValue(firmAddress);
      this.orgForm.get('address')?.markAllAsTouched();
    });

    this.getCpaUsersList();
    this.getPrimaryUsersList();

    this.orgForm.controls['primaryUser'].get('id').valueChanges.subscribe((val: string) => {
      this.orgForm.controls['primaryContactId'].setValue(val);
      let user = this.primaryUsersList.find(e => e.id === val);
      if (user) {
        this.orgForm.controls['primaryUser'].get('contactFullName').setValue(user.fullname);
        this.orgForm.controls['primaryUser'].get('contactPhone').setValue(user.phoneNumber);
        this.orgForm.controls['primaryUser'].get('contactEmail').setValue(user.username);
      }
    });
  }

  initializeForm() {
    this.orgForm = this.fb.group({
      accountNumber: new FormControl({ value: '', disabled: true }),
      name: new FormControl({ value: '', disabled: !this.isFirmAdmin }),
      website: new FormControl('', Validators.compose([Validators.required, CustomValidators.website, Validators.maxLength(250)])),
      supportEmail: new FormControl('', Validators.compose([Validators.email, Validators.maxLength(250)])),
      defaultCpa: new FormControl(''),
      address: this.fb.group({
        addressLine1: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500)])),
        city: new FormControl('', Validators.compose([Validators.required])),
        state: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(150)])),
        zip: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(10)])),
        country: this.country
      }),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.maxLength(250)])),
      phoneNumber: new FormControl('', Validators.compose([Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)])),
      primaryContactId: new FormControl('', Validators.compose([Validators.required])),
      primaryUser: this.fb.group({
        contactEmail: new FormControl({ value: '', disabled: true }),
        contactFullName: new FormControl({ value: '', disabled: true }),
        contactPhone: new FormControl({ value: '', disabled: true }),
        id: new FormControl('', Validators.compose([Validators.required]))
      })
    });
  }

  getOrgDetails() {
    this.httpService.getManageFirm().subscribe((res: HttpResponse<ManageFirmModel>) => {
      if (res.body !== null) {
        this.orgForm.patchValue(res.body);
        this.isLoading = false;
      }
    }, (error: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("error", error);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.gmService.getAddressAutoComplete(this.addressText, ['address']);
    }, 1500);
  }

  onSubmit() {
    this.orgForm.markAllAsTouched();
    this.errorsList = [];
    this.orgForm.controls['primaryContactId'].setValue(this.orgForm.controls['primaryUser'].get('id').value);
    if (this.orgForm.valid) {
      this.isSpinner = true;
      let orgFormRawValue = this.orgForm.getRawValue() as ManageFirmModel;
      delete orgFormRawValue['accountNumber'];
      this.httpService.updateManageFirm(orgFormRawValue).subscribe((res: HttpResponse<ManageFirmModel>) => {
        if (res.status === 200) {
          this.notificationService.notifyText("Firm details updated successfully!!");
          let formValue = this.orgForm.getRawValue();
          this.orgForm.reset(formValue);
          this.isSpinner = false;
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    }
  }

  getCpaUsersList() {
    this.httpService.getCompanyUsersForAssignment().subscribe((res: HttpResponse<any>) => {
      if (res.body !== null) {
        this.cpaUsersList = res.body;
      }
    }, (error: HttpErrorResponse) => {
      console.log("error", error);
    });
  }

  getPrimaryUsersList() {
    this.httpService.getUsersList().subscribe((res: HttpResponse<UsersListModel[]>) => {
      if (res.body !== null) {
        this.primaryUsersList = res.body;
      }
    }, (err: HttpResponse<any>) => {
      console.log("Error", err);
    });
  }

  canDeactivate() {
    return !this.orgForm.dirty;
  }
}
