import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(value: any[], key?: string): any {
    if (!value || !key) return value;

    let map = new Map();
    value.forEach((item) => {
      let subList = map.get(item[key]);
      if (!subList) {
        map.set(item[key], [item]);
      } else {
        subList.push(item);
      }
    });
    return map;
  }

}
