import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { DataService } from '../data-service.service';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { UsersListModel } from '../models/users-list.model';
import { HttpCommonService } from '../services/http-common.service';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { COLUMN_FILTER_TYPE, COLUMN_TYPES } from '../shared/enums/table.enum';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { TableToExcelService } from '../shared/services/table-to-excel.service';

@Component({
  selector: 'scj-client-user-list',
  templateUrl: './client-user-list.component.html',
  styleUrls: ['./client-user-list.component.scss']
})
export class ClientUserListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  usersList: UsersListModel[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  userStatus = STATUS_FIELDS;
  clientId: string;
  userName: string;
  exportFileName: string = `${this.dataService.client.businessName} Users`;
  selectedList: UsersListModel[] = [];
  enableButton: boolean = false;
  isSpinner: boolean = false;
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router,
    private activatedRoute: ActivatedRoute, private tfService: TableFilterService, private matDialog: MatDialog,
    private notificationService: NotificationService, private dataService: DataService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.clientId = this.activatedRoute.snapshot.parent.parent.params.clientId;
  }

  ngOnInit(): void {
    this.getUsersList()
  }

  getUsersList() {
    this.httpService.getClientUsersList(this.clientId).subscribe((res: HttpResponse<UsersListModel[]>) => {
      if (res.body !== null) {
        this.usersList = res.body;
        this.tfService.initializeColumnFilter(this.columnFilterConfig);
        this.isLoading = false;
      }
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'fullname', header: 'Full Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'prefferedName', header: 'Preferred Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'username', header: 'Email', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip', classType: 'user' },
      { name: 'creationDate', header: 'Created On', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'isPrimary', header: 'Primary', type: COLUMN_TYPES.BOOLEAN }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'fullname', label: 'Full Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'prefferedName', label: 'Preferred Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'username', label: 'Email', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Pending', 'Inactive'] },
      { name: 'creationDate', label: 'Created', type: COLUMN_FILTER_TYPE.DATE, value: [] },
    ]
    return colFilters;
  }

  navigate(record: UsersListModel) {
    this.router.navigateByUrl(`/firm/clients/${this.clientId}/client-users/edit/${record.id}`);
  }

  selectedClientList(list: UsersListModel[]) {
    this.selectedList = list;

    if (list.length === 0) {
      this.enableButton = false;
      return;
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].status !== 'PENDING_ACTIVATION') {
        this.enableButton = false;
        return;
      }
    }
    this.enableButton = true;
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }

  getSelectedClientList() {
    let userId: string[] = [];
    let userName: string[] = [];
    this.selectedList.forEach((e: UsersListModel) => {
      userId.push(e.id);
      userName.push(e.fullname);
    });
    return { userId: userId, userName: userName };
  }

  deleteClient() {
    let list = this.getSelectedClientList();
    let header = 'Confirm Delete';
    let message = `Are you sure you want to delete users? Users list: <b> ${list.userName.join(", ")} </b>`;
    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.deletePendingUser(list.userId);
        } else {
          return of(confirm);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! users deleted`);
          this.getUsersList();
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to delete users. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  resendActivationLink() {
    let list = this.getSelectedClientList();
    let header = 'Confirm Resend Activation Mail';
    let message = `Are you sure you want to resend activation mail to users? Users list: <b> ${list.userName.join(", ")}  </b>`;
    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.sendUserActivationMail(list.userId);
        } else {
          return of(confirm);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! Activation mail sent to users`);
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to send activation mail. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  opeDialog(header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true
    }
    return this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(AlertMessagesComponent, dialogConfig)
  }

}
