<div mat-dialog-title>
    <div class="scj-subheader">{{ header }}</div>
    <mat-icon class="close-sign" mat-dialog-close matTooltip="Close">close</mat-icon>
</div>

<mat-dialog-content>
    <!-- <div class="pen-color-list" *ngIf="enableColorIndicator">
        <div *ngFor="let pen of penColorList;let index=index" (click)="changeColor(index)"
            [style.background]="pen.color" class="pen-color" [attr.isChecked]="pen.isChecked">
            <ng-container *ngIf="pen.isChecked">
                <ng-template *ngTemplateOutlet="pencolor">
                </ng-template>
            </ng-container>
        </div>
    </div> -->

    <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);"
        class="esign-mat-tab">
        <mat-tab label="Draw" *ngIf="enableSignpad">
            <div class="signature-wrapper">
                <div class="clear-button" (click)="clear()">Clear</div>
                <canvas id="signature-pad" #canvas class="signature-pad" width=500 height=200></canvas>
            </div>
        </mat-tab>

        <mat-tab label="Type">
            <div class="signature-wrapper">
                <div style="position: relative;">
                    <div id="font-dropdown" class="font-selector"
                        (click)="displayFontDropdown();$event.stopPropagation();">
                        Select Font <span></span>
                    </div>
                    <div id="font-list" class="font-list" style="display: none;">
                        <div class="font-dropdown-option" *ngFor="let font of fontList; let index=index"
                            (click)="changeFont(index);$event.stopPropagation();" [style.fontFamily]="font.fontFamily">
                            <svg width="16" height="16" viewBox="-2 -3.5 16 16">
                                <polyline [attr.stroke]="font.isChecked? 'green' : '#fff'" fill="none" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" points="1,4.8 4.5,8.6 11,1">
                                </polyline>
                            </svg>
                            {{ inputFieldValue || font.name}}
                        </div>
                    </div>
                </div>
                <input id="input-field" [(ngModel)]="inputFieldValue" placeholder="Enter your {{ header }}"
                    [style.color]="penColor" [style.fontFamily]="fontFamily">
            </div>
        </mat-tab>

        <mat-tab label="My {{header}}" *ngIf="padValue">
            <div class="sign-value">
                <img [src]="padValue">
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="secondary" mat-dialog-close>Cancel</button>
    <button mat-flat-button (click)="save()">
        Save
    </button>
</mat-dialog-actions>

<ng-template #pencolor>
    <svg width="16" height="16" viewBox="-2 -3.5 16 16">
        <polyline stroke="#fff" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            points="1,4.8 4.5,8.6 11,1"></polyline>
    </svg>
</ng-template>