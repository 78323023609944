import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'scj-firm-billing',
  templateUrl: './firm-billing.component.html',
  styleUrls: ['./firm-billing.component.scss']
})
export class FirmBillingComponent implements OnInit {
 
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
