import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SubscriptionAttachmentsModel } from '../models/subscription-attachments.model';
import { NotificationService } from '../shared/services/notification.service';
import { FormControl } from '@angular/forms';
import { FileHandlerService } from '../shared/services/file-handler.service';
import { DOCUMENT } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { GroupByPipe } from '../shared/pipes/group-by.pipe';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Observable } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';
import { SUPPORTED_MERGE_FILE_TYPES } from '../shared/constants/app.constants';

@Component({
  selector: 'scj-client-files',
  templateUrl: './client-files.component.html',
  styleUrls: ['./client-files.component.scss']
})
export class ClientFilesComponent implements OnInit {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  clientId: string;
  searchControl: FormControl = new FormControl('');
  displayColumns: string[] = ['fileName', 'uploadedBy', 'uploadedDate', 'comments'];
  checklistSelection = new SelectionModel<any>(true);
  dataLength: number = 0;
  attachmentData: AttachmentNode[] = [];
  fileTypes = SUPPORTED_MERGE_FILE_TYPES;
  isSpinner: boolean = false;
  getLevel = (node: AttachmentFlatNode) => node.level;
  isExpandable = (node: AttachmentFlatNode) => node.expandable;
  hasChild = (node: AttachmentFlatNode) => node.expandable;
  isClient: boolean = false;
  disablecheckboxselection: boolean = false;

  private transformer = (node: AttachmentNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      uploadedBy: node.uploadedBy,
      uploadedDateTime: node.uploadedDateTime,
      comments: node.comments,
      level: level
    };
  };

  treeControl = new FlatTreeControl<AttachmentFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(@Inject(DOCUMENT) private document: Document, private handSet: HandSetService, private activatedRoute: ActivatedRoute, private http: HttpCommonService,
    private notificationService: NotificationService, private fhService: FileHandlerService, private groupBy: GroupByPipe,
    private authenticationService: AuthenticationService) {
    this.isHandset$ = this.handSet.isHandset$;
    if (this.authenticationService.userValue.clientId !== null)
      this.clientId = this.authenticationService.userValue.clientId;
    else
      this.clientId = this.activatedRoute.snapshot.parent.params.clientId;

    this.isClient = this.authenticationService.userValue.roles.includes("firmclient");
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe((value: string) => {
      if (value === '' || value === null || value === undefined) {
        this.dataSource.data = this.attachmentData;
      } else {
        value = value.toLowerCase();
        // let searchData = this.attachmentData.map((el: AttachmentNode) => {
        //   return { ...el, children: el.children.filter((child: AttachmentNode) => (child.name.toLowerCase().includes(value) || child.uploadedBy.toLowerCase().includes(value) || child.uploadedDateTime.toLowerCase().includes(value))) }
        // }).filter((el: AttachmentNode) => (el.children.length > 0));
        // this.dataSource.data = searchData;

        const getNodes = (result: any, attachment: AttachmentNode) => {
          if ((attachment.name.toLowerCase().includes(value) || attachment.uploadedBy?.toLowerCase().includes(value) || attachment.uploadedDateTime?.toLowerCase().includes(value))) {
            result.push(attachment);
            return result;
          }
          if (Array.isArray(attachment.children)) {
            const children = attachment.children.reduce(getNodes, []);
            if (children.length) {
              result.push({ ...attachment, children });
            }
          }
          return result;
        };

        this.dataSource.data = this.attachmentData.reduce(getNodes, []);
      }
    });

    this.http.getAllAttachmentByClientId(this.clientId).subscribe((res: HttpResponse<SubscriptionAttachmentsModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.generateAttachmentData(res.body);
      }
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.isError = true;
      this.isLoading = false;
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
    });
  }

  generateAttachmentData(resData: SubscriptionAttachmentsModel[]) {
    this.attachmentData;
    let attachmentGroup: Map<string, any> = this.groupBy.transform(resData, 'subscriptionId');
    attachmentGroup.forEach((value: SubscriptionAttachmentsModel[], key: string) => {
      let attachment: AttachmentNode = { name: value[0].subscriptionName, children: [] };
      let folderList: any[] = [];
      value.forEach((e: SubscriptionAttachmentsModel) => {
        if (!!e?.folderName) {
          folderList.push(e);
        } else {
          attachment.children.push({ name: e.fileName, id: e.id, uploadedBy: e.uploadedBy, uploadedDateTime: e.uploadedDateTime });
        }
      });
      if (folderList.length > 0) {
        let folderGroup: Map<string, any> = new Map([...this.groupBy.transform(folderList, 'folderName').entries()].sort().reverse());
        folderGroup.forEach((value: SubscriptionAttachmentsModel[], key: string) => {
          let folder: AttachmentNode = { name: key, children: [] };
          value.forEach((e: SubscriptionAttachmentsModel) => {
            folder.children.push({ name: e.fileName, id: e.id, uploadedBy: e.uploadedBy, uploadedDateTime: e.uploadedDateTime });
          });
          attachment.children.unshift(folder);
        });
      }
      this.attachmentData.push(attachment);
    });
    this.dataSource.data = this.attachmentData;
    this.dataLength = this.treeControl.dataNodes.length;
  }

  isAllSelected() {
    return this.checklistSelection.selected.length === this.dataLength;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.checklistSelection.clear();
      return;
    }

    this.checklistSelection.select(...this.treeControl.dataNodes);
  }

  descendantsAllSelected(node: AttachmentFlatNode): boolean {
    let descendants = this.treeControl.getDescendants(node);
    let descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  descendantsPartiallySelected(node: AttachmentFlatNode): boolean {
    let descendants = this.treeControl.getDescendants(node) ? this.treeControl.getDescendants(node) : null;
    let result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  nodeSelectionToggle(node: AttachmentFlatNode): void {
    this.checklistSelection.toggle(node);
    let descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);

    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  navigate(node: any) {
    this.treeControl.expand(node);
    this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...this.treeControl.getDescendants(node)) : this.checklistSelection.deselect(...this.treeControl.getDescendants(node));
  }

  leafSelectionToggle(node: AttachmentFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: AttachmentFlatNode): void {
    let parent: AttachmentFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: AttachmentFlatNode): void {
    let nodeSelected = this.checklistSelection.isSelected(node);
    let descendants = this.treeControl.getDescendants(node);
    let descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: AttachmentFlatNode): AttachmentFlatNode | null {
    let currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    let startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      let currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  downloadFile(file: AttachmentFlatNode) {
    let url = `/api/v1/client/subscription/attachment/${file.id}`;
    this.isSpinner = true;
    this.fhService.downloadFile(url).subscribe(res => {
      let blob: Blob = res;
      let downloadlink = this.document.createElement('a');
      downloadlink.href = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
      downloadlink.download = file.name;
      downloadlink.click();
      this.isSpinner = false;
    }, err => {
      this.isSpinner = false;
      this.notificationService.notifyText(`Could not download the file: ${file.name}`, "X");
      console.log("Error:", err);
    });
  }

  downloadSelectedFiles() {
    if (this.checklistSelection.hasValue()) {
      let url = `/api/v1/client/subscription/attachment/zip`;
      let fileIds: string = '';
      this.treeControl.dataNodes.forEach((e: AttachmentFlatNode) => {
        if (!e.expandable) {
          fileIds += this.checklistSelection.isSelected(e) ? e.id + "," : "";
        }
      });
      fileIds = fileIds.slice(0, -1);
      url += `?ids=${fileIds}`;
      this.isSpinner = true;
      this.fhService.downloadFile(url).subscribe(res => {
        let blob: Blob = res;
        let downloadlink = this.document.createElement('a');
        downloadlink.href = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
        downloadlink.download = this.authenticationService.userValue.fullname + " attachments.zip";
        downloadlink.click();
        this.isSpinner = false;
      }, err => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Could not download the selected files`, "X");
        console.log("Error:", err);
      });
    } else {
      this.notificationService.notifyText(`Atleast select one file to download.`, "X");
    }
  }

  isAllMergeable() {
    let enableMerge = this.checklistSelection.hasValue();
    this.treeControl.dataNodes?.forEach((el: AttachmentFlatNode) => {
      if (el.level === 1 && this.checklistSelection.isSelected(el))
        enableMerge = enableMerge && this.fileTypes.some((ft: string) => el.name.toLowerCase().includes(ft));
    });
    return enableMerge;
  }

  mergeFiles() {
    if (this.checklistSelection.hasValue()) {
      if (this.isAllMergeable()) {
        let url = `/api/v1/client/subscription/attachment/merge`;
        let fileIds: string = '';
        this.treeControl.dataNodes.forEach((e: AttachmentFlatNode) => {
          if (e.level === 1) {
            fileIds += this.checklistSelection.isSelected(e) ? e.id + "," : "";
          }
        });
        fileIds = fileIds.slice(0, -1);
        url += `?ids=${fileIds}`;
        this.isSpinner = true;
        this.fhService.downloadFile(url).subscribe(res => {
          let blob: Blob = res;
          let downloadlink = this.document.createElement('a');
          downloadlink.href = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
          downloadlink.download = this.authenticationService.userValue.fullname + " subscriptions merge";
          downloadlink.click();
          this.isSpinner = false;
        }, err => {
          this.isSpinner = false;
          this.notificationService.notifyText(`Could not download the selected files`, "X");
        });
      } else {
        this.notificationService.notifyText(`File merge is supported with file extensions ${this.fileTypes.join(", ")}`, "X");
      }
    } else {
      this.notificationService.notifyText(`Select files with file extensions ${this.fileTypes.join(", ")} to merge.`, "X");
    }
  }
}

export interface AttachmentNode {
  name: string;
  id?: string;
  uploadedBy?: string;
  uploadedDateTime?: string;
  comments?: string;
  children?: AttachmentNode[];
}

export interface AttachmentFlatNode {
  expandable: boolean;
  name: string;
  id: string;
  uploadedBy: string;
  uploadedDateTime: string;
  comments: string;
  level: number;
}