import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../shared/services/notification.service';

@Component({
    selector: 'scj-esign-template-user-mapper',
    templateUrl: './esign-template-user-mapper.component.html',
    styleUrls: ['./esign-template-user-mapper.component.scss']
})
export class EsignTemplateUserMapperComponent implements OnInit {
    templateUserList: any[];
    userList: any[];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<EsignTemplateUserMapperComponent>,
        private notificationService: NotificationService) {
        this.templateUserList = [...data.templateUserList];
        this.userList = [...data.userList];
    }

    ngOnInit(): void {
        for (let i = 0; i < this.data.userList?.length; i++) {
            this.userList[i]['list'] = [];
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    reset() {
        this.templateUserList = [...this.data.templateUserList];
        this.userList = [...this.data.userList];
        for (let i = 0; i < this.data.userList?.length; i++) {
            this.userList[i]['list'] = [];
        }
    }

    submit() {
        let templateUserMap = this.userList.reduce((map: any, user: any) => {
            let users = user.list.flatMap((e: any) => e.username);
            users.map((e: any) => {
                map[e] = { username: user.username, color: user.color, name: user.name };
            });
            return map;
        }, {});
        if (Object.keys(templateUserMap).length > 0) {
            this.dialogRef.close(templateUserMap);
        } else {
            this.notificationService.notifyText("Map template users with signers.", "X")
        }
    }

}
