import { Component, OnInit } from '@angular/core';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { forkJoin, Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HandSetService } from '../shared/services/hand-set.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { NotificationService } from '../shared/services/notification.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { NewServiceModel } from '../models/new-service.model';
import { PHONE_NUMBER_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { WorkFlowListModel } from '../models/work-flow-list.model';
import { WORKFLOW_TYPES } from '../shared/constants/workflow.constants';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { AgreementsListModel } from '../models/agreements-list.model';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { map, startWith } from 'rxjs/operators';
import { AutoCompleteService } from '../shared/services/auto-complete.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-org-new-service',
  templateUrl: './org-new-service.component.html',
  styleUrls: ['./org-new-service.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class OrgNewServiceComponent implements OnInit, ComponentCanDeactivate {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  status = STATUS_FIELDS;
  serviceForm: FormGroup = new FormGroup({});
  disable: boolean = false;
  minStartDate: Date | null = null;
  minEndDate = new Date();
  serviceId: string;
  header: string = '';
  confirmed: boolean = false;
  userMessage: string = '';
  workflowList: WorkFlowListModel[] = [];
  workflowList$: Observable<WorkFlowListModel[]>;
  workflowStatus = STATUS_FIELDS;
  workflowTypes = WORKFLOW_TYPES;
  templateId: string;
  tcAgreements: AgreementsListModel[];
  srvAgreements: AgreementsListModel[];
  errorsList: string[] = [];
  isSpinner = false;
  workflowData: any;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private httpService: HttpCommonService, private router: Router,
    private activatedRoute: ActivatedRoute, private notificationService: NotificationService, private dialog: MatDialog,
    private autoCompleteService: AutoCompleteService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.serviceId = this.activatedRoute.snapshot.params.serviceId;
    //this.disable = this.serviceId ? true : true;
    this.isLoading = this.serviceId ? true : false;
    this.minStartDate = this.serviceId ? null : new Date();
  }

  ngOnInit(): void {
    this.initializeForm();

    this.getAgreementList();

    if (this.serviceId) {
      this.getServiceById();
    } else {
      this.getWorkflowList();
    }

    this.serviceForm.get('workflowId')?.valueChanges.subscribe((id: string) => {
      this.templateId = this.workflowList.find((wf: WorkFlowListModel) => wf.id === id)?.templateId;
    });
  }

  getServiceById() {
    forkJoin({
      service$: this.httpService.getServiceById(this.serviceId),
      workflowList$: this.httpService.getWorkflowsListByLoggedInUser()
    }).subscribe(({ service$, workflowList$ }: any) => {
      this.workflowList = workflowList$.body;
      this.activateTemplateListAutocomplete();
      this.header = service$.body.name;
      this.workflowData = service$.body;
      this.serviceForm.patchValue(service$.body);
      this.serviceForm.controls['startDate'].setValue(moment(service$.body.startDate));
      this.serviceForm.controls['endDate'].setValue(service$.body.endDate ? moment(service$.body.endDate) : null);
      this.minEndDate = new Date(service$.body.startDate);
      //this.createInfoMenuData();
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.isError = true;
      console.log("error", err)
    });
  }

  initializeForm() {
    this.serviceForm = this.fb.group({
      name: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required, Validators.maxLength(250)])),
      description: new FormControl('', Validators.compose([Validators.maxLength(250)])),
      status: new FormControl('ACTIVE', Validators.compose([Validators.required])),
      allowMultiple: new FormControl({ value: false, disabled: this.disable }),
      showFirmOnly: new FormControl({ value: false, disabled: this.disable }),
      startDate: new FormControl({ value: moment(new Date()), disabled: this.disable }, Validators.compose([Validators.required])),
      endDate: new FormControl(''),
      srvcAgreementId: new FormControl({ value: '', disabled: this.disable }),
      tcAgreementId: new FormControl({ value: '', disabled: this.disable }),
      workflowId: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required]))
    });
  }

  onSubmit() {
    this.serviceForm.markAllAsTouched();
    this.errorsList = [];
    if (!this.confirmed)
      this.tcServiceAgreement();
    if (this.serviceForm.valid && this.confirmed) {
      let serviceFormRawValue = this.serviceForm.getRawValue() as NewServiceModel;

      serviceFormRawValue.startDate = formatMomentDate(serviceFormRawValue.startDate);
      serviceFormRawValue.endDate = serviceFormRawValue.endDate ? formatMomentDate(serviceFormRawValue.endDate) : null;
      this.isSpinner = true;
      if (!this.serviceId) {
        this.httpService.createService( serviceFormRawValue).subscribe((res: HttpResponse<NewServiceModel>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("New service created successfully!!");
            this.serviceForm.reset();
            this.router.navigate([`../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.confirmed = false;
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }

      if (this.serviceId) {
        this.httpService.updateService(this.serviceId, serviceFormRawValue).subscribe((res: HttpResponse<NewServiceModel>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Service details updated successfully!!");
            this.serviceForm.reset();
            this.router.navigate([`../../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }
    }
  }

  cancel() {
    this.router.navigate([this.serviceId ? '../../list' : '../list'], { relativeTo: this.activatedRoute });
  }

  resetEndDate() {
    this.serviceForm.get('endDate').reset();
  }

  tcServiceAgreement() {
    let tc = this.serviceForm.get('tcAgreementId').value;
    let svrc = this.serviceForm.get('srvcAgreementId').value;

    if (this.serviceForm.get("status").value === 'INACTIVE') {
      this.confirmed = true;
      return;
    }

    if (!tc && svrc) {
      this.userMessage = 'Terms and Conditions are not selcted for the service, Please select Yes to continue';
      this.alertDialog();
    } else if (!svrc && tc) {
      this.userMessage = 'Service agreement is not selcted for the service, Please select Yes to continue';
      this.alertDialog();
    } else if (!(tc && svrc)) {
      this.userMessage = 'T&C and Service agreement are not selcted for the service, Please select Yes to continue';
      this.alertDialog();
    } else {
      this.confirmed = true;
    }
  }

  alertDialog() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.minHeight = '40%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: this.userMessage,
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }

    let dialogRef = this.dialog.open(AlertMessagesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      this.confirmed = confirm;
      if (this.confirmed)
        this.onSubmit();
    });
  }

  async getWorkflowList() {
    await this.httpService.getWorkflowsListByLoggedInUser().toPromise().then((res: HttpResponse<WorkFlowListModel[]>) => {
      if (res.body !== null) {
        this.workflowList = res.body;
        this.activateTemplateListAutocomplete();
      }
    }).catch((err: HttpResponse<any>) => {
      console.log("Error", err);
    });
  }

  activateTemplateListAutocomplete() {
    this.workflowList$ = this.serviceForm.get('workflowId').valueChanges.pipe(
      startWith(''),
      map(value => {
        return (value ? this.autoCompleteService.filterByKey(this.workflowList, "name", value) : this.workflowList.slice());
      }),
    );
  }

  displayFn(templateId: string): string {
    return templateId && templateId ? this.workflowList.find((e: WorkFlowListModel) => e.id === templateId)?.name : '';
  }

  loadTemplate(option: MatAutocompleteSelectedEvent) {
    this.templateId = this.workflowList.find((wf: WorkFlowListModel) => wf.id === option.option.value)?.templateId;
  }

  getAgreementList() {
    this.httpService.getAgreementsList().subscribe((res: HttpResponse<AgreementsListModel[]>) => {
      if (res.body !== null) {
        this.tcAgreements = res.body.filter((tcAgr: AgreementsListModel) => tcAgr.type === 'TERMS_AND_CONDITIONS');
        this.srvAgreements = res.body.filter((tcAgr: AgreementsListModel) => tcAgr.type === 'SERVICE_AGREEMENT');
      }
    }, (err: HttpResponse<any>) => {
      console.log("Error", err);
    });
  }

  canDeactivate() {
    return !this.serviceForm.dirty;
  }
}
