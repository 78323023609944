import { Component, OnInit, Input } from '@angular/core';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';

@Component({
  selector: 'scj-stepper-status-indicator',
  templateUrl: './stepper-status-indicator.component.html',
  styleUrls: ['./stepper-status-indicator.component.scss']
})
export class StepperStatusIndicatorComponent implements OnInit {
  @Input()
  statusData: any;

  constructor(private sort: SortPipe) { }

  ngOnInit(): void {
    this.statusData=this.sort.transform(this.statusData, 'asc', 'order');
  }

}
