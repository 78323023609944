import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EsignTemplateModel } from '../models/esign-template.model';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HttpCommonService } from '../services/http-common.service';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { COLUMN_FILTER_TYPE, COLUMN_TYPES } from '../shared/enums/table.enum';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { TableToExcelService } from '../shared/services/table-to-excel.service';

@Component({
  selector: 'scj-org-sign-templates-list',
  templateUrl: './org-sign-templates-list.component.html',
  styleUrls: ['./org-sign-templates-list.component.scss']
})
export class OrgSignTemplatesListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  signTemplateList: any[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  signTemplateStatus = STATUS_FIELDS;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router,
    private notificationService: NotificationService, private teService: TableToExcelService, private tfService: TableFilterService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.getSignTemplates();
  }

  getSignTemplates() {
    this.httpService.getEsignTemplateList().subscribe((res: HttpResponse<EsignTemplateModel[]>) => {
      if (res.body !== null) {
        this.signTemplateList = res.body;
        this.tfService.initializeColumnFilter(this.columnFilterConfig);
        this.isLoading = false;
      }
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }
  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'name', header: 'Sign Template Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: "chip", classType: 'user' },
      { name: 'docName', header: 'Document Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'startDate', header: 'Created', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'name', label: 'Sign Template Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Inactive'] },
      { name: 'docName', label: 'Document Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'startDate', label: 'Created', type: COLUMN_FILTER_TYPE.DATE, value: '' },
    ]
    return colFilters;
  }


  createEsignTemplate() {
    if (!this.handSet.isHandset) {
      this.router.navigateByUrl('/firm/my-org/org-sign-templates/create');
    } else {
      this.notificationService.notifyText("Creating E-sign template supported only in desktop/laptops.", "X");
    }
  }

  rowSelected(record: any) {
    if (!this.handSet.isHandset) {
      this.router.navigateByUrl(`/firm/my-org/org-sign-templates/edit/${record.id}`);
    } else {
      this.notificationService.notifyText("Editing E-sign template supported only in desktop/laptops.", "X");
    }
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }

  navigate(record: any) {
    if (!this.handSet.isHandset) {
      this.router.navigateByUrl(`/firm/my-org/org-sign-templates/edit/${record.id}`);
    } else {
      this.notificationService.notifyText("Editing E-sign template supported only in desktop/laptops.", "X");
    }
  }
}
