<div class="flex-row jc-sb-ai-c">
    <h2 class="scj-h2">My Tasks</h2>

    <div class="search-filter">
        <div class="search-field">
            <mat-icon>search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>

        <!-- <button class="filter-button" (click)="openTableFilter()">
            <mat-icon>filter_list</mat-icon>
        </button> -->
    </div>
</div>

<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">

        <scj-table [data]="mySubscriptions.data" [dataLength]="mySubscriptions.total" [columnConfig]="columnConfig"
            [sortColumn]="reqBody.sort.column" [sortOrder]="reqBody.sort.order" [pageIndex]="reqBody.page.index - 1"
            [pageSize]="reqBody.page.size" exportFileName="My Assignments" (navigate)="navigate($event)"
            (esignStatus)="esign($event)" (tableDataChange)="modifyPageData($event)">
        </scj-table>
    </div>
</div>