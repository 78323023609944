import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scj-client-users',
  templateUrl: './client-users.component.html',
  styleUrls: ['./client-users.component.scss']
})
export class ClientUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
