import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientsListModel } from '../models/client-list.model';
import { ServicesListModel } from '../models/services-list.model';
import { HttpCommonService } from '../services/http-common.service';
import { AutoCompleteService } from '../shared/services/auto-complete.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-client-service-assignment',
  templateUrl: './client-service-assignment.component.html',
  styleUrls: ['./client-service-assignment.component.scss']
})
export class ClientServiceAssignmentComponent implements OnInit {
  header: string;
  clientList: ClientsListModel[];
  serviceList: ServicesListModel[] = [];
  serviceList$: Observable<ServicesListModel[]>;
  serviceControl: FormControl = new FormControl('', [Validators.required]);
  serviceName: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<ClientServiceAssignmentComponent>,
    private autoCompleteService: AutoCompleteService, private notificationService: NotificationService) {
    this.header = this.data.header;
    this.clientList = this.data.clientList;
    this.serviceList = this.data.serviceList;
    this.activateServiceListAutocomplete();
  }

  ngOnInit(): void { }

  activateServiceListAutocomplete() {
    this.serviceList$ = this.serviceControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        return (value ? this.autoCompleteService.filterByKey(this.serviceList, "name", value) : this.serviceList.slice());
      }),
    );
  }

  displayFn(serviceId: string): string {
    return serviceId ? this.serviceList.find((e: ServicesListModel) => e.id === serviceId)?.name : '';
  }

  selectedService(option: MatAutocompleteSelectedEvent) {
    let service = this.serviceList.find((e: ServicesListModel) => e.id === option.option.value);
    this.serviceName = service.name
  }

  assign() {
    let service = this.serviceList.find((e: ServicesListModel) => e.id === this.serviceControl.value);
    if (!service) {
      this.notificationService.notifyText("Select valid servie from the list.", "X");
    } else {
      this.dialogRef.close({ serviceId: this.serviceControl.value, serviceName: service.name });
    }
  }

}
