import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientMessageModel } from '../models/client.message.model';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-chat-fab',
  templateUrl: './chat-fab.component.html',
  styleUrls: ['./chat-fab.component.scss']
})
export class ChatFabComponent implements OnInit {
  isLoading = true;
  isError = false;
  refreshSpinner = false;
  sendSpinner = false;
  messagesList: ClientMessageModel[];
  clientId: string;
  isClient = false;

  @ViewChild('inputMessage')
  inputMessage: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<ChatFabComponent>,
    private http: HttpCommonService, private notificationService: NotificationService, private authenticationService: AuthenticationService) {
    this.clientId = this.data.clientId;
  }

  ngOnInit(): void {
    this.isClient = this.authenticationService.userValue.roles.includes("firmclient");
    if (this.isClient) {
      this.getClientUserMessages();
    } else {
      this.getClientMessagesForCpa();
    }
  }

  getClientUserMessages() {
    this.http.getClientUserMessages().subscribe((res: HttpResponse<ClientMessageModel[]>) => {
      if (res.body !== null) {
        this.messagesList = res.body;
      }
      this.isLoading = false;
      this.refreshSpinner = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.refreshSpinner = false;
      this.notificationService.notifyText("Something went wrong while fetching messages!! Please try after some time.");
    });
  }

  getClientMessagesForCpa() {
    this.refreshSpinner = true;
    this.http.getClientMessagesForCpa(this.clientId).subscribe((res: HttpResponse<ClientMessageModel[]>) => {
      if (res.body !== null) {
        this.messagesList = res.body;
      }
      this.isLoading = false;
      this.refreshSpinner = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.refreshSpinner = false;
      this.notificationService.notifyText("Something went wrong while fetching messages!! Please try after some time.");
    });
  }

  createMessages() {
    let message = this.inputMessage.nativeElement.innerHTML;
    if (message.trim().length > 0) {
      this.sendSpinner = true;
      let messageReq = { clientId: this.clientId, message: message };

      if (this.isClient) {
        this.http.createClientMessage(messageReq).subscribe((res: HttpResponse<ClientMessageModel[]>) => {
          this.sendSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Message sent successfully.");
            this.inputMessage.nativeElement.innerHTML = '';
            this.getClientUserMessages();
          }
        }, (err: HttpErrorResponse) => {
          this.sendSpinner = false;
          this.notificationService.notifyText("Unable to send message.");
        });
      } else {
        this.http.createClientMessageCpa(messageReq).subscribe((res: HttpResponse<ClientMessageModel[]>) => {
          this.sendSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Message sent successfully.");
            this.inputMessage.nativeElement.innerHTML = '';
            this.getClientMessagesForCpa();
          }
          this.refreshSpinner = false;
        }, (err: HttpErrorResponse) => {
          this.sendSpinner = false;
          this.notificationService.notifyText("Unable to send message.");
        });
      }
    } else {
      this.notificationService.notifyText("Message can't be empty.");
    }
  }

  refreshMessages() {
    this.refreshSpinner = true;
    if (this.isClient) {
      this.getClientUserMessages();
    } else {
      this.getClientMessagesForCpa();
    }
  }

}
