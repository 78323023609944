<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20">Details</h1>

            <div class="flex-row">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt_outlined</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button mat-flat-button class="secondary" routerLink="/firm/clients-list">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form [formGroup]="newClientForm" class="flex-row new-client-form" autocomplete="off">
            <div class="flex-column right-margin">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input type="text" matInput placeholder="Enter firm name" formControlName="businessName" required>
                    <mat-error *ngFor="let validator of errorMessages.businessName">
                        <mat-error
                            *ngIf="newClientForm.get('businessName').hasError(validator.type) && (newClientForm.get('businessName').dirty || newClientForm.get('businessName').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Account Number</mat-label>
                    <input matInput formControlName="accountNumber">
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="clientDetails?.isBusiness">
                    <mat-label>EIN Number</mat-label>
                    <input placeholder="Enter EIN number" matInput formControlName="einNumber">
                    <mat-error *ngFor="let validator of errorMessages.einNumber">
                        <mat-error
                            *ngIf="newClientForm.get('einNumber').hasError(validator.type) && (newClientForm.get('einNumber').dirty || newClientForm.get('einNumber').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Website</mat-label>
                    <input type="url" placeholder="Enter firm website" matInput formControlName="businessWebsite">
                    <mat-error *ngFor="let validator of errorMessages.businessWebsite">
                        <mat-error
                            *ngIf="newClientForm.get('businessWebsite').hasError(validator.type) && (newClientForm.get('businessWebsite').dirty || newClientForm.get('businessWebsite').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="email" placeholder="Enter firm email" matInput formControlName="businessEmail"
                        [required]="!clientDetails?.isBusiness">
                    <mat-error *ngFor="let validator of errorMessages.businessEmail">
                        <mat-error
                            *ngIf="newClientForm.get('businessEmail').hasError(validator.type) && (newClientForm.get('businessEmail').dirty || newClientForm.get('businessEmail').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input type="text" placeholder="Enter firm phone number" matInput
                        formControlName="businessPhonenumber" required>
                    <mat-error *ngFor="let validator of errorMessages.businessPhonenumber">
                        <mat-error
                            *ngIf="newClientForm.get('businessPhonenumber').hasError(validator.type) && (newClientForm.get('businessPhonenumber').dirty || newClientForm.get('businessPhonenumber').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <div formGroupName="clientAddress" class="top-margin flex-column">
                    <div class="scj-subheader contact-details">Address</div>

                    <mat-form-field appearance="outline">
                        <mat-label>Country</mat-label>
                        <mat-select placeholder="Select Country" [formControl]="country" disableOptionCentering>
                            <mat-option *ngFor="let el of countries" [value]="el.COUNTRY_CODE">{{el.COUNTRY_NAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Addess</mat-label>
                        <input type="text" #addressText placeholder="Enter your address" matInput
                            formControlName="addressLine1">
                        <mat-error *ngFor="let validator of errorMessages.clientAddress.addressLine1">
                            <mat-error
                                *ngIf="newClientForm.get('clientAddress').get('addressLine1').hasError(validator.type) && (newClientForm.get('clientAddress').get('addressLine1').dirty || newClientForm.get('clientAddress').get('addressLine1').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input type="text" placeholder="Enter city" matInput formControlName="city">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>State/Province</mat-label>
                        <input type="text" placeholder="Enter state/province" matInput formControlName="state">
                        <mat-error *ngFor="let validator of errorMessages.clientAddress.state">
                            <mat-error
                                *ngIf="newClientForm.get('clientAddress').get('state').hasError(validator.type) && (newClientForm.get('clientAddress').get('state').dirty || newClientForm.get('clientAddress').get('state').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Zip</mat-label>
                        <input type="text" #zip placeholder="Enter zip" matInput formControlName="zip">
                        <mat-error *ngFor="let validator of errorMessages.clientAddress.zip">
                            <mat-error
                                *ngIf="newClientForm.get('clientAddress').get('zip').hasError(validator.type) && (newClientForm.get('clientAddress').get('zip').dirty || newClientForm.get('clientAddress').get('zip').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="flex-column">
                <div class="checkbox">
                    <mat-checkbox formControlName="isBusiness">Business Filings
                    </mat-checkbox>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select placeholder="Status" formControlName="status" disableOptionCentering required>
                        <mat-option *ngFor="let stat of clientStatus" [value]="stat.statKey">{{stat.statValue}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngFor="let validator of errorMessages.status">
                        <mat-error
                            *ngIf="newClientForm.get('status').hasError(validator.type) && (newClientForm.get('status').dirty || newClientForm.get('status').touched)">
                            {{validator.message}}</mat-error>
                    </mat-error>
                </mat-form-field>

                <div class="active-date">
                    <mat-form-field appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="startDatePicker" [min]="minStartDate"
                            formControlName="startDate" placeholder="Select Start Date" required readonly>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker" matTooltip="Select start date"
                            [disableRipple]="true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker color="primary" [touchUi]="isHandset$ | async"
                            [restoreFocus]="false">
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="endDate">
                        <mat-label>End Date</mat-label>
                        <input matInput #endDate [matDatepicker]="endDatePicker" [min]="minEndDate"
                            formControlName="endDate" placeholder="Select end date" readonly>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker" matTooltip="Select end date"
                            [disableRipple]="true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker color="primary" [touchUi]="isHandset$ | async"
                            [restoreFocus]="false">
                        </mat-datepicker>
                        <mat-icon matSuffix *ngIf="endDate.value" (click)="resetEndDate()" matTooltip="Clear">
                            clear
                        </mat-icon>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline" class="text-area">
                    <mat-label>Notes</mat-label>
                    <textarea type="text" matInput formControlName="notes" placeholder="Notes" cdkTextareaAutosize
                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>