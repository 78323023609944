<div class="flex-row jc-sb-ai-c">
    <h2 class="scj-subheader">FILTERS</h2>

    <div>
        <button mat-flat-button type="submit" (click)="submit()" matTooltip="Apply Filter"
            [disabled]="columnFilterForm.invalid">
            Apply
        </button>
        <button type="button" (click)="reset()" mat-flat-button class="secondary" matTooltip="Clear Filter">
            Reset
        </button>
        <button type="button" mat-flat-button class="secondary" matTooltip="Cancel" mat-dialog-close>
            Cancel
        </button>
    </div>
</div>

<form [formGroup]="columnFilterForm">
    <ng-container *ngFor="let col of columnFilterConfig">
        <ng-container [ngSwitch]="col.type">
            <div *ngSwitchCase="'date'" [id]="col.name">
                <mat-form-field appearance="outline">
                    <mat-label>{{col.label}}</mat-label>
                    <mat-date-range-input [formGroup]="columnFilterForm.controls[col.name]" [rangePicker]="picker"
                        [min]="col.value[0]" [max]="col.value[1]" readonly>
                        <input formControlName="startDate" matStartDate placeholder="Start date" readonly>
                        <input formControlName="endDate" matEndDate placeholder="End date" readonly>
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix class="date-range" [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                </mat-form-field>
            </div>

            <div *ngSwitchCase="'select'" [id]="col.name">
                <mat-form-field appearance="outline">
                    <mat-label>{{col.label}}</mat-label>
                    <mat-select placeholder="Select {{col.lable}}" [formControl]="columnFilterForm.controls[col.name]"
                        disableOptionCentering [multiple]="true">
                        <mat-option *ngFor="let val of col.value" [value]="val">
                            {{val}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngSwitchCase="'number'" [id]="col.name">
                <mat-form-field appearance="outline">
                    <mat-label>{{col.label}}</mat-label>
                    <scj-number-range-input [formControl]="columnFilterForm.controls[col.name]">
                    </scj-number-range-input>
                    <mat-error *ngFor="let err of columnFilterForm.controls[col.name]?.errors | keyvalue">{{err.value}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngSwitchCase="'string'" [id]="col.name">
                <mat-form-field appearance="outline">
                    <mat-label>{{col.label}}</mat-label>
                    <input type="text" matInput [formControl]="columnFilterForm.controls[col.name]">
                </mat-form-field>
            </div>

        </ng-container>
    </ng-container>
</form>