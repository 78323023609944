import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ColumnFilterConfig } from 'src/app/models/table-columns-config.model';
import { TableFilterComponent } from '../components/table-filter/table-filter.component';
import { COLUMN_FILTER_TYPE } from '../enums/table.enum';
import { FormatColumnPipe } from '../pipes/format-column.pipe';

const moment = _rollupMoment || _moment;
@Injectable({
  providedIn: 'root'
})
export class TableFilterService {
  columnFilterForm: FormGroup;
  tableFilter$: EventEmitter<any>;// = new EventEmitter<any>();
  //BehaviorSubject<any | undefined> = new BehaviorSubject(undefined);
  resetFilterValue: any;
  filterJson: any = {};
  columnFilterConfig: ColumnFilterConfig[];

  constructor(private dialog: MatDialog, private formatColumn: FormatColumnPipe, private datePipe: DatePipe) { }

  initializeColumnFilter(filterConfig: ColumnFilterConfig[]) {
    //this.tableFilter$ = new BehaviorSubject(undefined);
    this.tableFilter$ = new EventEmitter<any>();
    this.columnFilterConfig = filterConfig;
    this.columnFilterForm = new FormGroup({});
    for (let i = 0; i < filterConfig?.length; i++) {
      if (filterConfig[i].type === COLUMN_FILTER_TYPE.STRING) {
        this.columnFilterForm.addControl(filterConfig[i].name, new FormControl(''));
      } else if (filterConfig[i].type === COLUMN_FILTER_TYPE.SELECT) {
        this.columnFilterForm.addControl(filterConfig[i].name, new FormControl(undefined));
      } else if (filterConfig[i].type === COLUMN_FILTER_TYPE.NUMBER) {
        this.columnFilterForm.addControl(filterConfig[i].name, new FormControl({ min: null, max: null }, this.validateNumberRange));
      } else {
        let dateForm = new FormGroup({
          startDate: new FormControl(null),
          endDate: new FormControl(null)
        });
        this.columnFilterForm.addControl(filterConfig[i].name, dateForm);
      }
    }
    this.filterJson = { ...this.columnFilterForm.getRawValue() };
    this.resetFilterValue = this.filterJson;
    //this.tableFilter$.next(this.resetFilterValue);
  }

  getColumnFilteForm() {
    this.columnFilterForm.patchValue(this.filterJson)
    return this.columnFilterForm;
  }

  setSearchValue(val: string) {
    this.filterJson['search'] = val;
    this.tableFilter$.next(this.filterJson);
  }

  openTableFilter() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '25%';
    dialogConfig.width = '550px';
    dialogConfig.minHeight = '25%';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '80%';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.panelClass = 'table-filter-dialog'
    dialogConfig.backdropClass = 'transparent-mat-dialog-backdrop'
    dialogConfig.position = { top: '68px', right: '36px' };
    //console.log({ ...this.filterJson });
    this.dialog.open<TableFilterComponent>(TableFilterComponent, dialogConfig);
  }

  applyFilter(val: any) {
    this.filterJson = { ...this.filterJson, ...val };
    //this.columnFilterForm.setValue(this.filterJson);
    //console.log({ ...this.filterJson });
    this.tableFilter$.emit(this.filterJson); //next(this.filterJson);
  }

  resetFilter() {
    this.filterJson = this.resetFilterValue
    this.tableFilter$.emit(this.filterJson);
  }

  clearFilter(col: string) {
    this.filterJson[col] = this.resetFilterValue[col];
    this.tableFilter$.emit(this.filterJson);
  }

  validateNumberRange(control: FormControl): ValidationErrors {
    let errors = {};
    let val = control.value;
    let min = val?.min;
    let max = val?.max;

    if (!min && !max) {
      return null;
    }

    if (min && !Number(min)) {
      errors['min'] = 'Minimum should be numeric value';
    }

    if (max && !Number(max)) {
      errors['max'] = 'Maximum should be numeric value';
    }

    if (min && max && Number(min) >= Number(max)) {
      errors['minMax'] = 'Minimum must be less than Maximum';
    }
    return errors;
  }
}
