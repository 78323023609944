import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';
import { NotificationService } from '../shared/services/notification.service';
import domtoimage from 'dom-to-image';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'scj-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  header: string;
  signaturePad: SignaturePad;
  selectedTabIndex: number = 0;
  inputFieldValue: string = "";
  penColor: string = "rgb(0,0,0)";
  fontFamily: string = "'Caveat', cursive"
  padValue: any;
  enableSignpad: boolean;
  enableColorIndicator: boolean = true;
  penColorList = [
    { color: 'rgb(0, 0, 0)', isChecked: true },
    { color: 'rgb(26, 97, 204)', isChecked: false },
    { color: 'rgb(212, 96, 18)', isChecked: false }
  ];
  fontList = [
    { name: "Caveat", fontFamily: "'Caveat', cursive", isChecked: true },
    { name: "Italianno", fontFamily: "'Italianno', cursive", isChecked: false },
    { name: "Mea Culpa", fontFamily: "'Mea Culpa', cursive", isChecked: false },
    { name: "Roboto Slab", fontFamily: "'Roboto Slab', serif", isChecked: false }
  ]

  @ViewChild("canvas")
  canvasRef: ElementRef

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<SignaturePadComponent>,
    private notificationService: NotificationService, private cdRef: ChangeDetectorRef) {
    if (this.data.padValue) {
      this.padValue = this.data.padValue;
      this.selectedTabIndex = (this.data.fieldType === "SIGNATURE") ? 2 : 1;
      this.enableColorIndicator = false;
    } else {
      if ((this.data.fieldType === "SIGNATURE")) {
        this.selectedTabIndex = 1;
      }
    }
  }

  ngOnInit(): void {
    if (this.data.fieldType === "SIGNATURE") {
      this.header = "Signature";
      this.enableSignpad = true;
    } else if (this.data.fieldType === "NAME") {
      this.header = "Name";
    } else {
      this.header = "Initials"
    }
    window.onresize = this.resizeCanvas;
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasRef.nativeElement, {
      backgroundColor: 'rgba(0,0,0,0)'
    });

    this.cdRef.detectChanges();

    document.onclick = (event: any) => {
      let el = document.getElementById("font-list");
      if ((el?.style?.display.length === 0) && (event.target?.id !== "font-dropdown")) {
        el.style.display = "none";
      }
    }
  }

  resizeCanvas() {
    let ratio = Math.max(window.devicePixelRatio || 1, 1);
    let canvas = this.canvasRef.nativeElement;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
  }

  // changeColor(index: number) {
  //   for (let i = 0; i < this.penColorList.length; i++) {
  //     if (i === index) {
  //       this.penColorList[i].isChecked = true;
  //       let data = this.signaturePad.toData();
  //       this.signaturePad.fromData(data.map(d => {
  //         d.penColor = this.penColorList[i].color;
  //         return d;
  //       }));
  //       this.signaturePad.penColor = this.penColorList[i].color;
  //       this.penColor = this.penColorList[i].color;
  //     } else {
  //       this.penColorList[i].isChecked = false;
  //     }
  //   }
  // }

  displayFontDropdown() {
    let el = document.getElementById("font-list");
    if (el?.style?.getPropertyValue("display") === "none") {
      el.style.removeProperty("display");
    } else {
      el.style.display = "none";
    }
  }

  changeFont(index: number) {
    let el = document.getElementById("font-list");
    el.style.display = "none";
    for (let i = 0; i < this.fontList.length; i++) {
      if (i === index) {
        this.fontFamily = this.fontList[i].fontFamily;
        this.fontList[i].isChecked = true;
      } else {
        this.fontList[i].isChecked = false;
      }
    }
  }

  clear() {
    this.signaturePad.clear();
  }

  onTabChanged(tab: MatTabChangeEvent) {
    // if (this.padValue && this.data.fieldType === "SIGNATURE" && this.selectedTabIndex == 2) {
    //   this.enableColorIndicator = false;
    // } else if (this.padValue && this.selectedTabIndex == 1 && (this.data.fieldType !== "SIGNATURE")) {
    //   this.enableColorIndicator = false;
    // } else {
    //   this.enableColorIndicator = true;
    // }
  }

  async save() {
    if ((this.data.fieldType === "SIGNATURE") && (this.selectedTabIndex === 0 || this.selectedTabIndex === 1)) {
      if (this.signaturePad?.isEmpty() && this.selectedTabIndex === 0) {
        this.notificationService.notifyText("Please draw your signature.", "X");
      } else {
        if (this.selectedTabIndex === 0) {
          this.dialogRef.close(this.signaturePad.toDataURL('image/png'));
        } else {
          if (this.inputFieldValue?.length > 0) {
            let textImage = await this.textToImage();
            this.dialogRef.close(textImage);
          } else {
            this.notificationService.notifyText("Please enter the value in input box.", "X");
          }
        }
      }
    } else if ((this.data.fieldType === "NAME" || this.data.fieldType === "INITIALS") && this.selectedTabIndex === 0) {
      if (this.inputFieldValue?.length > 0) {
        let textImage = await this.textToImage();
        this.dialogRef.close(textImage);
      } else {
        this.notificationService.notifyText("Please enter the value in input box.", "X");
      }
    } else {
      this.dialogRef.close(this.padValue);
    }
  }

  async textToImage(): Promise<string> {
    let div: HTMLDivElement = document.createElement("div");
    div.style.backgroundColor = 'none';
    div.style.fontFamily = this.fontFamily;
    div.style.fontSize = "36px";
    div.style.height = "fit-content";
    div.style.width = "max-content";
    div.style.padding = "8px"
    div.style.fontWeight = "600";
    div.style.color = this.penColor;
    div.id = `${this.data.fieldType}`;
    div.innerText = this.inputFieldValue;
    document.body.append(div);

    let sign = await domtoimage.toPng(div)
      .then((dataUrl: string) => {
        return dataUrl;
      })
      .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
      });
    document.body.removeChild(div);
    return sign
  }

}
