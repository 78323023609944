import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpCommonService } from 'src/app/services/http-common.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'scj-firm-logo-container',
  templateUrl: './firm-logo-container.component.html',
  styleUrls: ['./firm-logo-container.component.scss'],
})
export class FirmLogoContainerComponent implements OnInit {
  firmLogo: any;

  @Input()
  subdomainHost: string;

  @Output()
  subdomain: EventEmitter<string> = new EventEmitter<string>();

  constructor(private activatedRoute: ActivatedRoute, private http: HttpCommonService) { }

  ngOnInit(): void {
    if (!this.subdomainHost) {
      let domainUrl = window.location.hostname;
      let firmName = domainUrl.split('.');
      this.subdomainHost = this.activatedRoute.snapshot.queryParams?.identifier || firmName[0];
      this.subdomain.emit(this.subdomainHost);
      this.getFirmLogoOrName();
    } else {
      this.subdomain.emit(this.subdomainHost);
      this.getFirmLogoOrName();
    }
  }

  getFirmLogoOrName() {
    this.http.getFirmLogoOrName(this.subdomainHost).subscribe((res: HttpResponse<any>) => {
      if (res.body != null) {
        this.firmLogo = res.body;
      }
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }
}
