import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { DataService } from '../data-service.service';
import { NewAgreementModel } from '../models/new-agreement.model';
import { NewServiceModel } from '../models/new-service.model';
import { ServicesListModel } from '../models/services-list.model';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-firm-services',
  templateUrl: './firm-services.component.html',
  styleUrls: ['./firm-services.component.scss']
})
export class FirmServicesComponent implements OnInit {
  availableServices: ServicesListModel[]= [];
  servicesForm: FormArray;
  isSpinner: boolean = false;
  constructor(private handSet: HandSetService, private http: HttpCommonService, private dialog: MatDialog, private dataService: DataService,
    private notificationService: NotificationService, private authenticationService: AuthenticationService,
    private router: Router) { }

  ngOnInit(): void {

    this.getAvailableServices();
  }
  initializeForm() {
    this.servicesForm = new FormArray([]);
    this.availableServices.forEach((e: NewServiceModel) => {
      let group: FormGroup;
      if (e.tcAgreementId && e.srvcAgreementId) {
        group = new FormGroup({
          tcAgreement: new FormControl(false),
          svrAgreement: new FormControl(false)
        });
      } else if (e.tcAgreementId) {
        group = new FormGroup({
          tcAgreement: new FormControl(false),
          svrAgreement: new FormControl(null)
        });
      } else if (e.srvcAgreementId) {
        group = new FormGroup({
          tcAgreement: new FormControl(null),
          svrAgreement: new FormControl(false)
        });
      } else {
        group = new FormGroup({
          tcAgreement: new FormControl(null),
          svrAgreement: new FormControl(null)
        });
      }
      this.servicesForm.push(group);
    });
  }

  getAvailableServices() {
    this.isSpinner = true;
    this.http.getAvailableServicesList().subscribe((res: HttpResponse<ServicesListModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.availableServices = res.body;
        this.initializeForm();
        this.isSpinner = false;
      }
    }, (err: HttpResponse<any>) => {
      this.isSpinner = false;
      console.log("Error", err);
    });
  }

  validSubscribe(i: number): boolean {

    let tcAgreement = this.servicesForm.controls[i].get('tcAgreement').value;
    let svrAgreement = this.servicesForm.controls[i].get('svrAgreement').value;

    let service = this.availableServices[i];
    if (service.tcAgreementId && service.srvcAgreementId) {
      if (tcAgreement && svrAgreement)
        return false;
      else
        return true;
    }

    if (service.tcAgreementId) {
      if (tcAgreement)
        return false;
      else
        return true;
    }

    if (service.srvcAgreementId) {
      if (svrAgreement)
        return false;
      else
        return true;
    }
    return false;
  }

  subscribe(id: string) {
    let subscription = { serviceId: id };
    this.isSpinner = true;
    this.http.createClientSubscription(subscription).subscribe((res: string) => {
      this.isSpinner = false;
      this.notificationService.notifyText("Service is subscribed successfully");
      this.router.navigateByUrl(`/client/subscription/${res.split('"')[1]}`);
    }, (err: HttpResponse<any>) => {
      this.isSpinner = false;
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
      console.log("Error", err);
    });
  }

  expand(index: number) {
    let elSelected = document.getElementsByClassName('is-selected');
    let elExpanded = document.getElementsByClassName('fullwidth');
    if (elSelected.length > 0) {
      for (let i = 0; i < elSelected.length; i++) {
        elSelected[i]?.classList.toggle('is-selected');
      }
    }

    for (let i = 0; i < elExpanded.length; i++) {
      if (!elExpanded[i]?.classList.contains('is-hidden'))
        elExpanded[i]?.classList.toggle('is-hidden')
    }

    let elByTileId = document.getElementById(`tile-${index}`);
    let elByTileExpandId = document.getElementById(`tile-expand-${index}`);
    elByTileId.classList.toggle('is-selected');
    elByTileExpandId.classList.toggle('is-hidden');
  }

  loadAgreementData(control: FormControl, agreementId: string) {
    this.isSpinner=true;
    this.http.getAgreementById(agreementId).subscribe((res: HttpResponse<NewAgreementModel>) => {
      if (res.body !== null) {
        this.alertDialog(control, res.body.agreementName, res.body.agreementText);
      }
    }, (err: HttpResponse<any>) => {
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
      console.log("Error", err);
    });
  }

  alertDialog(control: FormControl, header: string, message: string) {
    this.isSpinner=false;
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.minHeight = '40%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true
    }

    let dialogRef = this.dialog.open(AlertMessagesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      //control.setValue(confirm);
    });
  }
}
