import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent implements OnInit {
  @Input()
  errors: string[] = [];

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    setTimeout(() => this.notificationService.notifyText("Fix above errors before resubmitting form"), 2000);
  }
}
