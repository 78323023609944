import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  private BASE_URL = environment.API_SERVER_URL;

  constructor(private http: HttpClient) {}

  setPassword(pm: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.BASE_URL + '/api/v1/user/activate', pm, {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf8'
      ),
      observe: 'response',
    });
  }

  updateForgotPassword(pm: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(
      this.BASE_URL + '/api/v1/user/resetPassword',
      pm,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }
}
