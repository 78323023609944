<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20" *ngIf="!agreementId">New Agreement</h1>

            <h1 class="scj-h2 mr-20" *ngIf="agreementId">
                <span class="text-header">Agreement - </span>
                {{header}}
            </h1>

            <div class="flex-row">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button class="secondary" mat-flat-button (click)="cancel()">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form class="flex-column" [formGroup]="agreementForm">
            <div class="flex-row name-type">
                <mat-form-field appearance="outline">
                    <mat-label>Agreement Name</mat-label>
                    <input type="text" placeholder="Agreement Name" matInput formControlName="agreementName" required>
                    <mat-error *ngIf="agreementForm.get('agreementName').invalid">
                        <mat-error *ngIf="agreementForm.get('agreementName')?.hasError('required')">
                            Agreement Name required
                        </mat-error>
                        <mat-error *ngIf="agreementForm.get('agreementName')?.hasError('pattern')">
                            Special characters not allowed
                        </mat-error>
                        <mat-error *ngIf="agreementForm.get('agreementName')?.hasError('maxlength')">
                            Agreement name can't exceed more than 250 characters
                        </mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select placeholder="Select type" formControlName="type" disableOptionCentering required>
                        <mat-option *ngFor="let typ of agreementTypes" [value]="typ.typeKey">{{typ.typeValue}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="agreementForm.get('type').invalid">
                        <mat-error *ngIf="agreementForm.get('agreementName')?.hasError('required')">
                            Agreement Type required
                        </mat-error>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="agreementId">
                    <mat-label>Version</mat-label>
                    <input type="text" placeholder="Version" matInput formControlName="version" readonly>
                </mat-form-field>
            </div>

            <div class="flex-row">
                <div class="active-date">
                    <mat-form-field appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="startDatePicker" [min]="minStartDate"
                            formControlName="startDate" placeholder="Start Date" required readonly>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker" matTooltip="Select start date"
                            [disableRipple]="true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker color="primary" [touchUi]="isHandset$ | async"
                            [restoreFocus]="false">
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="endDate">
                        <mat-label>End Date</mat-label>
                        <input matInput #endDate [matDatepicker]="endDatePicker" [min]="minEndDate"
                            formControlName="endDate" placeholder="End Date" readonly>
                        <mat-datepicker #endDatePicker color="primary" [touchUi]="isHandset$ | async"
                            [restoreFocus]="false"></mat-datepicker>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker" matTooltip="Select end date">
                        </mat-datepicker-toggle>
                        <mat-icon matSuffix *ngIf="endDate.value" (click)="resetEndDate()" matTooltip="Clear"
                            class="endDate">
                            clear
                        </mat-icon>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select placeholder="Status" formControlName="status" disableOptionCentering required>
                        <mat-option *ngFor="let stat of status" [value]="stat.statKey">{{stat.statValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="agreementId">
                    <mat-label>Usage</mat-label>
                    <input type="text" placeholder="Usage" matInput formControlName="usage" readonly>
                </mat-form-field>
            </div>

            <angular-editor formControlName="agreementText" [config]="editorConfig"></angular-editor>
            <mat-error *ngIf="agreementForm.get('agreementText').invalid && agreementForm.get('agreementText').touched">
                <mat-error *ngIf="agreementForm.get('agreementText')?.hasError('required')">
                    Agreement text is required
                </mat-error>
            </mat-error>
        </form>
    </div>
</div>