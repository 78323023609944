<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
        <ng-container *ngIf="data.type === 'INBOX'">
            <div class="flex-row mail-data">
                <div class="flex-column">
                    <div>From: {{data.mail.from}}</div>
                    <div>Subject: {{data.mail.subject}}</div>
                    <div>Date & Time: {{data.mail.date}}</div>
                </div>

                <button mat-icon-button mat-dialog-close cdkFocusInitial>
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <mat-divider></mat-divider>

            <mat-dialog-content>
                <ng-container *ngIf="isError">
                    Something went wrong!! Please try after some time
                </ng-container>

                <ng-container *ngIf="!isError && !mail">
                    No mail content found
                </ng-container>

                <ng-container *ngIf="!isError && mail">
                    <section [innerHtml]="mail.body | domSafe">
                    </section>
                    <section *ngIf="mail.attachments?.length>0">
                        <mat-divider></mat-divider>
                        <h2 class="scj-h2">Attachments</h2>
                        <div *ngFor="let attachment of mail.attachments">
                            <a (click)="download(attachment)">{{attachment.name}}</a>
                        </div>
                    </section>
                </ng-container>
            </mat-dialog-content>
        </ng-container>

        <ng-container *ngIf="data.type === 'OUTBOX'">
            <div class="flex-row mail-data">
                <div class="flex-column">
                    <div>To: {{data.mail.to}}</div>
                    <div>Subject: {{data.mail.subject}}</div>
                    <div>Date & Time: {{data.mail.date}}</div>
                </div>

                <div class="flex-row">
                    <button mat-icon-button mat-dialog-close cdkFocusInitial>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <mat-dialog-content>
                <ng-container *ngIf="isError">
                    Something went wrong!! Please try after some time
                </ng-container>

                <ng-container *ngIf="!isError && !mail">
                    No mail content found
                </ng-container>

                <ng-container *ngIf="!isError && mail">
                    <section [innerHtml]="mail.body | domSafe">
                    </section>
                    <section *ngIf="mail.attachments?.length>0">
                        <mat-divider></mat-divider>
                        <h2 class="scj-h2">Attachments</h2>
                        <div *ngFor="let attachment of mail.attachments">
                            <a (click)="download(attachment)">{{attachment.name}}</a>
                        </div>
                    </section>
                </ng-container>
            </mat-dialog-content>
        </ng-container>

    </div>
</div>