import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { HandSetService } from 'src/app/shared/services/hand-set.service';
import { ColumnFilterConfig, TableColumnsConfig } from 'src/app/models/table-columns-config.model';
import { COLUMN_TYPES, BOOLEAN_COLUMN_FILTER_CONDITIONS, COLUMN_FILTER_TYPE } from 'src/app/shared/enums/table.enum';
import { DOCUMENT } from '@angular/common';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { HttpCommonService } from 'src/app/services/http-common.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DataService } from 'src/app/data-service.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TableToExcelService } from 'src/app/shared/services/table-to-excel.service';
import { TableFilterService } from 'src/app/shared/services/table-filter.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from 'src/app/shared/date-formats/date.format';
import { PaymentService } from 'src/app/shared/services/payment.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-table-content',
  templateUrl: './table-content.component.html',
  styleUrls: ['./table-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class TableContentComponent implements OnInit, AfterViewInit {
  isHandset$: Observable<boolean>;
  displayColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  isFirmUser: boolean;
  selection = new SelectionModel<any>(true, []);
  filterValue: any[] = [];// Map<string, any> = new Map();
  isAchConfigured: boolean;
  isPaypalConfigured: boolean;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  @Input()
  data: any[] = [];

  @Input()
  columnConfig: TableColumnsConfig[] = [];

  @Input()
  searchControl: FormControl = new FormControl('');

  @Input()
  enableSearch: boolean = true;

  @Input()
  defaultSortColumn: string = '';

  @Input()
  sortOrder: string = 'desc';

  @Input()
  enableSort: boolean = true;

  @Input()
  enablePaginator: boolean = true;

  @Input()
  pageSizeOptions: number[] = [20, 40];

  @Input()
  exportFileName: string;

  @Input()
  errorMessege: string;

  @Input()
  enableFilter: boolean = true;

  @Output()
  navigate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectionList: EventEmitter<any[]> = new EventEmitter<any>();

  @Output()
  paymentStatus: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  esignStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DOCUMENT) private document: Document, private handSet: HandSetService, private cdRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService, private notificationService: NotificationService, private http: HttpCommonService,
    private dataService: DataService, private teService: TableToExcelService, private tfService: TableFilterService, private paymentService: PaymentService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.isFirmUser = this.authenticationService.userValue.roles.some((isFirmUser) => ['firmadmin', 'firmemployee'].includes(isFirmUser));
    this.isAchConfigured = this.paymentService.isAchConfigured.value;
    this.isPaypalConfigured = this.paymentService.isPaypalConfigured.value;
  }


  ngOnInit(): void {
    this.columnConfig.forEach((col: TableColumnsConfig) => {
      this.displayColumns.push(col.name);
    });

    if (this.displayColumns.length > 0 && this.enableFilter) {
      this.dataSource.filterPredicate = this.createFilter(this.tfService.columnFilterConfig);
    }

    if (this.enableSearch) {
      this.tfService.filterJson['search'] = '';
    }

    this.searchControl.valueChanges.subscribe((filterVal: string) => {
      if (this.enableSearch) {
        this.tfService.setSearchValue(filterVal.trim().toLowerCase());
      }
    });

    this.dataSource.data = this.data;

    this.dataSource.sortingDataAccessor = (data: any, property: string): string | number => {
      let type: COLUMN_TYPES | string = COLUMN_TYPES.STRING;
      this.columnConfig.forEach((col: TableColumnsConfig) => {
        if (col.name === property) type = col.type;
      });
      switch (type) {
        case COLUMN_TYPES.DATE:
          return new Date(data[property]).getTime();
        case COLUMN_TYPES.DATE_TIME:
          return new Date(data[property + 'Time']).getTime();
        case COLUMN_TYPES.NUMBER:
          return data[property];
        default:
          return data[property]?.toString().toLowerCase();
      }
    };

    this.tfService.tableFilter$.subscribe((val: any) => {
      if (val && this.enableFilter) {
        this.selection.clear();
        this.filterValue = [];

        this.columnConfig.forEach((e: TableColumnsConfig) => {
          if (e?.type) {
            this.filterValue.push({ name: e.name, label: e.header, type: e.filterType, value: this.tfService.filterJson[e.name] });
          }
        });
        this.dataSource.filter = JSON.stringify(this.tfService.filterJson);
      }
    });

  }

  ngAfterViewInit() {
    this.dataSource.data = this.data;
    this.isHandset$.subscribe((handSet: boolean) => {
      if (handSet) {
        this.dataSource.paginator = null;
      } else this.dataSource.paginator = this.paginator || null;
    });

    if (this.defaultSortColumn) {
      this.sort.sort({ id: this.defaultSortColumn, start: this.sortOrder, disableClear: false } as MatSortable);
      this.sort.active = this.defaultSortColumn;
      this.sort.disableClear = true;
    }

    if (!this.enableSort) {
      this.sort.disabled = true;
    }

    this.dataSource.sort = this.sort;

    this.cdRef.detectChanges();
  }

  ngOnChanges(): void {
    this.dataSource.data = this.data;
    this.selection.clear();
    this.selectionList.emit([]);
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.filteredData.length;
  }

  exportToExcel() {
    this.teService.exportToExcel(this.exportFileName, this.columnConfig, this.selection.selected);
  }

  pageChanged(event: any) {
    //this.selection.clear();
  }

  clearSelection() {
    this.selection.clear();
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectionList.emit(this.selection.selected);
      return;
    } else {
      this.selection.select(...this.dataSource.filteredData)//(...this.dataSource.connect().value);
      this.selectionList.emit(this.selection.selected);
    }
  }

  toggleSelection(element: any) {
    this.selection.toggle(element);
    this.selectionList.emit(this.selection.selected);
  }

  clearFilter(col: string) {
    this.tfService.clearFilter(col);
  }

  createFilter(columnFilterConfig: ColumnFilterConfig[]): (data: any, filter: string) => boolean {
    let filterFunction = function (data: any, filter: string): boolean {
      let filterJson = JSON.parse(filter);
      let filterResult: boolean = true;
      let searchResult: boolean = false;
      columnFilterConfig.forEach((col: ColumnFilterConfig) => {
        // if (col.type === COLUMN_FILTER_TYPE.) {
        //   filterResult = filterResult && booleanFilter(filterJson[col.name], data[col.name]);
        // } else
        if (col.type === COLUMN_FILTER_TYPE.NUMBER) {
          filterResult = filterResult && numberFilter(filterJson[col.name], data[col.name]);
        } else if (col.type === COLUMN_FILTER_TYPE.DATE) {
          filterResult = filterResult && dateFilter(filterJson[col.name], data[col.name]);
        } else if (col.type === COLUMN_FILTER_TYPE.SELECT) {
          filterResult = filterResult && selectFilter(filterJson[col.name], data[col.name]);
        } else {
          filterResult = filterResult && stringFilter(filterJson[col.name], data[col.name]?.toString().toLowerCase());
        }
        let searchValue = data[col.name]?.toString().trim();
        searchResult = searchResult || (searchValue === null || searchValue === undefined ? false : searchValue?.toLowerCase().indexOf(filterJson.search) !== -1);
      });
      return filterResult && searchResult;
    };
    return filterFunction;
  }

  cellselected(element: any) {
    this.navigate.emit(element);
  }

  markAsPaid(payment: any, invoiceId: string, invoiceNum: number) {
    if (payment.status === 'SUCESS') {
      this.notificationService.notifyText(`Paypal payment is successful for invoice id ${invoiceNum}`);
      let data = {
        invoiceId: invoiceId,
        paymentReference: JSON.stringify(payment),
      };
      this.http.updateMarkAsPaid(data).subscribe(
        (res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.paymentStatus.emit(true);
            setTimeout(() => {
              this.notificationService.notifyText(`Invoice payment status updated successfully`);
            }, 3000);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.notificationService.notifyText(`Invoice payment status updated failed. Please contact CPA`);
        }
      );
    } else {
      this.notificationService.notifyText(`Payment failed for invoice id ${invoiceId}`);
    }
  }

  markAsPaidAch(status: string) {
    if (status === 'SUCCESS') {
      this.paymentStatus.emit(true);
    }
  }

  esign(record: any) {
    let element = {
      id: record.esignAttachmentId,
      signatureRequired: record.signatureRequired,
      spouseSignatureRequired: record.spouseSignatureRequired,
      cpaSignatureRequired: record.cpaSignatureRequired,
      isSigned: record.isSigned,
      isSpouseSigned: record.isSpouseSigned,
      isCpaSigned: record.isCpaSigned,
    };
    this.dataService.esign(element).subscribe((reload: boolean) => {
      if (reload) {
        this.esignStatus.emit(true);
      }
    });
  }
}

export function selectFilter(filter: any[], data: string): boolean {
  // if (STRING_COLUMN_FILTER_CONDITIONS.CONTAINS === filter.condition) {
  //   return data?.includes(filter.value?.toLowerCase());
  // } else if (STRING_COLUMN_FILTER_CONDITIONS.ENDS_WITH === filter.condition) {
  //   return data?.endsWith(filter.value?.toLowerCase());
  // } else if (STRING_COLUMN_FILTER_CONDITIONS.EQUALS === filter.condition) {
  //   return data === filter.value?.toLowerCase();
  // } else if (STRING_COLUMN_FILTER_CONDITIONS.STARTS_WITH === filter.condition) {
  if (filter?.length > 0) {
    if (filter && data) {
      return filter.includes(data);
    }
  }
  return true;
}

export function stringFilter(filter: any, data: string): boolean {
  // if (STRING_COLUMN_FILTER_CONDITIONS.CONTAINS === filter.condition) {
  //   return data?.includes(filter.value?.toLowerCase());
  // } else if (STRING_COLUMN_FILTER_CONDITIONS.ENDS_WITH === filter.condition) {
  //   return data?.endsWith(filter.value?.toLowerCase());
  // } else if (STRING_COLUMN_FILTER_CONDITIONS.EQUALS === filter.condition) {
  //   return data === filter.value?.toLowerCase();
  // } else if (STRING_COLUMN_FILTER_CONDITIONS.STARTS_WITH === filter.condition) {
  if (filter && data) {
    return data?.includes(filter?.toLowerCase());
  } else {
    return true;
  }
}

export function numberFilter(filter: any, data: number): boolean {
  // if (NUMBER_COLUMN_FILTER_CONDITIONS.EQUALS === filter.condition) {
  //   return data === filter.value;
  // } else if (NUMBER_COLUMN_FILTER_CONDITIONS.GREATER_THAN_OR_EQUAL === filter.condition) {
  //   return data >= filter.value;
  // } else if (NUMBER_COLUMN_FILTER_CONDITIONS.LESS_THAN_OR_EQUAL === filter.condition) {
  //   return data <= filter.value;
  // } else if (NUMBER_COLUMN_FILTER_CONDITIONS.RANGE === filter.condition) {
  //   const x = parseInt(filter.value);
  //   const y = parseInt(filter.value2);
  if (filter && (data || data > -1)) {
    let min = parseFloat(filter.min);
    let max = parseFloat(filter.max);
    if (min && max) {
      return (data >= min) && (data <= max);
    } else if (min) {
      return data >= min;
    } else if (max) {
      return data <= max;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function booleanFilter(filter: any, data: boolean): boolean {
  if (BOOLEAN_COLUMN_FILTER_CONDITIONS.FALSE === filter.condition) {
    return data === filter.value;
  } else if (BOOLEAN_COLUMN_FILTER_CONDITIONS.TRUE === filter.condition) {
    return data >= filter.value;
  } else {
    return true;
  }
}

export function dateFilter(filter: any, data: string): boolean {
  // if (DATE_COLUMN_FILTER_CONDITIONS.AFTER === filter.condition) {
  //   return moment(data).isAfter(filter.value, 'day');
  // } else if (DATE_COLUMN_FILTER_CONDITIONS.BEFORE === filter.condition) {
  //   return moment(data).isBefore(filter.value, 'day')
  // } else if (DATE_COLUMN_FILTER_CONDITIONS.ON === filter.condition) {
  //   return moment(data).isSame(filter.value, 'day')
  // } else if (DATE_COLUMN_FILTER_CONDITIONS.BETWEEN === filter.condition) {
  if (filter.startDate && data) {
    // let start = moment(filter.value, 'YYYY-MM-DDTHH:mm:ss.sssZ');
    // let end = moment(filter.value2, 'YYYY-MM-DDTHH:mm:ss.sssZ');
    // let startValue = start.format('DD-MMM-YYYY');
    // let endValue = end.format('DD-MMM-YYYY');
    let time = new Date(data).getTime();
    let startTime = new Date(filter.startDate).getTime();
    let endTime = new Date(filter.endDate).getTime();

    if (filter.startDate && filter.endDate) {
      return time >= startTime && time <= endTime;
    }

    if (filter.startDate) {
      return time >= startTime;
    } else {
      return true;
    }

    //moment(data).isBetween(filter.startDate, filter.endDate) || (moment(data).isSame(filter.startDate) && moment(data).isSame(filter.endDate));
  } else {
    return true;
  }
}


