import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Optional, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { NUMBERS_PATTERN } from '../../validators/regex-expressions/regex-expressions';

export class NumberRange {
  constructor(public min: number | null, public max: number | null) { }
}
@Component({
  selector: 'scj-number-range-input',
  templateUrl: './number-range-input.component.html',
  styleUrls: ['./number-range-input.component.scss'],
  host: {
    '[class.number-range-input-floating]': 'shouldLabelFloat',
    '[id]': 'id'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatFormFieldControl, useExisting: NumberRangeInputComponent }
  ]
})
export class NumberRangeInputComponent implements MatFormFieldControl<NumberRange>, ControlValueAccessor {
  static nextId = 0;
  @ViewChild('min') minInput: HTMLInputElement;
  @ViewChild('max') maxInput: HTMLInputElement;

  parts = this._formBuilder.group({
    min: [null, Validators.pattern(NUMBERS_PATTERN)],
    max: [null, Validators.pattern(NUMBERS_PATTERN)]
  }, { validator: this.validate });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;

  controlType = 'number-range-input';
  id = `number-range-input-${NumberRangeInputComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { min, max },
    } = this.parts;

    return !min && !max;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): any {
    return this._required;
  }
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): any {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): NumberRange | null {
    if (this.parts.valid) {
      const {
        value: { min, max },
      } = this.parts;
      return new NumberRange(min!, max!);
    }
    let rangeValue = this.parts.value;
    let range = { min: rangeValue?.min?.length > 0 ? rangeValue?.min : null, max: rangeValue?.max?.length > 0 ? rangeValue?.max : null };
    return range;
  }
  set value(numRange: NumberRange | null) {
    const { min, max } = numRange || new NumberRange(null, null);
    this.parts.setValue({ min, max });
    this.stateChanges.next();
  }

  separator = '–';
  isFocused: boolean = false;

  get errorState(): any {
    return this.parts.invalid && this.touched;
  }

  constructor(private _formBuilder: FormBuilder, private _focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    this.isFocused = true;
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    this.isFocused = false;
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.number-range-input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    // if (!this.focused && !this.disabled) {
    //   if (!this.value || !this.value.min) {
    //     this.minInput.focus();
    //   } else {
    //     this.maxInput.focus();
    //   }
    // }
  }

  writeValue(numRange: NumberRange | null): void {
    const { min, max } = numRange || new NumberRange(null, null);
    this.parts.setValue({ min, max });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _shouldHideSeparator() {
    return (
      (!this._formField ||
        (this._formField.getLabelId() && !this._formField._shouldLabelFloat())) &&
      this.empty
    );
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  validate(control: FormGroup): ValidationErrors {
    let errors = {};
    let val = control.value;
    let min = val?.min;
    let max = val?.max;

    if (!min && !max) {
      return null;
    }

    if (min && !Number(min)) {
      errors['min'] = 'Minimum should be numeric value';
    }

    if (max && !Number(max)) {
      errors['max'] = 'Maximum should be numeric value';
    }

    if (min && max && Number(min) >= Number(max)) {
      errors['minMax'] = 'Minimum must be less than Maximum';
    }

    return errors;
  }
}
