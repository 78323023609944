import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AddressModel } from '../models/address.model';
import { NewUserModel } from '../models/new-user.model';
import { HttpCommonService } from '../services/http-common.service';
import { COUNTRY_CODES_NAMES } from '../shared/constants/country-codes-names';
import { GoogleMapsService } from '../shared/services/google-maps.service';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';
import { PHONE_NUMBER_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { UserValidationMessages } from '../shared/validators/validation-messages/user-validation.messages';

@Component({
  selector: 'scj-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  isHandset$: Observable<boolean>;
  disable: boolean = false;
  profileForm: FormGroup = new FormGroup({});
  country: FormControl = new FormControl('US');
  countries = COUNTRY_CODES_NAMES;
  isLoading = true;
  isSpinner = false;
  isError = false;
  errorsList: string[] = [];
  errorMessages = UserValidationMessages;

  @ViewChild('addressText')
  addressText: ElementRef;

  @ViewChild('zip')
  zip: ElementRef;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private router: Router, private httpService: HttpCommonService, private gmService: GoogleMapsService,
    private notificationService: NotificationService) {
    this.isHandset$ = this.handSet.isHandset$;

  }

  ngOnInit(): void {
    this.initializeForm();

    this.httpService.getUserProfile().subscribe((res: HttpResponse<NewUserModel>) => {
      if (res.body !== null) {

        if (res.body.address === null)
          delete res.body.address;

        this.profileForm.patchValue(res.body);
        this.isLoading = false;
        this.ngAfterViewInit();
      }
    }, (error: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
    });

    this.gmService.addressComponent.subscribe((place: any) => {
      let address = new AddressModel();
      address.addressLine1 = '';
      place.address_components.forEach((el: any) => {
        if (el.types?.indexOf('street_number') > -1) {
          address.addressLine1 = el.short_name + ' ';
        }
        if (el.types.indexOf('route') > -1) {
          address.addressLine1 = address.addressLine1 + el.short_name;
        }

        if (el.types.indexOf('locality') > -1) {
          address.city = el.long_name
        }

        if (el.types.indexOf('administrative_area_level_1') > -1) {
          address.state = el.short_name
        }

        if (el.types.indexOf('postal_code') > -1) {
          address.zip = el.short_name;
        }
      });
      address.country = this.country.value;
      this.profileForm.get('address')?.patchValue(address);
      this.zip.nativeElement.focus();
      this.zip.nativeElement.blur();
    });
  }

  initializeForm() {
    this.profileForm = this.fb.group({
      fullname: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(250)])),
      prefferedName: new FormControl('', Validators.compose([Validators.maxLength(250)])),
      phoneNumber: new FormControl('', Validators.compose([Validators.pattern(PHONE_NUMBER_PATTERN)])),
      username: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required, Validators.email, Validators.maxLength(250)])),
      address: this.fb.group({
        addressLine1: new FormControl('', Validators.compose([Validators.maxLength(500)])),
        city: new FormControl(''),
        state: new FormControl(''),
        zip: new FormControl('', Validators.compose([Validators.maxLength(15)])),
        country: this.country
      })
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.gmService.getAddressAutoComplete(this.addressText, ['address']);
    }, 2000);
  }

  onSubmit() {
    this.profileForm.markAllAsTouched();
    this.errorsList = [];
    if (this.profileForm.valid) {
      let profileFormRawValue = this.profileForm.getRawValue() as NewUserModel;
      delete profileFormRawValue.username;
      this.isSpinner = true;
      this.httpService.updateUserProfile(profileFormRawValue).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          this.notificationService.notifyText("Your info updated successfully!!");
          this.profileForm.reset(this.profileForm.getRawValue());
        }
        this.isSpinner = false;
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    }
  }

  cancel() {
    this.router.navigate([`/client/home`]);
  }

  canDeactivate() {
    return !this.profileForm.dirty;
  }
}
