import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  notifyText(message: string, action: string = "X") {
    this.snackBar.open(message, action, { duration: 5000, panelClass: "snackbar" });
  }
}
