import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { User } from 'src/app/models/user.model';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private notificationService: NotificationService, private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && err.error?.jwtTokenExpired) {
        return this.handle401Error(request, next);
      }
      return throwError(err);
    }));
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken()
        .pipe(switchMap((tokens) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.jwtToken);
          return next.handle(this.addToken(request, tokens.jwtToken));
        }), catchError((err) => {
          this.isRefreshing = false;
          this.notificationService.notifyText('Session Timeout!! Please logIn');
          this.authenticationService.logout();
          return throwError(err);
        }));
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null), take(1),
      switchMap((token) => next.handle(this.addToken(request, token)))
    );
  }

  addToken(request: HttpRequest<any>, jwtToken: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`
      }
    });
  }

}
