<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <!-- <mat-toolbar>Menu</mat-toolbar>  -->
        <mat-nav-list class="handset-nav">
            <a mat-list-item *ngFor="let item of navigationLinks" routerLink="{{item.link}}" routerLinkActive="active"
                #rla="routerLinkActive" class="non-active" (click)="drawer.toggle(); header = item.title">
                <div *ngIf="rla.isActive" class="highligt-bar"> </div>
                <div class="flex-column icon-text">
                    <mat-icon *ngIf="!item.type">{{item.icon}}</mat-icon>
                    <mat-icon *ngIf="item.type" [svgIcon]="item.icon"></mat-icon>
                    <div>{{item.title}}</div>
                </div>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>{{headerTitle}}</span>
        </mat-toolbar>
        <div class="sidenav-content">
            <mat-nav-list class="flex-column">
                <div class="nav-home">
                    <a mat-list-item routerLink="home" class="active">
                        <div class="flex-row logo">
                            Client
                        </div>
                    </a>
                </div>
                <div class="nav-links">
                    <a mat-list-item *ngFor="let item of navigationLinks" routerLink="{{item.link}}"
                    (click)="highlightLinks(item.link)"  class="non-active" [class.active]="item.active">
                        <div *ngIf="item.active" class="highligt-bar"> </div>
                        <div class="flex-column icon-text">
                            <mat-icon *ngIf="!item.type">{{item.icon}}</mat-icon>
                            <mat-icon *ngIf="item.type" [svgIcon]="item.icon"></mat-icon>
                            <div>{{item.title}}</div>
                        </div>
                    </a>
                </div>
                <div class="nav-links">
                    <a mat-list-item routerLink="preferences" (click)="highlightPreferences()"
                        class="non-active" [class.active]="isPreferences">
                        <div *ngIf="isPreferences" class="highligt-bar"> </div>
                        <div class="flex-column icon-text">
                            <mat-icon>tune_outlined</mat-icon>
                            <div>Preferences</div>
                        </div>
                    </a>
                    <a mat-list-item (click)="logout()" class="non-active">
                        <!-- <div *ngIf="rla.isActive" class="highligt-bar"> </div> -->
                        <div class="flex-column icon-text">
                            <mat-icon>exit_to_app_outlined</mat-icon>
                            <div>Logout</div>
                        </div>
                    </a>
                </div>
            </mat-nav-list>
            <div class="sidenav-route">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>