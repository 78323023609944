import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ClientValidationMessages } from '../shared/validators/validation-messages/client-validation.messages';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CustomValidators } from '../shared/validators/form-validators/custom.validators';
import { Observable } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';
import { GoogleMapsService } from '../shared/services/google-maps.service';
import { PHONE_NUMBER_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { NotificationService } from '../shared/services/notification.service';
import { COUNTRY_CODES_NAMES } from '../shared/constants/country-codes-names';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { AddressModel } from '../models/address.model';
import { NewClientModel } from '../models/new-client.model';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { ServicesListModel } from '../models/services-list.model';
import { map, startWith } from 'rxjs/operators';
import { AutoCompleteService } from '../shared/services/auto-complete.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class NewClientComponent implements OnInit, ComponentCanDeactivate {
  isHandset$: Observable<boolean>;
  newClientForm: FormGroup = new FormGroup({});
  country: FormControl = new FormControl('US');
  errorMessages = ClientValidationMessages;
  minStartDate: Date | null = null;
  minEndDate = new Date();
  countries = COUNTRY_CODES_NAMES;
  clientStatus = STATUS_FIELDS;
  isBusiness = false;
  errorsList: string[] = [];
  businessFiling: FormControl = new FormControl(false);
  isSpinner = false;
  serviceList: ServicesListModel[] = [];
  serviceList$: Observable<ServicesListModel[]>;

  @ViewChild('addressText')
  addressText: ElementRef;

  @ViewChild('zip')
  zip: ElementRef;

  constructor(private handSet: HandSetService, private fb: FormBuilder,
    private router: Router, private httpService: HttpCommonService, private gmService: GoogleMapsService,
    private notificationService: NotificationService, private autoCompleteService: AutoCompleteService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.getAvailableServices();
    this.businessFiling.valueChanges.subscribe((val: boolean) => {
      this.isBusiness = val;
      if (this.isBusiness) {
        this.newClientForm.setControl('businessName', new FormControl('', Validators.compose([Validators.required, Validators.maxLength(250)])));
        this.newClientForm.setControl('businessWebsite', new FormControl('', Validators.compose([CustomValidators.website, Validators.maxLength(250)])));
        this.newClientForm.setControl('businessEmail', new FormControl('', Validators.compose([Validators.email, Validators.maxLength(250)])));
        this.newClientForm.setControl('businessPhonenumber', new FormControl('', Validators.compose([Validators.pattern(PHONE_NUMBER_PATTERN), Validators.maxLength(25)])));
        this.newClientForm.setControl('einNumber', new FormControl(''));
      } else {
        this.newClientForm.removeControl('businessName');
        this.newClientForm.removeControl('businessWebsite');
        this.newClientForm.removeControl('businessEmail');
        this.newClientForm.removeControl('businessPhonenumber');
      }
    })
    this.initializeForm();

    this.gmService.addressComponent.subscribe((place: any) => {
      let clientAddress = new AddressModel();
      clientAddress.addressLine1 = '';
      place.address_components.forEach((el: any) => {
        if (el.types?.indexOf('street_number') > -1) {
          clientAddress.addressLine1 = el.short_name + ' ';
        }
        if (el.types.indexOf('route') > -1) {
          clientAddress.addressLine1 = clientAddress.addressLine1 + el.short_name;
        }

        if (el.types.indexOf('locality') > -1) {
          clientAddress.city = el.long_name
        }

        if (el.types.indexOf('administrative_area_level_1') > -1) {
          clientAddress.state = el.short_name
        }

        if (el.types.indexOf('postal_code') > -1) {
          clientAddress.zip = el.short_name;
        }
      });
      clientAddress.country = this.country.value;
      this.newClientForm.get('clientAddress')?.patchValue(clientAddress);
      this.zip.nativeElement.focus();
      this.zip.nativeElement.blur();
    });
  }

  initializeForm() {
    this.newClientForm = this.fb.group({
      fullName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(250)])),
      prefferedName: new FormControl('', Validators.compose([Validators.maxLength(250)])),
      username: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.maxLength(250)])),
      phoneNumber: new FormControl('', Validators.compose([Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN), Validators.maxLength(25)])),
      notes: new FormControl(''),
      status: new FormControl('ACTIVE', Validators.compose([Validators.required])),
      startDate: new FormControl(moment(new Date()), Validators.compose([Validators.required])),
      endDate: new FormControl(''),
      clientAddress: this.fb.group({
        addressLine1: new FormControl('', Validators.compose([Validators.maxLength(500)])),
        city: new FormControl(''),
        state: new FormControl('', Validators.compose([Validators.maxLength(150)])),
        zip: new FormControl('', Validators.compose([Validators.maxLength(15)])),
        country: this.country
      }),

      serviceId: new FormControl(''),
      isBusiness: this.businessFiling
    });
  }

  ngAfterViewInit() {
    this.gmService.getAddressAutoComplete(this.addressText, ['address']);
  }

  onSubmit() {
    this.newClientForm.markAllAsTouched();
    if (this.newClientForm.valid) {
      this.isSpinner = true;
      let ncFormRawValue = this.newClientForm.getRawValue() as NewClientModel;
      ncFormRawValue.startDate = formatMomentDate(ncFormRawValue.startDate);
      ncFormRawValue.endDate = ncFormRawValue.endDate ? formatMomentDate(ncFormRawValue.endDate) : null;

      this.errorsList = [];

      this.httpService.createClient(ncFormRawValue).subscribe((res: HttpResponse<NewClientModel>) => {
        this.isSpinner = false;
        if (res.status === 200) {
          this.notificationService.notifyText("New client created successfully!!");
          this.newClientForm.reset();
          this.router.navigateByUrl('/firm/clients-list');
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          this.errorMessages = err.error.message;
          this.isSpinner = false;
          this.notificationService.notifyText(`${this.errorMessages}`,'X');
        }
      });
    }
  }

  resetEndDate() {
    this.newClientForm.get('endDate').reset();
  }

  canDeactivate() {
    return !this.newClientForm.dirty;
  }

  getAvailableServices() {
    this.httpService.getAvailableServicesList().subscribe((res: HttpResponse<ServicesListModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.serviceList = res.body;
        this.activateServiceListAutocomplete();
      } else {
        this.notificationService.notifyText("There are no services available.", "X")
      }
    }, (err: HttpResponse<any>) => {
      console.log("Error", err);
      this.notificationService.notifyText("Error while fetching services list.", "X")
    });
  }

  activateServiceListAutocomplete() {
    this.serviceList$ = this.newClientForm.get('serviceId').valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        return (value ? this.autoCompleteService.filterByKey(this.serviceList, "name", value) : this.serviceList.slice());
      }),
    );
  }

  displayFn(serviceId: string): string {
    return serviceId ? this.serviceList.find((e: ServicesListModel) => e.id === serviceId)?.name : '';
  }
}
