import { Component, OnInit } from '@angular/core';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HttpResponse } from '@angular/common/http';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AgreementsListModel } from '../models/agreements-list.model';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { AGREEMENT_TYPES } from '../shared/constants/agreement.constants';
import { COLUMN_FILTER_TYPE, COLUMN_FORMAT, COLUMN_TYPES } from '../shared/enums/table.enum';
import { TableToExcelService } from '../shared/services/table-to-excel.service';
import { TableFilterService } from '../shared/services/table-filter.service';

@Component({
  selector: 'scj-org-agreements-list',
  templateUrl: './org-agreements-list.component.html',
  styleUrls: ['./org-agreements-list.component.scss']
})
export class OrgAgreementsListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  agreementList: AgreementsListModel[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  agreementStatus = STATUS_FIELDS;
  agreementTypes = AGREEMENT_TYPES;
  filteredAgreements: AgreementsListModel[] = [];

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router, private teService: TableToExcelService, private tfService: TableFilterService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.httpService.getAgreementsList().subscribe((res: HttpResponse<AgreementsListModel[]>) => {
      if (res.body !== null) {
        this.agreementList = res.body;

        this.agreementList.forEach((firm: AgreementsListModel, i) => {
          this.agreementTypes.forEach(at => {
            if (at.typeKey === firm.type)
              this.agreementList[i].type = at.typeValue;
          });
        });

        this.tfService.initializeColumnFilter(this.columnFilterConfig);
      }
      this.isLoading = false;
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'agreementName', header: 'Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'type', header: 'Type', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip' },
      { name: 'startDate', header: 'Start Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'endDate', header: 'End Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'usage', header: 'Usage', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER },
      { name: 'creationDate', header: 'Created On', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'agreementName', label: 'Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'type', label: 'Type', type: COLUMN_FILTER_TYPE.SELECT, value: ['Service Agreement', 'Terms and Conditions', 'Others'] },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Inactive'] },
      { name: 'startDate', label: 'Start Date', type: COLUMN_FILTER_TYPE.DATE, value: '' },
      { name: 'endDate', label: 'End Date', type: COLUMN_FILTER_TYPE.DATE, value: '' },
      { name: 'usage', label: 'Usage', type: COLUMN_FILTER_TYPE.NUMBER, value: [] },
      { name: 'creationDate', label: 'Created On', type: COLUMN_FILTER_TYPE.DATE, value: '' },
    ]
    return colFilters;
  }



  navigate(record: AgreementsListModel) {
    this.router.navigateByUrl(`/firm/my-org/org-agreements/edit/${record.id}`);
  }

  // rowSelected(record: AgreementsListModel) {
  //   console.log('record',record);
  //   this.router.navigateByUrl(`/firm/my-org/org-agreements/edit/${record.id}`);
  // }

  filterAgreements() {
    this.filteredAgreements = this.agreementList.filter((e: AgreementsListModel) => (e.status !== 'INACTIVE'));
  }

  exportToExcel() {
    this.teService.exportToExcel('Agreements List', this.columnConfig, this.agreementList);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }
}
