<!-- <header>
  Header
</header> -->
<!-- <mat-form-field appearance="outline">
  <mat-label>Subscription</mat-label>
  <scj-number-range-input [formGroup]="testForm" [min]="0" [max]="150">
    <input formControlName="startDate" matStartDate placeholder="Start date">
    <input formControlName="endDate" matEndDate placeholder="End date">
  </scj-number-range-input>
</mat-form-field> -->
<main class="base-content">
  <router-outlet></router-outlet>
</main>

<!-- <scj-theme-picker></scj-theme-picker> -->

<!-- <footer>
  Footer
</footer> -->