import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailAttachmentModel } from '../models/mail-attachment.model';
import { MailContentModel } from '../models/mail-content.model';
import { HttpCommonService } from '../services/http-common.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
  isLoading: boolean = true;
  isError: boolean = false;
  mail: MailContentModel;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private http: HttpCommonService,
    private notificationService: NotificationService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    if (this.data.type === 'INBOX') {
      this.getInboxMailContet();
    } else {
      this.getOutboxMailContet();
    }
  }

  getInboxMailContet() {
    let inboxMailParams = new HttpParams().append('messageId', this.data.mail.messageId);
    this.http.getInboxMailContent(inboxMailParams).subscribe((res: HttpResponse<MailContentModel>) => {
      if (res.body !== null) {
        this.mail = res.body;
      } else {
        this.notificationService.notifyText("There is no mail content.", "X");
      }
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.notificationService.notifyText("Unable to fetch mail content.", "X");
    });
  }

  getOutboxMailContet() {
    let outboxMailParams = new HttpParams().append('messageId', this.data.mail.messageId);
    this.http.getOutboxMailContent(outboxMailParams).subscribe((res: HttpResponse<MailContentModel>) => {
      if (res.body !== null) {
        this.mail = res.body;
      } else {
        this.notificationService.notifyText("There is no mail content.", "X");
      }
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.notificationService.notifyText("Unable to fetch mail content.", "X");
    });
  }

  download(attachment: MailAttachmentModel) {
    let downloadLink = this.document.createElement("a");
    downloadLink.href = `data:${attachment.contentType.split(';')[0].toLowerCase()};base64,` + attachment.content;;
    downloadLink.download = attachment.name;
    downloadLink.click();
  }

}
