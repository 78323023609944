<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20" *ngIf="!serviceId">New Service</h1>

            <h1 class="scj-h2 mr-20" *ngIf="serviceId">
                <span class="text-header">Service - </span>
                {{header}}
            </h1>

            <div class="flex-row">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button class="secondary" mat-flat-button (click)="cancel()">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form class="flex-row" [formGroup]="serviceForm">
            <div class="flex-column">
                <div class="flex-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Service Name</mat-label>
                        <input type="text" placeholder="Service Name" matInput formControlName="name" required>
                        <mat-error *ngIf="serviceForm.get('name').invalid">
                            <mat-error *ngIf="serviceForm.get('name')?.hasError('required')">
                                Service Name required
                            </mat-error>
                            <mat-error *ngIf="serviceForm.get('name')?.hasError('maxlength')">
                                Service name can't exceed more than 250 characters</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Workflow</mat-label>
                        <input type="text" matInput formControlName="workflowId" [matAutocomplete]="auto"
                            placeholder="Select Workflow" required>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
                            (optionSelected)="loadTemplate($event)">
                            <ng-container *ngIf="(workflowList$ | async) as list">
                                <ng-container *ngIf="list?.length === 0">
                                    <mat-option disabled>
                                        No Workflow Data Found
                                    </mat-option>
                                </ng-container>

                                <ng-container *ngIf="list?.length !== 0">
                                    <mat-option *ngFor="let wf of list" [value]="wf.id">
                                        {{ wf.name }}
                                    </mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-autocomplete>
                        <mat-error *ngIf="serviceForm.get('workflowId').invalid">
                            <mat-error *ngIf="serviceForm.get('workflowId')?.hasError('required')">
                                Workflow required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Terms & Conditions</mat-label>
                        <mat-select placeholder="Select terms & conditions" formControlName="tcAgreementId"
                            disableOptionCentering>
                            <mat-option [value]=""></mat-option>
                            <mat-option *ngFor="let tc of tcAgreements" [value]="tc.id">
                                {{tc.agreementName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Service Agreement</mat-label>
                        <mat-select placeholder="Select service agreement" formControlName="srvcAgreementId"
                            disableOptionCentering>
                            <mat-option [value]=""></mat-option>
                            <mat-option *ngFor="let srv of srvAgreements" [value]="srv.id">
                                {{srv.agreementName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="flex-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select placeholder="Status" formControlName="status" disableOptionCentering required>
                            <mat-option *ngFor="let stat of status" [value]="stat.statKey">{{stat.statValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="active-date">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" [min]="minStartDate"
                                formControlName="startDate" placeholder="Select Start Date" tabindex="-1" required
                                readonly>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker" matTooltip="Select start date">
                            </mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker color="primary" [touchUi]="isHandset$ | async">
                            </mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="endDate">
                            <mat-label>End Date</mat-label>
                            <input matInput #endDate [matDatepicker]="endDatePicker" [min]="minEndDate"
                                formControlName="endDate" placeholder="Select end date" tabindex="-1" readonly>
                            <mat-datepicker #endDatePicker color="primary" [touchUi]="isHandset$ | async">
                            </mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker" matTooltip="Select end date">
                            </mat-datepicker-toggle>
                            <mat-icon matSuffix *ngIf="endDate.value" (click)="resetEndDate()" matTooltip="Clear"
                                class="endDate">
                                clear
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="flex-column">
                <mat-form-field appearance="outline" class="text-area">
                    <mat-label>Description</mat-label>
                    <textarea type="text" matInput formControlName="description" placeholder="Description"
                        cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"></textarea>
                    <mat-error *ngIf="serviceForm.get('description').invalid">
                        <mat-error *ngIf="serviceForm.get('description')?.hasError('maxlength')">
                            description can't exceed more than 250 characters</mat-error>
                    </mat-error>
                </mat-form-field>
                
                <div class="flex-row allow-multiple">
                    <mat-checkbox formControlName="allowMultiple">Allow Multiple
                    </mat-checkbox>
                    <mat-icon matTooltip="Allow Multiple Active subscriptions for this Service" color="primary"
                        style="margin-right: 4px;">info_outlined</mat-icon>
                </div>

                <div class="flex-row allow-multiple">
                    <mat-checkbox formControlName="showFirmOnly">Internal Use Only
                    </mat-checkbox>
                    <mat-icon matTooltip="Allow Service to be shown only to firm" color="primary"
                        style="margin-right: 4px;">info_outlined</mat-icon>
                </div>
            </div>
        </form>

        <scj-template-loader *ngIf="templateId" [templateId]="templateId" [disableTemplate]="true">
        </scj-template-loader>
    </div>
</div>