import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AbstractControl, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { SectionsModel } from '../models/sections.model';
import { FormGroupService } from '../shared/services/form-group.service';
import { NotificationService } from '../shared/services/notification.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'scj-section-table',
  templateUrl: './section-table.component.html',
  styleUrls: ['./section-table.component.scss']
})
export class SectionTableComponent implements OnInit {
  displayColumns: string[] = [];
  dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource<AbstractControl>([]);

  @Input()
  section: SectionsModel;

  @Input()
  tableForm: FormGroup;

  constructor(private fgService: FormGroupService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    (this.section.isEditable) ? this.displayColumns.push('action') : '';
    this.dataSource.data = (this.tableForm.controls[this.section.name] as FormArray).controls;
    this.section.elements.forEach(element => {
      this.displayColumns.push(element.name);
    });
  }

  deleteRow(index: number) {
    if (this.section.isEditable) {
      let controlsArr = this.tableForm.controls[this.section.name] as FormArray;
      // if (controlsArr.length > 1) {
      controlsArr.removeAt(index);
      this.dataSource.data = controlsArr.controls;
      // } else {
      //   
      // }
    }else{
      this.notificationService.notifyText("Client can delete the row", "X");
    }
  }

  addRow() {
    if (this.section.isEditable) {
      let controlsArr = this.tableForm.controls[this.section.name] as FormArray;
      controlsArr.markAllAsTouched();
      if (controlsArr.valid) {
        controlsArr.push(this.fgService.toFormGroupByElements(this.section.elements));
        this.dataSource.data = controlsArr.controls;
      } else {
        this.notificationService.notifyText("Fill data in previous row to add another row", "X");
      }
    }else{
      this.notificationService.notifyText("Client can add the row", "X");
    }
  }
}
