<ng-container [ngSwitch]="element.type">

    <mat-form-field appearance="outline" *ngSwitchCase="'text'" [floatLabel]="element?.hideFloatLabel ? 'never' : 'auto'" [style.width]="element.width || '150px'">
        <mat-label *ngIf="!element?.hideFloatLabel">{{element.label}}</mat-label>
        <input matInput [type]="element.type" placeholder="{{element.label}}" [formControl]="elementControl"
            [required]="isRequired(element.validators)">
        <mat-error class="flex-column" *ngIf="elementControl.invalid">
            <ng-container *ngFor="let error of getErrorMessages(elementControl,element.validators)">
                <span>{{error}}</span>
            </ng-container>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngSwitchCase="'number'" [floatLabel]="element?.hideFloatLabel ? 'never' : 'auto'" [style.width]="element.width || '150px'">
        <mat-label *ngIf="!element?.hideFloatLabel">{{element.label}}</mat-label>
        <input matInput [type]="element.type" placeholder="{{element.label}}" [formControl]="elementControl"
            [required]="isRequired(element.validators)">
        <mat-error class="flex-column" *ngIf="elementControl.invalid">
            <ng-container *ngFor="let error of getErrorMessages(elementControl,element.validators)">
                <span>{{error}}</span>
            </ng-container>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngSwitchCase="'text-area'" class="text-area" [floatLabel]="element?.hideFloatLabel ? 'never' : 'auto'" [style.width]="element.width || '150px'">
        <mat-label *ngIf="!element?.hideFloatLabel">{{element.label}}</mat-label>
        <textarea matInput placeholder="{{element.label}}" [formControl]="elementControl" cdkTextareaAutosize
            cdkAutosizeMinRows="2" cdkAutosizeMaxRows="3" [required]="isRequired(element.validators)"></textarea>
        <mat-error class="flex-column" *ngIf="elementControl.invalid">
            <ng-container *ngFor="let error of getErrorMessages(elementControl,element.validators)">
                <span>{{error}}</span>
            </ng-container>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngSwitchCase="'date'" [floatLabel]="element?.hideFloatLabel ? 'never' : 'auto'" [style.width]="element.width || '150px'">
        <mat-label *ngIf="!element?.hideFloatLabel">{{element.label}}</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="{{element.label}}" [formControl]="elementControl"
            [required]="isRequired(element.validators)" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Select {{element.label}}"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error class="flex-column" *ngIf="elementControl?.invalid">
            <ng-container *ngFor="let error of getErrorMessages(elementControl,element.validators)">
                <span>{{error}}</span>
            </ng-container>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline"
        *ngSwitchCase="['multi-select', 'single-select'] | multiSwitchCase: element.type" [floatLabel]="element?.hideFloatLabel ? 'never' : 'auto'" [style.width]="element.width || '150px'">
        <mat-label *ngIf="!element?.hideFloatLabel">{{element.label}}</mat-label>
        <mat-select placeholder="{{element.label}}" [formControl]="elementControl"
            [required]="isRequired(element.validators)" [multiple]="element.type === 'multi-select'? true:false"
            disableOptionCentering>
            <mat-option *ngFor="let option of element.valueList" [value]="option.optionKey">
                {{option.optionValue}}
            </mat-option>
        </mat-select>
        <mat-error class="flex-column" *ngIf="elementControl.invalid">
            <ng-container *ngFor="let error of getErrorMessages(elementControl,element.validators)">
                <span>{{error}}</span>
            </ng-container>
        </mat-error>
    </mat-form-field>

    <mat-radio-group *ngSwitchCase="'radio'" [formControl]="elementControl">
        <mat-radio-button class="radio-button" *ngFor="let option of element.valueList" [value]="option.optionKey">
            {{option.optionValue}}
        </mat-radio-button>
        <div [class]="elementControl.valid? 'radio-label':''">{{element.label}} <span *ngIf="element.required" class="mandatory">*</span></div>
    </mat-radio-group>

    <ng-container *ngSwitchCase="'checkbox'">
        <mat-checkbox [formControl]="elementControl">{{element.hideLabel ? "" : element.label}}<span *ngIf="element.required" class="mandatory">*</span>
        </mat-checkbox>
        <mat-error class="flex-column" *ngIf="elementControl.touched && elementControl.invalid">
            <ng-container *ngFor="let error of getErrorMessages(elementControl, element.validators)">
                <span>{{error}}</span>
            </ng-container>
        </mat-error>
    </ng-container>
</ng-container>