<h2 class="scj-h2">
    <span class="text-header">Invoice For </span>
    <span>{{data.name}}</span>
</h2>

<div class="flex-row dialog-actions">
    <button mat-flat-button (click)="onSubmit()">
        <mat-icon class="button-icon">save_alt</mat-icon>
        <span class="button-text">Save</span>
    </button>
    <button mat-flat-button class="secondary" mat-dialog-close>
        <mat-icon class="button-icon">close</mat-icon>
        <span class="button-text">Cancel</span>
    </button>
    <div *ngIf="isLoading">
        <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
    </div>
</div>

<mat-dialog-content [formGroup]="billingForm">
    <div fxLayout="column">
        <mat-form-field appearance="outline" class="feild-margin">
            <mat-label>Invoice Amount</mat-label>
            <span matPrefix>&#36; &nbsp;</span>
            <input matInput type="text" placeholder="Service Fees" matInput formControlName="amount">
            <mat-error *ngIf="billingForm.get('amount').invalid">
                <mat-error *ngIf="billingForm.get('amount')?.hasError('required')">Invoice amount required
                </mat-error>
                <mat-error *ngIf="billingForm.get('amount')?.hasError('pattern')">Invalid $ amount (upto 2 decimal
                    places are allowed)</mat-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Invoice Date</mat-label>
            <input matInput [matDatepicker]="billingDatePicker" [min]="minBillingDate"
                formControlName="scheduleStartDate" placeholder="Invoice Date" required readonly>
            <mat-datepicker-toggle matSuffix [for]="billingDatePicker" matTooltip="Select Billing date"
                [disableRipple]="true">
            </mat-datepicker-toggle>
            <mat-datepicker #billingDatePicker color="primary" [touchUi]="isHandset$ | async" [restoreFocus]="false">
            </mat-datepicker>
        </mat-form-field>
    </div>
    <div fxLayout="row">

        <mat-form-field appearance="outline" class="text-area" style="width: 26vw;">
            <mat-label>Notes (Printed on Invoice)</mat-label>
            <textarea type="text" matInput formControlName="invoiceNotes" placeholder="Invoice Comments" cdkTextareaAutosize
                cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>