import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {

  private BASE_URL = environment.API_SERVER_URL;

  comments: Subject<String> = new Subject();

  constructor(private http: HttpClient) { }

  uploadFile(uploadUrl: string, formData: FormData): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.BASE_URL}${uploadUrl}`, formData, { observe: 'response' });
  }

  getClientSubscriptionAttachmentById(id: string): Observable<Blob> {
    return this.http.get<any>(this.BASE_URL + `/api/v1/client/subscription/attachment/${id}`, { responseType: 'blob' as 'json' });
  }

  downloadFile(downloadUrl: string): Observable<Blob> {
    return this.http.get<any>(`${this.BASE_URL}${downloadUrl}`, { responseType: 'blob' as 'json' });
  }

  getFilesList(listUrl: string): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(`${this.BASE_URL}${listUrl}`, { observe: 'response' })
  }

  deleteFile(deleteUrl: string, attachment: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.BASE_URL}${deleteUrl}`, attachment, { observe: 'response' });
  }

  uploadBulkFile(typeClient: string, attachment: FormData): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.BASE_URL}` + '/api/v1/client/bulk/create/' + typeClient, attachment);
  }

  validateFileExtension(fileName: string, fileExtentionArr: string | string[]) {
    let isFileExt: boolean = false;

    let fileExt: string = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);

    if (fileExt.length > 0) {
      isFileExt = fileExtentionArr.includes(fileExt.toUpperCase());
    }
    return isFileExt;
  }

  uploadFolder(folderUploadUrl: string, formData: FormData): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.BASE_URL}${folderUploadUrl}`, formData, { observe: 'response' });
  }
}
