import { Component, OnInit, TemplateRef, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TableColumnsConfig } from '../models/table-columns-config.model';
import { COLUMN_TYPES } from '../shared/enums/table.enum';
import { HttpCommonService } from '../services/http-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MailsModel } from '../models/mails.model';
import { NotificationService } from '../shared/services/notification.service';
import { MailContentModel } from '../models/mail-content.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MailAttachmentModel } from '../models/mail-attachment.model';
import { DOCUMENT } from '@angular/common';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { EmailModalComponent } from '../email-modal/email-modal.component';

@Component({
  selector: 'scj-client-email',
  templateUrl: './client-email.component.html',
  styleUrls: ['./client-email.component.scss']
})
export class ClientEmailComponent implements OnInit {
  mailLinks = [
    { path: 'inbox-mail', label: 'Inbox' },
    { path: 'sent-mail', label: 'Sent' }
  ];
  activeLink = this.mailLinks[0].path;

  constructor(public router: Router) {
  }

  ngOnInit(): void {
  }
}
