<div [formGroup]="tableForm" class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="section-table">

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row; let index=index">
                <button mat-icon-button type="button" matTooltip="Delete" (click)="deleteRow(index)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container *ngFor="let element of section.elements | sort:'asc':'sequence';">
            <ng-container matColumnDef="{{element.name}}">
                <th mat-header-cell *matHeaderCellDef>{{element.label}}</th>
                <td mat-cell *matCellDef="let row" [formGroup]="row">

                    <scj-dynamic-elements-form [element]="element" [elementControl]="row.controls[element.name]">
                    </scj-dynamic-elements-form>

                </td>
            </ng-container>
        </ng-container>

    </table>
</div>

<div class="add-row" *ngIf="section.isEditable">
    <button mat-raised-button type="button" (click)="addRow()">Add ROW</button>
</div>