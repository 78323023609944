import { Component, OnInit } from '@angular/core';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HttpResponse } from '@angular/common/http';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UsersListModel } from '../models/users-list.model';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { COLUMN_FILTER_TYPE, COLUMN_TYPES } from '../shared/enums/table.enum';
import { TableToExcelService } from '../shared/services/table-to-excel.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-org-users-list',
  templateUrl: './org-users-list.component.html',
  styleUrls: ['./org-users-list.component.scss']
})
export class OrgUsersListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  usersList: UsersListModel[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  userStatus = STATUS_FIELDS;
  selectedList: UsersListModel[] = [];
  enableButton: boolean = false;
  isSpinner: boolean = false;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router, private teService: TableToExcelService, private tfService: TableFilterService,
    private matDialog: MatDialog, private notificationService: NotificationService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.getUsersList();
  }

  getUsersList() {
    this.httpService.getUsersList().subscribe((res: HttpResponse<UsersListModel[]>) => {
      if (res.body !== null) {
        this.usersList = res.body;
        this.isLoading = false;
        this.tfService.initializeColumnFilter(this.columnFilterConfig);
      }
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }
  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'fullname', header: 'Full Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'prefferedName', header: 'Preferred Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'username', header: 'Email', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: "chip", classType: 'user' },
      { name: 'creationDate', header: 'Created On', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'fullname', label: 'Full Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'prefferedName', label: 'Preferred Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'username', label: 'Email', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Inactive', 'Pending'] },
      { name: 'creationDate', label: 'End Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
    ];
    return colFilters;
  }

  selectedClientList(list: UsersListModel[]) {
    this.selectedList = list;

    if (list.length === 0) {
      this.enableButton = false;
      return;
    }

    for (let i = 0; i < list.length; i++) {
      console.log(list[i].status);
      if (list[i].status !== 'PENDING_ACTIVATION') {
        this.enableButton = false;
        return;
      }
    }
    this.enableButton = true;
  }

  rowSelected(record: UsersListModel) {
    this.router.navigateByUrl(`/firm/my-org/org-users/edit/${record.id}`);
  }

  navigate(record: UsersListModel) {
    this.router.navigateByUrl(`/firm/my-org/org-users/edit/${record.id}`);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }

  getSelectedClientList() {
    let clientId: string[] = [];
    let clientName: string[] = [];
    this.selectedList.forEach((e: UsersListModel) => {
      clientId.push(e.id);
      clientName.push(e.fullname);
    });
    return { clientId: clientId, clientName: clientName };
  }

  resendActivationLink() {
    let list = this.getSelectedClientList();
    let header = 'Confirm Resend Activation Mail';
    let message = `Are you sure you want to resend activation mail to users? Usesrs list: <b> ${list.clientName.join(", ")}  </b>`;
    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.sendUserActivationMail(list.clientId);
        } else {
          return of(confirm);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! Activation mail sent to Users`);
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to send activation mail. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  deleteUser() {
    let list = this.getSelectedClientList();
    let header = 'Confirm Delete';
    let message = `Are you sure you want to delete Users? Users list: <b> ${list.clientName.join(", ")} </b>`;
    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.deletePendingUser(list.clientId);
        } else {
          return of(confirm);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! Users deleted`);
          this.getUsersList();
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to delete clients. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }


  opeDialog(header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true
    }
    return this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(AlertMessagesComponent, dialogConfig)
  }
}
