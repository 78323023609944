import { Component, OnInit } from '@angular/core';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { Observable } from 'rxjs';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NewAgreementModel } from '../models/new-agreement.model';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { AGREEMENT_TYPES } from '../shared/constants/agreement.constants';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TEXT_NUMBERS_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-org-new-agreement',
  templateUrl: './org-new-agreement.component.html',
  styleUrls: ['./org-new-agreement.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class OrgNewAgreementComponent implements OnInit, ComponentCanDeactivate {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  status = STATUS_FIELDS;
  agreementForm: FormGroup = new FormGroup({});
  disable: boolean = false;
  minStartDate: Date | null = null;
  minEndDate = new Date();
  agreementId: string;
  header: string = '';
  agreementTypes = AGREEMENT_TYPES;
  editorConfig = HTML_EDITOR_CONFIG;
  errorsList: string[] = [];
  isSpinner = false;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private httpService: HttpCommonService, private router: Router,
    private activatedRoute: ActivatedRoute, private notificationService: NotificationService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.agreementId = this.activatedRoute.snapshot.params.agreementId;
    this.disable = this.agreementId ? true : false;
    this.isLoading = this.agreementId ? true : false;
    this.minStartDate = this.agreementId ? null : new Date();
  }

  ngOnInit(): void {
    this.initializeForm();
    
    if (this.agreementId) {
      this.agreementForm.setControl('version', new FormControl(''));
      this.agreementForm.setControl('usage', new FormControl(''));
      this.httpService.getAgreementById(this.agreementId).subscribe((res: HttpResponse<NewAgreementModel>) => {
        if (res.body !== null) {
          this.header = res.body.agreementName;
          this.agreementForm.patchValue(res.body);
          this.agreementForm.controls['startDate'].setValue(moment(res.body.startDate));
          this.agreementForm.controls['endDate'].setValue(res.body.endDate ? moment(res.body.endDate) : null);
          this.minEndDate = new Date(res.body.startDate);
          this.isLoading = false;
        }
      }, (error: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        console.log("error", error)
      });
    }
  }

  initializeForm() {
    this.agreementForm = this.fb.group({
      agreementName: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required, Validators.pattern(TEXT_NUMBERS_PATTERN), Validators.maxLength(250)])),
      agreementText: new FormControl('', Validators.compose([Validators.required])),
      status: new FormControl('ACTIVE', Validators.compose([Validators.required])),
      startDate: new FormControl({ value: moment(new Date()), disabled: this.disable }, Validators.compose([Validators.required])),
      endDate: new FormControl(''),
      type: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required]))
    });
  }

  onSubmit() {
    this.agreementForm.markAllAsTouched();
    this.errorsList = [];
    console.log("agreement", this.agreementForm.getRawValue());
    if (this.agreementForm.valid) {
      let agreementFormRawValue = this.agreementForm.getRawValue() as NewAgreementModel;
      agreementFormRawValue.startDate = formatMomentDate(agreementFormRawValue.startDate);
      agreementFormRawValue.endDate = agreementFormRawValue.endDate ? formatMomentDate(agreementFormRawValue.endDate) : null;
      this.isSpinner = true;
      if (!this.agreementId) {
        this.httpService.createAgreement(agreementFormRawValue).subscribe((res: HttpResponse<NewAgreementModel>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("New agreement created successfully!!");
            this.agreementForm.reset();
            this.router.navigate([`../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }

      if (this.agreementId) {
        delete agreementFormRawValue.agreementName;
        delete agreementFormRawValue.version;
        delete agreementFormRawValue.usage;
        this.httpService.updateAgreement(this.agreementId, agreementFormRawValue).subscribe((res: HttpResponse<NewAgreementModel>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Agreement details updated successfully!!");
            this.agreementForm.reset();
            this.router.navigate([`../../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }
    }
  }

  cancel() {
    this.router.navigate([this.agreementId ? '../../list' : '../list'], { relativeTo: this.activatedRoute });
  }

  resetEndDate() {
    this.agreementForm.get('endDate').reset();
  }

  canDeactivate() {
    return !this.agreementForm.dirty;
  }

}

export const HTML_EDITOR_CONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '200px',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter text here...',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  fonts: [
    { class: 'arial', name: 'Arial' },
    { class: 'times-new-roman', name: 'Times New Roman' },
    { class: 'calibri', name: 'Calibri' },
    { class: 'comic-sans-ms', name: 'Comic Sans MS' }
  ],
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadUrl: 'v1/image',
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  outline: true
  // toolbarHiddenButtons: [
  //   ['bold', 'italic'],
  //   ['fontSize']
  // ]
};