import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scj-org-users',
  templateUrl: './org-users.component.html',
  styleUrls: ['./org-users.component.scss']
})
export class OrgUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
