<div class="flex-column mat-elevation-z8 login-main">
    <scj-firm-logo-container [subdomainHost]="subdomain"> </scj-firm-logo-container>

    <div style="display:flex"><div><h2 class="scj-h2 right-margin">Two Factor Authentication</h2></div>
    <div *ngIf="isLoading"> <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner></div></div>
    <div *ngIf="!isLoading">
    <p>
        Please Enter the Verfication code that we sent to email {{ username }}.
    </p>

    <form (ngSubmit)="onSubmit()">
        <div class="otp-container">
            <mat-form-field>
                <mat-label>Enter OTP</mat-label>
                <input matInput [formControl]="otpControl" required />
                <mat-error *ngIf="otpControl.invalid">
                    <mat-error *ngIf="otpControl.hasError('required')">
                        OTP required
                    </mat-error>
                    <mat-error *ngIf="otpControl.hasError('pattern')">
                        OTP must be 6 digit number
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <div class="resend" (click)="resendOtp()">Resend</div>
        </div>

        <mat-checkbox [formControl]="rememberMeControl">Remember me on this device for 30 days.</mat-checkbox>

        <div class="submitButton">
            <button type="submit">Submit</button>
        </div>
    </form>
</div>
</div>

