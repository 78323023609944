import { Component, OnInit } from '@angular/core';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HttpResponse } from '@angular/common/http';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ServicesListModel } from '../models/services-list.model';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { COLUMN_FILTER_TYPE, COLUMN_TYPES } from '../shared/enums/table.enum';
import { TableToExcelService } from '../shared/services/table-to-excel.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { NotificationService } from '../shared/services/notification.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'scj-org-services-list',
  templateUrl: './org-services-list.component.html',
  styleUrls: ['./org-services-list.component.scss'],
})
export class OrgServicesListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  serviceList: ServicesListModel[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  serviceStatus = STATUS_FIELDS;
  enableredirect: boolean = true;
  idselected: any;
  servicename: string;
  selectedList: ServicesListModel[] = [];
  enableButton: boolean = false;
  enableDeleteButton: boolean = false;

  isSpinner = false;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router, private teService: TableToExcelService, private matDialog: MatDialog, private notificationService: NotificationService,
    private tfService: TableFilterService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.getServicesList();
  }

  getServicesList() {
    this.httpService.getServicesList().subscribe(
      (res: HttpResponse<ServicesListModel[]>) => {
        if (res.body !== null) {
          this.serviceList = res.body;
          this.tfService.initializeColumnFilter(this.columnFilterConfig);
        }
        this.isLoading = false;
      },
      (err: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        console.log('Error', err);
      }
    );
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'name', header: 'Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'workflowName', header: 'Workflow', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip' },
      { name: 'startDate', header: 'Start Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'endDate', header: 'End Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'subscriptionCount', header: 'Subscriptions', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER },
      { name: 'creationDate', header: 'Created On', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'name', label: 'name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'workflowName', label: 'Workflow', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Inactive'] },
      { name: 'startDate', label: 'Start Date', type: COLUMN_FILTER_TYPE.DATE, value: '' },
      { name: 'endDate', label: 'End Date', type: COLUMN_FILTER_TYPE.DATE, value: '' },
      { name: 'subscriptionCount', label: 'Subscriptions', type: COLUMN_FILTER_TYPE.NUMBER, value: '' },
      { name: 'creationDate', label: 'Created On', type: COLUMN_FILTER_TYPE.DATE, value: '' },
    ]
    return colFilters;
  }

  sendDataByEmail() {
    let list = this.getSelectedServicesList();
    let header = 'Subscriptions Data by email';
    let message = '<b>' + list.clientName + '</b>' + `subscription(s) Data will be sent by email, are you Sure?`;
    `Are you sure you want to resend activation mail to users? Users list: <b> ${list.clientName.join(', ')}  </b>`;

    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.generateSubscriptionDataReport(
            list.clientId[0]
          );
        } else {
          return of(confirm);
        }
      })).subscribe(
        (res: boolean | HttpResponse<any>) => {
          if (res) {
            this.isSpinner = false;
            this.notificationService.notifyText('Success! ' + list.clientName[0] + ' Data will be sent to you by Email shortly');
          }
        },
        (err) => {
          this.isSpinner = false;
          this.notificationService.notifyText(`Failed! Unable to send activation mail. 'If the Problem persists Please contact support@simplecj.com`);
        }
      );
  }

  getSelectedServicesList() {
    let clientId: string[] = [];
    let clientName: string[] = [];
    this.selectedList.forEach((e: ServicesListModel) => {
      clientId.push(e.id);
      clientName.push(e.name);
    });
    return { clientId: clientId, clientName: clientName };
  }

  deleteService() {
    let list = this.getSelectedServicesList();
    let header = 'Confirm Delete';
    let message = `Are you sure you want to delete Services? Service list: <b> ${list.clientName.join(', ')} </b>`;
    this.opeDialog(header, message).afterClosed().pipe(switchMap((confirm: any) => {
      if (confirm) {
        this.isSpinner = true;
        return this.httpService.deleteService(list.clientId);
      } else {
        return of(confirm);
      }
    })).subscribe((res: boolean | HttpResponse<any>) => {
      if (res) {
        this.isSpinner = false;
        this.notificationService.notifyText(`Success! Services deleted`);
        this.getServicesList();
      }
    },
      (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(
          `Failed! Unable to delete Services. 'If the Problem persists Please contact support@simplecj.com`
        );
      }
    );
  }

  selectedServiceList(list: ServicesListModel[]) {
    this.selectedList = list;

    if (list.length === 0) {
      this.enableButton = false;
      this.enableDeleteButton = false;
      return;
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].subscriptionCount > 0) {
        if (list[i].workflowName.startsWith('K1')) {
          this.enableButton = true;
          return;
        }
        this.enableDeleteButton = false;
        return;
      }
    }
    this.enableDeleteButton = true;
    this.enableButton = false;
  }

  openPopupWindow(serviceId: string, servicename: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '50vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      header: 'Subscriptions Data by email',
      message: '<b>' + servicename + '</b> subscription(s) Data will be sent by email, are you Sure?',
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true
    }
    let sam = this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(AlertMessagesComponent,
      dialogConfig);
    sam.afterClosed().subscribe((data) => {
      if (data) {
        this.httpService.generateSubscriptionDataReport(serviceId).subscribe(
          (res) => {
            this.notificationService.notifyText('Success! ' + servicename + ' Data will be sent to you by Email shortly');
          },
          (err) => {
            this.notificationService.notifyText('Failed! Unable to send ' + servicename + ' Data by Email. If the Problem persists Please contact support@simplecj.com');
          }
        );
      }
      else {
        //This trigers when user clicks cancel button and returns back to old page
      }
    })

  }

  opeDialog(header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true,
    };
    return this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(
      AlertMessagesComponent,
      dialogConfig
    );
  }

  navigate(record: ServicesListModel) {
    this.router.navigateByUrl(`/firm/my-org/org-services/edit/${record.id}`);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }
}
