import { Directive, Output, EventEmitter, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { FILE_EXTENSION } from '../constants/app.constants';
import { FileHandlerService } from '../services/file-handler.service';
import { NotificationService } from '../services/notification.service';

@Directive({
  selector: '[appFilesDropZone]'
})
export class FilesDropZoneDirective implements OnInit {

  @Input()
  fileExtension: string | string[] = FILE_EXTENSION;

  @Output()
  filesDropped = new EventEmitter<any>();

  @Output()
  filesHovered = new EventEmitter<boolean>();

  constructor(private fhService: FileHandlerService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    if (!(this.fileExtension instanceof Array)) {
      this.fileExtension = [this.fileExtension];
    }
  }

  @HostListener('drop', ['$event'])
  onDrop($event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    let files = $event.dataTransfer.files;
    let dt = new DataTransfer();

    [...files].forEach((e: File) => {
      dt.items.add(e);
    });

    this.filesDropped.emit(dt.files);
    this.filesHovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.filesHovered.emit(false);
  }
}
