import { Component, OnInit } from '@angular/core';
import { User } from './models/user.model';
import { AuthenticationService } from './shared/services/authentication.service';
import { ICONS } from './shared/icon';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PaymentService } from './shared/services/payment.service';
import { ScriptLoaderService } from './shared/services/script-loader.service';
import { filter } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

declare let gtag: Function;
@Component({
  selector: 'scj-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: User;
  icons = ICONS
  testForm = new FormGroup({
    start: new FormControl(50),
    end: new FormControl(1000)
  });

  constructor(private authenticationService: AuthenticationService, private miRegistry: MatIconRegistry, private slService: ScriptLoaderService,
    private domSanitizer: DomSanitizer, private router: Router, private paymentService: PaymentService) {
    this.icons.forEach(icon => this.miRegistry.addSvgIcon(
      icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon.path}`)));

    this.authenticationService.user.subscribe((x: User | undefined) => {
      if (x !== undefined) {
        this.user = x;
        if (x.clientId) {
          this.paymentService.loadPaymentConfig();
        }
      }
    });
    this.slService.loadMapsScript();
    this.slService.loadGAScript();
    // this.slService.loadRecaptchaScript();
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        });
      });
  }

  logout() {
    this.authenticationService.logout();
  }
}
