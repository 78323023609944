<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" class="inline-block">
        <div class="flex-row ai-c mb-8">
            <h1 class="scj-h2 mr-20">Details</h1>
            <div class="flex-row">
                <button mat-flat-button (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button class="secondary" mat-flat-button routerLink="/firm/home">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form [formGroup]="orgForm" class="org-form" autocomplete="off">
            <div>
                <div class="flex-row">
                    <div class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Account Number</mat-label>
                            <input type="text" matInput formControlName="accountNumber">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Firm Name</mat-label>
                            <input type="text" matInput placeholder="Enter firm name" formControlName="name">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Firm Website</mat-label>
                            <input type="url" placeholder="Enter firm website" matInput formControlName="website">
                            <mat-error *ngFor="let validator of errorMessages.website">
                                <mat-error
                                    *ngIf="orgForm.get('website').hasError(validator.type) && (orgForm.get('website').dirty || orgForm.get('website').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Firm Phone Number</mat-label>
                            <input type="text" placeholder="Enter firm phone number" matInput
                                formControlName="phoneNumber" required>
                            <mat-error *ngFor="let validator of errorMessages.phoneNumber">
                                <mat-error
                                    *ngIf="orgForm.get('phoneNumber').hasError(validator.type) && (orgForm.get('phoneNumber').dirty || orgForm.get('phoneNumber').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div formGroupName="address" class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Country</mat-label>
                            <mat-select placeholder="Select Country" [formControl]="country" disableOptionCentering
                                required>
                                <mat-option *ngFor="let el of countries" [value]="el.COUNTRY_CODE">{{el.COUNTRY_NAME}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Addess</mat-label>
                            <input type="text" #addressText placeholder="Enter firm address" matInput
                                formControlName="addressLine1" required>
                            <mat-error *ngFor="let validator of errorMessages.address.addressLine1">
                                <mat-error
                                    *ngIf="orgForm.get('address').get('addressLine1').hasError(validator.type) && (orgForm.get('address').get('addressLine1').dirty || orgForm.get('address').get('addressLine1').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input type="text" placeholder="Enter city" matInput formControlName="city" required>
                            <mat-error *ngFor="let validator of errorMessages.address.city">
                                <mat-error
                                    *ngIf="orgForm.get('address').get('city').hasError(validator.type) && (orgForm.get('address').get('city').dirty || orgForm.get('address').get('city').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>State/Province</mat-label>
                            <input type="text" placeholder="Enter state/province" matInput formControlName="state"
                                required>
                            <mat-error *ngFor="let validator of errorMessages.address.state">
                                <mat-error
                                    *ngIf="orgForm.get('address').get('state').hasError(validator.type) && (orgForm.get('address').get('state').dirty || orgForm.get('address').get('state').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Zip</mat-label>
                            <input type="text" placeholder="Enter zip" matInput formControlName="zip" required>
                            <mat-error *ngFor="let validator of errorMessages.address.zip">
                                <mat-error
                                    *ngIf="orgForm.get('address').get('zip').hasError(validator.type) && (orgForm.get('address').get('zip').dirty || orgForm.get('address').get('zip').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="primary-contact">Support</div>

                <mat-divider></mat-divider>

                <div class="support-sales top-padding flex-row">
                    <div class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Support Email</mat-label>
                            <input type="email" placeholder="Enter support email" #support matInput
                                formControlName="supportEmail">
                            <mat-error *ngFor="let validator of errorMessages.supportEmail">
                                <mat-error
                                    *ngIf="orgForm.get('supportEmail').hasError(validator.type) && (orgForm.get('supportEmail').dirty || orgForm.get('supportEmail').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>IMAP Server Name</mat-label>
                            <input type="text" placeholder="Enter imap server name" matInput formControlName="imapHost"
                                [required]="support && support.value.length>0">
                            <mat-error *ngFor="let validator of errorMessages.imapHost">
                                <mat-error
                                    *ngIf="orgForm.get('imapHost').hasError(validator.type) && (orgForm.get('imapHost').dirty || orgForm.get('imapHost').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline">
                            <mat-label>Default Assignee</mat-label>
                            <mat-select placeholder="Select default assignee" formControlName="defaultCpa"
                                disableOptionCentering>
                                <mat-option *ngFor="let user of cpaUsersList" [value]="user.id">{{user.fullname}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Notification Email</mat-label>
                            <input type="email" placeholder="Enter notification email" #notifiEmail matInput
                                formControlName="notificationEmail">
                            <mat-error *ngFor="let validator of errorMessages.notificationEmail">
                                <mat-error
                                    *ngIf="orgForm.get('notificationEmail').hasError(validator.type) && (orgForm.get('notificationEmail').dirty || orgForm.get('supportEmail').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>SMTP Server Name</mat-label>
                            <input type="text" placeholder="Enter smtp server name" matInput formControlName="smtpHost"
                                [required]="notifiEmail && notifiEmail.value.length>0">
                            <mat-error *ngFor="let validator of errorMessages.smtpHost">
                                <mat-error
                                    *ngIf="orgForm.get('smtpHost').hasError(validator.type) && (orgForm.get('smtpHost').dirty || orgForm.get('smtpHost').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                    </div> -->

                </div>

                <!-- <div class="primary-contact">Finance</div>

                <mat-divider></mat-divider>

                <div class="top-padding">
                    <mat-form-field appearance="outline">
                        <mat-label>Paypal Merchant Id</mat-label>
                        <input type="text" placeholder="Enter paypal merchant id" matInput formControlName="merchantId">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Payment Term</mat-label>
                        <mat-select placeholder="Default Payment Term" formControlName="defaultPaymentTerm"
                            disableOptionCentering>
                            <mat-option *ngFor="let term of paymentTerm" [value]="term.termKey">
                                {{term.termValue}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div> -->

                <div class="primary-contact">Primary Contact Details</div>

                <mat-divider></mat-divider>

                <div class="flex-column" formGroupName="primaryUser">
                    <div class="top-padding">
                        <mat-form-field appearance="outline">
                            <mat-label>Primary User</mat-label>
                            <mat-select placeholder="Select primary user" formControlName="id" disableOptionCentering
                                required>
                                <mat-option *ngFor="let user of primaryUsersList" [value]="user.id">{{user.fullname}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngFor="let validator of errorMessages.primaryUser.id">
                                <mat-error
                                    *ngIf="orgForm.get('email').hasError(validator.type) && (orgForm.get('email').dirty || orgForm.get('email').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>User Full Name</mat-label>
                            <input type="text" placeholder="Full name" matInput formControlName="contactFullName">
                            <!-- <mat-error *ngFor="let validator of errorMessages.email">
                            <mat-error
                                *ngIf="orgForm.get('email').hasError(validator.type) && (orgForm.get('email').dirty || orgForm.get('email').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error> -->
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>User Email</mat-label>
                            <input type="text" placeholder="Full name" matInput formControlName="contactEmail">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>User Phone Number</mat-label>
                            <input type="text" placeholder="Full name" matInput formControlName="contactPhone">
                        </mat-form-field>
                    </div>
                    <!-- <mat-form-field>
                        <mat-label>Contact Phone Number</mat-label>
                        <input type="text" placeholder="Enter your contact phone number" matInput
                            formControlName="phoneNumber" required>
                        <mat-error *ngFor="let validator of errorMessages.phoneNumber">
                            <mat-error
                                *ngIf="orgForm.get('phoneNumber').hasError(validator.type) && (orgForm.get('phoneNumber').dirty || orgForm.get('phoneNumber').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field> -->
                </div>

                <!-- <mat-divider></mat-divider>

                <div class="flex-row support-sales">


                    <mat-form-field>
                        <mat-label>Sales Email</mat-label>
                        <input type="email" placeholder="Enter sales email" matInput formControlName="salesEmail">
                        <mat-error *ngFor="let validator of errorMessages.salesEmail">
                            <mat-error
                                *ngIf="orgForm.get('salesEmail').hasError(validator.type) && (orgForm.get('salesEmail').dirty || orgForm.get('salesEmail').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field> 
                </div>-->
            </div>
        </form>
    </div>
</div>