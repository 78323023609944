import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { EsignTemplateModel } from '../models/esign-template.model';
import { EsignTemplateFeildsModel } from '../models/esign-template-fields.model';
import { COLOR } from '../shared/enums/color.enum';
import { DATE_ICON, ESIGN_ICON, NAME_ICON, INITIALS_ICON } from '../shared/constants/svg-icon';
import { DomSafePipe } from '../shared/pipes/dom-safe.pipe';
import { domSafeEnum } from '../shared/enums/dom-safe.enum';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { FileHandlerService } from '../shared/services/file-handler.service';

const moment = _rollupMoment || _moment;

declare let $: any;
export let selectedUser = { index: 0, color: COLOR.COLOR_1 };

pdfjsLib.GlobalWorkerOptions.workerSrc = './assets/js/pdf.worker.min.js';
@Component({
  selector: 'scj-org-new-sign-template',
  templateUrl: './org-new-sign-template.component.html',
  styleUrls: ['./org-new-sign-template.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class OrgNewSignTemplateComponent implements OnInit, ComponentCanDeactivate {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  status = STATUS_FIELDS;
  signTemplateForm: FormGroup = new FormGroup({});
  disable: boolean = false;
  minStartDate: Date | null = null;
  minEndDate = new Date();
  signTemplateId: string;
  header: string = '';
  errorsList: string[] = [];
  isSpinner = false;
  pdfBase64: string;
  pages: any[] = [];
  isHovering: boolean = false;
  fileError: string = "";
  signTemplate: EsignTemplateFeildsModel[];
  userList: any[];
  selectedUserControl = new FormControl({ index: 0, color: COLOR.COLOR_1 });
  iconList: any;
  userSelected = { index: 0, color: COLOR.COLOR_1 };
  fileExtension: string[] = ["PDF"];

  @ViewChild('user')
  user: TemplateRef<any>;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private httpService: HttpCommonService, private router: Router,
    private activatedRoute: ActivatedRoute, private notificationService: NotificationService, private domSafe: DomSafePipe,
    private fhService: FileHandlerService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.signTemplateId = this.activatedRoute.snapshot.params.signTemplateId;
    this.disable = this.signTemplateId ? true : false;
    this.isLoading = this.signTemplateId ? true : false;
    this.minStartDate = this.signTemplateId ? null : new Date();
  }

  ngOnInit(): void {
    this.iconList = {
      sign: this.generateIcons(ESIGN_ICON),
      name: this.generateIcons(NAME_ICON),
      initial: this.generateIcons(INITIALS_ICON),
      date: this.generateIcons(DATE_ICON)
    };
    ;
    this.initializeForm();

    if (this.signTemplateId) {
      this.getSignTemplateById();
    }

    this.userList = Array(10).fill(0).map((val: any, index: number) => {
      return { index: index, color: COLOR[`COLOR_${index + 1}`] };
    });

    this.selectedUserControl.valueChanges.subscribe((user: any) => {
      this.userSelected = selectedUser = { index: user.index, color: user.color };
    });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {

    if (files.length > 1) {
      this.notificationService.notifyText('Multiple files upload not supported.', "X");
      return;
    }

    let templateFile = files.item(0);

    if (!this.fhService.validateFileExtension(templateFile.name, this.fileExtension)) {
      this.notificationService.notifyText('Only PDF file is supported.', "X");
      return;
    }

    let fileReader = new FileReader();
    this.signTemplateForm.get('docName').setValue(templateFile.name);

    fileReader.readAsDataURL(templateFile);
    fileReader.onload = () => {
      this.pdfBase64 = fileReader.result as string;
      this.signTemplateForm.get('docAsBase64String').setValue(this.pdfBase64);
      this.setPages();
    }
    fileReader.onerror = (error) => {
      this.notificationService.notifyText("Unable to load PDF file");
    }
  }

  generateIcons(icon: string) {
    let SVGContainer = document.createElement("div");
    SVGContainer.style.display = "none";
    SVGContainer.innerHTML = icon;
    let svgNode = SVGContainer.firstElementChild as SVGElement;
    svgNode.setAttribute("fill", "rgba(0,0,0,0.5");

    let svgXml = new XMLSerializer().serializeToString(svgNode);
    return this.domSafe.transform("data:image/svg+xml;base64," + btoa(svgXml), domSafeEnum.SAFE_RESOURCE_URL);
  }

  compareFn(o1: any, o2: any) {
    if (o1.index === o2.index && o1.color === o2.color)
      return true;
    else return false;
  }


  async setPages(): Promise<void> {
    let pdfDoc = await pdfjsLib.getDocument({ url: this.pdfBase64 }).promise;
    const totalPages = pdfDoc.numPages;
    this.pages = [];

    for (let i = 0; i < totalPages; i++) {
      await pdfDoc.getPage(i + 1).then(async (page: PDFPageProxy) => {
        let canvas = document.createElement('canvas');
        canvas.setAttribute("id", `canvas-${i}`);
        const viewport = page.getViewport({ scale: 1 });
        const height = viewport.height;
        const width = viewport.width;
        canvas.height = height;
        canvas.width = width;
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };
        await page.render(renderContext).promise.then(() => {
          this.pages.push({ data: canvas.toDataURL('image/png'), width: width, height: height });
        });
      });
    }
    if (this.signTemplate) {
      let pageCount = Math.max(...this.signTemplate.map((o: any) => o.page));
      let timer = setInterval(() => {
        let pages = document.querySelectorAll(".page");
        if (pageCount <= pages?.length) {
          this.signTemplate.forEach((e: EsignTemplateFeildsModel, index: number) => {
            let page = pages[e.page - 1];
            let div = document.getElementById('cloner').cloneNode(true) as HTMLElement;
            div.setAttribute('field-type', e.fieldType);
            div.setAttribute('username', e.username);
            div.setAttribute('name', e.name);
            div.classList.add('field-editable');
            div.removeAttribute('id');
            div.removeAttribute('style');
            div.style.top = `${e.y}px`;
            div.style.left = `${e.x}px`;
            div.style.width = `${e.width}px`;
            div.style.height = `${e.height}px`;
            div.style.backgroundColor = `${e.bgColor}`;
            let nodes = div.children;
            nodes.item(0).setAttribute("src", e.icon);
            nodes.item(1).innerHTML = e.displayText;
            nodes.item(2).innerHTML = e.tooltip;
            nodes.item(3).classList.remove('field-delete-hide');

            page.appendChild(div);
          });
          clearInterval(timer);
          loadJQuery();
        }
      }, 200);
    } else {
      loadJQuery();
    }
  }

  setFileValidations() {
    this.pdfBase64 = undefined;
    this.signTemplateForm.get('docAsBase64String').setValue(null);
    this.fileError = 'Sign Template PDF is required'
    this.notificationService.notifyText("PDF file is required for Sign Template");
  }

  getSignTemplateById() {
    this.httpService.getEsignTemplateById(this.signTemplateId).subscribe((res: HttpResponse<EsignTemplateModel>) => {
      this.signTemplateForm.patchValue(res.body);
      this.signTemplateForm.controls['startDate'].setValue(moment(res.body.startDate));
      this.signTemplateForm.controls['endDate'].setValue(res.body.endDate ? moment(res.body.endDate) : null);
      this.minEndDate = new Date(res.body.startDate);
      this.minEndDate = new Date(res.body.startDate);
      this.pdfBase64 = res.body.docAsBase64String;
      try {
        this.signTemplate = JSON.parse(res.body.jsonData) as EsignTemplateFeildsModel[];
      } catch (e: any) {
        this.signTemplate = undefined
      }
      this.header = res.body.name;
      this.setPages();
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.isError = true;
      console.log("error", err);
    });
  }

  initializeForm() {
    this.signTemplateForm = this.fb.group({
      name: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required, Validators.maxLength(250)])),
      status: new FormControl('ACTIVE', Validators.compose([Validators.required])),
      docName: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required])),
      docAsBase64String: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required])),
      jsonData: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.required])),
      startDate: new FormControl({ value: moment(new Date()), disabled: this.disable }, Validators.compose([Validators.required])),
      endDate: new FormControl(''),
    });
  }

  onSubmit() {
    let pages = document.querySelectorAll(".page");
    let signTemplateMarkers: EsignTemplateFeildsModel[] = [];

    pages.forEach((page: any, index: number) => {
      let fields = page?.querySelectorAll('.field-editable') as NodeListOf<Element>;
      fields.forEach((field: HTMLElement) => {
        let width = (field.clientWidth < 100) ? 100 : (field.clientWidth > 250) ? 250 : field.clientWidth;
        let height = (field.clientHeight < 25) ? 25 : (field.clientHeight > 60) ? 60 : field.clientHeight
        signTemplateMarkers.push({
          page: index + 1, fieldType: field.getAttribute("field-type"), username: field.getAttribute("username"),
          width: width - 16, height: height - 8, x: field.offsetLeft, y: field.offsetTop, name: field.getAttribute('name'),
          bgColor: field.style.getPropertyValue('background-color'), icon: field.children.item(0).getAttribute("src"),
          displayText: field.children.item(1).innerHTML, tooltip: field.children.item(2).innerHTML
        });
      });
    });

    if (signTemplateMarkers.length > 0) {
      this.signTemplateForm.get('jsonData').setValue(JSON.stringify(signTemplateMarkers));
    } else {
      this.signTemplateForm.get('jsonData').setValue(undefined);
      this.notificationService.notifyText("Empty sign template can't be created.");
    }

    this.signTemplateForm.markAllAsTouched();
    this.errorsList = [];
    if (this.signTemplateForm.valid && this.pdfBase64) {
      let signTemplateFormRawValue = this.signTemplateForm.getRawValue() as EsignTemplateModel;
      signTemplateFormRawValue.startDate = formatMomentDate(signTemplateFormRawValue.startDate);
      signTemplateFormRawValue.endDate = signTemplateFormRawValue.endDate ? formatMomentDate(signTemplateFormRawValue.endDate) : null;
      this.isSpinner = true;
      if (!this.signTemplateId) {
        this.httpService.createEsignTemplate(signTemplateFormRawValue).subscribe((res: HttpResponse<any>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("New sign template created successfully!!");
            this.signTemplateForm.reset();
            this.router.navigate([`../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }

      if (this.signTemplateId) {
        this.httpService.updateEsignTemplate(this.signTemplateId, signTemplateFormRawValue).subscribe((res: HttpResponse<any>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("Sign Template details updated successfully!!");
            this.signTemplateForm.reset();
            this.router.navigate([`../../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }
    }
  }

  cancel() {
    this.router.navigate([this.signTemplateId ? '../../list' : '../list'], { relativeTo: this.activatedRoute });
  }

  resetEndDate() {
    this.signTemplateForm.get('endDate').reset();
  }

  canDeactivate() {
    return !this.signTemplateForm.dirty;
  }

}

function loadJQuery() {

  $(function () {
    $('.field-editable').draggable({
      appendTo: '.page',
      cursor: 'move',
      containment: "parent",
    }).resizable({
      containment: "parent",
      handles: "se, nw, sw, s, n, w",
      minWidth: 75,
      minHeight: 25,
      maxWidth: 250,
      maxHeight: 50
    });

    $('.draggable').draggable({
      helper: 'clone',
      cursor: 'move',
      revert: true,
      revertDuration: 0,
      tolerance: 'fit',
      drag: function (event: any, ui: any) {
        ui.helper.addClass('field-editable');
        ui.helper.css('border-style', 'dashed').css('background-color', `${selectedUser.color}`).css('color', 'white');
        let fieldType = ui.helper.attr('field-type')
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = (fieldType === 'NAME') ? NAME_ICON : (fieldType === 'INITIALS') ? INITIALS_ICON : (fieldType === 'SIGNATURE') ? ESIGN_ICON : DATE_ICON;
        let svgNode = SVGContainer.firstElementChild as SVGElement;
        svgNode.setAttribute("fill", "rgba(255,255,255,1)");
        let svgXml = new XMLSerializer().serializeToString(svgNode);
        ui.helper.find("img.esign-icon").attr("src", "data:image/svg+xml;base64," + btoa(svgXml));
      }
    });

    $(".page").droppable({
      accept: '.draggable',
      drop: function (event: any, ui: any) {
        let dropped = ui.helper.clone();
        let width = ui.helper.width();
        let height = ui.helper.height();
        ui.helper.remove();
        $(this).append(dropped);
        let leftPosition = ui.offset.left - $(this).offset().left;
        let topPosition = ui.offset.top - $(this).offset().top;
        dropped.find("svg.field-delete").removeClass("field-delete-hide");
        dropped.css({
          top: topPosition, left: leftPosition, width: width, height: height
        });
        dropped.addClass("field-editable");
        $(dropped).resizable({
          containment: "parent",
          handles: "se, nw, sw, s, n, w",
          minWidth: 75,
          minHeight: 25,
          maxWidth: 250,
          maxHeight: 50
        }).draggable({
          appendTo: '.page',
          cursor: 'move',
          containment: "parent",
        })
      }
    });

    $(document).on("click", ".field-delete", function () {
      let parent = $(this).parent();
      parent.remove();
    });
  });
}
