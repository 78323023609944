import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, fromEvent, of, BehaviorSubject } from 'rxjs';
import { catchError, first, map, mapTo, switchMap } from 'rxjs/operators';
import { HttpCommonService } from 'src/app/services/http-common.service';
import { NotificationService } from './notification.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Finance } from 'src/app/models/finance.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _isPaypalConfigured: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isAchConfigured: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _paymentConfig: Finance;

  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpCommonService,
    private notificationService: NotificationService) { }

  get isPaypalConfigured() {
    return this._isPaypalConfigured;
  }

  get isAchConfigured() {
    return this._isAchConfigured;
  }

  get paymentConfig() {
    return this._paymentConfig;
  }

  public async loadPaymentConfig() {
    let financeConfigRes = await this.http.getCompanyFinanceSettings().toPromise();
    this._paymentConfig = financeConfigRes.body;
    if (this._paymentConfig != null) {
      this.loadPaypalConfig(this._paymentConfig.merchantId);
      this.loadStripeConfig(this._paymentConfig.stripeClientId);
    }
  }

  public loadPaypalConfig(merchantId: string) {
    let paypalScriptElement: HTMLScriptElement = this.document.getElementById('p-script') as HTMLScriptElement;

    if (!paypalScriptElement && merchantId) {
      paypalScriptElement = this.document.createElement('script');
      paypalScriptElement.src = `https://www.paypal.com/sdk/js?client-id=${environment.PAYPAL_CLIENT_ID}&merchant-id=${merchantId}&disable-funding=paylater,card,credit,venmo`;
      paypalScriptElement.id = 'p-script';
      paypalScriptElement.async = true;
      //paypalScriptElement.defer = true;
      paypalScriptElement.setAttribute("data-namespace", "paypal");
      paypalScriptElement.onload = () => this._isPaypalConfigured.next(true);
      paypalScriptElement.onerror = () => this._isPaypalConfigured.next(false);
      this.document.head.appendChild(paypalScriptElement);
    }
  }

  public loadStripeConfig(stripeClientId: string) {
    if (stripeClientId) {
      let stripeScriptElement: HTMLScriptElement = this.document.createElement('script');
      stripeScriptElement.src = `https://js.stripe.com/v3/`;
      stripeScriptElement.id = 'stripe';
      stripeScriptElement.onload = () => this._isAchConfigured.next(true);
      stripeScriptElement.onerror = () => this._isAchConfigured.next(false);
      this.document.head.appendChild(stripeScriptElement);
    }
  }

  public removePaymentConfig() {
    let paypalScriptElement: HTMLScriptElement = this.document.getElementById('paypal') as HTMLScriptElement;
    let stripeScriptElement: HTMLScriptElement = this.document.getElementById('stripe-script') as HTMLScriptElement;
    paypalScriptElement?.remove();
    stripeScriptElement?.remove();
  }
}
