<div class="flex-column" class="attachment-container">

    <div class="folder-upload" *ngIf="section?.canUploadFolder">
        <input type="file" #folderField (change)="validateFolderContent($event.target.files)" hidden webkitdirectory>
        <a (click)="folderField.click()">Upload Folder</a>
    </div>

    <ng-container *ngFor="let element of section.elements;let index=index" [ngSwitch]="element.type">

        <div *ngSwitchCase="'display'" class="checklist" (click)="openAttachmentInfo()">
            <div class="flex-row" class="showMore" matTooltip="{{section.label}}">
                {{section.label}}
            </div>
        </div>

        <div *ngSwitchCase="'file'" class="flex-column attachment-section">
            <div class="flex-row upload-section">
                <ng-container *ngIf="section.isEditable">
                    <button mat-flat-button class="file-upload-button" (click)="getAllFiles()">Refresh</button>

                    <div *ngIf="isSpinner" class="right-margin">
                        <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                    </div>

                    <mat-icon *ngIf="!isSpinner" matTooltip="Refresh to view latest attachments"
                        (click)="getAllFiles()">
                        refresh_sharp
                    </mat-icon>

                    <button mat-flat-button class="file-upload-button" (click)="fileField.click()">Upload Files</button>

                    <div class="dropzone" appFilesDropZone (filesHovered)="toggleHover($event)"
                        (filesDropped)="onDrop($event)" [class.hovering]="isHovering">
                        <input type="file" #fileField (change)="onDrop($event.target.files)" [accept]="accept" hidden
                            multiple>
                        <div class="upload-actions" (click)="fileField.click()">
                            <mat-icon>cloud_upload_outlined</mat-icon>
                            Drop files to attach, or <a>browse</a>
                            <ng-container *ngIf="section.infoText">
                                <mat-icon (click)="toggle(tooltip);$event.stopPropagation()" #tooltip="matTooltip"
                                    matTooltip="{{section.infoText}}">info_outlined
                                </mat-icon>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div [formGroup]="attachmentsForm" class="attachment-grid" [hidden]="!isUploaded">
                <mat-list class="attachment-list">
                    <div mat-subheader class="upload-background">{{clientName}} Uploads</div>
                    <ng-container *ngIf="clientAttachments">
                        <ng-container *ngFor="let el of clientAttachments | keyvalue">
                            <div mat-subheader>{{el.key}}</div>
                            <mat-list-item *ngFor="let attachment of el.value">
                                <mat-icon matListIcon
                                    svgIcon="{{attachment.fileName?.includes('pdf')? 'pdf' : attachment.fileName?.includes('docx')? 'word' : attachment.fileName?.includes('xlsx')? 'excel' : 'text'}}">
                                </mat-icon>
                                <h3 matLine>
                                    <div (click)="downloadFile(attachment)" class="file-name"
                                        [matTooltip]="attachment.fileName">{{attachment.fileName}}
                                    </div>
                                    <div class="flex-row action-icon">
                                        <mat-icon *ngIf="section.isEditable && attachment.canDelete" class="file-delete"
                                            matTooltip="Delete" (click)="deleteFile(attachment)">
                                            delete
                                        </mat-icon>
                                    </div>
                                </h3>
                                <div matLine>
                                    <div class="flex-row comment-section">
                                        <textarea #comment [ngModel]="attachment.comments"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter your comments"
                                            [disabled]="!section.isEditable"></textarea>
                                        <mat-icon *ngIf="attachment.comments !== comment.value" matTooltip="Save"
                                            (click)="updateComments(comment.value, attachment); attachment.comments=comment.value">
                                            done
                                        </mat-icon>
                                        <mat-icon *ngIf="attachment.comments !== comment.value" matTooltip="Cancel"
                                            (click)="comment.value = attachment.comments">
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!clientAttachments">
                        <mat-list-item>
                            <div matLine>
                                No attachments found.
                            </div>
                        </mat-list-item>
                    </ng-container>
                </mat-list>

                <mat-list class="attachment-list">
                    <div mat-subheader class="upload-background">{{firmName}} Uploads</div>
                    <ng-container *ngIf="firmAttachments">
                        <ng-container *ngFor="let el of Object.keys(firmAttachments)">
                            <div mat-subheader>{{el}}</div>
                            <ng-container *ngFor="let attachment of firmAttachments[el]">
                                <mat-list-item *ngIf="!attachment?.isFolder">
                                    <mat-icon matListIcon
                                        svgIcon="{{attachment.fileName?.includes('pdf')? 'pdf' : attachment.fileName?.includes('docx')? 'word' : attachment.fileName?.includes('xlsx')? 'excel' : 'text'}}">
                                    </mat-icon>
                                    <h3 matLine>
                                        <div (click)="downloadFile(attachment)" class="file-name"
                                            [matTooltip]="attachment.fileName">{{attachment.fileName}}
                                        </div>
                                        <div class="flex-row action-icon">
                                            <mat-icon *ngIf="attachment.canSign" svgIcon="sign"
                                                (click)="esign(attachment)" class="icon-ml" matTooltip="E-sign">
                                            </mat-icon>
                                            <mat-icon *ngIf="attachment.canRequestSignature"
                                                (click)="signRequest(attachment)" matTooltip="Request Signature"
                                                class="icon-ml">
                                                assignment_outline</mat-icon>
                                            <mat-icon *ngIf="attachment.canCancelSign" class="icon-ml"
                                                (click)="cancelSignRequest(attachment)"
                                                matTooltip="Cancel Request Signature">
                                                close</mat-icon>
                                            <mat-icon *ngIf="section.isEditable && attachment.canDelete" class="icon-ml"
                                                matTooltip="Delete" (click)="deleteFile(attachment)">
                                                delete
                                            </mat-icon>
                                        </div>
                                    </h3>
                                    <div matLine>
                                        <div class="flex-row comment-section">
                                            <textarea #comment [ngModel]="attachment.comments"
                                                [ngModelOptions]="{standalone: true}" placeholder="Enter your comments"
                                                [disabled]="!section.isEditable"></textarea>
                                            <mat-icon *ngIf="attachment.comments !== comment.value" matTooltip="Save"
                                                (click)="updateComments(comment.value, attachment); attachment.comments=comment.value">
                                                done
                                            </mat-icon>
                                            <mat-icon *ngIf="attachment.comments !== comment.value" matTooltip="Cancel"
                                                (click)="comment.value = attachment.comments">
                                                close
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-list-item *ngIf="attachment.isFolder" class="folder-list">
                                    <mat-list>
                                        <div mat-subheader class="folder-header">
                                            <mat-icon svgIcon="folder"></mat-icon>
                                            <div>{{attachment.folderName}} </div>
                                            <mat-icon class="folder-toggle" (click)="toggleFolderExpansion(attachment)">
                                                {{attachment.isExpanded ? 'expand_more' : 'chevron_right'}}
                                            </mat-icon>
                                        </div>
                                        <ng-container *ngIf="attachment.isExpanded">
                                            <mat-list-item *ngFor="let file of attachment.files">
                                                <mat-icon matListIcon
                                                    svgIcon="{{file.fileName?.includes('pdf')? 'pdf' : file.fileName?.includes('docx')? 'word' : file.fileName?.includes('xlsx')? 'excel' : 'text'}}">
                                                </mat-icon>
                                                <h3 matLine>
                                                    <div (click)="downloadFile(file)" class="file-name"
                                                        [matTooltip]="file.fileName">{{file.fileName}}
                                                    </div>
                                                    <div class="flex-row action-icon">
                                                        <mat-icon *ngIf="file.canSign" svgIcon="sign"
                                                            (click)="esign(file)" class="icon-ml" matTooltip="E-sign">
                                                        </mat-icon>
                                                        <mat-icon *ngIf="file.canRequestSignature"
                                                            (click)="signRequest(file)" matTooltip="Request Signature"
                                                            class="icon-ml">
                                                            assignment_outline</mat-icon>
                                                        <mat-icon *ngIf="file.canCancelSign" class="icon-ml"
                                                            (click)="cancelSignRequest(file)"
                                                            matTooltip="Cancel Request Signature">
                                                            close</mat-icon>
                                                        <mat-icon *ngIf="section.isEditable && file.canDelete"
                                                            class="icon-ml" matTooltip="Delete"
                                                            (click)="deleteFile(file)">
                                                            delete
                                                        </mat-icon>
                                                    </div>
                                                </h3>
                                                <div matLine>
                                                    <div class="flex-row comment-section">
                                                        <textarea #comment [ngModel]="file.comments"
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="Enter your comments"
                                                            [disabled]="!section.isEditable"></textarea>
                                                        <mat-icon *ngIf="file.comments !== comment.value"
                                                            matTooltip="Save"
                                                            (click)="updateComments(comment.value, file); file.comments=comment.value">
                                                            done
                                                        </mat-icon>
                                                        <mat-icon *ngIf="file.comments !== comment.value"
                                                            matTooltip="Cancel" (click)="comment.value = file.comments">
                                                            close
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </ng-container>
                                    </mat-list>
                                </mat-list-item>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!firmAttachments">
                        <mat-list-item>
                            <div matLine>
                                No attachments found.
                            </div>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #attachmentInfo let-data>
    <div class="flex-column check-list-container">
        <ng-container>
            <div *ngIf="!data.html">{{data.content}}</div>
            <div *ngIf="data.html" [innerHTML]="data.content | domSafe"></div>
        </ng-container>
        <mat-icon matTooltip="Close" mat-dialog-close>close</mat-icon>
    </div>
</ng-template>