import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { map, catchError, tap } from 'rxjs/operators';
import { PaymentService } from './payment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
  private BASE_URL = environment.API_SERVER_URL; //'http://ec2-18-188-190-253.us-east-2.compute.amazonaws.com';
  public user: Observable<User | undefined>;


  constructor(
    private router: Router,
    private http: HttpClient,
    private notificationService: NotificationService, private paymentService: PaymentService
  ) {
    let loggedUser = localStorage.getItem('user');
    if (loggedUser !== null) {
      let parseUser: User | undefined = JSON.parse(loggedUser) || undefined;
      this.userSubject = new BehaviorSubject<User | undefined>(parseUser);
    }

    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User | undefined {
    return this.userSubject.value;
  }

  login(user: any, subdomain: string, recaptcha: string, isMfaEnabled: boolean = true): Observable<any> {
    if (isMfaEnabled) {
      return this.http.post<any>(`${this.BASE_URL}/api/v1/user/mfa?g-recaptcha-response=${recaptcha}&identifier=${subdomain}`, user, { observe: 'response' })
        .pipe(map((res) => res.body.token));
    } else {
      return this.http.post<any>(`${this.BASE_URL}/api/v1/user/login?g-recaptcha-response=${recaptcha}&identifier=${subdomain}`, user, { observe: 'response' })
        .pipe(map((res: HttpResponse<User>) => {
          return this.handleAuthResponse(res);
        }),
          catchError((error) => {
            return throwError(error);
          }));;
    }
  }

  mfaConfirm(optData: any, subdomain: string) {
    return this.http.post<User>(`${this.BASE_URL}/api/v1/user/otp`, optData, { observe: 'response' }).pipe(
      map((res: HttpResponse<User>) => {
        return this.handleAuthResponse(res);
      }),
      catchError((error) => {
        return throwError(error.error);
      })
    );
  }

  handleAuthResponse(res: HttpResponse<User>) {
    if (res.status !== 200) {
      this.router.navigateByUrl('/login');
      this.notificationService.notifyText('Error occurred during login. Please try after some time.');
    }
    let loginUser = res.body;
    if (loginUser !== null) {
      localStorage.setItem('user', JSON.stringify(loginUser));
      if (loginUser?.deviceToken) {
        localStorage.setItem('deviceToken', loginUser.deviceToken);
      }
      this.userSubject.next(loginUser);
    } else {
      this.notificationService.notifyText('Error occurred during login. Please try after some time.');
      this.router.navigateByUrl('/login');
    }
    return res.body;
  }

  resendOtp(otpToken: any) {
    return this.http.post<any>(`${this.BASE_URL}/api/v1/user/resend-otp`, otpToken, { observe: 'response' });
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(undefined);
    this.paymentService.removePaymentConfig();
    this.router.navigateByUrl('/login');
  }

  refreshToken() {
    let userdata = localStorage.getItem('user');
    let parseUser: User | undefined = JSON.parse(userdata) || undefined;
    return this.http.post<any>(`${this.BASE_URL}/api/v1/user/refreshtoken`, {
      refreshToken: parseUser.refreshToken,
    }).pipe(
      tap((res: any) => {
        parseUser = JSON.parse(userdata);
        parseUser.jwtToken = res.jwtToken;
        parseUser.refreshToken = res.refreshToken;
        localStorage.setItem('user', JSON.stringify(parseUser));
        this.userSubject.next(parseUser);
      })
    );
  }

  generateForgotPasswordLink(data: any, subdomain: string, token: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.BASE_URL}/api/v1/user/resetPassword?g-recaptcha-response=${token}&identifier=${subdomain}`,
      data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }
}