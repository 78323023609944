<div role="group" class="number-range-input-container" [formGroup]="parts"
    [attr.aria-labelledby]="_formField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
    <input class="number-range-input-element" [placeholder]="isFocused?'Minimum':''" formControlName="min" #min
        [autofocus]="!min?.value" (input)="_handleInput()" />

    <span class="number-range-input-separator" [class.number-range-input-separator-hidden]="_shouldHideSeparator()">
        {{separator}} </span>

    <input class="number-range-input-element" [placeholder]="isFocused?'Maximum':''" formControlName="max" #max
        (input)="_handleInput()" />
</div>