import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BillingListModel } from '../models/billing-list.model';
import { HttpCommonService } from '../services/http-common.service';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../shared/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { AuthenticationService } from '../shared/services/authentication.service';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { FileHandlerService } from '../shared/services/file-handler.service';
import { DOCUMENT } from '@angular/common';
import { PAYMENT_METHOD } from '../shared/constants/app.constants';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-manage-billing',
  templateUrl: './manage-billing.component.html',
  styleUrls: ['./manage-billing.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class ManageBillingComponent implements OnInit, ComponentCanDeactivate {
  isLoading = true;
  isError = false;
  header: string;
  billingForm: FormGroup;
  billData: BillingListModel;
  errorsList: string[] = [];
  scheduleId: string;
  role: boolean;
  paymentMethods = PAYMENT_METHOD;
  maxDate = new Date();
  isSpinner = false;

  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpCommonService, private authenticationService: AuthenticationService,
    private notificationService: NotificationService, private router: Router, private activatedRoute: ActivatedRoute,
    private fhService: FileHandlerService) {
    this.scheduleId = this.activatedRoute.snapshot.params.scheduleId;
    this.role = this.authenticationService.userValue.roles.some(role => ['firmadmin', 'firmemployee'].includes(role));
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getBillingDetails();
  }

  initializeForm() {
    this.billingForm = new FormGroup({
      invoiceNumber: new FormControl({ value: '', disabled: true }),
      paymentStatus: new FormControl({ value: '', disabled: true }),
      scheduleStartDate: new FormControl({ value: '', disabled: true }),
      scheduleDueDate: new FormControl(''),
      comments: new FormControl(''),
      scheduleAmount: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required])),
      tax: new FormControl({ value: '', disabled: true }),
      total: new FormControl({ value: '', disabled: true }),
      paymentMethod: new FormControl('', Validators.compose([Validators.required])),
      paymentReference: new FormControl('', Validators.compose([Validators.required])),
      paymentDate: new FormControl(moment(new Date()), Validators.compose([Validators.required])),
    });
  }

  getBillingDetails() {
    this.http.getBillingScheduleDetailsForScheduleId(this.scheduleId).subscribe((res: HttpResponse<any>) => {
      if (res.body !== null) {
        this.billData = res.body;
        this.header = this.billData.serviceName;
        this.billingForm.patchValue(this.billData);
        this.billingForm.controls['total'].patchValue(this.billData.scheduleAmount);
        this.billingForm.controls['scheduleStartDate'].setValue(moment(this.billData.scheduleStartDate));
        this.billingForm.controls['scheduleDueDate'].setValue(this.billData.scheduleDueDate ? moment(this.billData.scheduleDueDate) : null);
        if (!this.role) {
          this.billingForm.disable();
        }
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.notificationService.notifyText("Unable to fetch Schedule details");
    });
  }

  markAsPaid() {
    this.billingForm.markAllAsTouched();
    this.errorsList = [];
    if (this.billingForm.valid) {
      this.isSpinner = true;
      let billingData = this.billingForm.getRawValue();
      let paymentData = { invoiceId: '', comments: '', paymentMethod: '', paymentReference: '', paymentStatus: '', paymentDate: '' };
      paymentData.invoiceId = this.billData.invoiceId;
      paymentData.comments = billingData.comments;
      paymentData.paymentMethod = billingData.paymentMethod;
      paymentData.paymentReference = billingData.paymentReference;
      paymentData.paymentStatus = billingData.paymentStatus;
      paymentData.paymentDate = formatMomentDate(billingData.paymentDate);

      this.http.updateBillingPayment(paymentData).subscribe((res: HttpResponse<any>) => {
        this.isSpinner = false;
        if (res.status === 200) {
          this.notificationService.notifyText("Invoice is marked as paid!!");
          this.billingForm.reset();
          this.router.navigate([`../../list`], { relativeTo: this.activatedRoute });
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    }
  }

  viewInvoice() {
    this.isSpinner = true;
    this.http.viewInvoiceByInvoiceId(this.billData.invoiceId).subscribe((data: any) => {
      let file = new Blob([data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      this.isSpinner = false;
      window.open(fileURL, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=600,width=800,scrollbars=yes,status=yes');
    });
  }

  viewInvoice1() {
    let url = `/api/v1/client/subscription/billing/invoice/download/${this.billData.invoiceId}`;
    this.isSpinner = true;
    this.fhService.downloadFile(url).subscribe(res => {
      let blob: Blob = res;
      let downloadlink = this.document.createElement('a');
      downloadlink.href = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
      downloadlink.download = `Invoice - ${this.billData.invoiceId}.pdf`;
      downloadlink.click();
      this.isSpinner = false;
    }, err => {
      this.isSpinner = false;
      this.notificationService.notifyText(`Could not download the invoice`, "X");
      console.log("Error:", err);
    });
  }

  canDeactivate() {
    return !this.billingForm.dirty;
  }

  updatePayment(payment: any, invoiceId: string) {
    if (payment.status === 'SUCESS') {
      this.isSpinner = true;
      this.notificationService.notifyText(`Paypal payment is successful for invoice id ${invoiceId}`);
      let data = { invoiceId: invoiceId, paymentReference: JSON.stringify(payment) };
      this.http.updateMarkAsPaid(data).subscribe((res: HttpResponse<any>) => {
        this.isSpinner = false;
        if (res.status === 200) {
          setTimeout(() => {
            this.notificationService.notifyText(`Invoice payment status updated successfully`);
          }, 3000);
          this.billingForm.reset();
          this.router.navigate([`../../list`], { relativeTo: this.activatedRoute });
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Invoice payment status updated failed. Please contact CPA`);
        console.log(err);
      });
    } else {
      this.notificationService.notifyText(`Payment failed for invoice id ${invoiceId}`);
    }
  }
}
