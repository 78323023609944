import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  addressComponent = new Subject<any | undefined>();

  constructor() { }

  getAddressAutoComplete(addressText: any, addressType: string[] = [], country: string = '') {
    let address: any;
    const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement, {
      types: addressType
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      address = autocomplete.getPlace();
      this.addressComponent.next(address);
    });
  }

  getSelectedAddress(): Observable<any | undefined> {
    return this.addressComponent;
  }
}
