<div class="flex-row ai-c mb-8">
    <h2 class="scj-h2 mr-20">New Client</h2>

    <div class="flex-row">
        <button mat-flat-button (click)="onSubmit()">
            <mat-icon class="button-icon">add</mat-icon>
            <span class="button-text">Add</span>
        </button>
        <button mat-flat-button class="secondary" routerLink="/firm/clients-list">
            <mat-icon class="button-icon">close</mat-icon>
            <span class="button-text">Cancel</span>
        </button>
        <div *ngIf="isSpinner">
            <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
        </div>
    </div>
</div>

<mat-radio-group [formControl]="businessFiling">
    <mat-radio-button [value]="false">Individual</mat-radio-button>
    <mat-radio-button [value]="true">Organization</mat-radio-button>
</mat-radio-group>

<scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

<form [formGroup]="newClientForm" class="flex-row" autocomplete="off">

    <div class="flex-column right-margin">

        <div *ngIf="isBusiness" class="flex-column">

            <mat-form-field appearance="outline" class="top-margin">
                <mat-label>Organization Name</mat-label>
                <input type="text" matInput placeholder="Enter business name" formControlName="businessName" required>
                <mat-error *ngFor="let validator of errorMessages.businessName">
                    <mat-error
                        *ngIf="newClientForm.get('businessName').hasError(validator.type) && (newClientForm.get('businessName').dirty || newClientForm.get('businessName').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>EIN Number</mat-label>
                <input placeholder="Enter EIN number" matInput formControlName="einNumber">
            </mat-form-field>
        </div>

        <div class="flex-column">
            <div *ngIf="isBusiness" class="scj-subheader contact-details">Primary User</div>

            <mat-form-field appearance="outline" class="top-margin">
                <mat-label>Full Name</mat-label>
                <input type="text" placeholder="Enter your full name" matInput formControlName="fullName" required>
                <mat-error *ngFor="let validator of errorMessages.fullName">
                    <mat-error
                        *ngIf="newClientForm.get('fullName').hasError(validator.type) && (newClientForm.get('fullName').dirty || newClientForm.get('fullName').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Preferred Name</mat-label>
                <input type="text" placeholder="Enter your preferred name" matInput formControlName="prefferedName">
                <mat-error *ngFor="let validator of errorMessages.prefferedName">
                    <mat-error
                        *ngIf="newClientForm.get('prefferedName').hasError(validator.type) && (newClientForm.get('prefferedName').dirty || newClientForm.get('prefferedName').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Contact Email</mat-label>
                <input type="text" placeholder="Enter contact email" matInput formControlName="username" required>
                <mat-error *ngFor="let validator of errorMessages.username">
                    <mat-error
                        *ngIf="newClientForm.get('username').hasError(validator.type) && (newClientForm.get('username').dirty || newClientForm.get('username').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Contact Phone Number</mat-label>
                <input type="text" placeholder="Enter your contact phone number" matInput formControlName="phoneNumber"
                    required>
                <mat-error *ngFor="let validator of errorMessages.phoneNumber">
                    <mat-error
                        *ngIf="newClientForm.get('phoneNumber').hasError(validator.type) && (newClientForm.get('phoneNumber').dirty || newClientForm.get('phoneNumber').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div formGroupName="clientAddress" class="top-margin flex-column">
            <div class="scj-subheader contact-details">Address</div>

            <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select placeholder="Select Country" [formControl]="country" disableOptionCentering>
                    <mat-option *ngFor="let el of countries" [value]="el.COUNTRY_CODE">{{el.COUNTRY_NAME}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Addess</mat-label>
                <input type="text" #addressText placeholder="Enter your address" matInput
                    formControlName="addressLine1">
                <mat-error *ngFor="let validator of errorMessages.clientAddress?.addressLine1">
                    <mat-error
                        *ngIf="newClientForm.get('clientAddress').get('addressLine1').hasError(validator.type) && (newClientForm.get('clientAddress').get('addressLine1').dirty || newClientForm.get('clientAddress').get('addressLine1').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input type="text" placeholder="Enter city" matInput formControlName="city">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>State/Province</mat-label>
                <input type="text" placeholder="Enter state/province" matInput formControlName="state">
                <mat-error *ngFor="let validator of errorMessages.clientAddress?.state">
                    <mat-error
                        *ngIf="newClientForm.get('clientAddress').get('state').hasError(validator.type) && (newClientForm.get('clientAddress').get('state').dirty || newClientForm.get('clientAddress').get('state').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Zip</mat-label>
                <input type="text" #zip placeholder="Enter zip" matInput formControlName="zip">
                <mat-error *ngFor="let validator of errorMessages.clientAddress?.zip">
                    <mat-error
                        *ngIf="newClientForm.get('clientAddress').get('zip').hasError(validator.type) && (newClientForm.get('clientAddress').get('zip').dirty || newClientForm.get('clientAddress').get('zip').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="isBusiness" class="flex-column">
            <div class="scj-subheader contact-details">Organization Details</div>

            <mat-form-field appearance="outline">
                <mat-label>Org Email</mat-label>
                <input type="email" placeholder="Enter business email" matInput formControlName="businessEmail">
                <mat-error *ngFor="let validator of errorMessages.businessEmail">
                    <mat-error
                        *ngIf="newClientForm.get('businessEmail').hasError(validator.type) && (newClientForm.get('businessEmail').dirty || newClientForm.get('businessEmail').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Org Phone Number</mat-label>
                <input type="text" placeholder="Enter business phone number" matInput
                    formControlName="businessPhonenumber">
                <mat-error *ngFor="let validator of errorMessages.businessPhonenumber">
                    <mat-error
                        *ngIf="newClientForm.get('businessPhonenumber').hasError(validator.type) && (newClientForm.get('businessPhonenumber').dirty || newClientForm.get('businessPhonenumber').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Org Website</mat-label>
                <input type="url" placeholder="Enter business website" matInput formControlName="businessWebsite">
                <mat-error *ngFor="let validator of errorMessages.businessWebsite">
                    <mat-error
                        *ngIf="newClientForm.get('businessWebsite').hasError(validator.type) && (newClientForm.get('businessWebsite').dirty || newClientForm.get('businessWebsite').touched)">
                        {{validator.message}}</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="flex-column">
        <mat-form-field appearance="outline" class="text-area">
            <mat-label>Notes</mat-label>
            <textarea type="text" matInput formControlName="notes" placeholder="Notes" cdkTextareaAutosize
                cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"></textarea>
        </mat-form-field>

        <div style="display: flex;align-items: flex-end;" class="contact-details">
            <mat-icon class="padding-right"> add_shopping_cart</mat-icon>
            <div class="scj-subheader"> Add a Service </div>
        </div>
        <mat-form-field appearance="outline" class="top-margin">
            <mat-label>Service</mat-label>
            <input type="text" matInput formControlName="serviceId" [matAutocomplete]="auto"
                placeholder="Select service">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <ng-container *ngIf="(serviceList$ | async) as list">
                    <ng-container *ngIf="list?.length === 0">
                        <mat-option disabled>
                            No Service Data Found
                        </mat-option>
                    </ng-container>

                    <ng-container *ngIf="list?.length !== 0">
                        <mat-option *ngFor="let service of list" [value]="service.id">
                            {{ service.name }}
                        </mat-option>
                    </ng-container>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
    </div>

</form>