import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Finance } from '../models/finance.model';
import { HttpCommonService } from '../services/http-common.service';
import { PAYMENT_TERM } from '../shared/constants/app.constants';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-firm-finance',
  templateUrl: './firm-finance.component.html',
  styleUrls: ['./firm-finance.component.scss']
})
export class FirmFinanceComponent implements OnInit, ComponentCanDeactivate {
  isHandset: boolean;
  disable: boolean = false;
  isLoading = true;
  isSpinner = false;
  isError = false;
  financeForm: FormGroup;
  errorsList: string[] = [];
  paymentTerm = PAYMENT_TERM;
  stripeRequired: boolean = false;

  constructor(private authService: AuthenticationService, private handSet: HandSetService, private http: HttpCommonService,
    private notificationService: NotificationService, private cdRef: ChangeDetectorRef) {
    this.isHandset = this.handSet.isHandset;
    this.disable = !this.authService.userValue.roles.includes('firmadmin');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getFirmFinance();

    let stripeSecretControl = this.financeForm.controls['stripeSecret'];
    let stripeClientIdControl = this.financeForm.controls['stripeClientId'];

    stripeClientIdControl.valueChanges.subscribe((val: string) => {
      if (val?.length > 0) {
        this.stripeRequired = true;
        stripeClientIdControl.addValidators(Validators.required);
      } else {
        val = stripeSecretControl.value;
        if (val?.length > 0) {
          this.notificationService.notifyText('Remove Stripe secret to deactivate ACH payment.', 'X');
        } else {
          this.stripeRequired = false;
          stripeSecretControl.clearValidators();
          stripeClientIdControl.clearValidators();
        }
      }
      this.cdRef.detectChanges();
    });

    stripeSecretControl.valueChanges.subscribe((val: string) => {
      if (val?.length > 0) {
        this.stripeRequired = true;
        stripeSecretControl.addValidators(Validators.required);
      } else {
        val = stripeClientIdControl.value;
        if (val?.length > 0) {
          this.notificationService.notifyText('Remove Stripe client id to deactivate ACH payment.', 'X');
        } else {
          this.stripeRequired = false;
          stripeSecretControl.clearValidators();
          stripeClientIdControl.clearValidators();
        }
      }
      this.cdRef.detectChanges();
    });
  }

  initializeForm() {
    this.financeForm = new FormGroup({
      defaultPaymentTerm: new FormControl({ value: '', disabled: this.disable }, Validators.compose([])),
      merchantId: new FormControl({ value: '', disabled: this.disable }),
      stripeClientId: new FormControl({ value: '', disabled: this.disable }),
      stripeSecret: new FormControl({ value: '', disabled: this.disable })
    });
  }

  getFirmFinance() {
    this.http.getCompanyFinanceSettings().subscribe((res: HttpResponse<Finance>) => {
      if (res.body !== null) {
        this.financeForm.patchValue(res.body);
        //this.updateValidators();
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.notificationService.notifyText("Error occurred while fetching email sync details.", "X");
      console.log("error", err);
    });
  }

  onSubmit() {
    this.financeForm.markAllAsTouched();
    this.errorsList = [];
    if (this.financeForm.valid) {
      this.isSpinner = true;
      let financeFormRawValue = this.financeForm.getRawValue() as Finance;
      this.http.updateCompanyFinanceSettings(financeFormRawValue).subscribe((res: HttpResponse<Finance>) => {
        if (res.status === 200) {
          this.notificationService.notifyText("Firm finance details updated successfully!!");
          let formValue = this.financeForm.getRawValue();
          this.financeForm.reset(formValue);
          this.isSpinner = false;
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    } else {

    }
  }

  canDeactivate() {
    return !this.financeForm.dirty;
  }
}
