<div class="flex-row jc-sb-ai-c">
    <h1 class="scj-h2 mr-20">Agreements</h1>

    <div class="search-filter">
        <div class="search-field">
            <mat-icon>search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>

        <button class="filter-button" (click)="openTableFilter()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>


</div>

<!-- <div class="flex-row jc-sb-ai-c">
    <h2 class="scj-h2">Agreements</h2>

    <div class="search-filter">
        <div class="search-field">
            <mat-icon>search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>

        <button class="filter-button" (click)="openTableFilter()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>
</div> -->

<div class="search-field" *ngIf="isHandset$ | async">
    <mat-icon>search</mat-icon>
    <input type="text" [formControl]="searchControl">
</div>


<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <scj-table-content [data]="agreementList" [searchControl]="searchControl" [columnConfig]="columnConfig"
            defaultSortColumn="creationDate" [pageSizeOptions]="[20, 40, 60, 80, 100]" exportFileName="My Agreements"
            (navigate)="navigate($event)">


            <button primaryAction mat-flat-button routerLink="../create">
                <mat-icon class="button-icon">add</mat-icon>
                <span class="button-text">New</span>
            </button>

        </scj-table-content>
    </div>
</div>