import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { HandSetService } from '../shared/services/hand-set.service';
import { Observable } from 'rxjs';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ServicesListModel } from '../models/services-list.model';
import { NewServiceModel } from '../models/new-service.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { NewAgreementModel } from '../models/new-agreement.model';
import { NotificationService } from '../shared/services/notification.service';
import { FormControl, FormArray, FormGroup } from '@angular/forms';
import { ClientSubscriptionsListModel } from '../models/client-subscription-list.model';
import { InvoiceDetailsModel } from '../models/invoice-details.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import { DataService } from '../data-service.service';
import { CompanyDetailsModel } from '../models/company-details.model';
import { PaymentService } from '../shared/services/payment.service';
import { ESignComponent } from '../e-sign/e-sign.component';

@Component({
  selector: 'scj-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.scss']
})
export class ClientHomeComponent implements OnInit,AfterContentInit {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isActiveServicesLoading = true;
  isError = false;
  activeServices: ClientSubscriptionsListModel[] = [];
  availableServices: any[] = [];
  servicesForm: FormArray;
  invoiceIndex: number = 0;
  invoiceList: InvoiceDetailsModel[] = [];
  clientId: string;
  isSpinner: boolean = false;
  companyList: CompanyDetailsModel;
  isAchConfigured: boolean = false;
  isPaypalConfigured: boolean = false;

  constructor(private handSet: HandSetService, private http: HttpCommonService, private dialog: MatDialog, private dataService: DataService,
    private notificationService: NotificationService, private authenticationService: AuthenticationService, private paymentService: PaymentService,
    private router: Router) {
    this.isHandset$ = this.handSet.isHandset$;
    this.clientId = this.authenticationService.userValue.clientId;
    this.isAchConfigured = this.paymentService.isAchConfigured.value;
    this.isPaypalConfigured = this.paymentService.isPaypalConfigured.value;
  }

  ngOnInit(): void {
    this.getActiveServices();
   // this.getAvailableServices();
    this.getInvoiceDueDetailsByClientId();
    this.getCompanyDetails();
  }

  ngAfterContentInit(): void {
   // console.log("content init")
    this.paymentService.isAchConfigured.subscribe((val: boolean) => {
      this.isAchConfigured = val;
    });

    this.paymentService.isPaypalConfigured.subscribe((val: boolean) => {
      this.isPaypalConfigured = val;
    });
  }

  initializeForm() {
    this.servicesForm = new FormArray([]);
    this.availableServices.forEach((e: NewServiceModel) => {
      let group: FormGroup;
      if (e.tcAgreementId && e.srvcAgreementId) {
        group = new FormGroup({
          tcAgreement: new FormControl(false),
          svrAgreement: new FormControl(false)
        });
      } else if (e.tcAgreementId) {
        group = new FormGroup({
          tcAgreement: new FormControl(false),
          svrAgreement: new FormControl(null)
        });
      } else if (e.srvcAgreementId) {
        group = new FormGroup({
          tcAgreement: new FormControl(null),
          svrAgreement: new FormControl(false)
        });
      } else {
        group = new FormGroup({
          tcAgreement: new FormControl(null),
          svrAgreement: new FormControl(null)
        });
      }
      this.servicesForm.push(group);
    });
  }

  getActiveServices() {
    this.http.getClientSubscriptionsList().subscribe((res: HttpResponse<ClientSubscriptionsListModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.activeServices = res.body.sort((a, b) => b?.subscriptionId - a?.subscriptionId);
        this.activeServices = this.activeServices.slice(0, 5);
        this.activeServices = this.activeServices.map(x => {
          if (x.pendingCpaAction && x.status !== 'COMPLETED' && x.status !== 'CANCEL') {
            x.status = 'PENDING_CPA'
          } else if (!x.pendingCpaAction && x.status !== 'COMPLETED' && x.status !== 'CANCEL') {
            x.status = 'PENDING_CLIENT'
          }
          return x;
        });
      }
      this.isActiveServicesLoading = false;
    }, (err: HttpResponse<any>) => {
      this.isActiveServicesLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }

  getAvailableServices() {
    this.http.getAvailableServicesList().subscribe((res: HttpResponse<ServicesListModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.availableServices = res.body;
        this.initializeForm();
      }
    }, (err: HttpResponse<any>) => {
      console.log("Error", err);
    });
  }

  loadAgreementData(control: FormControl, agreementId: string) {
    this.http.getAgreementById(agreementId).subscribe((res: HttpResponse<NewAgreementModel>) => {
      if (res.body !== null) {
        this.alertDialog(control, res.body.agreementName, res.body.agreementText);
      }
    }, (err: HttpResponse<any>) => {
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
      console.log("Error", err);
    });
  }

  alertDialog(control: FormControl, header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.minHeight = '40%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true
    }

    let dialogRef = this.dialog.open(AlertMessagesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      //control.setValue(confirm);
    });
  }

  validSubscribe(i: number): boolean {

    let tcAgreement = this.servicesForm.controls[i].get('tcAgreement').value;
    let svrAgreement = this.servicesForm.controls[i].get('svrAgreement').value;

    let service = this.availableServices[i];
    if (service.tcAgreementId && service.srvcAgreementId) {
      if (tcAgreement && svrAgreement)
        return false;
      else
        return true;
    }

    if (service.tcAgreementId) {
      if (tcAgreement)
        return false;
      else
        return true;
    }

    if (service.srvcAgreementId) {
      if (svrAgreement)
        return false;
      else
        return true;
    }
    return false;
  }

  subscribe(id: string) {
    let subscription = { serviceId: id };
    this.isSpinner = true;
    this.http.createClientSubscription(subscription).subscribe((res: string) => {
      this.isSpinner = false;
      this.notificationService.notifyText("Service is subscribed successfully");
      this.router.navigateByUrl(`/client/subscription/${res.split('"')[1]}`);
    }, (err: HttpResponse<any>) => {
      this.isSpinner = false;
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
      console.log("Error", err);
    });
  }

  getInvoiceDueDetailsByClientId() {
    this.http.getInvoiceDueDetailsByClientId(this.clientId).subscribe((res: HttpResponse<InvoiceDetailsModel[]>) => {
      if (res.body !== null) {
        this.invoiceList = res.body;
      }
    }, (err: HttpResponse<any>) => {
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
    });
  }

  getCompanyDetails() {
    this.http.getCompanyDetails().subscribe((res: HttpResponse<CompanyDetailsModel>) => {
      if (res.body !== null) {
        this.companyList = res.body;
        this.isLoading = false;
      }
    }, (err: HttpResponse<any>) => {
      this.notificationService.notifyText("Something went wrong!! Please try after some time");
    })
  }

  prev() {
    if (this.invoiceIndex > -1)
      this.invoiceIndex -= 1;
  }

  next() {
    if (this.invoiceIndex < this.invoiceList.length - 1) {
      this.invoiceIndex += 1;
    }
  }

  markAsPaid(payment: any, invoiceId: string, invoiceNum: number) {
    if (payment.status === 'SUCESS') {
      this.notificationService.notifyText(`Paypal payment is successful for invoice id ${invoiceNum}`);
      let data = { invoiceId: invoiceId, paymentReference: JSON.stringify(payment) };
      this.http.updateMarkAsPaid(data).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.notificationService.notifyText(`Invoice ${invoiceNum} payment status updated successfully`);
            //this.getInvoiceDueDetailsByClientId();
            this.invoiceList.splice(this.invoiceIndex, 1);
            this.invoiceIndex = 0;
          }, 3000);
        }
      }, (err: HttpErrorResponse) => {
        this.notificationService.notifyText(`Failed to update payment status for Invoice ${invoiceNum}. Contact support`, 'X')
        console.log(err);
      });
    } else {
      this.notificationService.notifyText(`Payment failed for invoice id ${invoiceNum}`);
    }
  }

  markAsPaidAch(status: string) {
    if (status === 'SUCCESS') {
      this.invoiceList.splice(this.invoiceIndex, 1);
      this.invoiceIndex = 0;
    }
  }


  esign(element: any) {
    this.isLoading = true;
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '50vw'
    dialogConfig.width =  'auto';
    dialogConfig.maxWidth ="80vw";
    dialogConfig.height = '100%';
    dialogConfig.maxHeight ='90vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "esign-modal";
    dialogConfig.data = {
      attachment: element
    }

    this.dialog.open(ESignComponent, dialogConfig).afterClosed().subscribe((signReq: any) => {
      if (signReq) {
        this.http.signAttachment(signReq).subscribe((res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.notificationService.notifyText(`Attachment ${element.fileName} E-signed successfully.`, 'X');
            this.getActiveServices();
            this.isLoading = false;
          }
        }, (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.notificationService.notifyText('Error occurred during the esign.', "X");
        });
      }
    });
  }


  esign1(record: any) {
    let element = {
      id: record.esignAttachmentId,
      signatureRequired: record.signatureRequired,
      spouseSignatureRequired: record.spouseSignatureRequired,
      cpaSignatureRequired: record.cpaSignatureRequired,
      isSigned: record.isSigned,
      isSpouseSigned: record.isSpouseSigned,
      isCpaSigned: record.isCpaSigned
    };
    this.dataService.esign(element).subscribe((reload: boolean) => {
      if (reload) {
        this.getActiveServices();
      }
    });
  }

  expand(index: number) {
    let elSelected = document.getElementsByClassName('is-selected');
    let elExpanded = document.getElementsByClassName('fullwidth');
    if (elSelected.length > 0) {
      for (let i = 0; i < elSelected.length; i++) {
        elSelected[i]?.classList.toggle('is-selected');
      }
    }

    for (let i = 0; i < elExpanded.length; i++) {
      if (!elExpanded[i]?.classList.contains('is-hidden'))
        elExpanded[i]?.classList.toggle('is-hidden')
    }

    let elByTileId = document.getElementById(`tile-${index}`);
    let elByTileExpandId = document.getElementById(`tile-expand-${index}`);
    elByTileId.classList.toggle('is-selected');
    elByTileExpandId.classList.toggle('is-hidden');
  }

  cancel(index: number) {
    document.getElementsByClassName('is-selected').item(0).classList.toggle('is-selected');
    document.getElementsByClassName('fullwidth').item(index).classList.toggle('is-hidden');
  }
}
