<h2 class="scj-h2">{{header}}</h2>

<mat-dialog-content>
    <div class="flex-row mt-12">
        <mat-form-field appearance="outline" class="mr-12">
            <mat-label>Service</mat-label>
            <input type="text" matInput [formControl]="serviceControl" [matAutocomplete]="auto"
                placeholder="Select service" required>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
                (optionSelected)="selectedService($event)">
                <ng-container *ngIf="(serviceList$ | async) as list">
                    <ng-container *ngIf="list?.length === 0">
                        <mat-option disabled>
                            No Service Data Found
                        </mat-option>
                    </ng-container>

                    <ng-container *ngIf="list?.length !== 0">
                        <mat-option *ngFor="let service of list" [value]="service.id">
                            {{ service.name }}
                        </mat-option>
                    </ng-container>
                </ng-container>
            </mat-autocomplete>
            <mat-error *ngIf="serviceControl.hasError('required') && (serviceControl.dirty || serviceControl.touched)">
                Service is required
            </mat-error>
        </mat-form-field>

        <div>
            <button mat-flat-button (click)="assign()" [disabled]="!serviceName">
                <span class="button-text">Assign Service</span>
            </button>
            <button mat-flat-button class="secondary" [mat-dialog-close]="false">
                <span class="button-text">Cancel</span>
            </button>
        </div>
    </div>
    <div *ngIf="serviceName" class="service-assignment-info">
        You are about to assign the service to <span style="font-weight: 700;">{{clientList.length}}</span> clients:
        <ol>
            <li *ngFor="let client of clientList">{{client.name}}</li>
        </ol>
    </div>
</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
    <button mat-flat-button (click)="assign()" [disabled]="serviceControl.invalid">
        <span class="button-text">Assign Service</span>
    </button>
    <button mat-flat-button class="secondary" [mat-dialog-close]="false">
        <span class="button-text">Cancel</span>
    </button>
</mat-dialog-actions> -->