<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">

        <div class="flex-row ai-c mb-8">
            <h2 class="scj-h2 mr-20" *ngIf="!userId">New User</h2>

            <h2 class="scj-h2 mr-20" *ngIf="userId">
                <span class="text-header">User - </span>
                {{header}}
            </h2>

            <div class="flex-row">
                <button mat-flat-button *ngIf="sendEmail" (click)="resendActivationMail()">
                    <mat-icon class="button-icon">email</mat-icon>
                    <span class="button-text">Resend Activation Link</span>
                </button>
                <button mat-flat-button [class.secondary]="sendEmail" (click)="onSubmit()">
                    <mat-icon class="button-icon">save_alt_outlined</mat-icon>
                    <span class="button-text">Save</span>
                </button>
                <button mat-flat-button class="secondary" (click)="cancel()">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>

                <div *ngIf="sendEmailSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form [formGroup]="userForm" class="new-user-form" autocomplete="off">
            <div class="flex-row details">
                <div class="flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Full Name</mat-label>
                        <input type="text" matInput placeholder="Enter your full name" formControlName="fullname"
                            required>
                        <mat-error *ngFor="let validator of errorMessages.fullname">
                            <mat-error
                                *ngIf="userForm.get('fullname').hasError(validator.type) && (userForm.get('fullname').dirty || userForm.get('fullname').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Preferred Name</mat-label>
                        <input type="url" placeholder="Enter your preferred name" matInput
                            formControlName="prefferedName">
                        <mat-error *ngFor="let validator of errorMessages.prefferedName">
                            <mat-error
                                *ngIf="userForm.get('prefferedName').hasError(validator.type) && (userForm.get('prefferedName').dirty || userForm.get('prefferedName').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <div *ngIf="userId">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select placeholder="Status" formControlName="status" disableOptionCentering>
                                <mat-option *ngFor="let stat of userStatus" [value]="stat.statKey">{{stat.statValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngFor="let validator of errorMessages.status">
                                <mat-error
                                    *ngIf="userForm.get('status').hasError(validator.type) && (userForm.get('status').dirty || userForm.get('status').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="email" placeholder="Enter your email" matInput formControlName="username" required>
                        <mat-error *ngFor="let validator of errorMessages.username">
                            <mat-error
                                *ngIf="userForm.get('username').hasError(validator.type) && (userForm.get('username').dirty || userForm.get('username').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input type="text" placeholder="Enter your phone number" matInput formControlName="phoneNumber">
                        <mat-error *ngFor="let validator of errorMessages.phoneNumber">
                            <mat-error
                                *ngIf="userForm.get('phoneNumber').hasError(validator.type) && (userForm.get('phoneNumber').dirty || userForm.get('phoneNumber').touched)">
                                {{validator.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <div formGroupName="address" class="flex-column">
                        <mat-form-field appearance="outline">
                            <mat-label>Country</mat-label>
                            <mat-select placeholder="Select Country" [formControl]="country" disableOptionCentering>
                                <mat-option *ngFor="let el of countries" [value]="el.COUNTRY_CODE">{{el.COUNTRY_NAME}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Addess</mat-label>
                            <input type="text" #addressText placeholder="Enter your address" matInput
                                formControlName="addressLine1">
                            <mat-error *ngFor="let validator of errorMessages.address.addressLine1">
                                <mat-error
                                    *ngIf="userForm.get('address').get('addressLine1').hasError(validator.type) && (userForm.get('address').get('addressLine1').dirty || userForm.get('address').get('addressLine1').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input type="text" placeholder="Enter city" matInput formControlName="city">
                            <mat-error *ngFor="let validator of errorMessages.address.city">
                                <mat-error
                                    *ngIf="userForm.get('address').get('city').hasError(validator.type) && (userForm.get('address').get('city').dirty || userForm.get('address').get('city').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>State/Province</mat-label>
                            <input type="text" placeholder="Enter state/province" matInput formControlName="state">
                            <mat-error *ngFor="let validator of errorMessages.address.state">
                                <mat-error
                                    *ngIf="userForm.get('address').get('state').hasError(validator.type) && (userForm.get('address').get('state').dirty || userForm.get('address').get('state').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Zip</mat-label>
                            <input type="text" #zip placeholder="Enter zip" matInput formControlName="zip">
                            <mat-error *ngFor="let validator of errorMessages.address.zip">
                                <mat-error
                                    *ngIf="userForm.get('address').get('zip').hasError(validator.type) && (userForm.get('address').get('zip').dirty || userForm.get('address').get('zip').touched)">
                                    {{validator.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="flex-column" *ngIf="userId">
                    <mat-form-field appearance="outline">
                        <mat-label>Created On</mat-label>
                        <input type="text" [matDatepicker]="createdDatePicker" matInput formControlName="created">
                        <mat-datepicker-toggle matSuffix [for]="createdDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #createdDatePicker color="primary">
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Activated On</mat-label>
                        <input type="text" [matDatepicker]="activatedDatePicker" matInput formControlName="activated">
                        <mat-datepicker-toggle matSuffix [for]="activatedDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #activatedDatePicker color="primary">
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>createdBy</mat-label>
                        <input type="text" matInput formControlName="createdBy">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>