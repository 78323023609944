import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BillingListModel } from 'src/app/models/billing-list.model';
import { ColumnFilterConfig, TableColumnsConfig } from 'src/app/models/table-columns-config.model';
import { HttpCommonService } from 'src/app/services/http-common.service';
import { COLUMN_FILTER_TYPE, COLUMN_FORMAT, COLUMN_TYPES } from 'src/app/shared/enums/table.enum';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { HandSetService } from 'src/app/shared/services/hand-set.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TableFilterService } from 'src/app/shared/services/table-filter.service';

@Component({
  selector: 'scj-billing-future-component',
  templateUrl: './billing-future-component.component.html',
  styleUrls: ['./billing-future-component.component.scss']
})
export class BillingFutureComponentComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  billingList: BillingListModel[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  clientId: string;
  role: boolean;
  currentTab: any;
  sub: any;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router
    , private authenticationService: AuthenticationService, private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService, private tfService: TableFilterService) {
    this.isHandset$ = this.handSet.isHandset$;
    if (this.authenticationService.userValue.clientId !== null)
      this.clientId = this.authenticationService.userValue.clientId;
    else
      this.clientId = this.activatedRoute.snapshot.parent.parent.params.clientId;

    this.role = this.authenticationService.userValue.roles.some(role => ['firmadmin', 'firmemployee'].includes(role));
  }

  ngOnInit(): void {

    this.sub = this.activatedRoute.data.subscribe((data: any) => {
      data ? this.currentTab = data.breadcrumb.name : '';
      this.getBillingListByClientId();
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getBillingListByClientId() {
    this.isLoading = true;
    this.isError = false;
    if (this.currentTab === 'Future Client Billing List') {
      this.httpService.getBillingListByClientId(this.clientId, true).subscribe((res: HttpResponse<BillingListModel[]>) => {
        if (res.body !== null) {
          this.billingList = res.body;
          this.tfService.initializeColumnFilter(this.columnFilterConfig);
        }
        if (!this.role && this.billingList.find(x => x.paymentStatus === 'Active')) {
          this.columnConfig.push({ name: 'pay', header: '', type: undefined })
        }
        this.isLoading = false;
      }, (err: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        console.log("Error", err);
      });
    }
    else if (this.currentTab === 'Future Firm Billing List') {
      this.httpService.getBillingScheduleDetailsForCompany(true).subscribe((res: HttpResponse<BillingListModel[]>) => {
        if (res.body !== null) {
          this.billingList = res.body;
          this.tfService.initializeColumnFilter(this.columnFilterConfig);
        }
        this.isLoading = false;
      }, (err: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        console.log("Error", err);
      });
    }
  }
  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'invoiceNumber', header: 'Invoice Number', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'serviceName', header: 'Service', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'paymentStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip' },
      { name: 'scheduleStartDate', header: 'Invoice Date', type: COLUMN_TYPES.DATE_TIME, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'scheduleDueDate', header: 'Due Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'scheduleAmount', header: 'Amount', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'displayPaymentMethod', header: 'Payment Method', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT },
      { name: 'paymentDate', header: 'Payment Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'invoiceNumber', label: 'Invoice Number', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'serviceName', label: 'Service', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'paymentStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Draft'] },
      { name: 'scheduleStartDate', label: 'Invoice Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'scheduleDueDate', label: 'Due Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'scheduleAmount', label: 'Amount', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'displayPaymentMethod', label: 'Payment Method', type: COLUMN_FILTER_TYPE.SELECT, value: ['Credit Card', 'Debit Card', 'Paypal', 'Cash', 'ACH', 'Other'] },
      { name: 'paymentDate', label: 'Payment Date', type: COLUMN_FILTER_TYPE.DATE, value: [] }
    ]
    return colFilters;
  }

  navigate(record: BillingListModel) {
    if (this.authenticationService.userValue.clientId !== null)
      this.router.navigateByUrl(`/client/invoices/manage-billing/${record.scheduleId}`);
    else
      this.router.navigateByUrl(`/firm/clients/${this.clientId}/billing/manage-billing/${record.scheduleId}`);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }

  refresh(status: boolean) {
    if (status) {
      this.getBillingListByClientId();
    }
  }

}
