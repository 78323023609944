<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" style="margin-top: 20px; margin-left: 20px;">

        <div class="flex-row ai-c mb-8" >
            <h2 class="scj-h2 mr-20" >
                <span class="text-header">Invoice - </span>
                {{header}}
            </h2>

            <div class="flex-row">
                <button mat-flat-button *ngIf="billData.invoiceNumber && (billData.paymentStatus === 'Active') && role"
                    (click)="markAsPaid()">
                    <mat-icon class="button-icon" svgIcon="payment_done"></mat-icon>
                    <span class="button-text">Mark As Paid</span>
                </button>
                <div *ngIf="!role && billData.scheduleAmount && billData.invoiceNumber && (billData.paymentStatus === 'Active')"
                    style="margin-right: 32px;">
                    <div scjPaypal description="Payment for {{billData.serviceName}}" [amount]="billData.scheduleAmount"
                        (paymentStatus)="updatePayment($event, billData.invoiceId)">
                    </div>
                </div>
                <button mat-flat-button *ngIf="billData.invoiceNumber"
                    [class.secondary]="billData.paymentStatus === 'Active'" (click)="viewInvoice()">
                    <mat-icon class="button-icon invoice-icon" svgIcon="invoice"></mat-icon>
                    <span class="button-text">View Invoice</span>
                </button>
                <button mat-flat-button class="secondary" routerLink="../../list">
                    <mat-icon class="button-icon">close</mat-icon>
                    <span class="button-text">Cancel</span>
                </button>
                <div *ngIf="isSpinner">
                    <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
                </div>
            </div>
        </div>

        <scj-error-msg *ngIf="errorsList.length > 0" [errors]="errorsList"></scj-error-msg>

        <form class="flex-column" [formGroup]="billingForm">
            <div class="flex-row">
                <div class="flex-column right-margin">
                    <mat-form-field appearance="outline">
                        <mat-label>Invoice Number</mat-label>
                        <input type="text" placeholder="Invoice Number" matInput formControlName="invoiceNumber">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <input type="text" placeholder="Status" matInput formControlName="paymentStatus">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Invoice Date</mat-label>
                        <input matInput [matDatepicker]="invoiceDatePicker" formControlName="scheduleStartDate"
                            placeholder="Payment Date" required readonly>
                        <mat-datepicker-toggle matSuffix [for]="invoiceDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #invoiceDatePicker color="primary" [touchUi]="isHandset$ | async">
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Due Date</mat-label>
                        <input matInput [matDatepicker]="dueDatePicker" formControlName="scheduleDueDate"
                            placeholder="Due Date" required readonly>
                        <mat-datepicker-toggle matSuffix [for]="dueDatePicker" matTooltip="Select due date"
                            [disableRipple]="true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dueDatePicker color="primary" [touchUi]="isHandset$ | async"
                            [restoreFocus]="false">
                        </mat-datepicker>
                    </mat-form-field>

                    
                </div>

                <div class="flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Amount</mat-label>
                        <input type="text" placeholder="Amount" matInput formControlName="scheduleAmount" required>
                        <mat-error
                            *ngIf="billingForm.get('scheduleAmount').invalid && billingForm.get('scheduleAmount').touched">
                            <mat-error *ngIf="billingForm.get('scheduleAmount')?.hasError('required')">
                                Comments is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Tax</mat-label>
                        <input type="text" placeholder="Tax" matInput formControlName="tax">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Total</mat-label>
                        <input type="text" placeholder="Total" matInput formControlName="total">
                    </mat-form-field>
                </div>
            </div>

            <div class="scj-subheader">Payment Details</div>

            <div class="flex-row top-padding">
                <div class="flex-column right-margin">
                    <mat-form-field appearance="outline">
                        <mat-label>Payment Method</mat-label>
                        <mat-select placeholder="Select payment method" formControlName="paymentMethod"
                            disableOptionCentering required>
                            <mat-option *ngFor="let method of paymentMethods" [value]="method.paymentKey">
                                {{method.paymentValue}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="billingForm.get('paymentMethod').invalid && billingForm.get('paymentMethod').touched">
                            <mat-error *ngIf="billingForm.get('paymentMethod')?.hasError('required')">
                                Payment method is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Reference Number</mat-label>
                        <input type="text" placeholder="Reference Number" matInput formControlName="paymentReference"
                            required>
                        <mat-error
                            *ngIf="billingForm.get('paymentReference').invalid && billingForm.get('paymentReference').touched">
                            <mat-error *ngIf="billingForm.get('paymentReference')?.hasError('required')">
                                Payment reference number is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <div fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label>Payment Date</mat-label>
                    <input matInput [matDatepicker]="paymentDatePicker" [max]="maxDate" formControlName="paymentDate"
                        placeholder="Payment Date" required readonly>
                    <mat-datepicker-toggle matSuffix [for]="paymentDatePicker" color="primary"
                        matTooltip="Select payment date" [disableRipple]="true">
                    </mat-datepicker-toggle>
                    <mat-datepicker #paymentDatePicker color="primary" [touchUi]="isHandset$ | async"
                        [restoreFocus]="false">
                    </mat-datepicker>
                    <mat-error *ngIf="billingForm.get('paymentDate').invalid && billingForm.get('paymentDate').touched">
                        <mat-error *ngIf="billingForm.get('paymentDate')?.hasError('required')">
                            Payment date is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                </div>
                <div fxLayout="column">
                <mat-form-field appearance="outline" class="text-area">
                    <mat-label>Comments</mat-label>
                    <textarea type="text" placeholder="Comments" matInput formControlName="comments"
                        cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="3"></textarea>
                </mat-form-field>
                </div>
                </div>
            </div>
        </form>
    </div>
</div>