import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailSync } from '../models/email-sync.model';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-firm-email-sync',
  templateUrl: './firm-email-sync.component.html',
  styleUrls: ['./firm-email-sync.component.scss']
})
export class FirmEmailSyncComponent implements OnInit, ComponentCanDeactivate {
  emailSyncForm: FormGroup;
  isHandset: boolean;
  disable: boolean = false;
  isLoading = true;
  isSpinner = false;
  isError = false;
  errorsList: string[] = [];
  emailServerList = ['IMAP', 'API'];
  emailServer: string;

  constructor(private authService: AuthenticationService, private handSet: HandSetService, private http: HttpCommonService,
    private notificationService: NotificationService) {
    this.isHandset = this.handSet.isHandset;
    this.disable = !this.authService.userValue.roles.includes('firmadmin');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getEmailSyncDetails();

    this.emailSyncForm.get('emailIntegrationType').valueChanges.subscribe((val: string) => {
      this.emailServer = val;
      this.updateValidators();
    });
  }

  initializeForm() {
    //, Validators.compose([Validators.email])
    this.emailSyncForm = new FormGroup({
      emailSyncEmail: new FormControl({ value: '', disabled: this.disable }, Validators.compose([Validators.email, Validators.required])),
      emailIntegrationType: new FormControl({ value: '', disabled: this.disable }),
      imapServerName: new FormControl({ value: '', disabled: this.disable }),
      imapApiKey: new FormControl({ value: '', disabled: this.disable }),
      apiClientId: new FormControl({ value: '', disabled: this.disable }),
      apiTenantId: new FormControl({ value: '', disabled: this.disable }),
      apiSecret: new FormControl({ value: '', disabled: this.disable })
    });
  }

  updateValidators() {
    if (this.emailServer === 'IMAP') {
      this.emailSyncForm.get('imapServerName').setValidators(Validators.required);
      this.emailSyncForm.get('imapApiKey').setValidators(Validators.required);

      this.emailSyncForm.get('apiClientId').clearValidators();
      this.emailSyncForm.get('apiTenantId').clearValidators();
      this.emailSyncForm.get('apiSecret').clearValidators();
    } else if (this.emailServer === 'API') {
      this.emailSyncForm.get('apiClientId').setValidators(Validators.required);
      this.emailSyncForm.get('apiTenantId').setValidators(Validators.required);
      this.emailSyncForm.get('apiSecret').setValidators(Validators.required);

      this.emailSyncForm.get('imapServerName').clearValidators();
      this.emailSyncForm.get('imapApiKey').clearValidators();
    }
  }

  getEmailSyncDetails() {
    this.http.getCompanyEmailSyncSettings().subscribe((res: HttpResponse<EmailSync>) => {
      if (res.body !== null) {
        this.emailSyncForm.patchValue(res.body);
        this.emailServer = res.body.emailIntegrationType;
        this.updateValidators();
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.notificationService.notifyText("Error occurred while fetching email sync details.", "X");
      console.log("error", err);
    });
  }

  onSubmit() {
    this.emailSyncForm.markAllAsTouched();
    this.errorsList = [];
    if (this.emailSyncForm.valid) {
      this.isSpinner = true;
      let emailSyncFormRawValue = this.emailSyncForm.getRawValue() as EmailSync;
      this.http.updateCompanyEmailSyncSettings(emailSyncFormRawValue).subscribe((res: HttpResponse<EmailSync>) => {
        if (res.status === 200) {
          this.notificationService.notifyText("Firm email sync details updated successfully!!");
          let formValue = this.emailSyncForm.getRawValue();
          this.emailSyncForm.reset(formValue);
          this.isSpinner = false;
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    } else {

    }
  }

  canDeactivate() {
    return !this.emailSyncForm.dirty;
  }
}
