<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">

        <div *ngIf="roleAdmin">
            <div class="flex-row stats-data">
                <div class="mat-elevation-z8">
                    <div class="scj-subheader">Clients Pending Activation</div>
                    <div class="scj-h2">
                        {{adminStats.allPendingUsersStats !== null ? adminStats.allPendingUsersStats.Pending : 'Please
                        try after some time!!'}}
                    </div>
                </div>

                <div class="mat-elevation-z8">
                    <div class="scj-subheader">Open Subscriptions</div>
                    <div class="scj-h2">
                        {{adminStats.allSubscriptionStats !== null ? adminStats.allSubscriptionStats.open : 'Please try
                        after some time!!'}}
                    </div>
                </div>

                <div class="mat-elevation-z8">
                    <div class="scj-subheader">Due / Overdue Invoices</div>
                    <div class="scj-h2">
                        <div *ngIf="adminStats.allInvoicesStats !== null; else due">
                            <span>{{adminStats.allInvoicesStats.active}}</span>
                            <span [style.color]="'rgba(0, 0, 0, 0.54)'">/</span>
                            <span [style.color]="'#F25022'">{{adminStats.allInvoicesStats.overdue}}</span>
                        </div>
                        <ng-template #due> 'Please try after some time!!'</ng-template>
                    </div>
                </div>
            </div>

            <div class="flex-row chart-data">
                <div class="mat-elevation-z8">
                    <div class="scj-subheader">Subscriptions </div>
                    <div *ngIf="employeeStats; else employeeSubStats" class="bar-chart">
                        <canvas id="bar-chart" width="90%" height="100%"></canvas>
                    </div>

                    <ng-template #employeeSubStats>
                        Please try after some time!!
                    </ng-template>
                </div>

                <div class="mat-elevation-z8">
                    <div class="scj-subheader">My Assignments</div>
                    <div *ngIf="mySubStats; else myStats" class="donut-chart">
                        <canvas id="donut-chart" width="90%" height="100%"></canvas>
                    </div>

                    <ng-template #myStats>
                        Please try after some time!!
                    </ng-template>
                </div>
            </div>
        </div>

        <div *ngIf="!roleAdmin" class="flex-column stats-employee mat-elevation-z8">
            <div class="scj-subheader">My Assignments</div>
            <div *ngIf="mySubStats; else myStats" class="donut-chart">
                <canvas id="donut-chart" width="90%" height="100%"></canvas>
            </div>

            <ng-template #myStats>
                Please try after some time!!
            </ng-template>
        </div>
    </div>
</div>