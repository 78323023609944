import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ClientValidationMessages } from '../shared/validators/validation-messages/client-validation.messages';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CustomValidators } from '../shared/validators/form-validators/custom.validators';
import { Observable } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';
import { GoogleMapsService } from '../shared/services/google-maps.service';
import { PHONE_NUMBER_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { NotificationService } from '../shared/services/notification.service';
import { COUNTRY_CODES_NAMES } from '../shared/constants/country-codes-names';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { AddressModel } from '../models/address.model';
import { TableColumnsConfig } from '../models/table-columns-config.model';
import { NewClientModel } from '../models/new-client.model';
import { ClientContactsModel, ContactsModel } from '../models/client-contacts.model';
import { COLUMN_TYPES } from '../shared/enums/table.enum';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { AuthenticationService } from '../shared/services/authentication.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'scj-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class ClientDetailsComponent implements OnInit, ComponentCanDeactivate {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  newClientForm: FormGroup = new FormGroup({});
  country: FormControl = new FormControl('US');
  errorMessages = ClientValidationMessages;
  minStartDate: Date | null = null;
  minEndDate = new Date();
  countries = COUNTRY_CODES_NAMES;
  clientStatus = STATUS_FIELDS;
  clientId: string;
  client: NewClientModel;
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  errorsList: string[] = [];
  isSpinner = false;
  clientDetails: any;
  isFirmAdmin: boolean = false;

  @ViewChild('addressText')
  addressText: ElementRef;

  @ViewChild('zip')
  zip: ElementRef;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private router: Router, private httpService: HttpCommonService, private gmService: GoogleMapsService,
    private notificationService: NotificationService, private authService: AuthenticationService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.clientId = this.activatedRoute.snapshot.parent.params.clientId;
    //this.isFirmAdmin = this.authService.userValue.roles.includes('firmadmin');
  }

  ngOnInit(): void {
    this.initializeForm();

    this.gmService.addressComponent.subscribe((place: any) => {
      let clientAddress = new AddressModel();
      clientAddress.addressLine1 = '';
      place.address_components.forEach((el: any) => {
        if (el.types?.indexOf('street_number') > -1) {
          clientAddress.addressLine1 = el.short_name + ' ';
        }
        if (el.types.indexOf('route') > -1) {
          clientAddress.addressLine1 = clientAddress.addressLine1 + el.short_name;
        }

        if (el.types.indexOf('locality') > -1) {
          clientAddress.city = el.long_name
        }

        if (el.types.indexOf('administrative_area_level_1') > -1) {
          clientAddress.state = el.short_name
        }

        if (el.types.indexOf('postal_code') > -1) {
          clientAddress.zip = el.short_name;
        }
      });
      clientAddress.country = this.country.value;
      this.newClientForm.get('clientAddress')?.patchValue(clientAddress);
      this.newClientForm.get('clientAddress')?.markAllAsTouched();
      this.zip.nativeElement.focus();
      this.addressText.nativeElement.focus();
    });

    if (this.clientId) {
      this.isLoading = true;
      this.httpService.getClientById(this.clientId).subscribe((res: HttpResponse<ClientContactsModel>) => {
        if (res.body !== null) {
          this.clientDetails = res.body;
          if (this.clientDetails.clientAddress === null)
            delete res.body.clientAddress;
          
          this.newClientForm.addControl('accountNumber', new FormControl({ value: '', disabled: true }));  

          if (this.clientDetails?.isBusiness) {
            this.newClientForm.addControl('einNumber', new FormControl({ value: '', disabled: false }));
            this.newClientForm.get('businessEmail').enable();
            this.newClientForm.get('businessEmail').setValidators(null);
          }
          this.newClientForm.patchValue(res.body);
          this.newClientForm.controls['startDate'].setValue(moment(res.body.startDate));
          this.newClientForm.controls['endDate'].setValue(res.body.endDate ? moment(res.body.endDate) : null);
          this.minEndDate = new Date(res.body.startDate);
          if (res.body.status === 'PENDING') {
            this.clientStatus.push({ statKey: 'PENDING', statValue: 'Pending' });
            this.newClientForm.get('status').disable();
          }
        }
        this.isLoading = false;
      }, (error: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        console.log("error", error);
      });
    }
  }

  initializeForm() {
    this.newClientForm = this.fb.group({
      isBusiness: new FormControl({ value: false, disabled: true }),
      accountNumber: new FormControl({ value: false, disabled: true }),
      businessName: new FormControl({ value: '', disabled: this.isFirmAdmin }, Validators.compose([Validators.required, Validators.maxLength(250)])),
      businessWebsite: new FormControl({ value: '', disabled: true }, Validators.compose([CustomValidators.website, Validators.maxLength(250)])),
      businessEmail: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required, Validators.email, Validators.maxLength(250)])),
      businessPhonenumber: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN), Validators.maxLength(25)])),
      notes: new FormControl({ value: '' }),
      status: new FormControl({ value: 'ACTIVE', disabled: false }, Validators.compose([Validators.required])),
      startDate: new FormControl({ value: moment(new Date()), disabled: true }, Validators.compose([Validators.required])),
      endDate: new FormControl(''),
      clientAddress: this.fb.group({
        addressLine1: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.maxLength(500)])),
        city: new FormControl({ value: '', disabled: true }),
        state: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.maxLength(150)])),
        zip: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.maxLength(15)])),
        country: this.country
      })
    });
    this.country.disable();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.gmService.getAddressAutoComplete(this.addressText, ['address']);
    }, 2000);
  }

  onSubmit() {
    this.newClientForm.markAllAsTouched();
    this.errorsList = [];
    if (this.newClientForm.valid) {
      this.isSpinner = true;
      let ncFormRawValue = this.newClientForm.getRawValue() as ClientContactsModel;
      let reqData = {};
      reqData['endDate'] = ncFormRawValue.endDate ? formatMomentDate(ncFormRawValue.endDate) : null;
      reqData['status'] = ncFormRawValue.status;
      reqData['notes'] = ncFormRawValue.notes;
      reqData['businessName'] = ncFormRawValue.businessName;
      reqData['businessEmail'] = ncFormRawValue.businessEmail;
      reqData['einNumber'] = ncFormRawValue.einNumber;
      this.httpService.updateClient(this.clientId, reqData).subscribe((res: HttpResponse<ClientContactsModel>) => {
        this.isSpinner = false;
        if (res.status === 200) {
          this.notificationService.notifyText("Client details updated successfully!!");
          this.newClientForm.reset(ncFormRawValue);
        }
      }, (err: HttpErrorResponse) => {
        this.isSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    }
  }

  resetEndDate() {
    this.newClientForm.get('endDate').reset();
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'fullname', header: 'Full Name', type: COLUMN_TYPES.STRING },
      { name: 'prefferedName', header: 'Preferred Name', type: COLUMN_TYPES.STRING },
      { name: 'username', header: 'Email', type: COLUMN_TYPES.STRING },
      { name: 'status', header: 'Status', type: COLUMN_TYPES.STRING },
      { name: 'creationDate', header: 'Created', type: COLUMN_TYPES.DATE },
      { name: 'isPrimary', header: 'Primary', type: COLUMN_TYPES.STRING },
    ];
    return columns;
  }

  canDeactivate() {
    return !this.newClientForm.dirty;
  }
}
