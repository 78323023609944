import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../models/user.model';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FileHandlerService } from '../shared/services/file-handler.service';
import { NotificationService } from '../shared/services/notification.service';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { FormControl } from '@angular/forms';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';
import { EsignTemplateFeildsModel } from '../models/esign-template-fields.model';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { distinctUntilChanged } from 'rxjs/operators';
import { HandSetService } from '../shared/services/hand-set.service';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import domtoimage from 'dom-to-image';

pdfjsLib.GlobalWorkerOptions.workerSrc = './assets/js/pdf.worker.min.js';

@Component({
  selector: 'scj-e-sign',
  templateUrl: './e-sign.component.html',
  styleUrls: ['./e-sign.component.scss']
})
export class ESignComponent implements OnInit {
  user: User;
  isFileLoading: boolean = true;
  isFileLoadingError: boolean = false;
  pdfBase64: string;
  pages: any[] = [];
  tcControl: FormControl = new FormControl(false);
  isHandset: boolean = false;
  esignValue: any = {};
  enableEsign: boolean = false;
  fieldArr: any[] = [];
  signFieldCount: number = 0;
  nameFieldCount: number = 0;
  initialFieldCount: number = 0;
  dateFieldCount: number = 0;
  signCounter: number = 0;
  nameCounter: number = 0;
  initialCounter: number = 0;
  fieldIndex: number = 0;
  isEsignStarted: boolean = false;
  displayFieldCount: number = 0;
  isSigned: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<ESignComponent>, private dialog: MatDialog,
    private fhService: FileHandlerService, private http: HttpCommonService, private notificationService: NotificationService,
    private authService: AuthenticationService, private handsetServie: HandSetService) {
    this.user = this.authService.userValue;
    this.isHandset = this.handsetServie.isHandset;
  }

  async ngOnInit() {
    this.esignValue['date'] = await this.textToImage();
    this.getFilePreview();

    this.tcControl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: boolean) => {
      if (val) {
        this.tcControl.disable();
        this.enableEsign = true;
        document.getElementById("esign-container").style.removeProperty("pointer-events");
      }
    });
  }

  getFilePreview() {
    this.isFileLoading = true;
    this.isFileLoadingError = false;
    this.fhService.downloadFile(`/api/v1/client/subscription/attachment/${this.data.attachment.id}`).subscribe(res => {
      let blob: Blob = new Blob([res], { type: res.type });
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.pdfBase64 = reader.result as string;
        this.setPages();
      }
      this.isFileLoading = false;
    }, err => {
      this.isFileLoading = false;
      this.isFileLoadingError = true;
    });
  }

  async setPages(): Promise<void> {
    let pdfDoc = await pdfjsLib.getDocument({ url: this.pdfBase64 }).promise;
    const totalPages = pdfDoc.numPages;
    this.pages = [];

    for (let i = 0; i < totalPages; i++) {
      await pdfDoc.getPage(i + 1).then(async (page: PDFPageProxy) => {
        let canvas = document.createElement('canvas');
        canvas.setAttribute("id", `canvas-${i}`);
        const viewport = page.getViewport({ scale: 1.2 });
        const height = viewport.height;
        const width = viewport.width;
        canvas.height = height;
        canvas.width = width;
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };
        await page.render(renderContext).promise.then(() => {
          this.pages.push({ data: canvas.toDataURL('image/png'), width: width, height: height });
        });
      });
    }
    this.loadEsignMarker();
  }

  loadEsignMarker() {
    let signMarkers = JSON.parse(this.data.attachment.jsonData) as EsignTemplateFeildsModel[];

    signMarkers = signMarkers.sort((a: EsignTemplateFeildsModel, b: EsignTemplateFeildsModel) => {
      if (a.page === b.page) {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      }
      return a.page - b.page;
    }).map((e: EsignTemplateFeildsModel, index: number) => {
      if (this.user.username === e.username) {
        e.id = `esign-${index}`;
      }
      e.height = e.height + (e.height * 0.2);
      e.width = e.width + (e.width * 0.2);
      e.x = e.x + (e.x * 0.2);
      e.y = e.y + (e.y * 0.2);
      return e;
    });;

    let pageCount = Math.max(...signMarkers.map((o: any) => o.page));
    let timer = setInterval(() => {
      let pages = document.querySelectorAll(".page");
      if (pageCount <= pages?.length) {
        signMarkers.forEach((e: EsignTemplateFeildsModel, index: number) => {
          let page = pages[e.page - 1];
          let div = document.getElementById('cloner').cloneNode(true) as HTMLElement;
          div.setAttribute('field-type', e.fieldType);
          div.setAttribute('username', e.username);
          div.setAttribute('isApplied', "false");
          div.classList.add('field-editable');
          div.removeAttribute('style');
          div.style.top = `${e.y}px`;
          div.style.left = `${e.x}px`;
          div.style.width = `${e.width}px`;
          div.style.height = `${e.height}px`;
          div.style.overflow = 'visible';
          div.style.display = 'flex';
          let nodes = div.children;
          nodes.item(0).setAttribute("src", e.icon);
          nodes.item(1).innerHTML = e.displayText;

          if (e?.id) {
            div.setAttribute('id', e.id);
            div.style.backgroundColor = `rgba(255,0,0, 0.25)`;
            let tooltip: string;
            let appliedTooltip: string;
            if (e.fieldType === 'NAME') {
              tooltip = '<div class="esign-tooltip-wrapper"> <div class="sign-required"></div> Required: Apply Name </div>';
              appliedTooltip = '<div class="esign-tooltip-wrapper"><div class="sign-applied">&check;</div> Name applied. </div>';
              this.nameFieldCount++;
              this.nameCounter++;
            } else if (e.fieldType === 'INITIALS') {
              tooltip = '<div class="esign-tooltip-wrapper"> <div class="sign-required"></div> Required: Apply Initials </div>';
              appliedTooltip = '<div class="esign-tooltip-wrapper"><div class="sign-applied">&check;</div> Initials applied. </div>';
              this.initialFieldCount++;
              this.initialCounter++;
            } else if (e.fieldType === 'SIGNATURE') {
              tooltip = '<div class="esign-tooltip-wrapper"> <div class="sign-required"></div> Required: Apply Signature </div>';
              appliedTooltip = '<div class="esign-tooltip-wrapper"><div class="sign-applied">&check;</div> Signature applied. </div>';
              this.signFieldCount++;
              this.signCounter++;
            } else {
              tooltip = '<div class="esign-tooltip-wrapper"> <div class="sign-required"></div> Required: Apply Sign Date </div>';
              appliedTooltip = '<div class="esign-tooltip-wrapper"><div class="sign-applied">&check;</div> Sign Date applied. </div>';
              this.dateFieldCount++;
            }
            nodes.item(2).innerHTML = tooltip;
            this.fieldArr.push({ index: e.id, icon: e.icon, fieldType: e.fieldType, isApplied: false, tooltip: tooltip, appliedTooltip: appliedTooltip });
            div.onclick = () => this.apply(div, e.id, e.fieldType);
            div.style.borderColor = "red";
          } else {
            div.style.backgroundColor = 'rgba(0,0,0,0.25)';
            nodes.item(2).innerHTML = e.tooltip;
          }
          page.appendChild(div);
          this.displayFieldCount = this.nameCounter + this.signCounter + this.initialCounter + this.dateFieldCount;
        });
        clearInterval(timer);
      }
    }, 200);
  }

  async apply(div: HTMLElement, id: string, fieldType: string) {
    if (!this.isEsignStarted) {
      this.isEsignStarted = true;
    }
    if ((this.signCounter === this.signFieldCount) && fieldType === "SIGNATURE") {
      this.esignValue["sign"] = await this.openSignaturePad(fieldType);
      if (!this.esignValue?.sign) {
        this.notificationService.notifyText("Signature required.");
        return;
      }
    }

    if ((this.nameCounter === this.nameFieldCount) && fieldType === "NAME") {
      this.esignValue["name"] = await this.openSignaturePad(fieldType);
      if (!this.esignValue?.name) {
        this.notificationService.notifyText("Name required.");
        return;
      }
    }

    if ((this.initialCounter === this.initialFieldCount) && fieldType === "INITIALS") {
      this.esignValue["initial"] = await this.openSignaturePad(fieldType);
      if (!this.esignValue?.initial) {
        this.notificationService.notifyText("Initials required.");
        return;
      }
    }

    let isApplied = JSON.parse(div.getAttribute("isApplied"));
    let nodes = div.children;
    let fields = this.fieldArr.filter((e: any) => (e.fieldType === fieldType))[0];
    let index = this.fieldArr.findIndex((e: any) => e.index === div.getAttribute("id"));
    if (!isApplied) {
      div.style.justifyContent = 'left';
      let img = nodes.item(0) as HTMLImageElement;
      let text = nodes.item(1) as HTMLSpanElement;
      img.style.width = "auto";
      img.style.height = "auto";
      img.style.maxWidth = "100%";
      img.style.maxHeight = "100%";
      img.style.marginRight = "0";
      text.style.display = "none";
      nodes.item(2).innerHTML = fields.appliedTooltip;
      div.setAttribute("isApplied", "true");
      div.style.borderColor = "green";
      div.style.background = "none";
      div.style.borderStyle = "double";
      this.fieldArr[index].isApplied = true;
      if (fieldType === "SIGNATURE") {
        img.setAttribute("src", this.esignValue.sign);
        this.signCounter--;
      } else if (fieldType === "NAME") {
        img.setAttribute("src", this.esignValue.name);
        this.nameCounter--;
      } else if (fieldType === "INITIALS") {
        img.setAttribute("src", this.esignValue.initial);
        this.initialCounter--;
      } else {
        img.setAttribute("src", this.esignValue.date);
        this.dateFieldCount--;
      }
    } else {
      div.style.removeProperty("justify-content");
      let img = nodes.item(0) as HTMLImageElement;
      let text = nodes.item(1) as HTMLSpanElement;
      img.style.removeProperty("width");
      img.style.removeProperty("height");
      img.style.removeProperty("margin-right");
      text.style.display = 'block';
      nodes.item(2).innerHTML = fields.tooltip;
      div.setAttribute("isApplied", "false");
      div.style.borderColor = "red";
      div.style.borderStyle = "dashed";
      div.style.background = "rgba(255,0,0, 0.25)";
      img.src = this.fieldArr[index].icon;
      this.fieldArr[index].isApplied = false;
      if (fieldType === "SIGNATURE") {
        this.signCounter++;
      } else if (fieldType === "NAME") {
        this.nameCounter++;
      } else if (fieldType === "INITIALS") {
        this.initialCounter++;
      } else {
        this.dateFieldCount++;
      }
    }
    this.displayFieldCount = this.nameCounter + this.signCounter + this.initialCounter + this.dateFieldCount;
    this.fieldIndex = index;
    if (this.displayFieldCount === 0) {
      this.isSigned = true;
    }
  }

  openSignaturePad(fieldType: string): Promise<string> {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '25vw';
    dialogConfig.width = this.isHandset ? "90vw" : 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = "esign-modal";
    let padValue;
    if (fieldType === "NAME") {
      padValue = this.esignValue?.name;
    } else if (fieldType === "INITIALS") {
      padValue = this.esignValue?.initial;
    } else {
      padValue = this.esignValue?.sign;
    }
    dialogConfig.data = { fieldType: fieldType, padValue: padValue };
    return this.dialog.open<SignaturePadComponent>(SignaturePadComponent, dialogConfig).afterClosed().toPromise();
  }

  next() {
    this.isEsignStarted = true;
    if (this.fieldIndex > this.fieldArr.length - 1) {
      this.fieldIndex = this.fieldArr.findIndex((e: any) => !e.isApplied);
    }
    let field = this.fieldArr.find((e: any) => (e.index === `esign-${this.fieldIndex}`) && !e.isApplied) || this.fieldArr.find((e: any) => !e.isApplied);
    let el = document.getElementById(field.index);
    el.scrollIntoView({ block: 'center' });
    this.fieldIndex++;
  }

  esign() {
    if (this.displayFieldCount === 0) {
      let signReq = {
        attachmentId: this.data.attachment.id,
        signatureData: JSON.stringify(this.esignValue)
      };
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.width = 'auto';
      dialogConfig.height = 'auto';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.closeOnNavigation = true;
      dialogConfig.disableClose = false;

      dialogConfig.data = {
        message: `You are about to apply E-signature for ${this.data.attachment.fileName}. Please confirm`,
        isHTML: false,
        buttonText: { ok: 'Yes', cancel: 'No' },
        revertBoolean: false
      }
      this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          this.dialogRef.close(signReq);
        }
      });
    } else {
      this.notificationService.notifyText("Fill in all the fields.", "X");
      this.fieldIndex = this.fieldArr.findIndex((e: any) => !e.isApplied);
      this.next();
    }
  }

  cancel() {
    if (this.isEsignStarted) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.width = 'auto';
      dialogConfig.height = 'auto';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.closeOnNavigation = true;
      dialogConfig.disableClose = false;

      dialogConfig.data = {
        message: `You are about to exit E-signature for ${this.data.attachment.fileName} and signature data will not be saved. Please confirm`,
        isHTML: false,
        buttonText: { ok: 'Yes', cancel: 'No' },
        revertBoolean: true
      }
      this.dialog.open(AlertMessagesComponent, dialogConfig).afterClosed().subscribe((confirm: boolean) => {
        if (!confirm) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  async textToImage(): Promise<string> {
    let div: HTMLDivElement = document.createElement("div");
    div.style.backgroundColor = 'none';
    div.style.fontSize = "16px";
    div.style.height = "fit-content";
    div.style.width = "fit-content";
    div.style.padding = "4px"
    div.style.color = 'black';
    div.id = "date";
    div.innerText = formatMomentDate(new Date().toDateString());
    document.body.append(div);

    let date = await domtoimage.toPng(div)
      .then((dataUrl: string) => {
        return dataUrl;
      })
      .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
      });
    document.body.removeChild(div);
    return date;
  }
}
