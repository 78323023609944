<div mat-dialog-title>
    <div class="scj-subheader">Signers Mapping</div>
    <mat-icon class="close-user-mapping" matTooltip="Close" [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content>
    <div cdkDropListGroup class="drag-list-group">
        <div class="user-list-container">
            <div class="scj-subheader" style="padding-bottom: 12px;">Esign Template Users</div>
            <div cdkDropList [cdkDropListData]="templateUserList" (cdkDropListDropped)="drop($event)"
                class="user-mapping-list">
                <div cdkDrag class="user-wrapper-box" *ngFor="let user of templateUserList">{{ user.name}}</div>
            </div>
        </div>

        <div class="user-list-container">
            <div class="scj-subheader" style="padding-bottom: 12px;">Signers</div>
            <div class="signer-container">
                <ng-container *ngFor="let user of userList">
                    <div class="scj-subheader" style="padding-bottom: 6px;">{{user.name}}</div>
                    <div cdkDropList [cdkDropListData]="user.list" (cdkDropListDropped)="drop($event)"
                        class="user-mapping-list">
                        <div cdkDrag class="user-wrapper-box" *ngFor="let list of user.list">{{ list.name }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button class="secondary" (click)="reset()">Reset</button>
    <button mat-flat-button (click)="submit()">Submit</button>
</mat-dialog-actions>