import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scj-client-service-data',
  templateUrl: './client-service-data.component.html',
  styleUrls: ['./client-service-data.component.scss']
})
export class ClientServiceDataComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
