import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { exhaustMap, map } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  error = '';
  hide = true;
  isDisabled = false;
  errorsList: string[] = [];
  subdomain: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit(data: any) {
    this.recaptchaV3Service.execute('resetPassword').pipe(map((token: string) => {
      if (token) {
        return token;
      } else {
        throw new Error("User is not validated as human");
      }
    })).pipe(exhaustMap((token: string) => {
      return this.authenticationService.generateForgotPasswordLink({ username: data.username }, this.subdomain, token);
    })).subscribe((res: any) => {
      if (res.status === 200) {
        this.isDisabled = true;
        this.notificationService.notifyText('Reset Password URL Sent Succefully', 'X');
      } else {
        this.notificationService.notifyText(
          'Something went wrong. Please try after some time.'
        );
      }
    }, (err: any) => {
      if (err.status === 400) {
        err.error?.subErrors.forEach((error: any) =>
          this.errorsList.push(error.message)
        );
      } else {
        this.notificationService.notifyText('Enter Valid Username');
      }
    });
  }

  setSubdomain(event: string) {
    this.subdomain = event;
  }

  redirect() {
    this.router.navigate(['/login']);
  }
}
