import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { AllSubscriptionsModel } from '../models/all-subscriptions.model';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse } from '@angular/common/http';
import { COLUMN_FILTER_TYPE, COLUMN_FORMAT, COLUMN_TYPES } from '../shared/enums/table.enum';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { PaginationModel } from '../models/pagination.model';
import { PaginationRequestModel } from '../models/pagination-request.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'scj-my-assignments',
  templateUrl: './my-assignments.component.html',
  styleUrls: ['./my-assignments.component.scss']
})
export class MyAssignmentsComponent implements OnInit {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  mySubscriptions: PaginationModel;
  searchControl: FormControl = new FormControl('');
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  reqBody: PaginationRequestModel;

  constructor(private handset: HandSetService, private httpService: HttpCommonService, private router: Router,
    private tfService: TableFilterService) {
    this.isHandset$ = this.handset.isHandset$;
  }

  ngOnInit(): void {
    this.tfService.initializeColumnFilter(this.columnFilterConfig);
    this.initializeRequest();
    this.getMySubscriptions();

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchText: string) => {
      //this.tfService.setSearchValue(filterVal.trim().toLowerCase());
      this.reqBody.searchText = searchText;
      this.reqBody.page.index = 0;
      this.modifyPageData(this.reqBody);
    });
  }

  initializeRequest() {
    this.reqBody = {
      page: {
        index: 1,
        size: 20
      },
      sort: {
        column: "requestDate",
        order: "desc"
      }
    } as PaginationRequestModel;
  }

  modifyPageData(event: PaginationRequestModel) {
    this.isError = false;
    delete event.filter;
    this.reqBody = event;
    if(this.searchControl.value){
      this.reqBody.searchText = this.searchControl.value;
      }
    this.isLoading = true;
    this.getMySubscriptions();
  }

  getMySubscriptions() {
    this.httpService.getPaginatedMySubscriptions(this.reqBody).subscribe((res: HttpResponse<PaginationModel>) => {
      if (res.body !== null) {
        this.mySubscriptions = res.body;

        for (let i = 0; i < this.mySubscriptions.data.length; i++) {
          let subscription = this.mySubscriptions.data[i];
          if (subscription.pendingCpaAction && subscription.status !== 'COMPLETED' && subscription.status !== 'CANCEL') {
            subscription.status = 'PENDING_CPA'
          } else if (!subscription.pendingCpaAction && subscription.status !== 'COMPLETED' && subscription.status !== 'CANCEL') {
            subscription.status = 'PENDING_CLIENT'
          }
        }

        //this.tfService.initializeColumnFilter(this.columnFilterConfig);
        this.isLoading = false;
      }
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'clientName', header: 'Client', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'service', header: 'Service', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip' },
      { name: 'priority', header: 'Priority', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT },
      { name: 'serviceFee', header: '$ Service Fee', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'amountDue', header: '$ Due', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'requestDate', header: 'Created', type: COLUMN_TYPES.DATE_TIME, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'dueDate', header: 'Due Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'subscriptionNumber', header: 'ID', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'clientName', label: 'Client', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'service', label: 'Service', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Pending data from Client', 'Under Review', 'Completed', 'Canceled'] },
      { name: 'priority', label: 'Priority', type: COLUMN_FILTER_TYPE.SELECT, value: ['Critical', 'High', 'Medium', 'Low'] },
      { name: 'serviceFee', label: '$ Service Fee', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'amountDue', label: '$ Due', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'requestDate', label: 'Created', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'dueDate', label: 'Due Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'subscriptionNumber', label: 'ID', type: COLUMN_FILTER_TYPE.STRING, value: '' }
    ]
    return colFilters;
  }

  navigate(record: AllSubscriptionsModel) {
    this.router.navigateByUrl(`/firm/clients/${record.clientId}/services/subscription/${record.subscriptionid}`);
  }

  esign(esigned: boolean) {
    if (esigned) {
      this.getMySubscriptions();
    }
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }
}