export const FirmValidationMessages = {
    "name": [
        { type: "required", message: "Firm name is required" },
        { type: "maxlength", message: "Firm name can't exceed 250 characters" }
    ],
    "website": [
        { type: "required", message: "Firm website is required" },
        { type: "website", message: "Firm website is invalid" }
    ],
    "referralEmail": [
        { type: "email", message: "Referral email is invalid" }
    ],
    "salesRepEmail": [
        { type: "email", message: "Sales representative email is invalid" }
    ],
    "phoneNumber": [
        { type: "required", message: "Phone number is required" },
        { type: "pattern", message: "Phone number must be formatted or have only numbers" }
    ],
    "email": [
        { type: "required", message: "Email is required" },
        { type: "email", message: "Email is invalid" }
    ],
    "status": [
        { type: "required", message: "Status is required" }
    ],
    "primaryUser": {
        "contactFullName": [
            { type: "required", message: "Full name is required" },
            { type: "pattern", message: "Numbers & Special characters not allowed" },
            { type: "maxlength", message: "Full name can't exceed 250 characters" }
        ],
        "contactPrefferedName": [
            { type: "maxlength", message: "Preffered name can't exceed 250 characters" },
        ],
        "contactEmail": [
            { type: "required", message: "Email is required" },
            { type: "email", message: "Email is invalid" }
        ],
        "contactPhone": [
            { type: "required", message: "Contact number is required" },
            { type: "pattern", message: "Contact number must have only numbers" }
        ],
        "id": [
            { type: "required", message: "Primary contact is required" }
        ]
    },
    "address": {
        "addressLine1": [
            { type: "required", message: "Address is required" },
            { type: "maxlength", message: "Address can't exceed 500 characters" }
        ],
        "city": [
            { type: "required", message: "City is required" },
            { type: "pattern", message: "Numbers or Special characters are not allowed" }
        ],
        "state": [
            { type: "required", message: "State is required" },
            { type: "pattern", message: "Numbers or Special characters are not allowed" }
        ],
        "zip": [
            { type: "required", message: "Zip is required" },
            { type: "pattern", message: "Zip must contain only numbers" }
        ]
    },
    "salesEmail": [
        { type: "required", message: "Sales email is required" },
        { type: "email", message: "Sales email is invalid" },
        { type: "pattern", message: "Sales email must be valid simplecj email" }
    ],
    "supportEmail": [
        { type: "required", message: "Support email is required" },
        { type: "email", message: "Support email is invalid" }
    ],
    "merchantId": [
        { type: "required", message: "Merchant Id is required" }
    ]
};