export const ESIGN_ICON = `<svg id="esign" x="0px" y="0px" viewBox="0 0 25 25" xml:space="preserve" fill="white">
<g>
    <path d="M18.724,9.903l3.855,1.416l-3.206,8.729c-0.3,0.821-1.927,3.39-3.06,3.914l-0.275,0.75 c-0.07,0.19-0.25,0.309-0.441,0.309c-0.054,0-0.108-0.01-0.162-0.029c-0.243-0.09-0.369-0.359-0.279-0.604l0.26-0.709 c-0.575-1.117-0.146-4.361,0.106-5.047L18.724,9.903z M24.303,0.667c-1.06-0.388-2.301,0.414-2.656,1.383l-2.322,6.326l3.854,1.414 l2.319-6.325C25.79,2.673,25.365,1.056,24.303,0.667z M17.328,9.576c0.108,0.04,0.219,0.059,0.327,0.059 c0.382,0,0.741-0.234,0.882-0.614l2.45-6.608c0.181-0.487-0.068-1.028-0.555-1.208c-0.491-0.178-1.028,0.068-1.209,0.555 l-2.45,6.608C16.592,8.855,16.841,9.396,17.328,9.576z M13.384,21.967c-0.253-0.239-0.568-0.537-1.078-0.764 c-0.42-0.187-0.829-0.196-1.128-0.203c-0.031,0-0.067-0.001-0.103-0.002c-0.187-0.512-0.566-0.834-1.135-0.96 c-0.753-0.159-1.354,0.196-1.771,0.47c0.037-0.21,0.098-0.46,0.143-0.64c0.144-0.58,0.292-1.18,0.182-1.742 c-0.087-0.444-0.462-0.774-0.914-0.806c-1.165-0.065-2.117,0.562-2.956,1.129c-0.881,0.595-1.446,0.95-2.008,0.749 c-0.686-0.244-0.755-2.101-0.425-3.755c0.295-1.49,0.844-4.264,2.251-5.524c0.474-0.424,1.16-0.883,1.724-0.66 c0.663,0.26,1.211,1.352,1.333,2.653c0.051,0.549,0.53,0.952,1.089,0.902c0.55-0.051,0.954-0.539,0.902-1.089 c-0.198-2.12-1.192-3.778-2.593-4.329C6.058,7.07,4.724,6.982,3.107,8.429c-1.759,1.575-2.409,4.246-2.88,6.625 c-0.236,1.188-0.811,5.13,1.717,6.029c1.54,0.549,2.791-0.298,3.796-0.976c0.184-0.124,0.365-0.246,0.541-0.355 c-0.167,0.725-0.271,1.501,0.167,2.155c0.653,0.982,1.576,1.089,2.742,0.321c0.045-0.029,0.097-0.063,0.146-0.097 c0.108,0.226,0.299,0.475,0.646,0.645c0.42,0.206,0.84,0.216,1.146,0.224c0.131,0.003,0.31,0.007,0.364,0.031 c0.188,0.083,0.299,0.185,0.515,0.389c0.162,0.153,0.333,0.312,0.55,0.476c0.18,0.135,0.39,0.199,0.598,0.199 c0.304,0,0.605-0.139,0.801-0.4c0.331-0.442,0.241-1.069-0.201-1.4C13.61,22.183,13.495,22.072,13.384,21.967z"></path>
</g>
</svg>`;


export const NAME_ICON = `<svg id="name" height="100%" width="100%" viewBox="0 0 520 520" fill="white">
<path d="m306.035156 482h-13.707031l-116.863281-377.726562c-1.941406-6.285157-7.753906-10.566407-14.328125-10.566407-.007813 0-.015625 0-.019531 0-6.585938.011719-12.394532 4.3125-14.324219 10.609375l-115.640625 377.683594h-16.152344c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h64.398438c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-16.871094l18.519531-60.492188h97.773437l18.570313 60.492188h-16.441406c-8.28125 0-15 6.714844-15 15s6.71875 15 15 15h125.085937c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm-215.800781-90.492188 39.640625-129.460937 39.738281 129.460937zm0 0"></path>
<path d="m497 482h-22.113281v-137.070312c0-33.113282-26.910157-60.058594-59.988281-60.058594h-9.558594c-33.078125 0-59.988282 26.945312-59.988282 60.058594v3.941406c0 8.285156 6.71875 15 15 15 8.285157 0 15-6.714844 15-15v-3.941406c0-16.574219 13.453126-30.058594 29.988282-30.058594h9.558594c16.535156 0 29.988281 13.484375 29.988281 30.058594v11.730468l-72.195313 28.261719c-23.441406 9.175781-38.585937 31.386719-38.585937 56.585937v6.136719c0 29.304688 20.894531 54.433594 49.679687 59.746094 3.667969.679687 7.351563 1.011719 11.011719 1.011719 15.945313-.003906 31.429687-6.300782 42.914063-17.804688l7.175781-7.1875v2.324219c0 14.484375 11.773437 26.265625 26.246093 26.265625h25.867188c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15zm-80.519531-12.601562c-7.140625 7.148437-17.328125 10.328124-27.25 8.492187-14.558594-2.6875-25.125-15.410156-25.125-30.246094v-6.136719c0-12.761718 7.660156-24.007812 19.519531-28.648437l61.261719-23.980469v52.070313zm0 0"></path>
<path d="m497 0c-8.285156 0-15 6.714844-15 15v10.039062h-452v-10.039062c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v50.078125c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-10.039063h452v10.039063c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-50.078125c0-8.285156-6.714844-15-15-15zm0 0"></path>
</svg>`;

export const INITIALS_ICON = `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1625pt" height="1140pt" viewBox="0 0 1625 1140" preserveAspectRatio="xMidYMid meet" fill="white">

        <g transform="translate(0.000000,1140.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M463 11265 c-167 -36 -293 -164 -328 -332 -22 -105 -22 -1712 0
-1798 24 -90 52 -141 118 -211 215 -231 617 -177 752 100 44 91 55 162 55 369
l0 167 7060 0 7060 0 0 -172 c0 -112 5 -197 15 -241 49 -231 259 -384 498
-364 154 13 268 75 344 185 82 121 78 64 78 1062 l0 885 -22 57 c-105 275
-411 387 -674 246 -71 -37 -147 -116 -184 -188 -42 -83 -55 -165 -55 -357 l0
-173 -7060 0 -7060 0 0 173 c0 267 -34 373 -151 481 -120 111 -272 149 -446
111z" />
                <path d="M11728 9141 c-72 -23 -133 -68 -170 -123 -15 -24 -51 -115 -79 -203
-28 -88 -65 -200 -80 -249 -16 -49 -29 -96 -29 -105 0 -10 -22 -84 -49 -167
-26 -82 -69 -216 -96 -299 -26 -82 -56 -177 -66 -209 -10 -33 -19 -67 -19 -77
0 -9 -18 -70 -39 -135 -21 -66 -62 -193 -90 -284 -29 -91 -58 -183 -66 -205
-8 -23 -15 -50 -15 -62 0 -11 -18 -76 -40 -144 -22 -68 -61 -189 -86 -269 -25
-80 -59 -182 -75 -228 -16 -46 -29 -93 -29 -105 0 -12 -18 -76 -39 -142 -131
-404 -172 -536 -180 -580 -6 -27 -30 -111 -54 -185 -74 -227 -155 -489 -188
-609 -17 -63 -35 -121 -39 -130 -14 -25 -160 -498 -160 -516 0 -10 -13 -56
-29 -104 -16 -47 -48 -147 -71 -221 -23 -74 -57 -180 -76 -236 -18 -55 -39
-129 -47 -165 -8 -35 -62 -212 -121 -392 -58 -181 -106 -336 -106 -345 0 -10
-32 -116 -71 -237 -106 -332 -139 -442 -139 -459 0 -9 -18 -69 -39 -133 -21
-65 -60 -185 -86 -268 -26 -82 -57 -181 -70 -220 -13 -38 -26 -86 -30 -105 -7
-43 -81 -283 -101 -328 l-14 -32 -199 0 c-179 0 -206 -2 -256 -21 -67 -25
-132 -89 -172 -168 -24 -46 -28 -67 -28 -135 1 -68 5 -89 27 -131 60 -111 137
-168 250 -186 40 -6 335 -9 774 -7 l709 3 51 23 c61 28 132 98 168 167 22 42
26 63 26 133 0 98 -24 155 -97 233 -74 79 -113 89 -358 89 -112 0 -206 4 -209
8 -3 5 17 78 44 163 28 85 50 164 50 176 0 12 9 46 19 75 11 29 43 127 71 218
29 91 70 217 91 281 21 63 39 127 39 142 0 15 4 35 9 45 5 9 20 53 33 97 l24
80 1048 0 1048 0 19 -60 c11 -33 26 -87 33 -120 8 -33 28 -100 44 -150 16 -49
55 -171 86 -270 32 -99 66 -204 77 -233 10 -30 19 -64 19 -75 0 -12 25 -98 55
-190 31 -93 53 -173 50 -178 -4 -5 -93 -9 -205 -9 -177 0 -205 -2 -255 -21
-91 -34 -173 -137 -196 -246 -27 -132 58 -290 189 -351 l57 -27 1400 0 1400 0
56 23 c68 28 127 86 168 166 27 50 31 69 31 133 0 88 -29 157 -97 233 -68 75
-97 84 -317 90 -154 4 -190 8 -197 20 -14 26 -146 442 -157 495 -6 28 -29 106
-52 175 -23 69 -61 188 -86 265 -24 77 -53 165 -64 195 -10 30 -19 66 -20 80
0 14 -29 115 -65 225 -81 246 -141 437 -153 490 -5 22 -46 153 -92 290 -79
241 -93 288 -162 530 -17 60 -35 117 -39 125 -13 24 -140 431 -149 475 -4 22
-10 47 -13 55 -4 12 -96 296 -198 614 -10 33 -19 67 -19 76 0 10 -13 55 -29
101 -16 46 -48 145 -71 219 -23 74 -58 179 -76 233 -19 55 -34 109 -34 121 0
13 -24 97 -54 187 -130 397 -166 514 -166 534 0 12 -9 44 -19 71 -11 27 -38
110 -61 184 -23 74 -51 160 -62 190 -31 88 -88 277 -88 293 0 8 -20 75 -44
148 -24 74 -65 202 -91 284 -26 83 -53 167 -61 188 -8 20 -14 46 -14 56 0 19
-40 147 -156 500 -35 107 -67 211 -70 230 -6 32 -24 93 -109 357 -13 40 -32
89 -43 111 -67 131 -235 200 -374 154z m-548 -3673 c12 -40 41 -134 66 -208
88 -270 112 -347 119 -381 7 -37 72 -246 166 -537 32 -101 59 -191 59 -200 0
-10 13 -58 29 -107 16 -50 41 -130 56 -180 15 -49 31 -96 36 -105 8 -15 60
-185 81 -265 6 -22 23 -80 38 -130 16 -49 38 -124 50 -165 11 -41 25 -84 30
-95 5 -11 21 -58 35 -105 15 -47 33 -102 41 -123 8 -20 14 -44 14 -52 0 -13
-103 -15 -842 -13 -836 3 -843 3 -841 23 1 20 44 158 118 385 21 63 41 135 45
160 5 25 24 92 43 150 20 58 59 179 87 270 29 91 61 189 71 218 10 29 19 62
19 74 0 12 18 76 39 142 123 377 182 567 190 612 6 28 14 61 20 75 5 13 28 85
51 159 23 74 59 187 80 250 20 63 43 139 51 168 7 28 16 52 20 52 5 0 18 -33
29 -72z" />
                <path d="M4355 9137 c-95 -32 -164 -90 -201 -171 -13 -28 -53 -150 -89 -271
-74 -244 -121 -399 -188 -615 -108 -347 -179 -580 -317 -1035 -49 -159 -115
-378 -148 -485 -33 -107 -77 -249 -97 -315 -38 -125 -186 -609 -281 -920 -31
-99 -73 -236 -94 -305 -22 -69 -58 -186 -80 -260 -90 -295 -151 -494 -210
-685 -34 -110 -74 -243 -90 -295 -29 -97 -91 -299 -207 -675 -36 -115 -89
-289 -118 -385 -29 -96 -74 -242 -99 -325 -26 -82 -75 -244 -110 -360 -35
-115 -99 -322 -141 -460 -72 -236 -95 -310 -180 -590 -19 -63 -35 -117 -35
-120 0 -3 -91 -5 -202 -5 -189 0 -207 -2 -260 -24 -93 -38 -155 -101 -186
-188 -9 -26 -20 -48 -24 -48 -5 0 -8 -27 -8 -60 0 -33 3 -60 8 -60 4 0 13 -19
20 -41 19 -60 66 -124 118 -159 90 -63 56 -61 889 -58 l760 3 47 24 c65 33
126 96 154 159 29 64 32 174 8 246 -22 64 -100 147 -166 177 -50 23 -66 24
-270 27 -120 2 -218 6 -218 10 0 4 22 79 49 167 27 88 64 207 81 265 18 58 45
146 60 195 16 50 58 189 95 310 37 121 76 248 87 283 l20 62 1043 0 1043 0 15
-47 c8 -27 39 -129 70 -228 189 -613 307 -999 307 -1006 0 -4 -96 -9 -212 -11
-240 -3 -274 -12 -351 -86 -163 -156 -122 -419 80 -523 l48 -24 1425 0 1425 0
43 23 c97 52 168 144 180 235 3 23 10 46 16 50 8 6 7 10 -1 14 -7 2 -13 18
-13 35 0 46 -55 151 -101 193 -79 72 -111 80 -321 83 -182 3 -187 4 -193 25
-4 12 -29 92 -55 177 -64 204 -107 343 -240 775 -61 198 -128 416 -150 485
-21 69 -75 244 -120 390 -45 146 -109 351 -142 455 -33 105 -86 276 -118 380
-124 404 -297 965 -355 1150 -24 77 -76 244 -115 370 -39 127 -80 257 -90 290
-10 33 -75 245 -145 470 -132 430 -227 735 -300 970 -24 77 -128 412 -230 745
-103 333 -209 677 -236 765 -28 88 -68 219 -91 290 -108 351 -118 378 -158
431 -72 96 -226 147 -335 111z m-484 -3902 c49 -160 105 -339 124 -400 19 -60
50 -162 69 -225 44 -146 174 -572 231 -755 24 -77 62 -198 83 -270 57 -187
144 -469 191 -618 22 -70 41 -138 43 -150 l3 -22 -834 -3 c-459 -1 -838 1
-842 5 -4 5 17 87 46 183 91 296 222 724 296 965 39 127 92 302 119 390 26 88
62 203 78 255 17 52 37 118 45 145 23 76 144 473 189 620 22 72 43 140 45 153
3 13 10 21 15 20 6 -2 50 -134 99 -293z" />
        </g>
</svg>`

export const DATE_ICON = `<svg id="date" height="100%" width="100%" viewBox="0 0 36 36" xml:space="preserve" fill="white">
<g>
    <g>
        <path d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75 c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75 c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v24c0,2.343,1.907,4.25,4.25,4.25h24 c2.344,0,4.25-1.907,4.25-4.25v-24C34.474,5.855,32.567,3.948,30.224,3.948z M25.286,2.75c0-0.689,0.525-1.25,1.17-1.25 c0.646,0,1.17,0.561,1.17,1.25v4.896c0,0.689-0.524,1.25-1.17,1.25c-0.645,0-1.17-0.561-1.17-1.25V2.75z M17.206,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M9.125,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z  M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75v-22h27.5V32.198z"></path>
        <rect x="6.724" y="14.626" width="4.595" height="4.089"></rect>
        <rect x="12.857" y="14.626" width="4.596" height="4.089"></rect>
        <rect x="18.995" y="14.626" width="4.595" height="4.089"></rect>
        <rect x="25.128" y="14.626" width="4.596" height="4.089"></rect>
        <rect x="6.724" y="20.084" width="4.595" height="4.086"></rect>
        <rect x="12.857" y="20.084" width="4.596" height="4.086"></rect>
        <rect x="18.995" y="20.084" width="4.595" height="4.086"></rect>
        <rect x="25.128" y="20.084" width="4.596" height="4.086"></rect>
        <rect x="6.724" y="25.54" width="4.595" height="4.086"></rect>
        <rect x="12.857" y="25.54" width="4.596" height="4.086"></rect>
        <rect x="18.995" y="25.54" width="4.595" height="4.086"></rect>
        <rect x="25.128" y="25.54" width="4.596" height="4.086"></rect>
    </g>
</g>
</svg>`;