// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_SERVER_URL: '',
  RECAPCHA_SITE_KEY: '6Ld8CvogAAAAANinsBYesdCA5xeiMNjqJogEmR_f',
  GOOGLE_MAPS_KEY: 'AIzaSyCOW_MDmnZozbeCBTjps9OLZb7dzmyPnVs', 
  PAYPAL_CLIENT_ID: 'sb',
  GA_TRACKING_ID: "G-BD010J97NV",
  STRIPE_PUBLISHABLE_KEY: 
  'pk_test_51L39n0H362D9EKLF0TZueKWp9vInQI5xOd87FjGYseJbWWfnyNPz766TzqF18rZ9c8dILNecNdZ7syO7zsdvSUqa00Is4HNm3Q',
  firmLogo: 'https://scj-prd-firm.s3.us-east-2.amazonaws.com/logo/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
