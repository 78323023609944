import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, NgForm } from '@angular/forms';
import { NUMBERS_PATTERN, PASSWORD_PATTERN, PHONE_NUMBER_PATTERN, TEXT_NUMBERS_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { CustomValidators } from '../shared/validators/form-validators/custom.validators';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../shared/services/notification.service';
import { MatTooltip } from '@angular/material/tooltip';
import { ComponentCanDeactivate } from '../shared/services/can-deactivate.guared';
import { AuthenticationService } from '../shared/services/authentication.service';
import moment from 'moment';
import { ClientContactsModel } from '../models/client-contacts.model';
import { AddressModel } from '../models/address.model';
import { GoogleMapsService } from '../shared/services/google-maps.service';
import { ClientValidationMessages } from '../shared/validators/validation-messages/client-validation.messages';
import { COUNTRY_CODES_NAMES } from '../shared/constants/country-codes-names';

@Component({
  selector: 'scj-firm-preferences',
  templateUrl: './firm-preferences.component.html',
  styleUrls: ['./firm-preferences.component.scss']
})
export class FirmPreferencesComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
  changePasswordForm: FormGroup;
  hideCurrentPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideRetypePassword: boolean = true;
  passwordTooltip = 'Password must be 8 characters length with atleast 1 lowercase, 1 upper case letter & numbers';
  role: boolean = true;
  isPasswordSpinner = false;
  isMyOrgSpinner = false;
  isBusiness = false;
  clientId: string;
  myOrgForm: FormGroup;
  country: FormControl = new FormControl('US');
  countries = COUNTRY_CODES_NAMES;
  errorMessages = ClientValidationMessages;
  orgErrorsList: string[] = [];

  @ViewChild('formDirective')
  formDirective: NgForm;

  @ViewChild('addressText')
  addressText: ElementRef;

  @ViewChild('zip')
  zip: ElementRef;

  constructor(private fb: FormBuilder, private http: HttpCommonService, private notificationService: NotificationService,
    private authenticationService: AuthenticationService, private gmService: GoogleMapsService) {
    this.role = !this.authenticationService.userValue.roles.includes('firmclient');
    this.clientId = this.role ? undefined : this.authenticationService.userValue.clientId;
  }

  ngOnInit(): void {
    this.initializeChangePasswordForm();

    if (this.clientId) {
      this.getClientDetails();
    }

    this.gmService.addressComponent.subscribe((place: any) => {
      let clientAddress = new AddressModel();
      clientAddress.addressLine1 = '';
      place.address_components.forEach((el: any) => {
        if (el.types?.indexOf('street_number') > -1) {
          clientAddress.addressLine1 = el.short_name + ' ';
        }
        if (el.types.indexOf('route') > -1) {
          clientAddress.addressLine1 = clientAddress.addressLine1 + el.short_name;
        }

        if (el.types.indexOf('locality') > -1) {
          clientAddress.city = el.long_name
        }

        if (el.types.indexOf('administrative_area_level_1') > -1) {
          clientAddress.state = el.short_name
        }

        if (el.types.indexOf('postal_code') > -1) {
          clientAddress.zip = el.short_name;
        }
      });
      clientAddress.country = this.country.value;
      this.myOrgForm.get('clientAddress')?.patchValue(clientAddress);
      this.zip.nativeElement.focus();
      this.zip.nativeElement.blur();
    });
  }

  initializeChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      currentPassword: new FormControl('', [Validators.required, Validators.pattern(new RegExp(PASSWORD_PATTERN))]),
      password: new FormControl('', [Validators.required, Validators.pattern(new RegExp(PASSWORD_PATTERN))]),
      retypePassword: new FormControl('', [Validators.required])
    }, {
      validator: CustomValidators.passwordMatch('password', 'retypePassword')
    });
  }

  changePassword() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      this.isPasswordSpinner = true;
      this.http.updateUserPassword(this.changePasswordForm.getRawValue()).subscribe((res: HttpResponse<any>) => {
        this.isPasswordSpinner = false;
        if (res.status === 200) {
          this.notificationService.notifyText("Password has been changed successfully.");
          this.changePasswordForm.reset();
          this.formDirective.resetForm();
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time");
        }
      }, (error: HttpErrorResponse) => {
        this.isPasswordSpinner = false;
        this.notificationService.notifyText("Something went wrong!! Please try after some time");
      });
    }
  }

  reset() {
    this.changePasswordForm.reset();
    this.formDirective.resetForm();
  }

  toggle(tooltip: MatTooltip) {
    return tooltip.toggle();
  }

  getClientDetails() {
    this.http.getClientById(this.clientId).subscribe((res: HttpResponse<ClientContactsModel>) => {
      if (res.body !== null) {
        this.isBusiness = res.body.isBusiness;
        if (this.isBusiness) {
          this.initializeMyOrgForm()
          if (res.body.clientAddress === null) {
            delete res.body.clientAddress;
          }

          this.myOrgForm.patchValue(res.body);
          this.ngAfterViewInit();
        }
      }
    }, (error: HttpResponse<any>) => {
      this.isBusiness = false;
    });
  }

  canDeactivate() {
    return !this.changePasswordForm.dirty || !this.myOrgForm.dirty;
  }

  initializeMyOrgForm() {
    this.myOrgForm = new FormGroup({
      businessName: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required, Validators.maxLength(250)])),
      businessWebsite: new FormControl({ value: '', disabled: false }, Validators.compose([CustomValidators.website, Validators.maxLength(250)])),
      businessEmail: new FormControl({ value: '', disabled: false }, Validators.compose([ Validators.email, Validators.maxLength(250)])),
      businessPhonenumber: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.pattern(PHONE_NUMBER_PATTERN), Validators.maxLength(25)])),
      einNumber: new FormControl({ value: '', disabled: false }),
      startDate: new FormControl({ value: '', disabled: true }),
      clientAddress: this.fb.group({
        addressLine1: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.maxLength(500)])),
        city: new FormControl({ value: '', disabled: false }),
        state: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.maxLength(150)])),
        zip: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.maxLength(15)])),
        country: this.country
      })
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.gmService.getAddressAutoComplete(this.addressText, ['address']);
    }, 2000);
  }

  onSubmit() {
    this.myOrgForm.markAllAsTouched();
    this.orgErrorsList = [];
    if (this.myOrgForm.valid) {
      this.isMyOrgSpinner = true;
      let myOrgFormRawValue = this.myOrgForm.getRawValue() as ClientContactsModel;
      delete myOrgFormRawValue.startDate;
      this.http.updateClientOrg(this.clientId, myOrgFormRawValue).subscribe((res: HttpResponse<ClientContactsModel>) => {
        this.isMyOrgSpinner = false;
        if (res.status === 200) {
          this.notificationService.notifyText("Organization details updated successfully!!");
          this.myOrgForm.reset(myOrgFormRawValue);
        }
      }, (err: HttpErrorResponse) => {
        this.isMyOrgSpinner = false;
        if (err.status === 400) {
          err.error?.subErrors.forEach((error: any) => this.orgErrorsList.push(error.message));
        } else {
          this.notificationService.notifyText("Something went wrong!! Please try after some time.");
        }
      });
    }
  }

}
