import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ClientContactsModel } from './models/client-contacts.model';
import { SignAttachmentComponent } from './sign-attachment/sign-attachment.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  client: ClientContactsModel;
  triggerMessages: BehaviorSubject<boolean | undefined> = new BehaviorSubject(undefined);

  constructor(private dialog: MatDialog) { }

  setTriggerMessages(value: boolean){
    return this.triggerMessages.next(value);
  }

  getTriggerMessages(){
    return this.triggerMessages.asObservable();
  }

  esign(element:any){
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '75%';
    dialogConfig.height = '80%';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      attachment: element,
      view: 'SIGN'
    }
    dialogConfig.panelClass = 'client-signature';
    return this.dialog.open(SignAttachmentComponent, dialogConfig).afterClosed()
  }
}
