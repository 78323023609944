<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" class="container">

        <div class="client-name" (click)="navigate()">{{clientName}}</div>

        <div #scroll [scrollTop]="scroll.scrollHeight">
            <ng-container *ngFor="let message of messagesList">
                <div class="flex-column message-body">
                    <div class="flex-row">
                        <div class="name-highlight" [class.client]="!message.isCpa">
                            {{message.createdBy | letters}}
                        </div>
                        <div [style.flex]="'1'">
                            <span class="name">{{message.createdBy}}</span>
                            <span class="date">{{message.createdOn}}</span>
                            <div [innerHtml]="message.message | domSafe"> </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="flex-row message-container">
            <div #inputMessage placeholder="Type your message" contenteditable="true" class="input-message"></div>
            <mat-icon *ngIf="!sendSpinner" (click)="createMessages()">send</mat-icon>
            <mat-spinner *ngIf="sendSpinner" [diameter]="24" [strokeWidth]="2"></mat-spinner>
            <mat-icon *ngIf="!refreshSpinner" (click)="getMessages()">refresh</mat-icon>
            <mat-spinner *ngIf="refreshSpinner" [diameter]="24" [strokeWidth]="2"></mat-spinner>
        </div>

    </div>
</div>