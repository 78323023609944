<!-- <div class="stepper-bar">
    <div *ngFor="let e of statusData" class="step">
        <div class="step-icon" [ngClass]="!(e.status === 'Not Started')? 'step-icon-active' : 'step-icon-inactive'">
            <div class="step-icon-content">
                <mat-icon *ngIf="!(e.status === 'Not Started')">{{e.status === 'In Progress' ? 'more_horiz' : 'done'}}
                </mat-icon>
            </div>
        </div>
        <div [class.boldfont]="e.status === 'In Progress'">
            {{e.name}}
        </div>
    </div>
    <div class="vertical-line"></div>
</div> -->

<!-- <div class="progress">
    <ul class="">
        <li *ngFor="let e of statusData" class="">
            <div class="step-icon-content">
                <div class="step-icon"
                    [ngClass]="!(e.status === 'Not Started')? 'step-icon-active' : 'step-icon-inactive'">
                    <mat-icon *ngIf="!(e.status === 'Not Started')">{{e.status === 'In Progress' ? 'more_horiz' :
                        'done'}}
                    </mat-icon>
                </div>
                <div class="step-content boldfont">
                    {{(e.status === 'In Progress')? e.name : ''}}
                </div>
            </div>
        </li>
    </ul>
</div> -->

<div class="stepper-container">
    <ng-container *ngFor="let e of statusData;let last=last">
        <div class="step" [ngClass]="!(e.status === 'Not Started')? 'step-active' : 'step-inactive'">
            <div class="step-icon" #tooltip="matTooltip" [matTooltip]="e.name"
                [ngClass]="!(e.status === 'Not Started')? 'step-icon-active' : 'step-icon-inactive'">
                <div *ngIf="!(e.status === 'Not Started')" [class.circle]="e.status === 'In Progress'">
                </div>
            </div>
            <div class="step-label boldfont">
                {{(e.status === 'In Progress')? e.name : ''}}
            </div>
        </div>

        <!-- <div *ngIf="!last" class="step-horizontal-line"> </div> -->
    </ng-container>

</div>