import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HandSetService } from '../shared/services/hand-set.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { User } from '../models/user.model';
import { HttpCommonService } from '../services/http-common.service';
import { DataService } from '../data-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatFabComponent } from '../chat-fab/chat-fab.component';
import { NotificationService } from '../shared/services/notification.service';
import { UsersListModel } from '../models/users-list.model';
import { CdkDragStart } from '@angular/cdk/drag-drop';


@Component({
  selector: 'scj-firm-nav',
  templateUrl: './firm-navigation.component.html',
  styleUrls: ['./firm-navigation.component.scss']
})
export class FirmNavigationComponent implements OnInit {
  isHandset$: Observable<boolean>;
  navHeaderTitle: string = '';
  headerTitle: string = '';
  navigationLinks: any[];
  user: User;
  isFirmClient: boolean;
  isFirmAdmin: boolean;
  isFirmEmployee: boolean;
  messageCount: number = 0;
  messageIndicator: boolean = false;
  dragging: boolean = false;

  firmEmployeeNavigationLinks: any[] = [
    {
      link: 'home', title: 'Home', icon: 'apps', type: '', active: true
    },
    {
      link: 'clients-list', title: 'Clients', icon: 'account_balance_wallet_outlined', type: '', active: false
    },
    {
      link: 'all-tasks', title: 'Tasks', icon: 'assignment_turned_in_outlined', type: '', active: false
    },
    {
      link: 'preferences', title: 'Preferences', icon: 'tune_outlined', type: '', active: false
    }
  ];

  firmNavigationLinks: any[] = [
    {
      link: 'home', title: 'Home', icon: 'apps', type: '', active: true
    },
    {
      link: 'clients-list', title: 'Clients', icon: 'account_balance_wallet_outlined', type: '', active: false
    },
    {
      link: 'all-tasks', title: 'Tasks', icon: 'assignment_turned_in_outlined', type: '', active: false
    },
    {
      link: 'billing', title: 'Invoices', icon: 'monetization_on_outlined', type: '', active: false
    },
    {
      link: 'messages', title: 'Messages', icon: 'chat_bubble_outlined', type: '', active: false
    },
    {
      link: 'my-org', title: 'My Org', icon: 'myOrg', type: "svg", active: false, separator: true
    },
    {
      link: 'preferences', title: 'Preferences', icon: 'tune_outlined', type: '', active: false
    }
  ];

  clientNavigationLinks: any[] = [
    {
      link: 'home', title: 'Home', icon: 'apps', type: '', active: true
    }, {
      link: 'services', title: 'Subscriptions', icon: 'description_outlined', type: '', active: false
    }, {
      link: 'invoices', title: 'Invoices', icon: 'monetization_on_outlined', type: '', active: false
    }, {
      link: 'files', title: 'Files', icon: 'attachment_outlined', type: '', active: false
    },
    {
      link: 'firm-services', title: 'Services', icon: 'add_shopping_cart', type: '', active: false
    },//{firm-services
    //link: 'messages', title: 'Messages', icon: 'chat_bubble_outlined', type: '', active: false
    //},
    {
      link: 'preferences', title: 'Preferences', icon: 'tune_outlined', type: '', active: false, separator: true
    }
  ];

  @ViewChild('headerButton')
  headerButton: TemplateRef<any>;

  @ViewChild('feedback')
  feedbackButton: TemplateRef<any>;

  feedbackForm: FormGroup;
  enableFeedback: boolean = false;

  constructor(private handSet: HandSetService, public router: Router, private authenticationService: AuthenticationService,
    private http: HttpCommonService, private dataService: DataService, private dialog: MatDialog, private fb: FormBuilder, private notificationService: NotificationService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.user = this.authenticationService.userValue;

    this.isFirmAdmin = this.user.roles.includes("firmadmin");
    this.isFirmEmployee = this.user.roles.includes("firmemployee");
    this.isFirmClient = this.user.roles.includes("firmclient")
  }

  ngOnInit() {
    if (this.isFirmAdmin) {
      this.navigationLinks = this.firmNavigationLinks;
      this.navHeaderTitle = this.user.firmName;
      this.dataService.getTriggerMessages().subscribe((val: any) => {
        this.http.getCompanyUnreadMessages().subscribe((res: any) => {
          this.messageIndicator = (res.body === "false") ? false : true;
        }, err => {
          this.messageIndicator = false;
          console.log(err);
        });
      });
      this.enableFeedback = true;
    } else if (this.isFirmEmployee) {
      this.navigationLinks = this.firmEmployeeNavigationLinks;
      this.navHeaderTitle = this.user.firmName;
      this.enableFeedback = true;
    }
    else if (this.isFirmClient) {
      this.navigationLinks = this.clientNavigationLinks;
      this.navHeaderTitle = this.user.firmName;
      this.http.getClientUserUnreadMessages().subscribe((res: any) => {
        this.messageCount = res.body;
      }, err => console.log(err));
    }

    this.isHandset$.subscribe((handSet: boolean) => {
      if (handSet) {
        this.navigationLinks.forEach(el => {
          if (this.router.url.indexOf(el.link) !== -1) {
            this.headerTitle = el.title;
          }
        })
      }
    });

    this.feedbackForm = this.fb.group({
      feedbackcontent: ['', Validators.required]
    })
  }

  highlightLinks(link: string) {
    this.navigationLinks.forEach((e, i) => {
      if (e.link === link) {
        this.navigationLinks[i].active = true;
      } else {
        this.navigationLinks[i].active = false;
      }
    });
    this.resetMessageCount(link);
  }

  openTopNav() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '250px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'transparent-mat-dialog-backdrop';
    dialogConfig.panelClass = 'nav-panel';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = { navHeaderTitle: this.navHeaderTitle, name: this.user.fullname, email: this.user.username };
    dialogConfig.position = { top: '48px', right: '0' };
    this.dialog.open(this.headerButton, dialogConfig);
  }

  openChat(event: MouseEvent) {
    if (this.dragging) {
      this.dragging = false;
      return
    }
    this.messageCount = 0;
    this.dataService.setTriggerMessages(true);
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.handSet.isHandset ? '80vw' : '400px';
    dialogConfig.height = '75%';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = 'chat-panel';
    dialogConfig.data = { clientId: this.authenticationService.userValue.clientId };
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'transparent-mat-dialog-backdrop';
    dialogConfig.position = this.handSet.isHandset ? { bottom: '8px', left: '25px' } : { bottom: '12px', left: '72px' };

    this.dialog.open(ChatFabComponent, dialogConfig);
  }

  logout() {
    this.authenticationService.logout();
  }

  resetMessageCount(path: string) {
    if (path === 'messages') {
      this.messageIndicator = false;
      this.messageCount = 0;
    }
  }

  onImgError(event: { target: { src: string; }; }) {
    event.target.src = this.headerTitle;
  }

  onSubmitFeedback(content: any) {
    let msg = { message: content.feedbackcontent };
    this.http.setFeebackMessege(msg).subscribe(
      (data) => {
        this.notificationService.notifyText('Thank you for the Feedback!');
        this.feedbackForm.reset();
        this.dialog.closeAll();
      },
      (err) => {
        this.notificationService.notifyText('Error in sending Feedback!');
        this.feedbackForm.reset();
      }
    );
  }

  openFeedback() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.handSet.isHandset ? '80vw' : '350px';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '50vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.position = this.handSet.isHandset ? { bottom: '50%', right: '10px' } : { top: '8%', right: '10px' };
    this.dialog.open(this.feedbackButton, dialogConfig);
  }

  goTocancel() {
    this.dialog.closeAll();
  }

  public handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }
}
