import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { DataService } from '../data-service.service';
import { AddressModel } from '../models/address.model';
import { NewUserModel } from '../models/new-user.model';
import { RoleModel } from '../models/role.model';
import { HttpCommonService } from '../services/http-common.service';
import { STATUS_FIELDS } from '../shared/constants/app.constants';
import { COUNTRY_CODES_NAMES } from '../shared/constants/country-codes-names';
import { DATE_DD_MMM_YYYY_FORMAT } from '../shared/date-formats/date.format';
import { GoogleMapsService } from '../shared/services/google-maps.service';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';
import { PHONE_NUMBER_PATTERN } from '../shared/validators/regex-expressions/regex-expressions';
import { UserValidationMessages } from '../shared/validators/validation-messages/user-validation.messages';

@Component({
  selector: 'scj-client-user',
  templateUrl: './client-user.component.html',
  styleUrls: ['./client-user.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  { provide: MAT_DATE_FORMATS, useValue: DATE_DD_MMM_YYYY_FORMAT }]
})
export class ClientUserComponent implements OnInit {
  isHandset$: Observable<boolean>;
  disable: boolean = false;
  userForm: FormGroup = new FormGroup({});
  country: FormControl = new FormControl('US');
  errorMessages = UserValidationMessages;
  countries = COUNTRY_CODES_NAMES;
  userStatus = [...STATUS_FIELDS];
  userId: string;
  isLoading = true;
  isError = false;
  header: string = '';
  roles: RoleModel[];
  sendEmail: boolean = false;
  errorsList: string[] = [];
  isSpinner = false;
  sendEmailSpinner = false;

  @ViewChild('addressText')
  addressText: ElementRef;

  @ViewChild('zip')
  zip: ElementRef;

  constructor(private handSet: HandSetService, private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private router: Router, private httpService: HttpCommonService, private gmService: GoogleMapsService,
    private notificationService: NotificationService, private dataService: DataService) {
    this.isHandset$ = this.handSet.isHandset$;
    this.userId = this.activatedRoute.snapshot.params?.userId;
  }

  ngOnInit(): void {
    this.disable = this.userId ? true : false;

    this.initializeForm();

    if (this.userId) {
      this.userForm.setControl('status', new FormControl(''));
      this.httpService.getUserById(this.userId).subscribe((res: HttpResponse<NewUserModel>) => {
        if (res.body !== null) {
          this.header = res.body.fullname;

          if (res.body.address === null)
            delete res.body.address;

          this.userForm.patchValue(res.body);
          this.userForm.disable();
          if (res.body.status === 'PENDING_ACTIVATION') {
            this.userStatus.push({ statKey: 'PENDING_ACTIVATION', statValue: 'Pending' });
            //this.userForm.get('status');
            this.sendEmail = true;
          } else {
            this.userForm.get('status').enable();
            this.userForm.get('address').disable();
            this.userForm.get('phoneNumber').disable();
            this.userForm.get('username').disable();
          }
          this.userForm.controls['created'].setValue(moment(res.body.created ? moment(res.body.activated) : null));
          this.userForm.controls['activated'].setValue(res.body.activated ? moment(res.body.activated) : null);
          this.isLoading = false;
        }
      }, (error: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        console.log("error", error);
      });
    } else {
      this.isLoading = false;
    }

    this.gmService.addressComponent.subscribe((place: any) => {
      let address = new AddressModel();
      address.addressLine1 = '';
      place.address_components.forEach((el: any) => {
        if (el.types?.indexOf('street_number') > -1) {
          address.addressLine1 = el.short_name + ' ';
        }
        if (el.types.indexOf('route') > -1) {
          address.addressLine1 = address.addressLine1 + el.short_name;
        }

        if (el.types.indexOf('locality') > -1) {
          address.city = el.long_name
        }

        if (el.types.indexOf('administrative_area_level_1') > -1) {
          address.state = el.short_name
        }

        if (el.types.indexOf('postal_code') > -1) {
          address.zip = el.short_name;
        }
      });
      address.country = this.country.value;
      this.userForm.get('address')?.patchValue(address);
      this.zip.nativeElement.focus();
      this.zip.nativeElement.blur();
    });
  }

  initializeForm() {
    this.userForm = this.fb.group({
      fullname: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(250)])),
      prefferedName: new FormControl('', Validators.compose([Validators.maxLength(250)])),
      comments: new FormControl('', Validators.compose([Validators.maxLength(4000)])),
      address: this.fb.group({
        addressLine1: new FormControl('', Validators.compose([Validators.maxLength(500)])),
        city: new FormControl('', Validators.compose([])),
        state: new FormControl('', Validators.compose([Validators.maxLength(150)])),
        zip: new FormControl('', Validators.compose([Validators.maxLength(15)])),
        country: this.country
      }),
      phoneNumber: new FormControl('', Validators.compose([Validators.pattern(PHONE_NUMBER_PATTERN)])),
      username: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.maxLength(250)]))
    });
    if (this.userId) {
      this.userForm.addControl('created', new FormControl(null));
      this.userForm.addControl('activated', new FormControl(null));
      this.userForm.addControl('createdBy', new FormControl(null));
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.gmService.getAddressAutoComplete(this.addressText, ['address']);
    }, 2000);
  }

  onSubmit() {
    this.userForm.markAllAsTouched();
    this.errorsList = [];
    if (this.userForm.valid) {
      this.isSpinner = true;
      let userFormRawValue = this.userForm.getRawValue() as NewUserModel;
      // userFormRawValue.startDate = formatMomentDate(userFormRawValue.startDate);
      // userFormRawValue.endDate = userFormRawValue.endDate ? formatMomentDate(userFormRawValue.endDate) : null;
      userFormRawValue['clientId'] = this.dataService.client.clientId;
      if (!this.userId) {
        this.httpService.createClientUser(userFormRawValue).subscribe((res: HttpResponse<NewUserModel>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("New user created successfully!!");
            this.userForm.reset();
            this.router.navigate([`../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }

      if (this.userId) {
        this.httpService.updateUser(this.userId, userFormRawValue).subscribe((res: HttpResponse<NewUserModel>) => {
          this.isSpinner = false;
          if (res.status === 200) {
            this.notificationService.notifyText("User details updated successfully!!");
            this.userForm.reset();
            this.router.navigate([`../../list`], { relativeTo: this.activatedRoute });
          }
        }, (err: HttpErrorResponse) => {
          this.isSpinner = false;
          if (err.status === 400) {
            err.error?.subErrors.forEach((error: any) => this.errorsList.push(error.message));
          } else {
            this.notificationService.notifyText("Something went wrong!! Please try after some time.");
          }
        });
      }
    } else {
      this.notificationService.notifyText("Currently client details update not allowed");
    }
  }

  cancel() {
    this.router.navigate([this.userId ? '../../list' : '../list'], { relativeTo: this.activatedRoute });
  }

  resendActivationMail() {
    this.sendEmailSpinner = true;
    this.httpService.sendUserActivationMail(this.userId).subscribe((res: HttpResponse<any>) => {
      this.notificationService.notifyText("User activation mail resent successfully!!");
      this.sendEmailSpinner = false;
    }, (error: HttpResponse<any>) => {
      this.sendEmailSpinner = false;
      console.log("error", error);
    });
  }

  canDeactivate() {
    return !this.userForm.dirty;
  }

}
