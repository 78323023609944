import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse } from '@angular/common/http';
import { NewFirmModel } from '../models/new-firm.model';

@Component({
  selector: 'scj-firm-details',
  templateUrl: './firm-details.component.html',
  styleUrls: ['./firm-details.component.scss']
})
export class FirmDetailsComponent implements OnInit {
  firmId: string;
  firm: NewFirmModel;
  isLoading = true;
  isError = false;
  firmLinks: any[] = [
    { path: 'edit', label: 'Details' },
    { path: 'billing', label: 'Billing' },
    { path: 'assign-workflow', label: 'Workflows' }
  ];
  activeLink: string = this.firmLinks[0].path;

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpCommonService, private router: Router) {
    this.firmId = this.activatedRoute.snapshot.params.firmId;
  }

  ngOnInit(): void {
    if (this.firmId) {
      this.httpService.getFirmById(this.firmId).subscribe((res: HttpResponse<NewFirmModel>) => {
        if (res.body !== null) {
          this.firm = res.body;
          this.isLoading = false;
        }
      }, (err: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        console.log("Error", err);
      });
    }
  }

  navigate(url: string) {
    this.activeLink = url;
    this.router.navigateByUrl(`/user/manage-firm/${this.firmId}/${url}`);
  }
}
