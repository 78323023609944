import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoCompleteService {

  constructor() { }

  filterByKey(list: any[], key: string, value: any): any[] {
    return list.filter((e: any) => e[key]?.trim().toLowerCase().includes(value?.trim().toLowerCase()));
  }
}
