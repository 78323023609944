<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="(isHandset$ | async)" (click)="showMore = !showMore" class="flex-row showMore">
        <div class="scj-subheader">Clients</div>
        <button mat-icon-button>
            <mat-icon>{{showMore? 'expand_less' : 'expand_more'}}</mat-icon>
        </button>
    </div>

    <div *ngIf="!isError && !isLoading" class="flex-row messages-container">
        <div class="flex-column messages-list" *ngIf="showMore">
            <div class="flex-row filter-refresh">
                <mat-slide-toggle class="mat-toggle" color="primary" [formControl]="unreadMessageControl"
                    [disableRipple]="true">Unread only</mat-slide-toggle>
                <mat-icon *ngIf="!isRefresh" (click)="refresh()">refresh</mat-icon>
                <mat-spinner *ngIf="isRefresh" [diameter]="24" [strokeWidth]="2"></mat-spinner>
            </div>

            <div class="search">
                <mat-icon>search</mat-icon>
                <input [formControl]="searchControl" placeholder="Search client">
                <!-- <mat-icon *ngIf="searchControl.value.length > 0" (click)="refresh()">refresh</mat-icon> -->
            </div>

            <div class="chats">
                <div *ngIf="filteredMessages.length > 0 ;else noMessages">
                    <div *ngFor="let message of filteredMessages" class="flex-row chat" (click)="loadMessage(message)"
                        [class.active-chat]="selectedMessage===message">
                        <div class="client-name">{{message.name}}</div>
                        <div class="message-count">{{message.messageCount > 0? message.messageCount : ''}}</div>
                        <mat-icon *ngIf="selectedMessage===message">chevron_right</mat-icon>
                    </div>
                </div>

                <ng-template $noMessages>
                    No messgaes found
                </ng-template>
            </div>
        </div>
        <div class="full-message">
            <scj-client-messages [clientId]="selectedMessage.clientId" [clientName]="selectedMessage.name">
            </scj-client-messages>
        </div>
    </div>