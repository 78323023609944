import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HandSetService } from '../shared/services/hand-set.service';
import { HttpCommonService } from '../services/http-common.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { ClientsListModel } from '../models/client-list.model';
import { COLUMN_FILTER_TYPE, COLUMN_FORMAT, COLUMN_TYPES } from '../shared/enums/table.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertMessagesComponent } from '../common/components/alert-messages/alert-messages.component';
import { NotificationService } from '../shared/services/notification.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ClientServiceAssignmentComponent } from '../client-service-assignment/client-service-assignment.component';
import { ServicesListModel } from '../models/services-list.model';
import { PaginationRequestModel } from '../models/pagination-request.model';
import { PaginationModel } from '../models/pagination.model';
import { formatMomentDate } from '../shared/date-formats/format-moment-date';

@Component({
  selector: 'scj-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  clientListData: PaginationModel;
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();
  enableButton: boolean = false;
  selectedList: ClientsListModel[] = [];
  isSpinner: boolean = false;
  serviceList: ServicesListModel[] = [];
  serviceName: string;
  reqBody: PaginationRequestModel;

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router,
    private matDialog: MatDialog, private notificationService: NotificationService, private tfService: TableFilterService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.tfService.initializeColumnFilter(this.columnFilterConfig);
    this.initializeRequest();
    this.getClientList();
    this.getAvailableServices();

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchText: string) => {
      //this.tfService.setSearchValue(filterVal.trim().toLowerCase());
      this.reqBody.searchText = searchText;
      this.reqBody.page.index = 0;
      this.modifyPageData(this.reqBody);
    });
  }

  initializeRequest() {
    this.reqBody = {
      page: {
        index: 1,
        size: 20
      },
      sort: {
        column: "accountNumber",
        order: "desc"
      }
    } as PaginationRequestModel;
  }


  getClientList() {
    this.httpService.getPaginatedClientsList(this.reqBody).subscribe((res: HttpResponse<PaginationModel>) => {
      if (res.body !== null) {
        console.log(res.body);
        this.clientListData = res.body;
      }
      this.isLoading = false;
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      console.log("Error", err);
    });
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'name', header: 'Name', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'displayStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip', classType: 'user' },
      { name: 'cityState', header: 'Address', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, },
      { name: 'activeSubscriptions', header: 'Active Subscriptions', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, },
      { name: 'amountDue', header: 'Amount Due', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'accountNumber', header: 'Account Number', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER },
      { name: 'lastAccessed', header: 'Last Accessed', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE, columnValue: 'name' },
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'name', label: 'Name', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'displayStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Pending', 'Inactive'] },
      { name: 'cityState', label: 'Address', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'activeSubscriptions', label: 'Active Subscriptions', type: COLUMN_FILTER_TYPE.NUMBER, value: [] },
      { name: 'amountDue', label: 'Amount Due', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'accountNumber', label: 'Account Number', type: COLUMN_FILTER_TYPE.NUMBER, value: [] },
      { name: 'lastAccessed', label: 'Last Accessed', type: COLUMN_FILTER_TYPE.DATE, value: [] },
    ]
    return colFilters;
  }

  navigate(record: ClientsListModel) {
    this.router.navigateByUrl(`/firm/clients/${record.id}/services/client-services`);
  }

  selectedClientList(list: ClientsListModel[]) {
    this.selectedList = list;

    if (list.length === 0) {
      this.enableButton = false;
      return;
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].status !== 'PENDING') {
        this.enableButton = false;
        return;
      }
    }
    this.enableButton = true;
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }

  getSelectedClientList() {
    let clientId: string[] = [];
    let clientName: string[] = [];
    this.selectedList.forEach((e: ClientsListModel) => {
      clientId.push(e.id);
      clientName.push(e.name);
    });
    return { clientId: clientId, clientName: clientName };
  }

  modifyPageData(event: PaginationRequestModel) {
    
    this.isError = false;
    delete event.filter;
    this.reqBody = event;
    this.isLoading = true;
    if(this.searchControl.value){
    this.reqBody.searchText = this.searchControl.value;
    }
    this.getClientList();
  }

  deleteClient() {
    let list = this.getSelectedClientList();
    let header = 'Confirm Delete';
    let message = `Are you sure you want to delete clients? Client list: <b> ${list.clientName.join(", ")} </b>`;
    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.deletePendingClient(list.clientId);
        } else {
          return of(confirm);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! clients deleted`);
          this.getClientList();
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to delete clients. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  resendActivationLink() {
    let list = this.getSelectedClientList();
    let header = 'Confirm Resend Activation Mail';
    let message = `Are you sure you want to resend activation mail to clients? Client list: <b> ${list.clientName.join(", ")}  </b>`;
    this.opeDialog(header, message).afterClosed().pipe(
      switchMap((confirm: any) => {
        if (confirm) {
          this.isSpinner = true;
          return this.httpService.sendClientActivationMail(list.clientId);
        } else {
          return of(confirm);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! Activation mail sent to clients`);
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to send activation mail. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  opeDialog(header: string, message: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '20%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    dialogConfig.data = {
      header: header,
      message: message,
      isHTML: true,
      buttonText: { ok: 'Cancel', cancel: 'Yes' },
      revertBoolean: true
    }
    return this.matDialog.open<AlertMessagesComponent, void, MatDialogConfig>(AlertMessagesComponent, dialogConfig)
  }

  assignSubscription() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '30%';
    dialogConfig.width = 'auto';
    dialogConfig.maxWidth = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.closeOnNavigation = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'alert-dialog';
    let clientList = [...this.selectedList].sort((a: ClientsListModel, b: ClientsListModel) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    let serviceList = [...this.serviceList].sort((a: ServicesListModel, b: ServicesListModel) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    dialogConfig.data = {
      header: "Assign Service",
      clientList: clientList,
      serviceList: serviceList
    }

    this.matDialog.open<ClientServiceAssignmentComponent, void, MatDialogConfig>(ClientServiceAssignmentComponent, dialogConfig)
      .afterClosed().pipe(switchMap((data: any) => {
        if (data) {
          this.isSpinner = true;
          this.serviceName = data.serviceName;
          let req = {
            "clientIds": this.selectedList.map((e: ClientsListModel) => e.id),
            "serviceId": data.serviceId
          };
          return this.httpService.clientServiceAssignment(req);
        } else {
          return of(data);
        }
      })).subscribe((res: boolean | HttpResponse<any>) => {
        if (res) {
          this.isSpinner = false;
          this.notificationService.notifyText(`Success! clients are assigned to service ${this.serviceName}`);
        }
      }, (err) => {
        this.isSpinner = false;
        this.notificationService.notifyText(`Failed! Unable to Assign service. 'If the Problem persists Please contact support@simplecj.com`);
      });
  }

  getAvailableServices() {
    this.httpService.getAvailableServicesList().subscribe((res: HttpResponse<ServicesListModel[]>) => {
      if (res.body !== null && res.body?.length > 0) {
        this.serviceList = res.body;
      } else {
        this.notificationService.notifyText("There are no services available.", "X")
      }
    }, (err: HttpResponse<any>) => {
      console.log("Error", err);
      this.notificationService.notifyText("Error while fetching services list.", "X")
    });
  }
}