import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionNotesModel } from '../models/subscription-notes.model';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'scj-subscription-notes',
  templateUrl: './subscription-notes.component.html',
  styleUrls: ['./subscription-notes.component.scss']
})
export class SubscriptionNotesComponent implements OnInit {
  isLoading = false;
  isError = false;
  refreshSpinner = false;
  sendSpinner = false;
  notesList: SubscriptionNotesModel[];
  isClient: boolean = false;
  subscriptionId: string;
  isPrivate: boolean = true;

  @ViewChild('inputMessage')
  inputMessage: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<SubscriptionNotesComponent>,
    private http: HttpCommonService, private notificationService: NotificationService, private authenticationService: AuthenticationService) {
    this.isClient = this.authenticationService.userValue.roles.includes("firmclient");
    this.subscriptionId = this.data.subscriptionId;
  }

  ngOnInit(): void {
    this.getSubscriptionNotes();
  }

  getSubscriptionNotes() {
    this.http.getSubscriptionNotes(this.subscriptionId).subscribe((res: HttpResponse<any[]>) => {
      if (res.body !== null) {
        this.notesList = res.body;
      }
      this.isLoading = false;
      this.refreshSpinner = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.refreshSpinner = false;
      this.notificationService.notifyText("Something went wrong while fetching messages!! Please try after some time.");
    });
  }

  createSubscriptionNotes() {
    let noteText = this.inputMessage.nativeElement.innerHTML;
    if (noteText.trim().length > 0) {
      this.sendSpinner = true;
      let note = {
        clientSubscriptionId: this.subscriptionId,
        internalOnly: this.isPrivate,
        noteText: noteText
      };
      this.http.createSubscriptionNotes(note).subscribe((res: HttpResponse<SubscriptionNotesModel[]>) => {
        this.sendSpinner = false;
        this.notificationService.notifyText("Note saved successfully.");
        this.inputMessage.nativeElement.innerHTML = '';
        this.getSubscriptionNotes();
      }, (err: HttpErrorResponse) => {
        this.sendSpinner = false;
        this.notificationService.notifyText("Unable to save notess.");
      });
    } else {
      this.notificationService.notifyText("Notes can't be empty.");
    }
  }

  refreshNotes() {
    this.refreshSpinner = true;
    this.getSubscriptionNotes();
  }
}
