<div class="flex-row jc-sb-ai-c">
    <h1 class="scj-h2 mr-20">Users</h1>
    <div class="search-filter">
        <div class="search-field">
            <mat-icon>search</mat-icon>
            <input type="text" [formControl]="searchControl">
        </div>

        <button class="filter-button" (click)="openTableFilter()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>


</div>

<div class="search-field" *ngIf="isHandset$ | async">
    <mat-icon>search</mat-icon>
    <input type="text" [formControl]="searchControl">
</div>


<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">
        <scj-table-content [data]="usersList" [searchControl]="searchControl" [columnConfig]="columnConfig"
            defaultSortColumn="creationDate" [pageSizeOptions]="[20, 40, 60, 80, 100]" exportFileName="My Users"
            (navigate)="navigate($event)" (selectionList)="selectedClientList($event)">
            <button primaryAction mat-flat-button routerLink="../create">
                <mat-icon class="button-icon">add</mat-icon>
                <span class="button-text">New</span>
            </button>

            <button secondaryAction class="secondary" mat-flat-button *ngIf="enableButton"
                (click)="resendActivationLink()" matTooltip="Resend Mail">
                <mat-icon class="button-icon">email</mat-icon>
                <span class="button-text">Resend Activation Link</span>
            </button>

            <button secondaryAction mat-flat-button (click)="deleteUser()" class="secondary" matTooltip="Delete"
                *ngIf="enableButton">
                <mat-icon class="button-icon">delete</mat-icon>
                <span class="button-text">Delete</span>
            </button>

            <div spinner *ngIf="isSpinner">
                <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
            </div>

        </scj-table-content>
    </div>
</div>