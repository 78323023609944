export const ClientValidationMessages = {
    "fullName": [
        { type: "required", message: "Full name is required" },
        //{ type: "pattern", message: "Letters & spaces are allowed" },
        { type: "maxlength", message: "Full name can't exceed 250 characters" }
    ],
    "prefferedName": [
        { type: "maxlength", message: "Preffered name can't exceed 250 characters" },
    ],
    "username": [
        { type: "required", message: "Email is required" },
        { type: "email", message: "Email is invalid" }
    ],
    "phoneNumber": [
        { type: "required", message: "Phone number is required" },
        { type: "pattern", message: "Phone number must be formatted or have only numbers" },
        { type: "maxlength", message: "Phone number can't exceed 25 characters" }
    ],
    "status": [
        { type: "required", message: "Status is required" }
    ],
    "businessName": [
        { type: "required", message: "Organization name is required" },
        { type: "maxlength", message: "Organization name can't exceed 250 characters" }
    ],
    "businessWebsite": [
        { type: "website", message: "Organization website is invalid" },
        { type: "maxlength", message: "Organization name can't exceed 250 characters" }
    ],
    "businessEmail": [
        { type: "required", message: "Organization email is required" },
        { type: "email", message: "Organization email is invalid" },
        { type: "maxlength", message: "Organization email can't exceed 250 characters" }
    ],
    "businessPhonenumber": [
        { type: "required", message: "Organization phone number is required" },
        { type: "pattern", message: "Organization phone number must be formatted or have only numbers" },
        { type: "maxlength", message: "Organization phone number can't exceed 25 characters" }
    ],
    "clientAddress": {
        "addressLine1": [
            { type: "maxlength", message: "Address can't exceed 500 characters" }
        ],
        "state": [
            { type: "maxlength", message: "State can't exceed 150 characters" }
        ],
        "zip": [
            { type: "maxlength", message: "Zip can't exceed 15 characters" }
        ]
    },
    "einNumber": [
        { type: "required", message: "EIN Number is required" },
        { type: "pattern", message: "EIN Number must be 9 digits numeric value" }
    ]
};