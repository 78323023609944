import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpCommonService } from '../services/http-common.service';
import { HttpResponse } from '@angular/common/http';
import { NotificationService } from '../shared/services/notification.service';
import { ClientContactsModel } from '../models/client-contacts.model';
import { DataService } from '../data-service.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChatFabComponent } from '../chat-fab/chat-fab.component';
import { CdkDragStart } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { HandSetService } from '../shared/services/hand-set.service';

@Component({
  selector: 'scj-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  isHandset$: Observable<boolean>;
  isLoading = true;
  isError = false;
  clientLinks = [
    { path: 'services', label: 'Subscriptions' },
    { path: 'billing', label: 'Invoices' },
    { path: 'client-email', label: 'eMails' },
    //{ path: 'client-messages', label: 'Messages' },
    { path: 'client-files', label: 'Files' },
    { path: 'client-users', label: 'Users' },
    { path: 'client-details', label: 'Details' },
  ];
  activeLink = this.clientLinks[0].path;
  clientId: string;
  client: ClientContactsModel;
  messageCount: number = 0;
  dragging = false;

  constructor(
    private handSet: HandSetService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpCommonService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private dialog: MatDialog
  ) {
    this.isHandset$ = this.handSet.isHandset$;
    this.clientId = this.activatedRoute.snapshot.params.clientId;
  }

  ngOnInit(): void {
    this.http.getCpaUnreadMessages(this.clientId).subscribe(
      (res: any) => {
        this.messageCount = res.body;
      },
      (err) => console.log(err)
    );

    this.http.getClientById(this.clientId).subscribe(
      (res: HttpResponse<ClientContactsModel>) => {
        if (res.body !== null) {
          this.client = this.dataService.client = res.body;
          this.dataService.client['clientId'] = this.clientId;
          this.isLoading = false;
        }
      },
      (error: HttpResponse<any>) => {
        this.isLoading = false;
        this.isError = true;
        this.notificationService.notifyText(
          'Something went wrong!! Please try after sometime.'
        );
        console.log('error', error);
      }
    );
  }

  openChat(event: MouseEvent) {
    if (this.dragging) {
      this.dragging = false;
      return;
    }
    this.messageCount = 0;
    this.dataService.setTriggerMessages(true);
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.handSet.isHandset ? '80vw' : '400px';
    dialogConfig.height = '75%';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = 'chat-panel';
    dialogConfig.data = { clientId: this.clientId };
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'transparent-mat-dialog-backdrop';
    dialogConfig.position = this.handSet.isHandset ? { bottom: '8px', left: '25px' } : { bottom: '12px', left: '72px' };

    let dialogRef = this.dialog.open(ChatFabComponent, dialogConfig);
  }

  public handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }
}
