import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirmNavigationComponent } from './firm-navigation/firm-navigation.component';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/services/auth.guard';
import { MyOrgComponent } from './my-org/my-org.component';
import { OrgDetailsComponent } from './org-details/org-details.component';
import { OrgAgreementsComponent } from './org-agreements/org-agreements.component';
import { OrgUsersComponent } from './org-users/org-users.component';
import { OrgNewAgreementComponent } from './org-new-agreement/org-new-agreement.component';
import { OrgUsersListComponent } from './org-users-list/org-users-list.component';
import { OrgAgreementsListComponent } from './org-agreements-list/org-agreements-list.component';
import { OrgNewUserComponent } from './org-new-user/org-new-user.component';
import { FirmBillingComponent } from './firm-billing/firm-billing.component';
import { OrgServicesComponent } from './org-services/org-services.component';
import { OrgServicesListComponent } from './org-services-list/org-services-list.component';
import { OrgNewServiceComponent } from './org-new-service/org-new-service.component';
import { ActivateUserComponent } from './activate_update-user/activate_update-user.component';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { NewClientComponent } from './new-client/new-client.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { ClientSubscriptionDetailsComponent } from './client-subscription-details/client-subscription-details.component';
import { AllSubscriptionsComponent } from './all-subscriptions/all-subscriptions.component';
import { MyAssignmentsComponent } from './my-assignments/my-assignments.component';
import { ClientEmailComponent } from './client-email/client-email.component';
import { FirmPreferencesComponent } from './firm-preferences/firm-preferences.component';
import { ClientFilesComponent } from './client-files/client-files.component';
import { ClientServicesListComponent } from './client-services-list/client-services-list.component';
import { BillingListComponent } from './billing-list/billing-list.component';
import { ManageBillingComponent } from './manage-billing/manage-billing.component';
import { ClientServiceDataComponent } from './client-service-data/client-service-data.component';
import { ClientServicesComponent } from './client-services/client-services.component';
import { ClientInvoicesComponent } from './client-invoices/client-invoices.component';
import { CanDeactivateGuard } from './shared/services/can-deactivate.guared';
import { ClientUserComponent } from './client-user/client-user.component';
import { ClientMessagesComponent } from './client-messages/client-messages.component';
import { ClientUserListComponent } from './client-user-list/client-user-list.component';
import { ClientUsersComponent } from './client-users/client-users.component';
import { FirmHomeComponent } from './firm-home/firm-home.component';
import { FirmMessagesComponent } from './firm-messages/firm-messages.component';
import { FirmBillingListComponent } from './firm-billing-list/firm-billing-list.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { BulkClientsCreateComponent } from './bulk-clients-create/bulk-clients-create.component';
import { OrgSignTemplatesComponent } from './org-sign-templates/org-sign-templates.component';
import { OrgSignTemplatesListComponent } from './org-sign-templates-list/org-sign-templates-list.component';
import { OrgNewSignTemplateComponent } from './org-new-sign-template/org-new-sign-template.component';
import { AllTasksComponent } from './all-tasks/all-tasks.component';
import { MultiFactorAuthComponent } from './multi-factor-auth/multi-factor-auth.component';
import { MFAGuard } from './shared/services/MFAGuard';
import { BillingFutureComponentComponent } from './common/components/billing-future-component/billing-future-component.component';
import { FirmServicesComponent } from './firm-services/firm-services.component';
import { ClientEmailInboxComponent } from './client-email-inbox/client-email-inbox.component';
import { ClientEmailSentComponent } from './client-email-sent/client-email-sent.component';
import { FirmFinanceComponent } from './firm-finance/firm-finance.component';
import { FirmEmailSyncComponent } from './firm-email-sync/firm-email-sync.component';
import { ExternalEsignComponent } from './external-esign/external-esign.component';

const routes: Routes = [
  {
    path: 'firm', component: FirmNavigationComponent, children: [
      {
        path: 'my-org', component: MyOrgComponent, children: [
          {
            path: 'org-details', component: OrgDetailsComponent, canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'org-finance', component: FirmFinanceComponent, canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'org-email-sync', component: FirmEmailSyncComponent, canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'org-agreements', component: OrgAgreementsComponent, children: [
              {
                path: 'list', component: OrgAgreementsListComponent
              },
              {
                path: 'create', component: OrgNewAgreementComponent, canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'edit/:agreementId', component: OrgNewAgreementComponent,
                canDeactivate: [CanDeactivateGuard]
              },
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ]
          },
          {
            path: 'org-users', component: OrgUsersComponent, children: [
              {
                path: 'list', component: OrgUsersListComponent
              },
              {
                path: 'create', component: OrgNewUserComponent, canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'New User', label: 'New User' }
                }
              },
              {
                path: 'edit/:userId', component: OrgNewUserComponent, canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'Edit User', label: 'Edit User' }
                }
              },
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ], data: {
              breadcrumb: { name: 'Org Users', label: 'Org Users' }
            }
          },
          {
            path: 'org-services', component: OrgServicesComponent, children: [
              {
                path: 'list', component: OrgServicesListComponent, data: {
                  breadcrumb: { name: 'Services List', label: 'Services List' }
                }
              },
              {
                path: 'create', component: OrgNewServiceComponent, canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'New Service', label: 'New Service' }
                }
              },
              {
                path: 'edit/:serviceId', component: OrgNewServiceComponent, canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'Edit Service', label: 'Edit Service' }
                }
              },
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ], data: {
              breadcrumb: { name: 'Org Services', label: 'Org Services' }
            }
          },
          {
            path: 'org-sign-templates', component: OrgSignTemplatesComponent, children: [
              {
                path: 'list', component: OrgSignTemplatesListComponent, data: {
                  breadcrumb: { name: 'Sign Templates List', label: 'Sign Templates List' }
                }
              },
              {
                path: 'create', component: OrgNewSignTemplateComponent, canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'New Sign Template', label: 'New Sign Template' }
                }
              },
              {
                path: 'edit/:signTemplateId', component: OrgNewSignTemplateComponent, canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'Edit Sign Template', label: 'Edit Sign Template' }
                }
              },
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ], data: {
              breadcrumb: { name: 'Org Sign Templates', label: 'Org Sign Templates' }
            }
          },
          { path: '', redirectTo: 'org-details', pathMatch: 'full' },
          { path: '**', component: ResourceNotFoundComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['firmadmin'], 
          breadcrumb: { name: 'My Org', label: 'My Org' }
        }
      },
      {
        path: 'all-tasks', component: AllTasksComponent, children: [
          {
            path: 'my-assignments', component: MyAssignmentsComponent, data: {
              breadcrumb: { name: 'My Assignments', label: 'My Assignments' }
            }
          },
          {
            path: 'all-subscriptions', component: AllSubscriptionsComponent, data: {
              breadcrumb: { name: 'All Subscriptions', label: 'All Subscriptions' }
            }
          },
          { path: '', redirectTo: 'my-assignments', pathMatch: 'full' }
        ]
      },
      {
        path: 'clients-list', component: ClientsListComponent, data: {
          breadcrumb: { name: 'Clients List', label: 'Clients List' }
        }
      },
      {
        path: 'clients-create', component: NewClientComponent, canDeactivate: [CanDeactivateGuard], data: {
          breadcrumb: { name: 'New Client', label: 'New Client' }
        }
      },
      {
        path: 'bulk-clients-create', component: BulkClientsCreateComponent//, canDeactivate: [CanDeactivateGuard]
        // data: {
        //   breadcrumb: { name: 'Bulk New Client', label: 'Bulk New Client' }
        // }
      },
      {
        path: 'clients/:clientId', component: ClientsComponent, children: [
          {
            path: 'services', component: ClientServiceDataComponent, children: [
              {
                path: 'client-services', component: ClientServicesListComponent, data: {
                  breadcrumb: { name: 'Client Services', label: 'Client Services' }
                }
              },
              {
                path: 'subscription/:subscriptionId', component: ClientSubscriptionDetailsComponent,
                canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'Client Subscription', label: 'Client Subscription' }
                }
              },
              { path: '', redirectTo: 'client-services', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ], data: {
              breadcrumb: { name: 'Client Data', label: 'Client Data' }
            }
          },
          {
            path: 'client-files', component: ClientFilesComponent, data: {
              breadcrumb: { name: 'Client Files', label: 'Client Files' }
            }
          },
          {
            path: 'billing', component: FirmBillingComponent, children: [
              {
                path: 'list', component: BillingListComponent, data: {
                  breadcrumb: { name: 'Billing List', label: 'Billing List' }
                }
              },
              {
                path: 'manage-billing/:scheduleId', component: ManageBillingComponent,
                canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: '', label: 'Delete Invoice' }
                }
              },
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ], data: {
              breadcrumb: { name: 'Billing', label: 'Billing' }
            }
          },
          {
            path: 'client-email', component: ClientEmailComponent, children: [
              {
                path: 'inbox-mail', component: ClientEmailInboxComponent
              },
              {
                path: 'sent-mail', component: ClientEmailSentComponent
              },
              { path: '', redirectTo: 'inbox-mail', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ]
          },
          {
            path: 'client-details', component: ClientDetailsComponent, canDeactivate: [CanDeactivateGuard], data: {
              breadcrumb: { name: 'Client Details', label: 'Client Details' }
            }
          },
          {
            path: 'client-users', component: ClientUsersComponent, children: [
              {
                path: 'list', component: ClientUserListComponent, data: {
                  breadcrumb: { name: 'Client user list', label: 'Client user list' }
                }
              },
              {
                path: 'create', component: ClientUserComponent,
                canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'Create client user', label: 'Create client user' }
                }
              },
              {
                path: 'edit/:userId', component: ClientUserComponent,
                canDeactivate: [CanDeactivateGuard], data: {
                  breadcrumb: { name: 'Edit client user', label: 'Edit client user' }
                }
              },
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: '**', component: ResourceNotFoundComponent }
            ], data: {
              breadcrumb: { name: 'Client Users', label: 'Client Users' }
            }
          },
          {
            path: 'client-messages', component: ClientMessagesComponent, data: {
              breadcrumb: { name: 'Client Messages', label: 'Client Messages' }
            }
          },
          { path: '', redirectTo: 'client-services', pathMatch: 'full' },
          { path: '**', component: ResourceNotFoundComponent }
        ], data: {
          breadcrumb: { name: 'Clients', label: 'Clients' }
        }
      },
      {
        path: 'preferences', component: FirmPreferencesComponent, canDeactivate: [CanDeactivateGuard], data: {
          breadcrumb: { name: 'Home', label: 'Home' }
        }
      },
      {
        path: 'home', component: FirmHomeComponent, data: {
          breadcrumb: { name: 'Home', label: 'Home' }
        }
      },
      {
        path: 'billing', component: FirmBillingComponent, children: [
          {
            path: 'list', component: FirmBillingListComponent, 
            canActivate: [AuthGuard],
            data: {
              roles: ['firmadmin'],
              breadcrumb: { name: 'Billing List', label: 'Billing List' }
            }
          },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: '**', component: ResourceNotFoundComponent }
        ]
      },
      {
        path: 'messages', component: FirmMessagesComponent
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', component: ResourceNotFoundComponent }
    ],
    canActivate: [AuthGuard],
    data: { roles: ['firmadmin', 'firmemployee', 'admin'], breadcrumb: { name: 'Firm', label: 'Firm' } }
  },
  {
    path: 'client', component: FirmNavigationComponent, children: [
      {
        path: 'home', component: ClientHomeComponent, data: {
          breadcrumb: { name: 'Home', label: 'Home' }
        }
      },
      {
        path: 'subscription/:subscriptionId', component: ClientSubscriptionDetailsComponent,
        canDeactivate: [CanDeactivateGuard], data: {
          breadcrumb: { name: 'Subscription', label: 'Subscription' }
        }
      },
      {
        path: 'services', component: ClientServicesComponent, children: [
          {
            path: 'list', component: ClientServicesListComponent, data: {
              breadcrumb: { name: 'Services', label: 'List' }
            }
          },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: '**', component: ResourceNotFoundComponent }
        ], data: {
          breadcrumb: { name: 'Your Subscriptions', label: 'Services' }
        }
      },
      {
        path: 'firm-services', component: FirmServicesComponent, data: {
          breadcrumb: { name: 'Firm Services', label: 'Services' }
        }
      },
      {
        path: 'invoices', component: ClientInvoicesComponent, children: [
          {
            path: 'list', component: BillingListComponent, data: {
              breadcrumb: { name: 'Invoices List', label: 'List' }
            }
          },
          {
            path: 'manage-billing/:scheduleId', component: ManageBillingComponent,
            canDeactivate: [CanDeactivateGuard], data: {
              breadcrumb: { name: 'Delete Invoice', label: 'Delete Invoice' }
            }
          },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: '**', component: ResourceNotFoundComponent }
        ], data: {
          breadcrumb: { name: 'Invoices', label: 'Invoices' }
        }
      }, {
        path: 'files', component: ClientFilesComponent, data: {
          breadcrumb: { name: 'Files List', label: 'List' }
        }
      }, {
        path: 'messages', component: ClientMessagesComponent, data: {
          breadcrumb: { name: 'Client Messages', label: 'Client Messages' }
        }
      },
      {
        path: 'preferences', component: FirmPreferencesComponent, canDeactivate: [CanDeactivateGuard], data: {
          breadcrumb: { name: 'Home', label: 'Home' }
        }
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', component: ResourceNotFoundComponent }
    ],
    canActivate: [AuthGuard],
    data: { roles: ['firmclient'], breadcrumb: { name: 'Client', label: 'Client' } }
  },
  { path: 'login', component: LoginComponent },
  { path: 'multi-factor-auth', component: MultiFactorAuthComponent, canActivate: [MFAGuard] },
  { path: 'activate-user', component: ActivateUserComponent },
  { path: 'resetPassword', component: ActivateUserComponent },
  { path: 'forgetPassword', component: ForgetPasswordComponent },
  { path: 'esign', component: ExternalEsignComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: ResourceNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
