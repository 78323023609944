<div class="flex-row ai-c mb-8">
    <h1 class="scj-h2 mr-20">Bulk Client Upload</h1>

    <div class="flex-row">
        <button mat-flat-button (click)="onSubmit()">
            <mat-icon class="button-icon">file_upload_outlined</mat-icon>
            <span class="button-text">Upload</span>
        </button>
        <button mat-flat-button class="secondary" (click)="goToCancel()">
            <mat-icon class="button-icon">close</mat-icon>
            <span class="button-text">Cancel</span>
        </button>
        <div *ngIf="isSpinner">
            <mat-spinner [diameter]="32" [strokeWidth]="3"></mat-spinner>
        </div>
    </div>
</div>

<div>
    <div class="bulkupload-container">

        <div class="uploadTemplateType">

            <div class="radioInput">
                <mat-radio-button type="radio" value="Individual" (change)="onItemChange($event)"> <label
                        for="Individual">Individual</label>
                </mat-radio-button>
            </div>
            <div class="radioInput">
                <mat-radio-button type="radio" value="Organization" (change)="onItemChange($event)"> <label
                        for="Organization">Organization</label>
                </mat-radio-button>
            </div>
        </div>
        <div class="container">
            <div class="dropzone" appFilesDropZone (filesHovered)="toggleHover($event)" (filesDropped)="onDrop($event)"
                [class.hovering]="isHovering">
                <input type="file" #fileField (change)="onDrop($event.target.files)" accept=".csv" hidden>
                <div class="upload-actions" (click)="fileField.click()">
                    <mat-icon>cloud_upload</mat-icon>
                    Drop files to attach, or <a>browse</a>
                    <mat-icon (click)="toggle(tooltip);$event.stopPropagation()" #tooltip="matTooltip"
                        matTooltip="Upload document with file format .csv">info_outlined
                    </mat-icon>
                </div>
            </div>
            <i> <a href="../../assets/excel-templates/individual_clients_sample.csv">Download</a> Template for
                Individual or <a href="../../assets/excel-templates/business_clients_sample_template.csv">Download</a>
                Template for Organization</i>
        </div>



    </div>

    <div class="thirdContainer">
        <h3> Preview: </h3>
        <div class="previewTable">
            <br>
            <table>
                <thead>
                    <tr>
                        <th *ngFor="let x of csvTableHeader"> {{x}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let y of csvTableData">
                        <td *ngFor="let z of y">{{ z }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>