export const TEXT_SPACE_PATTERN = "^[a-zA-z]+([ ][a-zA-Z]+)*$";

export const NUMBERS_PATTERN = "^[0-9]*$";

export const NUMBERS_WITH_DECIMALS_PATTERN = "(-)?[0-9]*[.]*[0-9]{1,2}";//^(\d+(?:.\d{1,2})?).*";

export const PHONE_NUMBER_PATTERN = "^[0-9\(\)\+ -]*$";

export const TEXT_NUMBERS_PATTERN = "^[a-zA-z0-9 ]*$";

export const SIMPLE_CJ_MAIL_PATTERN = '^[a-z0-9._%+-]+@simplecj.com$';

export const PASSWORD_PATTERN = "^[0-9a-zA-Z~`!@#$%^&*()+=_,.<>?/;:{}|\\\'\"-]{8,}$";