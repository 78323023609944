import { HttpResponse } from '@angular/common/http';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { HttpCommonService } from '../services/http-common.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'scj-firm-home',
  templateUrl: './firm-home.component.html',
  styleUrls: ['./firm-home.component.scss']
})
export class FirmHomeComponent implements OnInit, AfterViewChecked {
  isLoading = true;
  isError = false;
  adminStats: any;
  employeeStats: any;
  mySubStats: any;
  roleAdmin: boolean = true;
  donutColors = {
    domain: ['#006cfa', '#6399df', '#5d7799']
  };
  barColors = {
    domain: ['#FFB900', '#00A4EF']
  };
  isBarChartLoaded: boolean = false;
  isDonutChartLoaded: boolean = false;

  constructor(private http: HttpCommonService, private authService: AuthenticationService) {
    this.roleAdmin = this.authService.userValue.roles.includes('firmadmin');
  }

  ngOnInit() {
    Chart.register(ChartDataLabels);
    if (this.roleAdmin) {
      this.getHomePageAdminData();
    } else {
      this.getMySubscriptionStats();
    }
  }

  ngAfterViewChecked() {
    let barCanvas = document.getElementById('bar-chart') as HTMLCanvasElement;
    if (!this.isLoading && !this.isBarChartLoaded && barCanvas !== null) {
      this.isBarChartLoaded = true;
      let ctx = barCanvas.getContext('2d');
      let barChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.employeeStats.labels,
          datasets: [{
            label: 'Closed',
            data: this.employeeStats.data.closed,
            backgroundColor: [
              '#00A4EF'
            ],
            borderWidth: 1
          },
          {
            label: 'Open',
            data: this.employeeStats.data.open,
            backgroundColor: [
              '#FFB900'
            ],
            borderWidth: 1
          }
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              }
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks:{
                precision: 0
              }
            }
          },
          plugins: {
            legend: {
              display: true
            },
            datalabels: {
              align: 'center',
              anchor: 'center',
              color: '#fff',
              font: {
                weight: 'bold',
                size: 20
              }
            }
          }
        }
      });
    }

    let donutCanvas = document.getElementById('donut-chart') as HTMLCanvasElement;
    if (!this.isLoading && !this.isDonutChartLoaded && donutCanvas !== null) {
      this.isDonutChartLoaded = true;
      let ctx = donutCanvas.getContext('2d');
      let donutChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: this.mySubStats.labels,
          datasets: [{
            data: this.mySubStats.data,
            backgroundColor: [
              '#7FBA00', '#FFB900', '#00A4EF'
            ],
            hoverOffset: 1
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true
            },
            datalabels: {
              align: 'center',
              anchor: 'center',
              color: '#fff',
              font: {
                weight: 'bold',
                size: 20
              }
            }
          }
        }
      });
    }
  }

  getHomePageAdminData() {
    this.http.getHomePageAdminData().subscribe((res: HttpResponse<any[]>) => {
      this.isLoading = false;
      if (res.body !== null) {
        this.adminStats = res.body;
        if (this.adminStats.allEmployeeStats !== null) {
          this.employeeStats = { labels: [], data: { open: [], closed: [] } };
          this.adminStats.allEmployeeStats.forEach((x: any) => {
            this.employeeStats.labels.push(x.name);
            this.employeeStats.data.open.push(x.open > 0 ? x.open : null);
            this.employeeStats.data.closed.push(x.closed > 0 ? x.closed : null);
          });
        }
        if (this.adminStats.mySubscriptionStats !== null) {
          this.mySubStats = { labels: [], data: [] };
          Object.keys(this.adminStats.mySubscriptionStats).forEach(x => {
            this.mySubStats.labels.push(x);
            let val = this.adminStats.mySubscriptionStats[x];
            this.mySubStats.data.push(val > 0 ? val : null);
          });
        }
      }
    }, err => {
      this.isLoading = false;
      this.isError = true;
    });
  }

  getMySubscriptionStats() {
    this.http.getMySubscriptionStats().subscribe((res: HttpResponse<any[]>) => {
      this.isLoading = false;
      if (res.body !== null && res.body.length > 0) {
        this.mySubStats = { labels: [], data: [] };
        res.body.forEach((x: any) => {
          this.mySubStats.labels.push(x.type);
          this.mySubStats.data.push(x.count > 0 ? x.count : null);
        });
      }
    }, err => {
      this.isLoading = false;
      this.isError = true;
    });
  }
}
