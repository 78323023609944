export const GOOGLE_API_KEY = 'AIzaSyDIJ7LUhw4t4bpYvHW7uJQAqLnFLrr5LHk';

export const STATUS_FIELDS = [
    { statKey: 'ACTIVE', statValue: 'Active' },
    { statKey: 'INACTIVE', statValue: 'Inactive' }
];

export const CLIENT_STATUS_FIELDS = [
    { statKey: 'ACTIVE', statValue: 'Active' },
    { statKey: 'INACTIVE', statValue: 'Inactive' },
    { statKey: 'PENDING', statValue: 'Pending' },
];

export const PAYMENT_PLAN_FIELDS = [
    { statKey: 'FULL', statValue: 'Full' },
    { statKey: 'MONTHLY', statValue: 'Monthly' },
    { statKey: 'QUATERLY', statValue: 'Quaterly' },
    { statKey: 'SEMI_ANNUAL', statValue: 'Semi Annual' }
];

export const PAYMENT_TERM = [
    { termKey: 0, termValue: 'Immediate' },
    { termKey: 7, termValue: 'Net 7' },
    { termKey: 30, termValue: 'Net 30' },
    { termKey: 45, termValue: 'Net 45' },
    { termKey: 60, termValue: 'Net 60' },
    { termKey: 90, termValue: 'Net 90' },
    { termKey: 180, termValue: 'Net 180' }
];

export const SUPPORTED_MERGE_FILE_TYPES = [
    'png', 'jpeg', 'jpg', 'pdf', 'svg'
]

export const PAYMENT_METHOD = [
    { paymentKey: "CREDIT_CARD", paymentValue: "Credit Card" },
    { paymentKey: "DEBIT_CARD", paymentValue: "Debit Card" },
    { paymentKey: "PAYPAL", paymentValue: "Paypal" },
    { paymentKey: "CASH", paymentValue: "Cash" },
    { paymentKey: "ACH", paymentValue: "ACH" },
    { paymentKey: "ZELLE", paymentValue: "Zelle" },
    { paymentKey: "OTHER", paymentValue: "Other" }
]

export const FILE_EXTENSION = ["PDF", "TXT", "CSV", "PNG", "JPG", "JPEG", "XLSX", "XLS", "ZIP"];

export const PRIORITY = ["Critical", "High", "Medium", "Low"
    // { priorityKey: "CRITICAL", priorityValue: "Critical" },
    // { priorityKey: "HIGH", priorityValue: "High" },
    // { priorityKey: "MEDIUM", priorityValue: "Medium" },
    // { priorityKey: "LOW", priorityValue: "Low" },
]