<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading" class="flex-column editor">
        <div class="flex-row notes-title">
            <h2 class="scj-subheader">Notes</h2>
            <div class="flex-row">
                <mat-icon *ngIf="!refreshSpinner" (click)="refreshNotes()">refresh</mat-icon>
                <mat-spinner *ngIf="refreshSpinner" [diameter]="24" [strokeWidth]="2"></mat-spinner>
                <mat-icon mat-dialog-close>close</mat-icon>
            </div>
        </div>

        <div class="notes-content" #scroll [scrollTop]="scroll.scrollHeight">
            <ng-container *ngFor="let note of notesList">
                <div class="flex-column notes-body">
                    <div class="flex-row notes-align">
                        <div class="name-highlight">
                            {{note.createdBy | letters}}
                        </div>
                        <div>
                            <div style="display: flex;">
                                <span class="name">{{note.createdBy}}</span>
                                <span class="date">{{note.createdDate}}</span>
                                <!--<mat-icon style="cursor: text;">
                                    {{note.internalOnly? 'public_off_outlined' : 'public_outlined'}}
                                </mat-icon>-->
                            </div>
                            <div [innerHtml]="note.noteText | domSafe"> </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="flex-row notes-input">
            <div #inputMessage placeholder="Add your note" contenteditable="true"></div>
            <!--<mat-icon (click)="isPrivate=!isPrivate" matTooltip="{{isPrivate? 'Private' : 'Public'}}">
                {{isPrivate? 'public_off_outlined' : 'public_outlined'}}
            </mat-icon>-->
            <mat-icon *ngIf="!sendSpinner" (click)="createSubscriptionNotes()">send</mat-icon>
            <mat-spinner *ngIf="sendSpinner" [diameter]="24" [strokeWidth]="2"></mat-spinner>
        </div>
    </div>
</div>