<div class="loader">
    <div class="spinner" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div *ngIf="isError">
        Something went wrong!! Please try after some time
    </div>

    <div *ngIf="!isError && !isLoading">

        <form [formGroup]="tabsFormGroup" (ngSubmit)="onSubmit()">
            <mat-horizontal-stepper #stepper labelPosition="bottom" class="horizontal-stepper-tabs"
                [selectedIndex]="selectedIndex" *ngIf="!(isHandset$ | async)">
                <ng-container *ngFor="let tab of tabs | sort:'asc':'sequence'; let first = first; let last = last">
                    <ng-template #stepperIcon>
                        <mat-icon>{{tab.icon}}</mat-icon>
                    </ng-template>

                    <mat-step [formGroup]="getFormGroup(tabsFormGroup?.controls[tab.name])" class="mat-elevation-z8"
                        [optional]="tab?.isOptional">

                        <ng-template matStepLabel>
                            <div class="step-icon-label">
                                <mat-icon *ngIf="getFormGroup(tabsFormGroup?.controls[tab.name]).invalid"
                                    matTooltip="Please fill in the required information" class="step-invalid">
                                    warning
                                </mat-icon>
                                <mat-icon *ngIf="getFormGroup(tabsFormGroup?.controls[tab.name]).valid"
                                    class="step-valid">
                                    check_circle_outlined_rounded
                                </mat-icon>
                                <div class="step-label">
                                    {{tab.label}}
                                </div>
                            </div>
                        </ng-template>
                        <div class="flex-row stepper-action">
                            <button type="button" *ngIf="!first" mat-flat-button class="secondary"
                                (click)="previousStep(stepper)">
                                <mat-icon class="button-icon">chevron_left</mat-icon>
                                <span class="button-text">Previous</span>
                            </button>
                            <button type="button" mat-flat-button *ngIf="allowSave" (click)="save(stepper, true)">
                                <mat-icon class="button-icon">save_alt</mat-icon>
                                <span class="button-text">Save</span>
                            </button>
                            <!-- <button type="button" mat-flat-button *ngIf="allowSave && !last"
                                    (click)="save(stepper, true)">Save & Continue</button> -->
                            <button type="button" mat-flat-button *ngIf="!last" (click)="nextStep(stepper)">
                                <span class="button-text">Save & Continue</span>
                                <mat-icon class="button-icon">chevron_right</mat-icon>
                            </button>
                        </div>

                        <ng-template *ngTemplateOutlet="stepTemplate; context: {tab : tab}">
                        </ng-template>

                        <div class="flex-row stepper-action" style="margin: 24px 0;">
                            <button type="button" *ngIf="!first" mat-flat-button class="secondary"
                                (click)="previousStep(stepper)">
                                <mat-icon class="button-icon">chevron_left</mat-icon>
                                <span class="button-text">Previous</span>
                            </button>
                            <button type="button" mat-flat-button *ngIf="allowSave" (click)="save(stepper, true)">
                                <mat-icon class="button-icon">save_alt</mat-icon>
                                <span class="button-text">Save</span>
                            </button>
                            <!-- <button type="button" mat-flat-button *ngIf="allowSave && !last"
                                    (click)="save(stepper, true)">Save & Continue</button> -->
                            <button type="button" mat-flat-button *ngIf="!last" (click)="nextStep(stepper)">
                                <span class="button-text">Save & Continue</span>
                                <mat-icon class="button-icon">chevron_right</mat-icon>
                            </button>
                        </div>
                    </mat-step>
                </ng-container>

                <ng-template matStepperIcon="number" let-index="index">
                    <ng-container [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"></ng-container>
                </ng-template>
                <ng-template matStepperIcon="edit" let-index="index">
                    <ng-container [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"></ng-container>
                </ng-template>
                <ng-template matStepperIcon="done" let-index="index">
                    <ng-container [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"></ng-container>
                </ng-template>
                <ng-template matStepperIcon="error" let-index="index">
                    <ng-container [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"></ng-container>
                </ng-template>
            </mat-horizontal-stepper>

            <mat-vertical-stepper class="vertical-stepper-tabs" selectedIndex="0" *ngIf="(isHandset$ | async)"
                (selectionChange)="markAsTouched($event)">
                <ng-container *ngFor="let tab of tabs | sort:'asc':'sequence'; let first = first; let last = last">
                    <mat-step label="{{tab.label}}" state="{{tab.icon}}"
                        [formGroup]="getFormGroup(tabsFormGroup?.controls[tab.name])"
                        [stepControl]="getFormGroup(tabsFormGroup?.controls[tab.name])"
                        errorMessage="Invalid/Insufficient Data" completed="true">

                        <ng-template *ngTemplateOutlet="stepTemplate; context: {tab : tab} ">
                        </ng-template>
                    </mat-step>

                    <ng-template matStepperIcon="{{tab.icon}}">
                        <mat-icon>{{tab.icon}}</mat-icon>
                    </ng-template>
                </ng-container>
            </mat-vertical-stepper>
        </form>
    </div>
</div>

<ng-template #stepTemplate let-tab="tab">
    <mat-accordion class="accordion" multi>
        <ng-container *ngFor="let section of tab.sections | sort:'asc':'sequence'; let index=index">

            <mat-expansion-panel #panel class="expansion-panel" [expanded]="true" hideToggle>
                <mat-expansion-panel-header class="panel-header" *ngIf="!section.isStandalone">
                    <mat-panel-title>
                        <mat-icon>{{!panel.expanded?'chevron_right':'expand_less'}}</mat-icon>
                        {{section.label}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div [formGroup]="getFormGroup(tabsFormGroup.controls[tab.name]?.controls[section.name])"
                    class="section-body" *ngIf="(section.type === 'column')">
                    <div *ngIf="(section.type === 'column') && section.colspan" class="flex-row">
                        <ng-container
                            *ngFor="let element of section.elements | sort:'asc':'sequence'; let first=first; let last=last;">
                            <div class="element" [ngClass]="'colspan-'+section.colspan">
                                <scj-dynamic-elements-form [element]="element"
                                    [elementControl]="getFormGroup(tabsFormGroup.controls[tab.name]?.controls[section.name]?.controls[element.name])">
                                </scj-dynamic-elements-form>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="(section.type === 'column') && !section.colspan">
                        <ng-container
                            *ngFor="let element of section.elements | sort:'asc':'sequence'; let first=first; let last=last;">
                            <div [ngSwitch]="element.type" class="element-span"
                                [ngClass]="'colspan-'+(element.colspan || '1')">

                                <div *ngSwitchCase="'display'" class="display-content">
                                    <div>{{element.label}}</div>
                                </div>

                                <scj-dynamic-elements-form *ngSwitchDefault [element]="element"
                                    [elementControl]="getFormGroup(tabsFormGroup.controls[tab.name]?.controls[section.name]?.controls[element.name])">
                                </scj-dynamic-elements-form>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="section.type === 'table'" class="section-body">
                    <scj-section-table [section]="section" [tableForm]="getFormGroup(tabsFormGroup.controls[tab.name])">
                    </scj-section-table>
                </div>
                <div *ngIf="section.type === 'fileUpload'" class="section-body">
                    <scj-attachments [section]="section"
                        [attachmentsForm]="getFormGroup(tabsFormGroup?.controls[tab.name])"></scj-attachments>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</ng-template>