import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BillingListModel } from '../models/billing-list.model';
import { ColumnFilterConfig, TableColumnsConfig } from '../models/table-columns-config.model';
import { HttpCommonService } from '../services/http-common.service';
import { COLUMN_FILTER_TYPE, COLUMN_FORMAT, COLUMN_TYPES } from '../shared/enums/table.enum';
import { HandSetService } from '../shared/services/hand-set.service';
import { NotificationService } from '../shared/services/notification.service';
import { TableFilterService } from '../shared/services/table-filter.service';

@Component({
  selector: 'scj-firm-billing-list',
  templateUrl: './firm-billing-list.component.html',
  styleUrls: ['./firm-billing-list.component.scss']
})
export class FirmBillingListComponent implements OnInit {
  isHandset$: Observable<boolean>;
  searchControl: FormControl = new FormControl('');
  isLoading = true;
  isError = false;
  billingList: BillingListModel[] = [];
  columnConfig: TableColumnsConfig[] = this.getTableColumnsConfig();
  columnFilterConfig: ColumnFilterConfig[] = this.getColumnFilterConfig();

  constructor(private handSet: HandSetService, private httpService: HttpCommonService, private router: Router,
    private notificationService: NotificationService, private tfService: TableFilterService) {
    this.isHandset$ = this.handSet.isHandset$;
  }

  ngOnInit(): void {
    this.getFirmBillingList();
  }

  getFirmBillingList() {
    this.httpService.getBillingScheduleDetailsForCompany(false).subscribe((res: HttpResponse<BillingListModel[]>) => {
      if (res.body !== null) {
        this.billingList = res.body;
        this.tfService.initializeColumnFilter(this.columnFilterConfig);
      }
      this.isLoading = false;
    }, (err: HttpResponse<any>) => {
      this.isLoading = false;
      this.isError = true;
      this.notificationService.notifyText("Something went wrong!! Please try after some time.");
      console.log("Error", err);
    });
  }

  getTableColumnsConfig(): TableColumnsConfig[] {
    let columns = [
      { name: 'select', header: '' },
      { name: 'clientName', header: 'Client', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING, enableNavigation: true },
      { name: 'serviceName', header: 'Service', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'invoiceNumber', header: 'Invoice Number', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.STRING },
      { name: 'paymentStatus', header: 'Status', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT, class: 'chip' },
      { name: 'scheduleStartDate', header: 'Invoice Date', type: COLUMN_TYPES.DATE_TIME, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'scheduleDueDate', header: 'Due Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE },
      { name: 'scheduleAmount', header: 'Amount', type: COLUMN_TYPES.NUMBER, filterType: COLUMN_FILTER_TYPE.NUMBER, format: COLUMN_FORMAT.CURRENCY },
      { name: 'displayPaymentMethod', header: 'Payment Method', type: COLUMN_TYPES.STRING, filterType: COLUMN_FILTER_TYPE.SELECT },
      { name: 'paymentDate', header: 'Payment Date', type: COLUMN_TYPES.DATE, filterType: COLUMN_FILTER_TYPE.DATE }
    ];
    return columns;
  }

  getColumnFilterConfig(): ColumnFilterConfig[] {
    let colFilters = [
      { name: 'clientName', label: 'Client', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'serviceName', label: 'Service', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'invoiceNumber', label: 'Invoice Number', type: COLUMN_FILTER_TYPE.STRING, value: '' },
      { name: 'paymentStatus', label: 'Status', type: COLUMN_FILTER_TYPE.SELECT, value: ['Active', 'Closed'] },
      { name: 'scheduleStartDate', label: 'Invoice Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'scheduleDueDate', label: 'Due Date', type: COLUMN_FILTER_TYPE.DATE, value: [] },
      { name: 'scheduleAmount', label: 'Amount', type: COLUMN_FILTER_TYPE.NUMBER, value: [], format: COLUMN_FORMAT.CURRENCY },
      { name: 'displayPaymentMethod', label: 'Payment Method', type: COLUMN_FILTER_TYPE.SELECT, value: ['Credit Card', 'Debit Card', 'Paypal', 'Cash', 'ACH', 'Other'] },
      { name: 'paymentDate', label: 'Payment Date', type: COLUMN_FILTER_TYPE.DATE, value: [] }
    ]
    return colFilters;
  }

  navigate(record: BillingListModel) {
    this.router.navigateByUrl(`/firm/clients/${record.clientId}/billing/manage-billing/${record.scheduleId}`);
  }

  openTableFilter() {
    this.tfService.openTableFilter();
  }
}
